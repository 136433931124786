import {
  LOAD_ALL_COURSES_REQUEST,
  LOAD_ALL_COURSES_SUCCESS,
  LOAD_ALL_COURSES_FAIL,
  ADD_COURSE_REQUEST,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_RESET,
  ADD_COURSE_FAIL,
  COURSE_DETAIL_REQUEST,
  COURSE_DETAIL_SUCCESS,
  COURSE_DETAIL_FAIL,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_RESET,
  UPDATE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminCourseConstants";

export const coursesReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_COURSES_REQUEST:
      return {
        user_loading: true,
        courses: [],
      };

    case LOAD_ALL_COURSES_SUCCESS:
      return {
        user_loading: false,
        courses: action.payload.data,
      };

    case LOAD_ALL_COURSES_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case UPDATE_COURSE_REQUEST:
      return {
        ...state,
        user_loading: true,
        errors: null,
      };

    case UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_COURSE_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_COURSE_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const courseDetailReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case COURSE_DETAIL_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case COURSE_DETAIL_SUCCESS:
      return {
        user_loading: true,
        course: action.payload,
      };

    case COURSE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_COURSE_REQUEST:
      return {
        user_loading: true,
        isCourseAdded: false,
        errors: null,
      };

    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isCourseAdded: true,
        course: action.payload,
        errors: null,
      };

    case ADD_COURSE_RESET:
      return {
        ...state,
        isCourseAdded: false,
        errors: null,
      };

    case ADD_COURSE_FAIL:
      return {
        ...state,
        user_loading: false,
        isCourseAdded: false,
        course: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COURSE_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_COURSE_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_COURSE_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
