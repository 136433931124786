import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useAlert } from 'react-alert';

import Loader from '../Loader';

import './mock.css';

const StartMock = ({ history, match }) => {

	const examId = match.params.id;
	const examType = localStorage.getItem("examType");

	const proceedToExam = (e) => {
		e.preventDefault();
		//history.push(`/mock-exam/${examId}`); 
		//return false;
		// window.open("http://trustseal.enamad.ir/Verify.aspx?id=15288&p=nbpdyncrwkynaqgwaqgw", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30");
		var h = window.screen.height;
		var w = window.screen.width;
		var t = window.location.href;
		if (examType === "theory") {
			var r = t.replace('start-mock', 'theories-exam');
		} else {
		var r = t.replace('start-mock', 'mock-exam');

			// var r = t.replace('start-mock', 'objective-exam');
		}
		window.open(r, 'window', 'toolbar=no, menubar=no, resizable=no,width=' + w + ',height=' + h);

        history.push(`/dashboard`);
        return false;
	};

	return (
		<Fragment>
			<section className="start-mock-sec text-center">
				<div className="container start-mock">
					<button type="button" className="btn start-mock-btn" onClick={proceedToExam}>
						Click to Start   &nbsp;<i className="fas fa-arrow-right" />
					</button>
				</div>
			</section>
		</Fragment>
	);
};

export default StartMock;
