import {
  LIST_UNIVERSITY_FAIL,
  LIST_UNIVERSITY_REQUEST,
  LIST_UNIVERSITY_SUCCESS,

  ADD_UNIVERSITY_FAIL,
  ADD_UNIVERSITY_REQUEST,
  ADD_UNIVERSITY_RESET,
  ADD_UNIVERSITY_SUCCESS,

  UNIVERSITY_DETAIL_FAIL,
  UNIVERSITY_DETAIL_REQUEST,
  UNIVERSITY_DETAIL_SUCCESS,

  UPDATE_UNIVERSITY_FAIL,
  UPDATE_UNIVERSITY_REQUEST,
  UPDATE_UNIVERSITY_RESET,
  UPDATE_UNIVERSITY_SUCCESS,

  DELETE_UNIVERSITY_FAIL,
  DELETE_UNIVERSITY_REQUEST,
  DELETE_UNIVERSITY_SUCCESS,

  CLEAR_ERRORS,
} from "./../constants/adminUniversityConstants";

const initialState = {
  loading: false,
  error: null,
  universityList: [],
  university: {},
  isUniversityAdded: false,
  isUniversityUpdated: false,
  isUniversityDeleted: false,
};

const universityReducer = (state = initialState, action) => {
  switch (action.type) {
    // List University cases
    case LIST_UNIVERSITY_REQUEST:
      return {
        ...state,
        loading: true,
        universityList: [],
      };

    case LIST_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        universityList: action.payload.data,
      };

    case LIST_UNIVERSITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Update University cases
    case UPDATE_UNIVERSITY_REQUEST:
      return {
        ...state,
        isUniversityUpdated: false,
        loading: true,
        errors: null,
      };

    case UPDATE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUniversityUpdated: true,
        university: action.payload,
      };

    case UPDATE_UNIVERSITY_RESET:
      return {
        ...state,
        loading: false,
        isUniversityUpdated: false,
        errors: null,
      };

    case UPDATE_UNIVERSITY_FAIL:
      return {
        ...state,
        loading: false,
        isUniversityUpdated: false,

        error: action.payload,
        errors: action.errors,
      };

    // University Detail and Add University cases
    case UNIVERSITY_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UNIVERSITY_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        university: action.payload,
      };

    case UNIVERSITY_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_UNIVERSITY_REQUEST:
      return {
        ...state,
        loading: true,
        isUniversityAdded: false,
        errors: null,
      };

    case ADD_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUniversityAdded: true,
        university: action.payload,
        errors: null,
      };

    case ADD_UNIVERSITY_RESET:
      return {
        ...state,
        loading: false,
        isUniversityAdded: false,
        errors: null,
      };

    case ADD_UNIVERSITY_FAIL:
      return {
        ...state,
        loading: false,
        isUniversityAdded: false,
        university: null,
        error: action.payload,
        errors: action.errors,
      };

    // Delete University cases
    case DELETE_UNIVERSITY_REQUEST:
      return {
        ...state,
        loading: true,
        isUniversityDeleted: false,
      };

    case DELETE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUniversityDeleted: true,
        university: action.payload,
      };

    case DELETE_UNIVERSITY_FAIL:
      return {
        ...state,
        isUniversityDeleted: false,
        loading: false,
        error: action.payload,
      };

    // Clear Errors case
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default universityReducer;
