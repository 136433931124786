import React, { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { resetPassword, clearErrors } from "../../../actions/authActions";

const AdminResetPassword = ({ history, match }) => {
  const alert = useAlert();

  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [errorData, setErrorData] = useState({});

  const { error, success, passwordErrors } = useSelector(
    (state) => state.forgotPassword
  );

  useEffect(() => {
    if (passwordErrors) {
      setErrorData(passwordErrors);
      // console.log("loginErrors ", loginErrors); return;
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Password updated successfully");
      history.push("/admin/login");
    }
  }, [dispatch, alert, error, success, history]);

  const submitHandler = (e) => {
    e.preventDefault();
    //submit the form

    dispatch(resetPassword(match.params.token, password, confirm_password));
  };
  return (
    <Fragment>
      <div className="login-box">
        <div className="login-logo">
          <Link to="#">
            <b>One Time </b>SUCCESS
          </Link>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Reset / New Password</p>

          <form onSubmit={submitHandler}>
            <div className="form-group has-feedback">
              <input
                type="password"
                className="form-control"
                autoComplete="off"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
              {errorData.password ? (
                <span className="text-danger">{errorData.password}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group has-feedback">
              <input
                type="password"
                className="form-control"
                autoComplete="off"
                placeholder="Confirm Password"
                name="confirm_password"
                value={confirm_password}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
              {errorData.confirm_password ? (
                <span className="text-danger">
                  {errorData.confirm_password}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-xs-12">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </div>
          </form>
          <br />
          {/*<div className="social-auth-links text-center">
                <Link to="/admin/login">Back to login</Link>
              </div>*/}
        </div>
        <br />
      </div>
    </Fragment>
  );
};

export default AdminResetPassword;
