import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAlert } from 'react-alert';
import moment from 'moment';
import Loader from '../Loader';
import CustomDataTable from '../Datatable/CustomDataTable';
import { getSavedSubjects } from '../../actions/subjectActions';

import './subject.css';
import { getSavedExamSubjects, saveExamSubjects } from '../../actions/admin/examSubjectAction';
import { confirmAlert } from 'react-confirm-alert';
import EmailForm from '../EmailForm/EmailForm';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getUserSubscription } from '../../actions/subscriptionActions';

const SavedExamSubjects = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const { userSubscription, sloading } = useSelector((state) => state.examSubscriptions);

    const [rows, setRows] = useState([]);

    const userId = user?._id;
    const router = useHistory();

    const examId = localStorage.getItem('examId');
    const examName = localStorage.getItem("examName");
    const examSlug = localStorage.getItem("examSlug");
    const courseId = localStorage.getItem('courseId');
    const categoryId = localStorage.getItem('categoryId');
    let ifRegSubjects = localStorage.getItem('RegSubjects')
    const maximumSubjects = localStorage.getItem("maximumSubjects");
    // const userSubscription = localStorage.getItem('userSubscription');



    // const { csloading } = useSelector((state) => state.courseSubjects);
    const { savedSubjects, csloading } = useSelector((state) => state.examSubjects);

    const [subjects, setSubjects] = useState([]);
    const [currentRow, setCurrentRow] = useState("");
    const [proceedSubjects, setProceedSubjects] = useState([]);
    const [registeredSubject, setRegisteredSubject] = useState([]);
    const [updateRequest, setUpdateRequest] = useState([]);
    const [maxSubjects, setMaxSubjects] = useState("");
    const [currSubjects, setCurrSubjects] = useState([]);
    // const [examIds, setExamIds] = useState("");
    useEffect(
        () => {
            if (userId) {
                dispatch(getSavedExamSubjects(userId));
            }
            if (userId && examId) {
                dispatch(getUserSubscription(userId, examId));
            }

        },
        [dispatch, alert, user]
    );

    useEffect(() => {
        let temp = [];
        let temp1 = [];
        let tempSub = [];
        if (savedSubjects && savedSubjects?.length > 0) {
            savedSubjects?.map((item, index) => {
                tempSub.push({ itemId: item?._id, examSlug: item?.exam?.slug, subjects: [] })
                // const tempSub = [...currSubjects,]
                const Max = item?.exam?.maximumSubjects;
                // setExamIds(item?.exam?._id)
                setMaxSubjects(Max)
                item?.subjectsProcessed?.map((item) => {
                    temp.push(item)
                })
                setProceedSubjects(temp)
                // localStorage.setItem("processedSubjects", temp)

                item.subjects?.map((item) => {
                    temp1.push(item?.subject?._id)
                })
                setRegisteredSubject(temp1);

                const update = item?.subjectsUpdate ? item?.subjectsUpdate : [];
                setUpdateRequest(update)

            })
            setCurrSubjects(tempSub);


        }
        return () => {

        }
    }, [savedSubjects, sloading])


    const handleCheck = (e, subject) => {
        const CurrentSubjectIndex = e.target.dataset.index;
        const temp = [...currSubjects]
        const updatedArr = temp && temp?.length > 0 && temp?.map((item) => {
            let checkArray;
            if (item?.itemId === CurrentSubjectIndex) {
                checkArray = [...item?.subjects, e.target.value];
                if (item?.subjects.includes(e.target.value)) {
                    checkArray = checkArray.filter((sub) => sub !== e.target.value);
                }
                return { ...item, subjects: checkArray };
            }
            return item;
            // Rest of your code...
        });
        setCurrSubjects(updatedArr)

    };

    const generateStyledSubjects = (subjectIds, examId) => {
        // Assuming savedSubjects is an array
        const result = savedSubjects
            ?.filter(element => element?.exam?._id === examId)
            .map(element => {
                return subjectIds.map((id, index) => {
                    const subject = element.subjects.find(c => c.subject?._id === id);
                    const isMandatory = subject?.mandetory === true;

                    // Use a variable to store the JSX element
                    const jsxElement = (
                        <span key={id} style={{ fontWeight: isMandatory ? 'bold' : 'normal', color: isMandatory ? 'red' : 'black' }}>
                            <strong>({index + 1}) </strong>{subject ? subject.subject.title : ''}{' '}
                        </span>
                    );

                    return jsxElement;
                });
            });

        return result;
    };

    console.log('userSubscription:', userSubscription);

    const handleCustomize = (item) => {
        localStorage.setItem("examId", item?.exam?._id);
        localStorage.setItem("examSlug", item?.exam?.slug);
        localStorage.setItem("examName", item?.exam?.title);
        localStorage.setItem("examinationType", item?.exam?.examType);
        localStorage.setItem("categoryId", item?.category?._id);
        localStorage.setItem("courseId", item.course?._id);
        localStorage.setItem("userSubscription", userSubscription?._id);
        localStorage.setItem("maximumSubjects", item?.exam?.maximumSubjects);

        const temp = [];

        item?.subjects?.length > 0 && item?.subjects?.map((sub) => {
            temp.push(sub?.subject?._id)
        })

        localStorage.setItem("processedSubjects", temp);
        localStorage.setItem("subjects", temp);

        // if(examSlug == 'jamb-utme'){
        //     const temp = [];
        //     item?.subj
        // }

        if (item.subjectsProcessed?.length == item?.exam?.maximumSubjects) {
            history.push(`/exam-type`);
        } else {
            router.push(`/course-subjects`)
        }
    }

    const handleSubmit = (e, item) => {
        console.log('e:', e);
        console.log('item:', item);

        e.preventDefault();

        if (!userSubscription || userSubscription?._id == null || userSubscription?._id == undefined) {
            confirmAlert({
                title: 'Error',
                message: (
                    <div>
                        <p>
                            Subscription id {userSubscription?._id} missing.
                        </p>

                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
        }
        const CurrentSubjectIndex = e.target.dataset.id;
        const CurrentSubj = currSubjects?.find((item) => item?.itemId == CurrentSubjectIndex);
        if (CurrentSubj && CurrentSubj?.subjects?.length != 0) {
            const selectedSubjectsInfo = generateStyledSubjects(CurrentSubj?.subjects, item?.exam?._id);
            confirmAlert({
                title: 'Confirmation',
                message: (
                    <div>
                        <p>
                            Are you sure you want to register these <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>{selectedSubjectsInfo}</strong> subjects?
                        </p>


                        <p>
                            Click "Yes" to confirm.
                        </p>
                        <p className="bg-danger" style={{ color: 'red' }}>
                            Note: You cannot change the selected subject after proceed.
                        </p>
                    </div>
                ),
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {

                            const checkArray = CurrentSubj?.subjects/* ... your logic to create checkArray ... */;

                            let proceedArray = item?.subjectsProcessed;

                            proceedArray = [...proceedArray, ...checkArray.filter(item => !proceedArray.includes(item))];
                            setProceedSubjects(proceedArray)

                            const payload = {
                                userId: user._id,
                                examId: examId ? examId : item?.exam?._id,
                                categoryId: categoryId ? categoryId : item?.category?.id,
                                courseId: courseId ? courseId : item?.course?.id,
                                userSubscription: item?.subscriptionPlan?._id,
                                subjects: [],
                                subjectsProcessed: proceedArray,
                                subjectsUpdate: [],
                                updateRequest: false
                            }
                            // console.log("payload at process sub", payload)

                            // console.log("payload",)
                            dispatch(saveExamSubjects(
                                payload
                            ))
                            if (item?.subscriptionPlan?.planName === 'Trial') {
                                localStorage.setItem("questionCount", item?.subscriptionPlan?.numOfQuestions);
                            } else {
                                localStorage.removeItem("questionCount");
                            }
                            // history.push(`/saved-subjects`);
                            localStorage.setItem("maximumSubjects", item?.exam?.maximumSubjects);
                            localStorage.setItem("examId", item?.exam?._id);
                            localStorage.setItem("examSlug", item?.exam?.slug);
                            localStorage.setItem("examName", item?.exam?.title);
                            localStorage.setItem("examinationType", item?.exam?.examType);
                            localStorage.setItem("categoryId", item?.category?._id);
                            localStorage.setItem("courseId", item.course?._id);
                            // localStorage.setItem("userSubscription", userSubscription?._id);
                            localStorage.setItem("userSubscription", item?.subscriptionPlan?._id);
                            localStorage.setItem("subjects", registeredSubject);
                            history.push(`/exam-type`);
                        },
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            // Handle user's cancellation
                        },
                    },
                ],
            });

        } else if (subjects?.length == 0) {
            confirmAlert({
                title: 'Error',
                message: (
                    <div>
                        <p>
                            Please select at least one subject to proceed.
                        </p>

                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
        }
    };

    const tableHeader = [
        {
            title: 'ID',
            prop: 'id',
            sortable: true,
            filterable: true
        },
        {
            title: 'Exam',
            prop: 'exam',
            sortable: true,
            filterable: true
        },
        {
            title: 'University',
            prop: 'university',
            sortable: true,
            filterable: true
        },
        {
            title: 'Category',
            prop: 'category',
            sortable: true,
            filterable: true
        },
        {
            title: 'Course',
            prop: 'course',
            sortable: true,
            filterable: true
        },
        {
            title: 'Subscription Plan',
            prop: 'subscriptionPlan',
            sortable: true,
            filterable: true
        },
        {
            title: 'Subjects',
            prop: 'subjects',
            sortable: true,
            filterable: true
        },
        {
            title: 'Date',
            prop: 'date',
            sortable: true,
            filterable: true
        },
        { title: 'Action', prop: 'action' }
    ];


    var tableRowData = [];
    if (savedSubjects !== undefined && savedSubjects.length > 0) {
        savedSubjects.map((item, key) => {
            let itemData = {
                id: key + 1,
                exam: item.exam?.title,
                university: item.subscriptionPlan?.university?.title,
                category: item.category?.title,
                course: item.course?.title,
                subscriptionPlan: <span>{item.subscriptionPlan?.planName} {item.subscriptionPlan?.subscriptionType}</span>,
                subjects: <ul className="subject-list">
                    {item.subjects.length > 0 &&
                        item.subjects.map((subject, iKey) => {
                            return (
                                <li id={iKey}>
                                    {!item?.subjectsProcessed.includes(subject?.subject?.id) &&
                                        <input
                                            type="checkbox"
                                            name="subject"
                                            id={`subject-${subject?.subject?.id}-${key}`}
                                            onClick={(e) => handleCheck(e, subject)}
                                            value={subject?.subject?.id}
                                            data-index={item._id}
                                            data-exam={item.exam?.slug}
                                        // checked={subject?.CurrentSubjectIndex == item?._id}
                                        />
                                    }{' '}
                                    <label htmlFor={`subject-${subject?.subject?.id}-${key}`}>{subject?.subject?.title} {item?.subjectsProcessed.includes(subject?.subject?.id) && <i className="fa fa-check" style={{ color: "green" }} />} </label>
                                </li>
                            );
                        })}
                </ul>,
                date: moment(item.createdAt).format('YYYY-MM-DD'),
                action:
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <button
                            className="btn btn-primary"
                            onClick={(e) => handleSubmit(e, item)}
                            // disabled={updateRequest?.length > 0 ? true : false}
                            data-id={item._id}
                            data-subjects={item.subjects}
                            data-idx={key}
                            data-exam={item?.exam?.slug}
                            disabled={item.subjectsProcessed?.length == item?.exam?.maximumSubjects ? true : false}
                        >
                            {item.subjectsProcessed?.length !== item?.exam?.maximumSubjects ? "Register" : "Subjects Registered"}
                        </button>

                        <button
                            className="btn btn-warning"
                            style={{ marginTop: "2rem" }}
                            onClick={() => handleCustomize(item)}
                            data-id={item._id}
                            data-subjects={item.subjects}
                            data-idx={key}
                        // disabled={item.subjectsProcessed?.length == item?.exam?.maximumSubjects}
                        >
                            {item.subjects?.length !== item?.exam?.maximumSubjects ? "Choose Remaining Subjects" :
                                item.subjectsProcessed?.length < item?.exam?.maximumSubjects ?
                                    "Exchange Selected Subjects" : "Go To Exam"}
                        </button>

                        <Link to={'/saved-subjects-update'}
                            className="btn btn-warning"
                            onClick={() => {

                                localStorage.setItem("maximumSubjects", item?.exam?.maximumSubjects);
                                localStorage.setItem("courseId", item?.course?._id)
                                localStorage.setItem("examId", item?.exam?._id);
                                localStorage.setItem("examSlug", item?.exam?.slug);
                                localStorage.setItem("examName", item?.exam?.title);
                                localStorage.setItem("examinationType", item?.exam?.examType);
                                localStorage.setItem("categoryId", item?.category?._id);
                                localStorage.setItem("courseId", item.course?._id);
                                localStorage.setItem("userSubscription", item?.subscriptionPlan?._id);


                            }
                            }


                            disabled={item?.updateRequest ? true : item?.subjectsProcessed?.length !== item?.exam?.maximumSubjects ? true : false}
                            style={{ marginTop: "2rem" }}>

                            {item?.updateRequest ? "Subjects Already Changed" : "Request For Subject Change"}
                        </Link>
                    </div>

            };
            tableRowData.push(itemData);
        });
    }

    const onSortFunction = {
        date(columnValue) {
            // Convert the string date format to UTC timestamp
            // So the table could sort it by number instead of by string
            return moment(columnValue, 'Do MMMM YYYY').valueOf();
        }
    };

    const customLabels = {
        first: '<<',
        last: '>>',
        prev: '<',
        next: '>',
        show: 'Display',
        entries: 'rows',
        noResults: 'There are no data to be displayed'
    };

    // console.log("LOADING max", csloading)

    return (
        <Fragment>
            {csloading ? (
                <Loader />
                // <div>Loading..</div>
            ) : (
                <Fragment>
                    <div className="right-auth-landing">
                        <div className="main-view-content">
                            <div className="all-ourt-style work-info">
                                <div className="all-heads">
                                    <h3>Saved Exams Subjects</h3>
                                </div>

                                <CustomDataTable
                                    tableHeaders={tableHeader}
                                    tableBody={tableRowData}
                                    rowsPerPage={10}
                                    rowsPerPageOption={[10, 20, 30, 40]}
                                    initialSort={{ prop: 'course', isAscending: true }}
                                    onSort={onSortFunction}
                                    labels={customLabels}
                                />

                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default SavedExamSubjects;
