import React, { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { adminForgotPassword, clearErrors } from "../../../actions/authActions";

import "../../../assets/admin/dist/css/blue.css";
import "./login.css";

const AdminForgotPassword = () => {
  const alert = useAlert();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const [errorData, setErrorData] = useState({});

  const { error, loading, message, passwordErrors } = useSelector(
    (state) => state.forgotPassword
  );

  useEffect(() => {
    if (passwordErrors) {
      setErrorData(passwordErrors);
      // console.log("loginErrors ", loginErrors); return;
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (message) {
      alert.success(message);
    }
  }, [dispatch, alert, error, message, passwordErrors]);

  const submitHandler = (e) => {
    e.preventDefault();
    //submit the form
    //const formData = new FormData();
    //formData.append('email', email);

    dispatch(adminForgotPassword(email));
  };

  return (
    <Fragment>
      <div className="login-box">
        <div className="login-logo">
          <Link to="#">
            <b>One Time </b>SUCCESS
          </Link>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Forgot Password</p>

          <form onSubmit={submitHandler}>
            <div className="form-group has-feedback">
              <input
                type="email"
                className="form-control"
                autoComplete="off"
                placeholder="Enter Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
              {errorData.email ? (
                <span className="text-danger">{errorData.email}</span>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-xs-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading ? true : false}
                >
                  Send Email
                </button>
              </div>
            </div>
          </form>
          <br />
          <div className="social-auth-links text-center">
            <Link to="/admin/login">Back to login</Link>
          </div>
        </div>
        <br />
      </div>
    </Fragment>
  );
};

export default AdminForgotPassword;
