import axios from "axios";
import {
  GET_ALL_BANNERS,
  ADD_BANNER_REQUEST,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_RESET,
  ADD_BANNER_FAILED,
  GET_SINGLE_BANNER,
  UPDATE_BANNER_REQUEST,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAILED,
  DELETE_BANNER,
  CLEAR_ERRORS,
} from "./../../constants/admin/bannerConstants";

const _api_path = "/api/v1";
//get all banners
export const getBanners = () => async (dispatch) => {
  const { data } = await axios.get(_api_path + "/admin/banners");
  // console.log('data', data);
  dispatch({
    type: GET_ALL_BANNERS,
    payload: data,
  });
};

//Add Baner
export const addBanner =
  (title, description, btnUrl, btnName, status, image, imageName) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_BANNER_REQUEST,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        _api_path + "/admin/banners/add",
        // bannerData,
        {
          title,
          description,
          btnUrl,
          btnName,
          status,
          image,
          imageName,
        },
        config
      );

      dispatch({
        type: ADD_BANNER_SUCCESS,
        payload: data.banner,
      });
    } catch (error) {
      var errorMsg = error.response.data.errMessage;
      dispatch({
        type: ADD_BANNER_FAILED,
        payload: errorMsg,
        errors: error.response.data,
      });
    }
  };

// Update banner
export const getBannerDetail = (bannerId) => async (dispatch) => {
  const { data } = await axios.get(_api_path + `/admin/banners/${bannerId}`);

  dispatch({
    type: GET_SINGLE_BANNER,
    payload: data.banner,
  });
};

// Update banner
export const updateBanner =
  (
    bannerId,
    title,
    description,
    btnUrl,
    btnName,
    status,
    oldImage,
    image,
    imageName
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_BANNER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.put(
        _api_path + `/admin/banners/update/${bannerId}`,
        {
          bannerId,
          title,
          description,
          btnUrl,
          btnName,
          status,
          oldImage,
          image,
          imageName,
        },
        config
      );

      dispatch({
        type: UPDATE_BANNER_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      var errorMsg = error.response.data.errMessage;
      if (
        error.response.data.error !== undefined &&
        error.response.data.error.code === 11000
      ) {
        errorMsg = "Entered email already exist, please try another.";
      }
      dispatch({
        type: UPDATE_BANNER_FAILED,
        payload: errorMsg,
        errors: error.response.data,
      });
    }
  };

// Delete banner
export const deleteBannerData = (bannerId) => async (dispatch) => {
  const { data } = await axios.delete(_api_path + `/admin/banners/${bannerId}`);
  dispatch({
    type: DELETE_BANNER,
    payload: bannerId,
  });
  dispatch(getBanners());
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
