import axios from "axios";
import {
    LIST_UNIVERSITY_REQUEST,
    LIST_UNIVERSITY_SUCCESS,
    LIST_UNIVERSITY_FAIL,

    ADD_UNIVERSITY_REQUEST,
    ADD_UNIVERSITY_SUCCESS,
    ADD_UNIVERSITY_RESET,
    ADD_UNIVERSITY_FAIL,


    UNIVERSITY_DETAIL_REQUEST,
    UNIVERSITY_DETAIL_SUCCESS,
    UNIVERSITY_DETAIL_FAIL,


    UPDATE_UNIVERSITY_REQUEST,
    UPDATE_UNIVERSITY_SUCCESS,
    UPDATE_UNIVERSITY_RESET,
    UPDATE_UNIVERSITY_FAIL,

    DELETE_UNIVERSITY_REQUEST,
    DELETE_UNIVERSITY_SUCCESS,
    DELETE_UNIVERSITY_FAIL,
    CLEAR_ERRORS,
} from "../constants/adminUniversityConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all universitys
export const getUniversityList = () => async (dispatch) => {
    try {
        dispatch({
            type: LIST_UNIVERSITY_REQUEST,
        });

        const { data } = await axios.get(`${_api_path}/admin/university/list`);

        dispatch({
            type: LIST_UNIVERSITY_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_UNIVERSITY_FAIL,
            payload: error,
        });
    }
};

//get university detail
export const getUniversityDetail = (id) => async (dispatch) => {
    try {
        dispatch({
            type: UNIVERSITY_DETAIL_REQUEST,
        });

        const { data } = await axios.get(`/api/v1/admin/university/${id}`);
        dispatch({
            type: UNIVERSITY_DETAIL_SUCCESS,
            payload: data.data,
        });
    } catch (error) {
        dispatch({
            type: UNIVERSITY_DETAIL_FAIL,
            payload: error.response.data.errMessage,
        });
    }
};

// add university
export const addUniversity = (title,
    description,
    status,
    logo,
    imageName) => async (dispatch) => {

        try {
            dispatch({
                type: ADD_UNIVERSITY_REQUEST,
            });
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            const { data } = await axios.post(
                _api_path + "/admin/university/add",
                {
                    title,
                    description,
                    status,
                    logo,
                    imageName
                },
                config
            );

            dispatch({
                type: ADD_UNIVERSITY_SUCCESS,
                payload: data.user,
            });
        } catch (error) {

            dispatch({
                type: ADD_UNIVERSITY_FAIL,
                payload: error.response.data,
                errors: error.response.data,
            });
        }
    };

// Update university universityId,

export const updateUniversity = (universityId,
    title,
    description,
    status,
    logo,
    imageName,) => async (
        dispatch
    ) => {
        try {
            dispatch({
                type: UPDATE_UNIVERSITY_REQUEST,
            });

            const config = {

                headers: {
                    "Content-Type": "application/json",
                },
            };
            const { data } = await axios.put(
                _api_path + `/admin/university/edit/${universityId}`,
                {
                    universityId,
                    title,
                    description,
                    status,
                    logo,
                    imageName,
                },
                config
            );

            dispatch({
                type: UPDATE_UNIVERSITY_SUCCESS,
                payload: data.success,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_UNIVERSITY_FAIL,
                payload: error.response.data.errMessage,
                errors: error.response.data,
            });
        }
    };

// Delete university
export const deleteUniversity = (universityId) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_UNIVERSITY_REQUEST,
        });

        const { data } = await axios.delete(
            _api_path + `/admin/university/${universityId}`
        );

        dispatch({
            type: DELETE_UNIVERSITY_SUCCESS,
            payload: universityId,
        });
    } catch (error) {
        dispatch({
            type: DELETE_UNIVERSITY_FAIL,
            payload: error,
        });
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};
