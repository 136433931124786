import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";

// Using an ES6 transpiler like Babel
import Slider from "react-rangeslider";
// To include the default styles
import "react-rangeslider/lib/index.css";

import {
    getSelectedSubjects,
    getSubjectById,
} from "./../../actions/subjectActions";

import { getAssessmentById } from "./../../actions/assessmentActions";

import Loader from "../Loader";
import "./RegSubjects.css";

import { getYearQuestions } from "./../../actions/questionActions";
import { getMockExamQuestions } from "../../actions/mockActions";
import { getUniversityList } from "../../actions/adminUniversityAction";
import { getSavedExamSubjects } from "../../actions/admin/examSubjectAction";
import { getUserSubscription } from "../../actions/subscriptionActions";

const RegisteredSubjects = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const { savedSubjects, isSaved } = useSelector((state) => state.examSubjects);
    const [subjectType, setSubjectType] = useState("");
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedUniversity, setSelectedUniversity] = useState([]);
    const [sliderValue, setSliderValue] = useState(1999);
    const [sliderQueVal, setSliderQueVal] = useState(100);
    const examId = localStorage.getItem("examId");
    const examType = localStorage.getItem("examType");
    const examSlug = localStorage.getItem("examSlug");
    const utmeSubjects = localStorage.getItem("utmeSubjects");
    const maximumSubjects = localStorage.getItem("maximumSubjects");
    const categoryId = localStorage.getItem("categoryId");
    const subjects = localStorage.getItem("subjects");
    const ProcessSub = localStorage.getItem("processedSubjects");
    const assessmentId = localStorage.getItem("assessmentId");
    const assessmentType = localStorage.getItem("assessmentSlug");
    const assessmentName = localStorage.getItem("assessmentName");
    const subscriptionId = localStorage.getItem("userSubscription");
    // localStorage.setItem("noOfQuestion", sliderQueVal);
    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const userId = user._id;
    var minYear = 1999;
    const currentYear = new Date().getFullYear();
    const { userSubscription } = useSelector((state) => state.examSubscriptions);

    useEffect(() => {
        dispatch(getUserSubscription(user._id, examId));

        dispatch(getAssessmentById(assessmentId));
        dispatch(getSavedExamSubjects(userId));
        // if (examSlug == "post-utme") {
        // dispatch(getSelectedSubjects(subjects));
        // }
    }, [dispatch, alert, isSaved]);

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);


    const { csloading } = useSelector(
        (state) => state.courseSubjects
    );

    var objectiveTime = 180;
    var objectiveTimeLabel = "03 Hours 00 Min";
    if (assessmentType === "mock") {
        objectiveTime = 60;
        objectiveTimeLabel = "01 Hour";
    }
    const [time, setTime] = useState(objectiveTime);
    const [timeLabel, setTimeLabel] = useState(objectiveTimeLabel);

    const [subjectSelections, setSubjectSelections] = useState([]);
    const [postUtmeSubjects, setPostUtmeSubjects] = useState([]);

    const [shuffleQuestions, setShuffleQuestions] = useState(false);
    const [shuffleOptions, setShuffleOptions] = useState(false);
    const [regSubjects, setRegSubjects] = useState([]);
    const [proceedSubjects, setProceedSubjects] = useState([]);


    const { assessmentData, aloading } = useSelector(
        (state) => state.singleAssessment
    );


    useEffect(() => {
        let temp = [];
        let temp1 = [];
        if (savedSubjects && savedSubjects?.length > 0) {
            savedSubjects?.map((item) => {

                if (item?.exam?._id == examId && item?.subscriptionPlan?._id == subscriptionId) {

                    item?.subjectsProcessed?.map((item) => {
                        temp.push(item)
                    })
                    setProceedSubjects(temp)

                    item.subjects?.map((itemSub) => {
                        if (item?.subjectsProcessed?.includes(itemSub?.subject?.id)) {

                            temp1.push(itemSub?.subject)
                        }
                    })
                    setRegSubjects(temp1);
                    setPostUtmeSubjects(temp1)

                }


            })


        }
        return () => {

        }
    }, [savedSubjects])

    // console.log("reg", regSubjects);

    const onSubjectChange = (e) => {
        const subId = e.target.value;
        const subjectSlug = e.target.dataset.slug;

        // let subArray = assessmentName !== "Mock" || examSlug == "post-utme" ? [...selectedSubjects, subId] : [subId];
        let subArray =
            examSlug === "post-utme" ? [subId] : assessmentName !== "Mock" ? [...selectedSubjects, subId] : [subId];


        if (selectedSubjects.includes(e.target.value)) {
            subArray = subArray.filter((sub) => sub !== e.target.value);
        }
        localStorage.setItem("subjects", subArray);
        setSelectedSubjects(subArray);

        if (examSlug == 'post-utme' && universityList) {
            const tempSub = regSubjects.filter((item) => subArray.includes(item?._id))
            const temp = universityList.filter((item) =>
                tempSub.some((sub) => sub.university.includes(item._id))

            );
            setFilteredUniversity(temp);
        }

        let slugArr = [...subjectSelections, subjectSlug];
        if (subjectSelections.includes(subjectSlug)) {
            slugArr = slugArr.filter((slug) => slug !== subjectSlug);
        }

        setSubjectSelections(slugArr);
        if (assessmentType !== "mock") {
            dispatch(
                getYearQuestions(examId, examType, subArray, sliderValue, false, false, selectedUniversity)
            );
        } else {
            dispatch(getMockExamQuestions(examId, examType, subArray));
        }
    };


    const onUniversityChange = (e) => {

        let subArray = [e.target.value];
        if (selectedUniversity.includes(e.target.value)) {
            subArray = subArray.filter((sub) => sub !== e.target.value);
        }

        setSelectedUniversity(subArray);

        // Filter subjects based on selected university IDs
        const filteredSubjects = regSubjects.filter(subject =>
            subject.university.some(id =>
                subArray.includes(id)
            )
        );
        setPostUtmeSubjects(regSubjects)


    };

    const onSubjectRadioChange = (e) => {
        const subId = e.target.value;
        const subjectSlug = e.target.dataset.slug;
        localStorage.setItem("selectedsubjectId", subId);
        var subArray = subId.split();
        setSelectedSubjects(subArray);
        localStorage.setItem("subjects", subArray);

        // set selection
        var slugArr = subjectSlug.split();

        setSubjectSelections(slugArr);

        if (assessmentType !== "mock") {
            dispatch(
                getYearQuestions(examId, examType, subArray, sliderValue, false, false, [], "", selectedUniversity)
            );
        } else {
            dispatch(getMockExamQuestions(examId, examType, subArray));
        }

    };

    const maxYear = currentYear;

    const handleChangeStart = () => {
    };

    const handleChange = (value) => {
        setSliderValue(value);
        if (selectedSubjects !== undefined && selectedSubjects.length > 0) {
            const university = selectedUniversity;

            dispatch(
                getYearQuestions(
                    examId,
                    examType,
                    selectedSubjects,
                    value,
                    false,
                    false,
                    [],
                    "",
                    university
                )
            );
        }
    };

    const { questions, qLoading } = useSelector((state) => state.yearQuestions);
    //mock questions
    const { mloading, mockQuestions, examData, questionCount } = useSelector(
        (state) => state.mockList
    );
    const handleChangeComplete = () => {
    };

    const { universityList, loading, isUniversityDeleted } = useSelector((state) => state.university);
    const [filteredUniversity, setFilteredUniversity] = useState([]);

    useEffect(() => {
        dispatch(getUniversityList())
    }, [dispatch])

    // useEffect(() => {
    //     if (regSubjects && universityList) {
    //         const temp = universityList.filter((item) =>
    //             regSubjects.some((sub) => sub.university.includes(item._id))
    //         );

    //         setFilteredUniversity(temp);
    //     }
    // }, [regSubjects, universityList]);


    const handleTimeOnChange = (val) => {


        var hours = Math.floor(val / 60);
        var minutes = val % 60 === 0 ? "00" : val % 60;

        setTime(val);
        setTimeLabel(hours + " Hour " + minutes + " Min");
    };

    const handlequestionOnChange = (val) => {
        console.log("que val", val);
        localStorage.setItem("noOfQuestion", val);
        setSliderQueVal(val)
    }


    const submitHandler = (e) => {
        e.preventDefault();
        localStorage.setItem("subjectId", subjectType);
        localStorage.setItem("selectedUniversity", selectedUniversity);
        localStorage.setItem("year", sliderValue);

        if (assessmentType === "practice") {
            localStorage.setItem("timeDuration", time);
        }
        localStorage.setItem("shuffleQuestions", shuffleQuestions);
        localStorage.setItem("shuffleOptions", shuffleOptions);

        var isQuestionAvailabel = "No";
        if (selectedSubjects !== undefined && selectedSubjects.length > 0) {
            var quesAvailabSub = [];
            var sub = [];
            var removeValFromIndex = [];

            if (assessmentName !== "Mock") {
                if (examType === "theory") {
                    selectedSubjects.map((item, key) => {
                        if (
                            questions.length > 0 &&
                            questions.some(
                                (sItem) => sItem.theories.length > 0 && sItem.subject._id === item
                            )
                        ) {
                            var subjectQuesData = questions.find((sit) => {
                                return sit.subject._id === item;
                            });
                            quesAvailabSub.push(subjectQuesData.subject.title);
                            isQuestionAvailabel = "Yes";
                        } else {

                            removeValFromIndex.push(item);
                        }
                    });
                } else {
                    selectedSubjects?.map((item, key) => {
                        if (
                            questions !== undefined &&
                            questions.length > 0 &&
                            questions?.some(
                                (sItem) =>
                                    sItem.questions !== undefined &&
                                    sItem.questions.length > 0 &&
                                    sItem.subject._id === item
                            )
                        ) {
                            var subjectQuesData = questions?.find((sit) => {
                                return sit.subject._id === item;
                            });
                            quesAvailabSub.push(subjectQuesData?.subject.title);
                            isQuestionAvailabel = "Yes";
                        } else {

                            var subjectData = questions?.find((si) => {
                                return si.subject._id === item;
                            });
                            if (item !== undefined) {
                                removeValFromIndex.push(item);
                            }
                        }
                    });
                }

                var arrRemainingSubjects = selectedSubjects.filter(
                    (item) => !removeValFromIndex.includes(item)
                );

                localStorage.setItem("checkedSubjects", arrRemainingSubjects);

                if (removeValFromIndex.length > 0 && sub !== undefined && isQuestionAvailabel === "Yes") {
                    var questionAvaiMsg = "";
                    if (quesAvailabSub !== undefined && quesAvailabSub.length > 0) {
                        questionAvaiMsg = `${quesAvailabSub} for YEAR ${sliderValue}`;
                    }
                    //alert.info(isQuestionAvailabel);
                    alert.error(
                        `Question available for subject(s) ${questionAvaiMsg}`,
                        {
                            //timeout: 2000, // custom timeout just for this one alert
                            // examId, examType, subArray, minYear, false, false
                            onOpen: () => {
                                setSelectedSubjects(arrRemainingSubjects);
                                dispatch(
                                    getYearQuestions(
                                        examId,
                                        examType,
                                        arrRemainingSubjects,
                                        sliderValue,
                                        false,
                                        false
                                    )
                                );
                            },
                        }
                    );
                    return false;
                } else if (isQuestionAvailabel === "No") {
                    alert.error(`Question not available for year ${sliderValue}`);
                    return false;
                }

            } else {
                if (questionCount === 0) {
                    alert.error(`Question not available for this subject`);
                    return false;
                } else {
                    //history.push(`/start-exam`);
                    // history.push(`/start-mock/${examId}`);
                    history.push(`/instructions`);
                }
            }
        } else {
            alert.error("Please select subject.");
            return false;
        }

        if (isQuestionAvailabel === "Yes") {
            if (time === 0) {
                alert.error("Please select time.");
                return false;
            }
            history.push(`/select-subject-topic`);
            // history.push(`/start-exam`);
        }
    };

    const goToPreviousPage = (e) => {
        e.preventDefault();
        history.push(`/mode-of-assessment`);
    };

    const formatHr = (value) =>
        Math.floor(value / 60) + " Hrs " + (value % 60) + " Min";

    const handleShuffleQuestions = (event) => {
        setShuffleQuestions(!shuffleQuestions);
    };

    const handleShuffleOptions = (event) => {
        setShuffleOptions(!shuffleOptions);
    };

    // var postUtmeSubjects = regSubjects;
    // if (utmeSubjects !== undefined && utmeSubjects !== null) {
    //     postUtmeSubjects = JSON.parse(utmeSubjects);
    // }


    return (
        <Fragment>
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to={`/mode-of-assessment`}>
                                Mode of Assessment{" "}
                                <i>
                                    <u>
                                        {assessmentData !== undefined && assessmentData !== ""
                                            ? assessmentData.title
                                            : ""}
                                    </u>
                                </i>
                            </Link>
                        </li>
                    </ul>
                    {examSlug === "jamb-utme" && (
                        <Fragment>
                            <h1>Select Subjects: 1-{`${maximumSubjects}`}</h1>
                            <h3>(You may select 1 or more subjects from the {maximumSubjects} registered subjects)</h3>
                        </Fragment>
                    )}
                    {examSlug === "post-utme" && (
                        <Fragment>
                            <h1>Select Subjects: 1-{`${maximumSubjects}`}</h1>
                            <h3>(You may select any number of subjects)</h3>
                        </Fragment>
                    )}
                    {(examSlug === "wassce-waec-ssce" || examSlug === "jsce-bece") && (
                        <Fragment>
                            {/*<h1>Select Subjects: 1-{`${maximumSubjects}`}</h1>*/}
                            <h2>Select a subject from the {maximumSubjects} registered subjects</h2>
                        </Fragment>
                    )}
                </div>
            </section>

            <section className="subscription-page">
                <div className="container">





                    <div className="end-plan">
                        <h1>Registered Subjects</h1>
                    </div>
                    <div className="custom-radios-plan custom-check-box examtype-spaner">
                        {csloading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {examSlug === "post-utme" ? (
                                    <Fragment>
                                        {postUtmeSubjects !== undefined && postUtmeSubjects.length > 0
                                            ? postUtmeSubjects.map((item, key) => {
                                                //   alert.success(item._id);
                                                const indexId = key + 1;

                                                // const inputType = assessmentType === "mock" ? "radio" : "checkbox";
                                                return (
                                                    <Fragment key={indexId}>
                                                        <input
                                                            //type="checkbox"
                                                            type={"checkbox"}
                                                            name="select"
                                                            id={`option-${indexId}`}
                                                            value={item._id}
                                                            data-slug={item.slug}
                                                            // disabled={proceedSubjects?.length > 0 && proceedSubjects.includes(item._id) ? true : false}
                                                            checked={
                                                                selectedSubjects.length > 0 &&
                                                                    selectedSubjects.includes(item._id)
                                                                    ? true
                                                                    : false
                                                            }
                                                            // onClick={onSubjectChange}
                                                            onClick={onSubjectChange}
                                                        />

                                                        <label
                                                            htmlFor={`option-${indexId}`}
                                                            className={`option option-${indexId}`}
                                                        >
                                                            <div className="dot" />
                                                            <div className="extype">
                                                                <img
                                                                    // src={
                                                                    //     process.env.REACT_APP_PUBLIC_URL +
                                                                    //     "/assets/img/subjects/" +
                                                                    //     item.logo
                                                                    // }
                                                                    src={
                                                                        item.logo
                                                                            ? process.env.REACT_APP_PUBLIC_URL + "/assets/img/subjects/" + item.logo
                                                                            : process.env.REACT_APP_PUBLIC_URL + '/assets/img/No-Image-Placeholder.svg' // Replace with the path to your default image
                                                                    }
                                                                    alt="exam-logo"
                                                                />
                                                            </div>
                                                            <span>{item.title}</span>
                                                        </label>
                                                    </Fragment>
                                                );
                                            })
                                            : ""}


                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {regSubjects !== undefined && regSubjects.length > 0
                                            ? regSubjects.map((item, key) => {
                                                //   alert.success(item._id);
                                                const indexId = key + 1;
                                                const inputType = examSlug === "jamb-utme" ? "checkbox" : assessmentName !== "Mock" ? "checkbox" : "radio";
                                                // const inputTypeHandle = examSlug === "jamb-utme" ? "onSubjectChange" : "onSubjectRadioChange";
                                                return (
                                                    <Fragment key={indexId}>
                                                        <input
                                                            type={inputType}
                                                            name="select"
                                                            id={`option-${indexId}`}
                                                            value={item._id}
                                                            data-slug={item.slug}
                                                            // disabled={proceedSubjects?.length > 0 && !proceedSubjects.includes(item._id) ? true : false}

                                                            checked={
                                                                selectedSubjects.length > 0 &&
                                                                    selectedSubjects.includes(item._id)
                                                                    ? true
                                                                    : false
                                                            }
                                                            onClick={examSlug === "jamb-utme" ? onSubjectChange : assessmentName !== "Mock" ? onSubjectChange : onSubjectRadioChange}
                                                        />

                                                        <label
                                                            htmlFor={`option-${indexId}`}
                                                            className={`option option-${indexId}`}
                                                        >
                                                            <div className="dot" />
                                                            <div className="extype">
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_PUBLIC_URL +
                                                                        "/assets/img/subjects/" +
                                                                        item.logo
                                                                    }
                                                                    alt="exam-logo"
                                                                />
                                                            </div>
                                                            <span>{item.title}</span>
                                                        </label>
                                                    </Fragment>
                                                );
                                            })
                                            : ""}
                                    </Fragment>
                                )}
                            </div>
                        )}
                    </div>

                    {/* {examSlug === "post-utme" &&
                        <div className="end-plan">
                            <h1>Select  University</h1>
                        </div>
                    }
                    <div className="custom-radios-plan custom-check-box examtype-spaner">
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {examSlug === "post-utme" && (
                                    <Fragment>
                                        {filteredUniversity !== undefined && filteredUniversity.length > 0
                                            ? filteredUniversity?.map((item, key) => {
                                                const indexId = key + 1;
                                                return (
                                                    <Fragment key={indexId}>
                                                        <div className="university_card">
                                                            <input
                                                                type={"checkbox"}
                                                                name="select"
                                                                id={`optionU-${indexId}`}
                                                                value={item._id}
                                                                data-slug={item.slug}
                                                                checked={
                                                                    selectedUniversity.length > 0 &&
                                                                        selectedUniversity.includes(item._id)
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={onUniversityChange}
                                                            />

                                                            <label
                                                                htmlFor={`optionU-${indexId}`}
                                                                className={selectedUniversity?.includes(item._id) ? `option option-${indexId} checked` : `option option-${indexId} universityClass`}
                                                            >
                                                                <div className="dot" />
                                                                <div className="extype">
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_PUBLIC_URL +
                                                                            "/assets/img/subjects/" +
                                                                            item.logo
                                                                        }
                                                                        alt="exam-logo"
                                                                    />
                                                                </div>
                                                                <span>{item.title}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </Fragment>
                                                );
                                            })
                                            : ""}


                                    </Fragment>
                                )}
                            </div>
                        )}
                    </div>

                    <br /> */}



                    {(assessmentName !== "Mock") && (
                        <>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <br />
                                        <label style={{ fontSize: "18px" }}>
                                            Select Year of Examination{" "}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <div className="slider">
                                            <Slider
                                                min={minYear !== undefined ? minYear : ""}
                                                max={maxYear !== undefined ? maxYear : ""}
                                                value={sliderValue}
                                                tooltip={true}
                                                onChangeStart={handleChangeStart}
                                                onChange={handleChange}
                                                onChangeComplete={handleChangeComplete}
                                            />
                                            {sliderValue !== NaN && sliderValue !== undefined && (
                                                <div className="value">{sliderValue}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <br />
                                        <label style={{ fontSize: "18px" }}>Choose  Number Of Question (1 - 100)</label>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <div className="slider">
                                            <Slider
                                                min={1}
                                                max={100}
                                                step={1}
                                                value={sliderQueVal}
                                                tooltip={true}
                                                // format={formatHr}
                                                onChange={handlequestionOnChange}
                                            />
                                            {sliderQueVal !== NaN && sliderQueVal !== undefined && (
                                                <div className="value">{sliderQueVal}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {assessmentType === "practice" && (

                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <br />
                                    <label style={{ fontSize: "18px" }}>Choose Time</label>
                                </div>
                            </div>
                            <div className="col-sm-9">
                                <div className="form-group">
                                    <div className="slider">
                                        <Slider
                                            min={0}
                                            max={360}
                                            step={15}
                                            value={parseInt(time)}
                                            tooltip={true}
                                            format={formatHr}
                                            onChange={handleTimeOnChange}
                                        />
                                        {timeLabel !== NaN && timeLabel !== undefined && (
                                            <div className="value">{timeLabel}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {assessmentName !== "Mock" && examType !== "theory" && (
                        <div className="row">
                            <div
                                className="col-md-6 wow fadeInDown col-xs-12 col-sm-6 col-lg-6"
                                style={{ visibility: "visible", animationName: "fadeInDown" }}
                            >
                                <div className="boxes">
                                    <input
                                        type="checkbox"
                                        id="shuffle_question"
                                        name="shuffle_questions"
                                        onChange={handleShuffleQuestions}
                                    />{" "}
                                    <label htmlFor="shuffle_question" className="custom-checkboxes">
                                        Shuffle Questions
                                    </label>
                                </div>
                            </div>
                            <div
                                className="col-md-6 wow fadeInDown col-xs-12 col-sm-6 col-lg-6"
                                style={{ visibility: "visible", animationName: "fadeInDown" }}
                            >
                                <div className="boxes">
                                    <input
                                        type="checkbox"
                                        id="shuffle_answer"
                                        name="shuffle_options"
                                        onChange={handleShuffleOptions}
                                    />{" "}
                                    <label
                                        htmlFor="shuffle_answer"
                                        className="custom-checkboxes lts-rts"
                                    >
                                        Shuffle Answer Choices
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}




                    <div className="next-prev-steps text-center">
                        {localStorage.getItem("savedId") === undefined || localStorage.getItem("savedId") === null && (
                            <button onClick={goToPreviousPage} className="chose-more">
                                <i className="fas fa-arrow-left" /> Prev
                            </button>
                        )}
                        &nbsp;
                        <button
                            type="submit"
                            // disabled={!subjectType}
                            className="chose-more"
                            onClick={submitHandler}
                        >
                            Next <i className="fas fa-arrow-right" />
                        </button>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default RegisteredSubjects;
