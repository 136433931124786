import React from "react";
import { Link } from "react-router-dom";
import SubscribeNewsletter from "../SubscribeNewsletter/SubscribeNewsletter";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="top-footer">
          <div className="row">
            {/* <div className="col-sm-2">
                            <div className="footer-logo">
                                <Link to="/"><img
                                    src="../assets/frontend/img/foot-logo.png"
                                    alt="site-logo"
                                />
                                </Link>
                            </div>
                        </div> */}
            <div className="col-sm-7">
              <h3>Quick Links</h3>
              <div className="footer-nav">
                <ul className="clearfix">
                  <li>
                    <Link to="/news">Our News</Link>
                  </li>
                  {/*<li>
                                        <Link to="/pages/cancellation-policy">Cancellation policy</Link>
                                    </li>*/}
                  <li>
                    <Link to="/pages/terms-conditions">Terms & condition</Link>
                  </li>
                  <li>
                    <Link to="/pages/privacy-policy">Privacy policy</Link>
                  </li>
                  <li>
                    <Link to="/pages/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-sm-2">
              <h3>Explore</h3>
              <div className="footer-nav">
                <ul className="clearfix">
                  <li>
                  <Link to="/">Careers</Link>
                  </li>
                  <li>
                    <Link to="/">Locations</Link>
                  </li>
                  <li>
                    <Link to="/">Investors</Link>
                  </li>
                  <li>
                    <Link to="/">Press Room</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-sm-5">
              <h3>Sign Up for Our Newsletter</h3>
              <SubscribeNewsletter></SubscribeNewsletter>
              {/* <div className="social-nav clearfix">
                <ul className="clearfix">
                    <li>
                      <Link to="/">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fa fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fa fa-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
            </div>
          </div>
        </div>
        <div className="bot-footer">
          <p>
            © Copyright 2021 by <Link to="/">One Time Success</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
