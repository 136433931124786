import React, { Fragment, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, clearErrors } from "./../../../actions/authActions";

const LeftSide = ({ history }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const { user, loading, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (error) {
            clearErrors();
            return alert.error(error);
        }

        if (user.role === "user") {
            alert.error("You are not allowed to login here.");
            logoutHandler();
            return;
        }
        if (user.accountStatus !== "Active") {
            alert.error("Your account is not active or blocked.");
            logoutHandler();
            return;
        }
    }, [dispatch, alert, error, history]);

    const logoutHandler = () => {
        dispatch(logoutUser());
        // history.push("/login");
        // history.push("/admin/login");
    };

    return (
        <Fragment>
            <aside className="main-sidebar">
                <section className="sidebar">
                    <div className="user-panel">
                        <div className="pull-left image">
                            <img
                                src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"}
                                className="img-circle"
                                alt="User Image"
                            />
                        </div>
                        <div className="pull-left info">
                            <p>{`${user.first_name} ${user.last_name}`}</p>
                            {/*<a href="#"><i className="fa fa-circle text-success"></i> Online</a>*/}
                        </div>
                    </div>

                    <ul className="sidebar-menu" data-widget="tree">
                        <li className="header">MAIN NAVIGATION</li>
                        <li>
                            <NavLink to="/admin" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-dashboard"></i> <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/home-setting" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-edit"></i> <span>Home Setting</span>
                            </NavLink>
                        </li>
                        <li className="active treeview">
                            <a href="#">
                                <i className="fa fa-dashboard"></i> <span>Manage Users</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            <ul className="treeview-menu">
                                <li className="active">
                                    <NavLink to="/admin/users" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                        <i className="fa fa-circle-o"></i> Admin Users
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/admin/students" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                        <i className="fa fa-circle-o"></i> Students
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to="/admin/banners" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-image"></i> <span>Banners</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/pages" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>CMS Pages</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/emailtemplates" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Email Templates</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/admin/categories" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Categories</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/subscriptions" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Subscriptions</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/student-subscriptions" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i>{" "}
                                <span>Student Subscriptions</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/student-registered-subjects" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i>{" "}
                                <span>Registered Subjects</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/exams" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Exams</span>
                            </NavLink>
                            {/*<NavLink to="/admin/exam-instructions" activeStyle={{background:'#5cb85c', color:'#ffffff'}} exact>
                                <i className="fa fa-table"></i> <span>Exams Instructions</span>
                            </NavLink>*/}
                        </li>

                        <li>
                            <NavLink to="/admin/university" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>University</span>
                            </NavLink>
                            {/*<NavLink to="/admin/exam-instructions" activeStyle={{background:'#5cb85c', color:'#ffffff'}} exact>
                                <i className="fa fa-table"></i> <span>Exams Instructions</span>
                            </NavLink>*/}
                        </li>
                        <li>
                            <NavLink to="/admin/assessments" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Assessments</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/courses" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Courses</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/subjects" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Subjects</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/question-subjects" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Questions</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/advice-list" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Advices</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/news-list" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Manage News</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/contactus" activeStyle={{ background: '#5cb85c', color: '#ffffff' }}>
                                <i className="fa fa-table"></i> <span>Manage Contact Us</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/subscribenewsletter" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Subscribed Newsletter</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/testimonials" activeStyle={{ background: '#5cb85c', color: '#ffffff' }} exact>
                                <i className="fa fa-table"></i> <span>Manage Testimonial</span>
                            </NavLink>
                        </li>
                        {/*<li>
              <NavLink to="/admin/user-theories-exam" activeStyle={{background:'#5cb85c', color:'#ffffff'}} exact>
                <i className="fa fa-table"></i> <span>Theories Exams</span>
              </NavLink>
            </li>*/}

                        {/*<li className="treeview">
                            <a href="#">
                                <i className="fa fa-files-o"></i>
                                <span>Layout Options</span>
                                <span className="pull-right-container">
              <span className="label label-primary pull-right">4</span>
            </span>
                            </a>
                            <ul className="treeview-menu">
                                <li><a href="pages/layout/top-nav.html"><i className="fa fa-circle-o"></i> Top Navigation</a></li>
                                <li><a href="pages/layout/boxed.html"><i className="fa fa-circle-o"></i> Boxed</a></li>
                                <li><a href="pages/layout/fixed.html"><i className="fa fa-circle-o"></i> Fixed</a></li>
                                <li><a href="pages/layout/collapsed-sidebar.html"><i className="fa fa-circle-o"></i> Collapsed Sidebar</a></li>
                            </ul>
                        </li>*/}
                        {/*<li className="header">LABELS</li>
                        <li><a href="#"><i className="fa fa-circle-o text-red"></i> <span>Important</span></a></li>
                        <li><a href="#"><i className="fa fa-circle-o text-yellow"></i> <span>Warning</span></a></li>
                        <li><a href="#"><i className="fa fa-circle-o text-aqua"></i> <span>Information</span></a></li>*/}
                    </ul>
                </section>
            </aside>
        </Fragment>
    );
};

export default LeftSide;
