import React, {Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Moment from "moment";
import { useAlert } from 'react-alert';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { useDispatch, useSelector } from 'react-redux';
import { getSubscribeNewsletter, deleteSubscribeNewsletterData } from '../../../actions/adminSubscribeNewsletterActions';


import Loader from "../../Loader";
// import Loader from "react-loader-spinner";

const ListSubscribeNewsletter = () => {

    // const [users, setUsersData] = useState({});
    const alert = useAlert();
    const dispatch  = useDispatch();

    
    
    useEffect(() => {
        dispatch(
            getSubscribeNewsletter()
        )
    }, [dispatch]);

    const { subscribeNewsletterList, user_loading } = useSelector(state => state);

    const handleDeleteSubscribeNewsletter = (id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(deleteSubscribeNewsletterData(id));
                        alert.success('Subscribe Newsletter deleted successfully');
                        dispatch(getSubscribeNewsletter());
                        window.location.reload();
                    }
                },
                {
                    label: 'No',
                    onClick: () => alert.success('No data deleted')
                }
            ]
        });
    }

    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Manage
                                <small>Subscribe Newsletter</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li><Link to="/admin"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
                                <li className="active">Subscribe Newsletter</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="box">
                                        <div className="box-header">
                                            <h3 className="box-title">Subscribe Newsletter</h3>                                            
                                        </div>
                                        <div className="box-body table-responsive no-padding">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>Subscribed Date</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {subscribeNewsletterList.user_loading ? <Loader /> : ((subscribeNewsletterList.subscribeNesletter.length)? subscribeNewsletterList.subscribeNesletter.map(catItem => (
                                                    <tr key={catItem._id}>
                                                     
                                                        <td>{catItem.email}</td>
                                                        <td>{Moment(catItem.createdAt).format("YYYY-MM-DD")}</td>
                                                        <td>
                                                            
                                                            <a href="#" className="btn btn-danger btn-sm"
                                                               title="Delete"
                                                               onClick={() => handleDeleteSubscribeNewsletter(catItem._id)}
                                                               key={catItem._id}
                                                            ><i className="fa fa-trash"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )): <tr>
                                                    <td colSpan="3">
                                                        No records found
                                                    </td>
                                                </tr>)}
                                                </tbody>
                                                <tfoot>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default ListSubscribeNewsletter;
