import {
  LOAD_ALL_SUBJECTS_REQUEST,
  LOAD_ALL_SUBJECTS_SUCCESS,
  LOAD_ALL_SUBJECTS_FAIL,
  ADD_SUBJECT_REQUEST,
  ADD_SUBJECT_SUCCESS,
  ADD_SUBJECT_RESET,
  ADD_SUBJECT_FAIL,
  SUBJECT_DETAIL_REQUEST,
  SUBJECT_DETAIL_SUCCESS,
  SUBJECT_DETAIL_FAIL,
  UPDATE_SUBJECT_REQUEST,
  UPDATE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_RESET,
  UPDATE_SUBJECT_FAIL,
  DELETE_SUBJECT_REQUEST,
  DELETE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminSubjectConstants";

export const subjectsReducer = (state = { subjects: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_SUBJECTS_REQUEST:
      return {
        user_loading: true,
        subjects: [],
      };

    case LOAD_ALL_SUBJECTS_SUCCESS:
      return {
        user_loading: false,
        subjects: action.payload.data,
      };

    case LOAD_ALL_SUBJECTS_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case UPDATE_SUBJECT_REQUEST:
      return {
        ...state,
        user_loading: true,
        errors: null,
      };

    case UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_SUBJECT_RESET:
      return {
        ...state,
        isUpdated: null,
        errors: null,
      };

    case UPDATE_SUBJECT_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const subjectDetailReducer = (state = { subject: {} }, action) => {
  switch (action.type) {
    case SUBJECT_DETAIL_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case SUBJECT_DETAIL_SUCCESS:
      return {
        user_loading: false,
        subject: action.payload,
      };

    case SUBJECT_DETAIL_FAIL:
      return {
        ...state,
        user_loading: false,

        error: action.payload,
      };

    case ADD_SUBJECT_REQUEST:
      return {
        user_loading: true,
        isSubjectAdded: false,
        errors: null,
      };

    case ADD_SUBJECT_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isSubjectAdded: action.payload.success || null,
        message :  action.payload.message,
        subject: action.payload.subject,
        errors: null,
      };

    case ADD_SUBJECT_RESET:
      return {
        ...state,
        isSubjectAdded: null,
        errors: null,
      };

    case ADD_SUBJECT_FAIL:
      return {
        ...state,
        user_loading: false,
        isSubjectAdded: false,
        subject: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteSubjectReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SUBJECT_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_SUBJECT_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_SUBJECT_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
