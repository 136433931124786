import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import DatePicker from "react-date-picker";
import { addDays } from "date-fns";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

//import "react-datepicker/dist/react-datepicker.css";

import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../../actions/commonActions";
import {
  updateUserProfile,
  clearErrors,
} from "../../../../actions/adminUserActions";
import { UPDATE_PROFILE_RESET } from "../../../../constants/userConstants";

import Loader from "../../../Loader";

import nationalityData from "../../../../nationality.json";

const EditAdminUser = ({ history, match }) => {
  const [userId, setUserId] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  //const [dob, setDob] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [mobile_code, setMobileCode] = useState("");
  const [nationality, setNationality] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConFirmPassword] = useState("");
  const [accountStatus, setAccountStatus] = useState("InActive");
  const [superAdmin, setSuperAdmin] = useState(false);

  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(
    "./../../../styles/img/avatar.png"
  );

  const [errorData, setErrorData] = useState({});

  const alert = useAlert();
  const dispatch = useDispatch();

  const { isUpdated, user_loading, error, errors } = useSelector(
    (state) => state.users
  );

  const currentUserId = match.params.id;
  useEffect(() => {
    dispatch(getUserData(currentUserId));
  }, [dispatch]);

  const { userData } = useSelector((state) => state.userData);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (userData) {
      setUserId(userData._id);
      setFirstName(userData.first_name);
      setLastName(userData.last_name);
      setEmail(userData.email);
      setAccountStatus(userData.accountStatus);
      setNationality(userData.nationality);
      setMobileCode(userData.mobile_code);
      setMobileNumber(userData.mobile_number);
      //setDob(userData.dob);
      setGender(userData.gender);
      setSuperAdmin(userData.isSuperAdmin);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("User updated successfully");
      dispatch(getUserData(userData._id));

      //history.push(`/admin/users/${user._id}`);
      history.push(`/admin/users`);

      dispatch({
        type: UPDATE_PROFILE_RESET,
      });
    }
  }, [dispatch, userData, alert, error, errors, history, isUpdated]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateUserProfile(
        userId,
        first_name,
        last_name,
        mobile_code,
        mobile_number,
        email,
        DOBvalue,
        gender,
        nationality,
        accountStatus,
        password,
        confirm_password
      )
    );
  };

  const onPhoneInputChnage = (value, data, event, formattedValue) => {
    setMobileCode(value);
  };

  const [DOBvalue, setDate] = useState(new Date());

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>User</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/users">
                    <i className="fa fa-users"></i> Users
                  </Link>
                </li>
                <li className="active">Edit User</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit User</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>
                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="">
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="first_name">First Name</label>
                          <input
                            type="text"
                            name="first_name"
                            className="form-control"
                            id="first_name"
                            placeholder="Enter First Name"
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          {errorData.first_name ? (
                            <span className="text-danger">
                              {errorData.first_name}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="last_name">Last Name</label>
                          <input
                            type="text"
                            name="last_name"
                            className="form-control"
                            id="last_name"
                            placeholder="Enter Last Name"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          {errorData.last_name ? (
                            <span className="text-danger">
                              {errorData.last_name}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="email">Email address</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errorData.email ? (
                            <span className="text-danger">
                              {errorData.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="nationality">Nationality</label>
                          <select
                            className="form-control"
                            name="nationality"
                            id="nationality"
                            value={nationality}
                            onChange={(e) => setNationality(e.target.value)}
                          >
                            <option value="">Select Nationality</option>
                            {user_loading ? (
                              <Loader />
                            ) : (
                              <Fragment>
                                {nationalityData.length
                                  ? nationalityData.map((e, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            nationality == e.nationality
                                          }
                                          value={e.nationality}
                                        >
                                          {e.nationality}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </Fragment>
                            )}
                          </select>
                          {errorData.nationality ? (
                            <span className="text-danger">
                              {errorData.nationality}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="dob">Dob</label>

                          <DatePicker
                            name="dob"
                            value={DOBvalue}
                            selected={DOBvalue}
                            onChange={(date) => setDate(date)}
                            dateFormat="YYYY-MM-DD"
                            maxDate={addDays(new Date(), -6500)} //current date
                            placeholderText="Select your date of birth."
                            className="form-control"
                          />
                          {errorData.dob ? (
                            <span className="text-danger">{errorData.dob}</span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="gender">Gender</label>
                          <select
                            className="form-control"
                            name="gender"
                            id="gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                          {errorData.gender ? (
                            <span className="text-danger">
                              {errorData.gender}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="mobile_code">Mobile Code</label>
                          <PhoneInput
                            inputProps={{
                              name: "mobile_code",
                              id: "mobile_code",
                              required: true,
                              autoFocus: true,
                              readOnly: true,
                            }}
                            value={mobile_code}
                            onChange={onPhoneInputChnage}
                            enableSearch
                            country={"ng"}
                            className="form-control"
                          />
                          {errorData.mobile_code ? (
                            <span className="text-danger">
                              {errorData.mobile_code}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="mobile_number">Mobile Number</label>
                          <input
                            type="text"
                            name="mobile_number"
                            className="form-control"
                            id="mobile_number"
                            placeholder="Mobile Number"
                            value={mobile_number}
                            onChange={(e) => setMobileNumber(e.target.value)}
                          />
                          {errorData.mobile_number ? (
                            <span className="text-danger">
                              {errorData.mobile_number}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="password">Password</label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="password"
                            autoComplete="new-password"
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="confirm_password">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="confirm_password"
                            className="form-control"
                            id="confirm_password"
                            autoComplete="new-password"
                            placeholder="Enter Confirm Password"
                            value={confirm_password}
                            onChange={(e) => setConFirmPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      {superAdmin !== true && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="accountStatus">
                              Account Status
                            </label>
                            <select
                              className="form-control"
                              name="accountStatus"
                              onChange={(e) => setAccountStatus(e.target.value)}
                              value={accountStatus}
                            >
                              <option value="Active">Active</option>
                              <option value="InActive">In Active</option>
                              <option value="Blocked">Blocked</option>
                              <option value="Suspended">Suspended</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditAdminUser;
