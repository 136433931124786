import React from 'react'
import { Fragment } from 'react'
import Loader from '../../Loader'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getUniversityDetail, getUniversityList, updateUniversity } from '../../../actions/adminUniversityAction'
import { UPDATE_UNIVERSITY_RESET } from '../../../constants/adminUniversityConstants'
import { useState } from 'react'
import { useEffect } from 'react'

const EditUniversity = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [universityId, setUniversityId] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(false);
    const [logo, setLogo] = useState("");
    const [imageName, setImageName] = useState("");
    const [oldImage, setOldImage] = useState("");
    const [imagePreview, setImagePreview] = useState("");

    const { isUniversityUpdated, loading, error } = useSelector(
        (state) => state.university
    );

    const currentUniversityId = match.params.id;
    useEffect(() => {
        dispatch(getUniversityDetail(currentUniversityId));
    }, [dispatch]);

    const { university } = useSelector((state) => state.university);

    useEffect(() => {
        if (university) {
            setUniversityId(university._id);
            setTitle(university.title);
            setDescription(university.description);
            setStatus(university.status ? "Active" : "InActive");
            setLogo(university.logo);
            setOldImage(university.logo);
            setImagePreview(
                process.env.REACT_APP_PUBLIC_URL + "/assets/img/university/" + university.logo
            );
        }

        if (isUniversityUpdated) {
            alert.success("university updated successfully");
            dispatch(getUniversityList());
            history.push(`/admin/university`);
            dispatch({
                type: UPDATE_UNIVERSITY_RESET,
            });
        }
    }, [dispatch, university, alert, error, history, isUniversityUpdated]);

    const submitHandler = (e) => {
        console.log("status", status);
        const statusExam = status == '0' ? false : true
        if (logo == null) {
            alert.error("select university image");
        }
        e.preventDefault();
        dispatch(
            updateUniversity(
                universityId,
                title,
                description,
                statusExam,
                logo,
                imageName,
            )
        );

    };


    const onChange = (e) => {
        let file = e.target.files[0];

        if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setLogo("");
            setImageName("");
            alert.error("Please select valid image.");
            const preview = document.getElementById("img-preview");
            preview.src = "";
            return false;
        }

        const reader = new FileReader();

        reader.onload = function (readerEvt) {
            let binaryString = readerEvt.target.result;
            let base64String = btoa(binaryString);
            setLogo(base64String);
            setImageName(file.name);
            const preview = document.getElementById("img-preview");
            preview.src = "data:image/png;base64," + base64String;
        };
        reader.readAsBinaryString(file);
    };


    // console.log("universityt", university, logo);

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Edit
                                <small>University</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard" /> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/university">
                                        <i className="fa fa-users" /> University
                                    </Link>
                                </li>
                                <li className="active">Edit University</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Edit University</h3>

                                    {/*<div className="box-tools pull-right">

                                    </div>*/}
                                </div>

                                <form id="quickForm" onSubmit={submitHandler} encType="multipart/form-data">
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="title">Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Enter Title"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                // onChange={setTitle}
                                                />

                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="title">Description</label>
                                                <input
                                                    type="text"
                                                    name="description"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter Description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                />

                                            </div>

                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="logo">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="logo"
                                                    name="logo"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                />

                                                <div>
                                                    <img id="img-preview" src={
                                                        logo && process.env.REACT_APP_PUBLIC_URL +
                                                        "/assets/img/subjects/" +
                                                        logo
                                                    } />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="status">Status</label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    // value={status}
                                                    id="status"
                                                    checked={status}
                                                    // value={status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                // onChange={onChange}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">In Active</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default EditUniversity