import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getBannerDetail,
  updateBanner,
  clearErrors,
} from "../../../actions/admin/bannerActions";
import { UPDATE_BANNER_RESET } from "./../../../constants/admin/bannerConstants";

import Loader from "../../Loader";

import "./Banner.css";

const EditBanner = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [bannerId, setBannerId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [btnUrl, setBtnUrl] = useState("");
  const [btnName, setBtnName] = useState("");
  const [status, setStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [imageName, setImageName] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const [errorData, setErrorData] = useState({});

  const { isUpdated, bannerLoading, error, errors } = useSelector(
    (state) => state.banners
  );

  const currentItemId = match.params.id;
  useEffect(() => {
    dispatch(getBannerDetail(currentItemId));
  }, [dispatch]);

  const { banner } = useSelector((state) => state.banners);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (banner) {
      var imgName =
        banner.image !== undefined
          ? banner.image.substring(
              banner.image.lastIndexOf("/") + 1,
              banner.image.length
            )
          : "";

      setBannerId(banner._id);
      setTitle(banner.title);
      setDescription(banner.description);
      setBtnUrl(banner.btnUrl);
      setBtnName(banner.btnName);
      setStatus(banner.status);
      setLogo(imgName);
      setOldImage(imgName);
      setImagePreview(banner.image);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Banner updated successfully");
      dispatch(getBannerDetail(banner._id));
      history.push(`/admin/banners`);
      dispatch({
        type: UPDATE_BANNER_RESET,
      });
    }
  }, [dispatch, banner, alert, error, errors, history, isUpdated]);

  const onChange = (e) => {
    let file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setLogo("");
      setImageName("");
      alert.error("Please select valid image.");
      //remove preview picture if any
      const preview = document.getElementById("img-preview");
      preview.src = "";
      return false;
    }

    const reader = new FileReader();

    reader.onload = function (readerEvt) {
      // The file's text will be printed here
      //console.log(readerEvt.target.result);
      let binaryString = readerEvt.target.result;
      let base64String = btoa(binaryString);
      setLogo(base64String);
      setImageName(file.name);

      //preview picture
      const preview = document.getElementById("img-preview");
      // console.log('banary image', base64String);
      preview.src = "data:image/png;base64," + base64String;
    };
    reader.readAsBinaryString(file);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateBanner(
        bannerId,
        title,
        description,
        btnUrl,
        btnName,
        status,
        oldImage,
        logo,
        imageName
      )
    );
  };

  return (
    <Fragment>
      {bannerLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Banner</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/banners">
                    <i className="fa fa-table"></i> Banner
                  </Link>
                </li>
                <li className="active">Edit Banner</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Banner</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter the banner title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {errorData.title ? (
                          <span className="text-danger">{errorData.title}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="description">Decription</label>
                        <textarea
                          type="text"
                          name="description"
                          className="form-control"
                          id="description"
                          placeholder="Enter the banner decription"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        {errorData.description ? (
                          <span className="text-danger">
                            {errorData.description}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="logo">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          onChange={onChange}
                          accept={`.jpeg, .jpg, .png`}
                        />
                        {errorData.logo ? (
                          <span className="text-danger">{errorData.logo}</span>
                        ) : (
                          ""
                        )}
                        <div>
                          <img id="img-preview" src={imagePreview} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="btnUrl">Url</label>
                        <input
                          type="text"
                          className="form-control"
                          id="btnUrl"
                          name="btnUrl"
                          onChange={(e) => setBtnUrl(e.target.value)}
                          value={btnUrl}
                        />
                        {errorData.btnUrl ? (
                          <span className="text-danger">
                            {errorData.btnUrl}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="btnName">Button Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="btnName"
                          name="btnName"
                          onChange={(e) => setBtnName(e.target.value)}
                          value={btnName}
                        />
                        {errorData.btnName ? (
                          <span className="text-danger">
                            {errorData.btnName}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6 mt-30">
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          checked={status}
                          value={status}
                          onChange={(e) => setStatus(!status)}
                        />
                        <label htmlFor="status">Status </label>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditBanner;
