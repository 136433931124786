import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../../actions/adminUserActions";
import { deleteUserData } from "../../../../actions/commonActions";
import Loader from "../../../Loader";

// import Loader from "react-loader-spinner";

const ListUser = ({ history }) => {
  // const [users, setUsersData] = useState({});
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const { users, user_loading } = useSelector((state) => state);

  const handleDeleteUser = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to process this request.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteUserData(id));
            alert.success("User deleted successfully");
            dispatch(getUsers());
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Users</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li className="active">Users</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Users</h3>
                      {/*&nbsp;
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              <p className="box-title">
                                <Link to={"/admin/users/add"} className="">+ Add Admin User</Link>
                              </p>*/}

                      <div className="box-tools">
                        <Link
                          to={"/admin/users/add"}
                          className="btn btn-success btn-sm"
                        >
                          + Add Admin User
                        </Link>
                        {/*<div className="input-group input-group-sm hidden-xs" style={{ width: "150px" }}>
                                  <input type="text" name="table_search" className="form-control pull-right" placeholder="Search"/>
                                  <div className="input-group-btn">
                                    <button type="submit" className="btn btn-default"><i className="fa fa-search"></i></button>
                                  </div>
                                </div>*/}
                      </div>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile Number</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.user_loading ? (
                            <Loader />
                          ) : users.users.length ? (
                            users.users.map((user) => (
                              <tr key={user._id}>
                                <td>
                                  {user.first_name} {user.last_name}
                                </td>
                                <td>{user.email}</td>
                                <td>
                                  {user.mobile_code} {user.mobile_number}
                                </td>
                                <td>
                                  {user.accountStatus === "Active" && (
                                    <span className="label bg-green">
                                      {user.accountStatus}
                                    </span>
                                  )}
                                  {user.accountStatus === "InActive" && (
                                    <span className="label bg-yellow">
                                      {user.accountStatus}
                                    </span>
                                  )}
                                  {user.accountStatus === "Blocked" && (
                                    <span className="label bg-red">
                                      {user.accountStatus}
                                    </span>
                                  )}
                                  {user.accountStatus === "Suspended" && (
                                    <span className="label bg-gray">
                                      {user.accountStatus}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/users/edit/${user._id}`}
                                    className="btn btn-success btn-sm"
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                  &nbsp;
                                  {user.isSuperAdmin !== true && (
                                    <a
                                      className="btn btn-danger btn-sm"
                                      title="Delete"
                                      onClick={() => handleDeleteUser(user._id)}
                                      key={user._id}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListUser;
