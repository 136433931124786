import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

import { useSelector } from "react-redux";

import Loader from "../Loader";

const Profile = ({ history }) => {
  const { user, loading, error } = useSelector((state) => state.auth);

  const goToEditUser = () => {
    history.push("/profile/edit");
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="right-auth-landing">
            <div className="main-view-content">
              <div className="all-ourt-style w-80-cust  work-info">
                <div className="all-heads">
                  <h3>Profile</h3>
                </div>
                <div className="editable-forms-users">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="">Customer Name</label>
                        <p>
                          {user.first_name} {user.last_name}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">Nationality</label>
                        <p>{user.nationality ? user.nationality : "--"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="phone">User Phone</label>
                        <p>
                          +{user.mobile_code} {user.mobile_number}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="">Email ID</label>
                        <p>{user.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="">Gender</label>
                        <p>{user.gender == "F" ? "Female" : "Male"}</p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="">DOB</label>
                        <p>{Moment(user.dob).format("YYYY-MM-DD")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label for="">Joined On</label>
                        <p>{String(user.createdAt).substring(0, 10)}</p>
                      </div>
                    </div>
                  </div>

                  <div className="text-right foot-btns">
                    <button
                      type="button"
                      className="btn btn-primary scoler-details"
                      onClick={goToEditUser}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Profile;
