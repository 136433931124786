import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useAlert } from "react-alert";
// import MathTypeEditor from "./MathTypeEditor";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../actions/commonActions";
import {
  addQuestion,
  clearErrors,
} from "../../../actions/adminQuestionActions";
import { ADD_QUESTION_RESET } from "./../../../constants/adminQuestionConstants";
import makeAnimated from "react-select/animated";

import Loader from "../../Loader";

import "./Question.css";

import { Editor } from "@tinymce/tinymce-react";

import QuestionForm from "./QuestionForm";
import { getUniversityList } from "../../../actions/adminUniversityAction";

const AddQuestion = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const animatedComponents = makeAnimated();

  const subject = localStorage.getItem("subjectName");
  const examSlug = localStorage.getItem("examSlug");
  const [university, setUniversity] = useState([]);
  const [universityLists, setuniversityLists] = useState([]);
  const [editorContents, setEditorContents] = React.useState([]);
  const { universityList, loading, isUniversityDeleted } = useSelector((state) => state.university);


  useEffect(() => {
    dispatch(getUniversityList())
  }, [])



  const [questionData, setQuestionData] = useState({
    title: "",
    passage_check: false,
    passage: "",
    question: "",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    correct_answer: "",
    description: "",
    logo: "",
    status: false,
    imageName: "",
  });
  // console.log("quesationsa", questionData);


  const [formList, setFormList] = useState([
    {
      index: Math.random(),
      questionTitle: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      option5: "",
      rightAnswer: "",
      answerDesc: "",
    },
  ]);

  useEffect(() => {
    if (universityList !== undefined && universityList?.length > 0 && universityList !== null && examSlug == 'post-utme') {
      const tmpArr = [];
      universityList?.map((course) => {
        tmpArr?.push({ label: course?.title, value: course?._id })
      })

      setuniversityLists(tmpArr)
    }
  }, [universityList])

  const changeLinkedQuestionText = (txt, editor) => {
    const questionData = editor.getContent();
    const questionId = editor.id;
    const match = questionId.match(/(\d+)-(\w+)/);
    let quesList = [...formList];
    if (match) {
      const Id = match[1];
      const name = match[2];
      quesList[Id][name] = questionData;
      setFormList(quesList);
    }
  };




  const handleChange = (e) => {
    // console.log("event", e.target);
    // e.preventDefault();
    if (
      [
        "questionTitle",
        "option1",
        "option2",
        "option3",
        "option4",
        "option5",
        "rightAnswer",
        "answerDesc",
      ].includes(e.target.name)
    ) {
      let quesList = [...formList];
      quesList[e.target.dataset.id][e.target.name] = e.target.value;
      // console.log("quelist2", quesList);

      // setFormList(quesList);
    } else {
      setFormList({ [e.target.name]: e.target.value });
    }

  };



  const handleAnswerChange = (e) => {
    const id = e.target.dataset.id;
    const value = e.target.value;

    let newArr = [...formList]; // copying the old datas array
    newArr[id].rightAnswer = e.target.value; // replace e.target.value with whatever you want to change it to

    setFormList(newArr);
  };

  const addNewRow = () => {
    setFormList([
      ...formList,
      {
        index: Math.random(),
        questionTitle: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        option5: "",
        rightAnswer: "",
        answerDesc: "",
      },
    ]);
    // setEditorContents([...editorContents, ""]);
  };

  const deteteRow = (index) => {
    setFormList(formList.filter((s, sindex) => index !== sindex));
  };

  const clickOnDelete = (record) => {
    setFormList(formList.filter((r) => r !== record));
    // setEditorContents(editorContents.filter((_, i) => record.index !== i));
  };

  const [errorData, setErrorData] = useState({});

  const { title, passage_check, passage, question, status, logo, imageName } =
    questionData;
  const universityId = localStorage.getItem("universityId");
  const subjectId = match.params.subjectId;
  const yearId = match.params.yearId;
  const sectionId = match.params.sectionId;
  const topicId = match.params.topicId;

  const { isQuestionAdded, user_loading, error, errors } = useSelector(
    (state) => state.questionDetail
  );

  const changePassageText = (txt) => {
    setQuestionData({
      ...questionData,
      passage: txt,
    });
  };

  useEffect(() => {

    if (universityId && universityLists?.length > 0) {
      const temp = [];
      const local = universityLists.find((item) => item.value == universityId)
      temp.push(local)
      setUniversity(temp)
    }

    return () => {

    }
  }, [universityId, universityLists])


  // console.log(university);


  const changeEditorText = (data) => {
    setQuestionData({
      ...questionData,
      question: data,
    });

    //new method to update question
    let newArr = [...formList]; // copying the old datas array
    newArr[0].questionTitle = data; // replace e.target.value with whatever you want to change it to

    setFormList(newArr);
  };

  useEffect(() => {
    dispatch(getAllCategories());

    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isQuestionAdded === true && isQuestionAdded != undefined) {
      alert.success("Question successfully added.");
      history.push(`/admin/questions/${sectionId}/${subjectId}/${yearId}/${topicId}`);
      dispatch({
        type: ADD_QUESTION_RESET,
      });
    }
  }, [dispatch, isQuestionAdded, alert, error, errors]);

  const onChange = (e) => {
    if (e.target.name === "logo") {

      let file = e.target.files[0];

      if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setQuestionData({
          ...questionData,
          logo: "",
          imageName: "",
        });
        alert.error("Please select valid logo.");
        //remove preview picture if any
        const preview = document.getElementById("img-preview");
        preview.src = "";
        return false;
      }

      const reader = new FileReader();

      reader.onload = function (readerEvt) {
        // The file's text will be printed here
        let binaryString = readerEvt.target.result;
        let base64String = btoa(binaryString);
        setQuestionData({
          ...questionData,
          logo: base64String,
          imageName: file.name,
        });

        //preview picture
        const preview = document.getElementById("img-preview");
        preview.src = "data:image/png;base64," + base64String;
      };
      reader.readAsBinaryString(file);
    } else if (e.target.name === "passage_check") {

      // console.log("event check", e.target.name, passage_check);

      if (passage_check == true) {
        // setEditorContents([...editorContents, ""]);
        setFormList([
          {
            index: Math.random(),
            questionTitle: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            option5: "",
            rightAnswer: "",
            answerDesc: "",
          },
        ])
      }

      setQuestionData({
        ...questionData,
        passage_check: !passage_check,
      });
    } else if (e.target.name === "status") {
      setQuestionData({
        ...questionData,
        status: !status,
      });
    } else {
      setQuestionData({
        ...questionData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const setCorrectAnswer = (answer) => {
    setQuestionData({
      ...questionData,
      correct_answer: answer,
    });
  };



  const submitHandler = (e) => {
    e.preventDefault();

    if (formList && formList[0]?.rightAnswer == '') {
      alert.error("Please select atleast one correct answer.");
    } else {

      const isExist = university.find((item) => item.value == universityId)
      let temp = []

      if (isExist) {
        temp = [...university]
      } else if (!isExist) {
        temp = [...university]
        const local = universityLists.find((item) => item.value == universityId)
        temp.push(local)
      }


      // console.log("data add","subjID",subjectId,
      //     "SubjId",topicId,
      //     "yearId",yearId,
      //     "sectionId",sectionId,
      //     "title",title,
      //     "Passage chek",passage_check,
      //     "Passage",passage,
      //     "formList",formList,
      //     "status",status,
      //     "Logo",logo,
      //     "image Name",imageName,
      //     "Temp",temp,
      //     "ExamName",examSlug)

      dispatch(
        addQuestion(
          subjectId,
          topicId,
          yearId,
          sectionId,
          title,
          passage_check,
          passage,
          formList,
          status,
          logo,
          imageName,
          temp,
          examSlug

        )
      );
    }
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Add
                <small>Question</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard" /> Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/admin/questions/${sectionId}/${subjectId}/${yearId}/${topicId}`}
                  >
                    <i className="fa fa-users" /> Questions
                  </Link>
                </li>
                <li className="active">Add Question</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Question</h3>
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={onChange}
                        />
                        {errorData.title ? (
                          <span className="text-danger">{errorData.title}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {examSlug == 'post-utme' && <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="title">Select University</label>

                        <Select
                          value={university}
                          onChange={setUniversity}
                          options={universityLists}
                          isMulti
                          components={animatedComponents}
                          isSearchable={true}
                        />

                      </div>
                    </div>}

                    {true && (
                      <Fragment>
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="passage_check">
                              {subject === "english" ? "Common Passage / Linked Question" : "Linked Question"}
                            </label>
                            <input
                              style={{ marginRight: "5px" }}
                              type="checkbox"
                              className="form-check-input"
                              id="passage_check"
                              name="passage_check"
                              checked={passage_check}
                              value={passage_check}
                              onChange={onChange}
                            />
                          </div>
                        </div>

                        {passage_check === true && (
                          <div className="form-group row">
                            <div className="col-md-12">
                              <label htmlFor="Passage_Image">{subject !== "english" ? "Question Information" : "Passage / Question Information"} </label>
                              <Editor
                                apiKey="7hgk5af9gk831np2nnm83jqxqeyr8ft56gpan3th57e3u8q0"
                                init={{
                                  height: 200,
                                  menubar: false,
                                  plugins: [
                                    "advlist autolink lists link image imagetools charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                  ],
                                  external_plugins: {
                                    tiny_mce_wiris:
                                      'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
                                  },
                                  toolbar:
                                    // "undo redo | formatselect  " +
                                    // "bold italic backcolor | alignleft aligncenter | MathType" +
                                    // "alignright alignjustify | bullist numlist outdent indent | " +
                                    // "removeformat | help",
                                    "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                    "undo redo | formatselect " +
                                    "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                    "bullist numlist outdent indent | " +
                                    "removeformat | help",
                                  htmlAllowedTags: ['.*'],
                                  htmlAllowedAttrs: ['.*'],
                                  extended_valid_elements: '*[.*]',
                                  paste_data_images: true,
                                  file_picker_types: 'image',
                                  paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',
                                  // plugins: 'paste', // Include paste plugin
                                  // paste_as_text: false, // Allow pasting as HTML
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                }}

                                value={passage}
                                // initialValue={passage}
                                onEditorChange={(newText) =>
                                  changePassageText(newText)
                                }
                              />

                              {errorData.passage ? (
                                <span className="text-danger">
                                  {errorData.passage}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}

                    {!passage_check && (
                      <div className="form-group row">
                        <div className="col-md-12 customCKeditor">
                          <label htmlFor="question">Question</label>
                          <Editor
                            apiKey="7hgk5af9gk831np2nnm83jqxqeyr8ft56gpan3th57e3u8q0"
                            init={{
                              height: 200,
                              menubar: false,
                              external_plugins: {
                                tiny_mce_wiris:
                                  "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                //'http://www.onetimesuccess.com/node_modules/@wiris/mathtype-tinymce5/plugin.min.js'
                              },
                              plugins: [
                                "advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                              ],
                              toolbar:
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                "undo redo | formatselect " +
                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                "bullist numlist outdent indent | " +
                                "removeformat | help",
                              htmlAllowedTags: ['.*'],
                              htmlAllowedAttrs: ['.*'],
                              extended_valid_elements: '*[.*]',
                              paste_data_images: true,
                              file_picker_types: 'image',
                              // plugins: 'paste', // Include paste plugin
                              // paste_as_text: false, // Allow pasting as HTML
                              paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}

                            value={question}
                            // initialValue={question}
                            onEditorChange={(newText) =>
                              changeEditorText(newText)
                            }
                            required
                          />

                          {/* <Editor
                            apiKey="7hgk5af9gk831np2nnm83jqxqeyr8ft56gpan3th57e3u8q0"
                            initialValue="<p>This is the initial content of the editor</p>"
                            init={{
                              height: 500,
                              plugins: 'math',
                              toolbar: 'math',
                              mathTypeParameters: {
                                lang: 'en',
                                dir: 'ltr',
                                base: 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js',
                                format: 'mathml', // or 'image' for image output
                              },
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount '
                              ],
                              external_plugins: {
                                'tiny_mce_wiris': `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`
                              },
                              toolbar: [
                                ' bold italic |' +
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                "undo redo | formatselect " +
                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                "bullist numlist outdent indent | " +
                                "removeformat | help | image code ",
                              ],
                              htmlAllowedTags: ['.*'],
                              htmlAllowedAttrs: ['.*'],
                              extended_valid_elements: '*[.*]',
                              paste_data_images: true,
                              file_picker_types: 'image',
                            }}
                            onEditorChange={(newText) =>
                              changeEditorText(newText)
                            }
                          /> */}
                          {/* <MathTypeEditor /> */}
                          {errorData.questionTitle ? (
                            <span className="text-danger">
                              {errorData.questionTitle}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}

                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="logo">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          onChange={onChange}
                          accept={`.jpeg, .jpg, .png`}
                        // required
                        />
                        {errorData.logo ? (
                          <span className="text-danger">{errorData.logo}</span>
                        ) : (
                          ""
                        )}
                        <div>
                          <img id="img-preview" src="" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="questionData">
                      <QuestionForm
                        handleChange={handleChange}
                        handleAnswerChange={handleAnswerChange}
                        add={addNewRow}
                        delete={clickOnDelete.bind(this)}
                        formList={formList}
                        subject={subject}
                        passage_check={passage_check}
                        changeLinkedQuestionText={changeLinkedQuestionText}
                        // changeLinkedQuestionOptionText={changeLinkedQuestionOptionText}
                        editorContent={editorContents}
                      />
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status</label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          name="status"
                          checked={status}
                          value={status}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddQuestion;
