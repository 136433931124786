import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
const MockPerformanceGraph = ({ label, labels, quizzes, max }) => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                max: max,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                font: {
                    size: 20,
                },
                display: true,
                text: label,
            },
        },
    };



    const Data = {
        labels,
        datasets: [
            {
                label: '',
                data: quizzes?.map((item, index) => item),
                borderColor: 'rgb(20, 223, 125)',
                backgroundColor: 'rgb(20, 223, 125)',
            }
        ],
    };

    return (
        <div className="main-view-content">
            <Line data={Data} options={options} />
        </div>
    )
}

export default MockPerformanceGraph
