import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useAlert } from 'react-alert';

import Loader from '../Loader';

import './mock.css';
import { getExamInstruction } from '../../actions/mockActions';

const StartExam = ({ history, match }) => {
	const dispatch = useDispatch();
	const { user, loading, error } = useSelector((state) => state.auth);
	const examType = localStorage.getItem("examType");
	const examId = localStorage.getItem('examId');
	useEffect(
		() => {
			dispatch(getExamInstruction(examId));
		},
		[dispatch, user]
	);

	const { instruction } = useSelector((state) => state.mockList);
	// console.log("instruction", instruction);
	var timeDuration = 15;
	if (instruction !== undefined) {
		if (instruction.timeHour !== undefined) {
			timeDuration = Math.floor(instruction.timeHour * 60);
		}
		if (instruction.time !== undefined && instruction.time !== '00') {
			timeDuration = parseInt(timeDuration) + parseInt(instruction.time);
		}
	}
	localStorage.setItem("timeDuration", timeDuration);

	const proceedToExam = (e) => {
		e.preventDefault();
		// alert("Hello"); return false;
		// history.push(`/mock-exam/${examId}`); 
		// history.push(`/list-questions`);
		// return false;
		// window.open("http://trustseal.enamad.ir/Verify.aspx?id=15288&p=nbpdyncrwkynaqgwaqgw", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30");
		var h = window.screen.height;
		var w = window.screen.width;
		var t = window.location.href;
		if (examType === "theory") {
			var r = t.replace('start-exam', 'theories-questions');
		} else {
			var r = t.replace('start-exam', 'objective-questions');
		}
		window.open(r, 'window', 'toolbar=no, menubar=no, resizable=no,width=' + w + ',height=' + h);

        history.push(`/dashboard`);
        return false;
	};

	return (
		<Fragment>
			<section className="start-mock-sec text-center">
			{/* <div className="container start-mock m-2">
					<button type="button" className="btn start-mock-btn" onClick={proceedToExam}>
						Select Topics 
					</button>
				</div>
				<div></div> */}
				<div className="container start-mock">
					<button type="button" className="btn start-mock-btn" onClick={proceedToExam}>
						Click to Start   &nbsp;<i className="fas fa-arrow-right" />
					</button>
				</div>
			</section>
		</Fragment>
	);
};

export default StartExam;
