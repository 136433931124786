import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Loader from "react-loader-spinner";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import { getStudents } from "../../../../actions/adminStudentActions";
import { deleteStudentData } from "../../../../actions/adminStudentActions";
import Loader from "../../../Loader";

const ListStudents = ({ history }) => {
  const [refresh, setRefresh] = useState(false);
  // const [Customers, setCustomersData] = useState({});

  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudents());
  }, [dispatch,refresh]);

  const { students, user_loading } = useSelector((state) => state);

  const handleDeleteUser = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteStudentData(id));
            alert.success("Student deleted successfully");
            // dispatch(getStudents());
            setRefresh((prev) => !prev);
            // history.push(`/admin/students`);
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Manage
            <small>Students</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li className="active">Students</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">Students</h3>
                  {/*&nbsp;
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              <p className="box-title">
                                <Link to={"/admin/users/add"} className="">+ Add Admin User</Link>
                              </p>*/}

                  <div className="box-tools">
                    <Link
                      to={"/admin/students/add"}
                      className="btn btn-sm btn-success"
                    >
                      + Add New Student
                    </Link>
                    {/*<div className="input-group input-group-sm hidden-xs" style={{ width: "150px" }}>
                                  <input type="text" name="table_search" className="form-control pull-right" placeholder="Search"/>
                                  <div className="input-group-btn">
                                    <button type="submit" className="btn btn-default"><i className="fa fa-search"></i></button>
                                  </div>
                                </div>*/}
                  </div>
                </div>
                <div className="box-body table-responsive no-padding">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.user_loading ? (
                        <Loader />
                      ) : students.students.length ? (
                        students.students.map((user) => (
                          <tr key={user._id}>
                            <td>
                              {user.first_name} {user.last_name}
                            </td>
                            <td>{user.email}</td>
                            <td>
                              {user.mobile_code} {user.mobile_number}
                            </td>
                            <td>
                              {user.accountStatus === "Active" && (
                                <span className="label bg-green">
                                  {user.accountStatus}
                                </span>
                              )}
                              {user.accountStatus === "InActive" && (
                                <span className="label bg-yellow">
                                  {user.accountStatus}
                                </span>
                              )}
                              {user.accountStatus === "Blocked" && (
                                <span className="label bg-red">
                                  {user.accountStatus}
                                </span>
                              )}
                              {user.accountStatus === "Suspended" && (
                                <span className="label bg-gray">
                                  {user.accountStatus}
                                </span>
                              )}
                            </td>
                            <td>
                              <Link
                                to={`/admin/students/edit/${user._id}`}
                                className="btn btn-success btn-sm"
                                title="Edit"
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                              &nbsp;
                              <a
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={() => handleDeleteUser(user._id)}
                                key={user._id}
                              >
                                <i className="fa fa-trash"></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default ListStudents;
