import axios from "axios";
import {
  LOAD_ALL_ADVICE_REQUEST,
  LOAD_ALL_ADVICE_SUCCESS,
  LOAD_ALL_ADVICE_FAIL,
  ADD_ADVICE_REQUEST,
  ADD_ADVICE_SUCCESS,
  ADD_ADVICE_RESET,
  ADD_ADVICE_FAIL,
  ADVICE_DETAIL_REQUEST,
  ADVICE_DETAIL_SUCCESS,
  ADVICE_DETAIL_FAIL,
  UPDATE_ADVICE_REQUEST,
  UPDATE_ADVICE_SUCCESS,
  UPDATE_ADVICE_RESET,
  UPDATE_ADVICE_FAIL,
  DELETE_ADVICE_REQUEST,
  DELETE_ADVICE_SUCCESS,
  DELETE_ADVICE_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminAdviceConstants";

const _api_path = "/api/v1";

//get all advice
export const getAdvices = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_ADVICE_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/advice-list`);
    dispatch({
      type: LOAD_ALL_ADVICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_ADVICE_FAIL,
      payload: error,
    });
  }
};

//get advice detail
export const getAdviceDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADVICE_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/advice-list/${id}`);
    dispatch({
      type: ADVICE_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: ADVICE_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// add advice
export const addAdvice =
  (title, min_percentage, max_percentage, description, status) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_ADVICE_REQUEST,
      });
      const config = {
        headers: {
          //"content-type": "multipart/form-data",
          // "Accept": "application/json",
          "content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        _api_path + "/admin/advice-list/add",
        {
          title,
          min_percentage,
          max_percentage,
          description,
          status,
        },
        config
      );
      dispatch({
        type: ADD_ADVICE_SUCCESS,
        payload: data.advice
      });
    } catch (error) {
      dispatch({
        type: ADD_ADVICE_FAIL,
        payload: error.response.data.errMessage,
        errors: error.response.data,
      });
    }
  };

// Update advice
export const updateAdvice =
  (adviceId, title, min_percentage, max_percentage, description, status) =>
  async (dispatch) => {
    // console.log('status ', status);
    try {
      dispatch({
        type: UPDATE_ADVICE_REQUEST,
      });

      const config = {
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        // }
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.put(
        _api_path + `/admin/advice-list/update/${adviceId}`,
        {
          adviceId,
          title,
          min_percentage,
          max_percentage,
          description,
          status,
        },
        config
      );

      dispatch({
        type: UPDATE_ADVICE_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ADVICE_FAIL,
        payload: error.response.data.errMessage,
        errors: error.response.data,
      });
    }
  };

// Delete advice
export const deleteAdviceData = (adviceId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_ADVICE_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/advice-list/${adviceId}`
    );

    dispatch({
      type: DELETE_ADVICE_SUCCESS,
      payload: adviceId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ADVICE_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
