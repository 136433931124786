export const LOAD_ALL_EXAMS_REQUEST = "LOAD_ALL_EXAMS_REQUEST";
export const LOAD_ALL_EXAMS_SUCCESS = "LOAD_ALL_EXAMS_SUCCESS";
export const LOAD_ALL_EXAMS_FAIL = "LOAD_ALL_EXAMS_FAIL";

export const EXAM_DETAIL_REQUEST = "EXAM_DETAIL_REQUEST";
export const EXAM_DETAIL_SUCCESS = "EXAM_DETAIL_SUCCESS";
export const EXAM_DETAIL_FAIL = "EXAM_DETAIL_FAIL";

export const ADD_EXAM_REQUEST = "ADD_EXAM_REQUEST";
export const ADD_EXAM_SUCCESS = "ADD_EXAM_SUCCESS";
export const ADD_EXAM_RESET = "ADD_EXAM_RESET";
export const ADD_EXAM_FAIL = "ADD_EXAM_FAIL";

export const UPDATE_EXAM_REQUEST = "UPDATE_EXAM_REQUEST";
export const UPDATE_EXAM_SUCCESS = "UPDATE_EXAM_SUCCESS";
export const UPDATE_EXAM_RESET = "UPDATE_EXAM_RESET";
export const UPDATE_EXAM_FAIL = "UPDATE_EXAM_FAIL";
export const DELETE_EXAM_REQUEST = "DELETE_EXAM_REQUEST";
export const DELETE_EXAM_SUCCESS = "DELETE_EXAM_SUCCESS";
export const DELETE_EXAM_FAIL = "DELETE_EXAM_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
