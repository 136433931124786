import React, { Fragement, Fragment } from "react";
import { Pie } from 'react-chartjs-2';

const PieChart = (props) => {
    const data = {
        labels: ['Correct', 'Incorrect', 'unseen'],
        datasets: [
            {
                label: '# of Votes',
                data: [props.correctAnswers, props.incorrectAnswers, props.unseenCount],
                backgroundColor: [
                    'green',
                    'red',
                    'blue'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Fragment>
            <Pie data={data}
                 width={400}
                 height={200}
                 options={{ maintainAspectRatio: false }}
            />
        </Fragment>
    );
};

export default PieChart;
