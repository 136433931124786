export const LOAD_ALL_CATEGORIES_REQUEST = "LOAD_ALL_CATEGORIES_REQUEST";
export const LOAD_ALL_CATEGORIES_SUCCESS = "LOAD_ALL_CATEGORIES_SUCCESS";
export const LOAD_ALL_CATEGORIES_FAIL = "LOAD_ALL_CATEGORIES_FAIL";

export const CATEGORY_DETAIL_REQUEST = "CATEGORY_DETAIL_REQUEST";
export const CATEGORY_DETAIL_SUCCESS = "CATEGORY_DETAIL_SUCCESS";
export const CATEGORY_DETAIL_FAIL = "CATEGORY_DETAIL_FAIL";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_RESET = "ADD_CATEGORY_RESET";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_RESET = "UPDATE_CATEGORY_RESET";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
