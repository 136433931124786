import React, {Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loader from "../../Loader";

import { useDispatch, useSelector } from 'react-redux';
import { getCmsPages } from '../../../actions/adminCmsActions';

const ListCms = () => {

    const dispatch  = useDispatch();

    useEffect(() => {
        dispatch(getCmsPages());
    }, [dispatch]);

    const { pages, page_loading } = useSelector(state => state.cmsList);

    return (
        <Fragment>
            {page_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Manage
                                <small>Pages</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li><Link to="/admin"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
                                <li className="active">Pages</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="box">
                                        <div className="box-header">
                                            <h3 className="box-title">Pages</h3>
                                        </div>
                                        <div className="box-body table-responsive no-padding">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Slug</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {pages.page_loading ? <Loader /> : ((pages.length)? pages.map(pageItem => (
                                                    <tr key={pageItem._id}>
                                                        <td>{pageItem.page_title}</td>
                                                        <td>{pageItem.page_url}</td>
                                                        <td>
                                                            {pageItem.status === 'Active' && (
                                                                <span className="badge bg-green">{pageItem.status}</span>
                                                            )}
                                                            {pageItem.status === 'InActive' && (
                                                                <span className="badge bg-yellow">{pageItem.status}</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Link to={`/admin/pages/edit/${pageItem._id}`} className="btn btn-success btn-sm" title="Edit" ><i className="fa fa-edit"></i></Link>
                                                        </td>
                                                    </tr>
                                                )): <tr>
                                                    <td colSpan="2">
                                                        No records found
                                                    </td>
                                                </tr>)}

                                                </tbody>
                                                <tfoot>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
}

export default ListCms;
