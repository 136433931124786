import axios from "axios";
import {
  LOAD_ALL_EXAMS_REQUEST,
  LOAD_ALL_EXAMS_SUCCESS,
  LOAD_ALL_EXAMS_FAIL,
  ADD_EXAM_REQUEST,
  ADD_EXAM_SUCCESS,
  ADD_EXAM_RESET,
  ADD_EXAM_FAIL,
  EXAM_DETAIL_REQUEST,
  EXAM_DETAIL_SUCCESS,
  EXAM_DETAIL_FAIL,
  UPDATE_EXAM_REQUEST,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_RESET,
  UPDATE_EXAM_FAIL,
  DELETE_EXAM_REQUEST,
  DELETE_EXAM_SUCCESS,
  DELETE_EXAM_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminExamConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all exams
export const getExams = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_EXAMS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/exams`);

    dispatch({
      type: LOAD_ALL_EXAMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_EXAMS_FAIL,
      payload: error,
    });
  }
};

//get category detail
export const getExamDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EXAM_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/exams/${id}`);
    // console.log('data ', data)
    dispatch({
      type: EXAM_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: EXAM_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Update category
export const updateExam = (
  examId,
  title,
  maximumSubjects,
  yearOfExamination,
  examType,
  timeHour,
  time,
  examInstructions,
  status,
  oldImage,
  logo,
  imageName,
  videoLink
) => async (dispatch) => {
  // console.log('status ', status);
  try {
    dispatch({
      type: UPDATE_EXAM_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/exams/update/${examId}`,
      {
        examId,
        title,
        maximumSubjects,
        yearOfExamination,
        examType,
        timeHour,
        time,
        examInstructions,
        status,
        oldImage,
        logo,
        imageName,
        videoLink
      },
      config
    );

    dispatch({
      type: UPDATE_EXAM_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_EXAM_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

export const addExam = (
  title,
  maximumSubjects,
  yearOfExamination,
  examType,
  timeHour,
  time,
  examInstructions,
  status,
  logo,
  imageName
) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_EXAM_REQUEST,
    });
    const config = {
      headers: {
        //"content-type": "multipart/form-data",
        // "Accept": "application/json",
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/admin/exams/add",
      {
        title,
        maximumSubjects,
        yearOfExamination,
        examType,
        timeHour,
        time,
        examInstructions,
        status,
        logo,
        imageName,
      },
      config
    );
    if (!data) {
      dispatch({
        type: ADD_EXAM_FAIL,
        payload: "Invalid email or password",
      });
    }
    dispatch({
      type: ADD_EXAM_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: ADD_EXAM_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Delete category
export const deleteExamData = (examId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_EXAM_REQUEST,
    });

    const { data } = await axios.delete(_api_path + `/admin/exams/${examId}`);

    dispatch({
      type: DELETE_EXAM_SUCCESS,
      payload: examId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_EXAM_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
