import {
  GET_YEAR_QUESTIONS_REQUEST,
  GET_YEAR_QUESTIONS_SUCCESS,
  GET_YEAR_QUESTIONS_FAIL,
  CLEAR_ERRORS,
} from "./../constants/questionConstants";

export const yearQuestionsReducer = (
  state = {
    questions: [],
  },
  action
) => {
  switch (action.type) {
    case GET_YEAR_QUESTIONS_REQUEST:
      return {
        qLoading: true,
        questions: [],
      };

    case GET_YEAR_QUESTIONS_SUCCESS:
      return {
        qLoading: false,
        questions: action.payload.questionsList,
        questionCount: action.questionCount,
        examData: action.currentExam
      };

    case GET_YEAR_QUESTIONS_FAIL:
      return {
        qLoading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
