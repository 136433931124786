import React, {Fragment}  from 'react';

const AdminLoginLayout = props => {
    return (
        <Fragment>
            <div className="login-page">
                {props.children}
            </div>
        </Fragment>
    );
}

export default AdminLoginLayout;
