import React, { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { resetPassword, clearErrors } from "../../actions/authActions";
import AuthCommonBanner from "./AuthCommonBanner";

const ResetPassword = ({ history, match }) => {
  const alert = useAlert();

  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [errorData, setErrorData] = useState({});

  const { error, success, passwordErrors } = useSelector(
    (state) => state.forgotPassword
  );

  useEffect(() => {
    if (passwordErrors) {
      setErrorData(passwordErrors);
      // console.log("loginErrors ", loginErrors); return;
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Password updated successfully");
      history.push("/login");
    }
  }, [dispatch, alert, error, success, history]);

  const submitHandler = (e) => {
    e.preventDefault();
    //submit the form

    dispatch(resetPassword(match.params.token, password, confirm_password));
  };
  const goToHome = () => {
    history.push("/");
  };
  return (
    <section className="registration-page clearfix fullHt">
      <div className="right-regi-step login-page">
        <div className="vh-center">
          <h1>
            Reset <span>Password</span>
          </h1>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.{" "}
          </p> */}
          <div className="inner-que">
            <form onSubmit={submitHandler}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="password">
                      Password<span className="sub">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errorData.password ? (
                      <span className="text-danger">{errorData.password}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="confirm_password">
                      Confirm Password<span className="sub">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Confirm Password"
                      name="confirm_password"
                      value={confirm_password}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {errorData.confirm_password ? (
                      <span className="text-danger">
                        {errorData.confirm_password}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <ul className="log-btn-main clearfix">
                <li className="pull-right">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
      <AuthCommonBanner></AuthCommonBanner>
    </section>
  );
};

export default ResetPassword;
