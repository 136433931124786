export const LIST_UNIVERSITY_REQUEST = "LIST_UNIVERSITY_REQUEST";
export const LIST_UNIVERSITY_SUCCESS = "LIST_UNIVERSITY_SUCCESS";
export const LIST_UNIVERSITY_FAIL = "LIST_UNIVERSITY_FAIL";

export const ADD_UNIVERSITY_REQUEST = "ADD_UNIVERSITY_REQUEST";
export const ADD_UNIVERSITY_SUCCESS = "ADD_UNIVERSITY_SUCCESS";
export const ADD_UNIVERSITY_RESET = "ADD_UNIVERSITY_RESET";
export const ADD_UNIVERSITY_FAIL = "ADD_UNIVERSITY_RESET";


export const UNIVERSITY_DETAIL_REQUEST = "UNIVERSITY_DETAIL_REQUEST";
export const UNIVERSITY_DETAIL_SUCCESS = "UNIVERSITY_DETAIL_SUCCESS";
export const UNIVERSITY_DETAIL_FAIL = "UNIVERSITY_DETAIL_FAIL";


export const UPDATE_UNIVERSITY_REQUEST = "UPDATE_UNIVERSITY_REQUEST";
export const UPDATE_UNIVERSITY_SUCCESS = "UPDATE_UNIVERSITY_SUCCESS";
export const UPDATE_UNIVERSITY_RESET = "UPDATE_UNIVERSITY_RESET";
export const UPDATE_UNIVERSITY_FAIL = "UPDATE_UNIVERSITY_FAIL";

export const DELETE_UNIVERSITY_REQUEST = "DELETE_UNIVERSITY_REQUEST";
export const DELETE_UNIVERSITY_SUCCESS = "DELETE_UNIVERSITY_SUCCESS";
export const DELETE_UNIVERSITY_FAIL = "DELETE_UNIVERSITY_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";