import axios from "axios";
import {
  LOAD_ALL_CATEGORIES_REQUEST,
  LOAD_ALL_CATEGORIES_SUCCESS,
  LOAD_ALL_CATEGORIES_FAIL,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_RESET,
  ADD_CATEGORY_FAIL,
  CATEGORY_DETAIL_REQUEST,
  CATEGORY_DETAIL_SUCCESS,
  CATEGORY_DETAIL_FAIL,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_RESET,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminCategoryConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all categories
export const getCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_CATEGORIES_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/categories`);

    dispatch({
      type: LOAD_ALL_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_CATEGORIES_FAIL,
      payload: error,
    });
  }
};

//get category detail
export const getCategoryDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/categories/${id}`);
    // console.log('data ', data)
    dispatch({
      type: CATEGORY_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Update category
export const updateCategory = (
  categoryId,
  title,
  description,
  status,
  oldImage,
  image,
  imageName
) => async (dispatch) => {
  // console.log('status ', status);
  try {
    dispatch({
      type: UPDATE_CATEGORY_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/categories/update/${categoryId}`,
      { categoryId, title, description, status, oldImage, image, imageName },
      config
    );

    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CATEGORY_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

export const addCategory = (
  title,
  description,
  status,
  image,
  imageName
) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CATEGORY_REQUEST,
    });
    const config = {
      headers: {
        //"content-type": "multipart/form-data",
        // "Accept": "application/json",
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/admin/categories/add",
      {
        title,
        description,
        status,
        image,
        imageName,
      },
      config
    );
    if (!data) {
      dispatch({
        type: ADD_CATEGORY_FAIL,
        payload: "Invalid email or password",
      });
    }
    dispatch({
      type: ADD_CATEGORY_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: ADD_CATEGORY_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Delete category
export const deleteCategoryData = (categoryId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_CATEGORY_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/categories/${categoryId}`
    );

    dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: categoryId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CATEGORY_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
