import axios from "axios";
import {
  LOAD_ALL_SUBSCRIPTIONS_REQUEST,
  LOAD_ALL_SUBSCRIPTIONS_SUCCESS,
  LOAD_ALL_SUBSCRIPTIONS_FAIL,
  ADD_SUBSCRIPTION_REQUEST,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_RESET,
  ADD_SUBSCRIPTION_FAIL,
  SUBSCRIPTION_DETAIL_REQUEST,
  SUBSCRIPTION_DETAIL_SUCCESS,
  SUBSCRIPTION_DETAIL_FAIL,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION_REQUEST,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  /**
   * List student subscriptions
   */
  LIST_STUDENT_SUBSCRIPTIONS_REQUEST,
  LIST_STUDENT_SUBSCRIPTIONS_SUCCESS,
  LIST_STUDENT_SUBSCRIPTIONS_FAIL,
  /**
   * List student subscriptions
   */
  CLEAR_ERRORS,
} from "../constants/adminSubscriptionConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all subscriptions
export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_SUBSCRIPTIONS_REQUEST,
    });

    const { data } = await axios.get(_api_path + "/admin/subscriptions");

    dispatch({
      type: LOAD_ALL_SUBSCRIPTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_SUBSCRIPTIONS_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

export const addSubscription =
  (
    examType,
    title,
    numOfQuestions,
    subscriptionType,
    duration,
    price,
    description,
    status
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_SUBSCRIPTION_REQUEST,
      });
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        _api_path + "/admin/subscriptions/add",
        {
          examType,
          title,
          numOfQuestions,
          subscriptionType,
          duration,
          price,
          description,
          status,
        },
        config
      );
      dispatch({
        type: ADD_SUBSCRIPTION_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: ADD_SUBSCRIPTION_FAIL,
        payload: error.response.data.errMessage,
        errors: error.response.data,
      });
    }
  };

//get subscription detail
export const getSubscriptionDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SUBSCRIPTION_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/subscriptions/${id}`);
    // console.log('data ', data)
    dispatch({
      type: SUBSCRIPTION_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Update subscription
export const updateSubscription =
  (
    subscriptionId,
    examType,
    title,
    numOfQuestions,
    duration,
    subscriptionType,
    price,
    description,
    status
  ) =>
  async (dispatch) => {
    // console.log('status ', status);
    try {
      dispatch({
        type: UPDATE_SUBSCRIPTION_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.put(
        _api_path + `/admin/subscriptions/update/${subscriptionId}`,
        {
          subscriptionId,
          examType,
          title,
          numOfQuestions,
          duration,
          subscriptionType,
          price,
          description,
          status,
        },
        config
      );

      dispatch({
        type: UPDATE_SUBSCRIPTION_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SUBSCRIPTION_FAIL,
        payload: error.response.data.errMessage,
      });
    }
  };

// Delete subscription
export const deleteSubscriptionData = (subscriptionId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_SUBSCRIPTION_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/subscriptions/${subscriptionId}`
    );

    dispatch({
      type: DELETE_SUBSCRIPTION_SUCCESS,
      subscriptionId,
      //payload: data.success
    });
  } catch (error) {
    dispatch({
      type: DELETE_SUBSCRIPTION_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

/**
 * List all student subscriptions
 */
export const getStudentSubscriptions = () => async (dispatch) => {
  try {
    dispatch({
      type: LIST_STUDENT_SUBSCRIPTIONS_REQUEST,
    });

    const { data } = await axios.get(
      _api_path + "/admin/students-subscriptions"
    );
    dispatch({
      type: LIST_STUDENT_SUBSCRIPTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_STUDENT_SUBSCRIPTIONS_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
