import axios from "axios";
import {
  LOAD_ALL_SUBSCRIBE_NEWSLETTER_REQUEST,
  LOAD_ALL_SUBSCRIBE_NEWSLETTER_SUCCESS,
  LOAD_ALL_SUBSCRIBE_NEWSLETTER_FAIL,
  ADD_SUBSCRIBE_NEWSLETTER_REQUEST,
  ADD_SUBSCRIBE_NEWSLETTER_SUCCESS,
  ADD_SUBSCRIBE_NEWSLETTER_FAIL,  
  DELETE_SUBSCRIBE_NEWSLETTER_REQUEST,
  DELETE_SUBSCRIBE_NEWSLETTER_SUCCESS,
  DELETE_SUBSCRIBE_NEWSLETTER_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminSubscribeNewsletterConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all SUBSCRIBE_NEWSLETTER
export const getSubscribeNewsletter = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_SUBSCRIBE_NEWSLETTER_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/subscribenewsletter`);

    dispatch({
      type: LOAD_ALL_SUBSCRIBE_NEWSLETTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_SUBSCRIBE_NEWSLETTER_FAIL,
      payload: error,
    });
  }
};

export const addSubscribeNewsletter = (
  email
) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SUBSCRIBE_NEWSLETTER_REQUEST,
    });
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/admin/subscribenewsletter/add",
      {
        email
      },
      config
    );
    if (!data) {
      dispatch({
        type: ADD_SUBSCRIBE_NEWSLETTER_FAIL,
        payload: "Invalid id",
      });
    }
    dispatch({
      type: ADD_SUBSCRIBE_NEWSLETTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_SUBSCRIBE_NEWSLETTER_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Delete SUBSCRIBE_NEWSLETTER
export const deleteSubscribeNewsletterData = (categoryId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_SUBSCRIBE_NEWSLETTER_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/subscribenewsletter/${categoryId}`
    );

    dispatch({
      type: DELETE_SUBSCRIBE_NEWSLETTER_SUCCESS,
      payload: categoryId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SUBSCRIBE_NEWSLETTER_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
