export const SAVE_EXAM_SUBJECT_REQUEST = "SAVE_EXAM_SUBJECT_REQUEST";
export const SAVE_EXAM_SUBJECT_SUCCESS = "SAVE_EXAM_SUBJECT_SUCCESS";
export const SAVE_EXAM_SUBJECT_RESET = "SAVE_EXAM_SUBJECT_RESET";
export const SAVE_EXAM_SUBJECT_FAIL = "SAVE_SUBJECT_FAIL";

export const GET_SAVED_EXAM_SUBJECTS_REQUEST = "GET_SAVED_EXAM_SUBJECTS_REQUEST";
export const GET_SAVED_EXAM_SUBJECTS_SUCCESS = "GET_SAVED_EXAM_SUBJECTS_SUCCESS";
export const GET_SAVED_EXAM_SUBJECTS_FAIL = "GET_SAVED_EXAM_SUBJECTS_FAIL";

export const GET_ALL_SAVED_EXAM_SUBJECTS_REQUEST = "GET_ALL_SAVED_EXAM_SUBJECTS_REQUEST";
export const GET_ALL_SAVED_EXAM_SUBJECTS_SUCCESS = "GET_ALL_SAVED_EXAM_SUBJECTS_SUCCESS";
export const GET_ALL_SAVED_EXAM_SUBJECTS_FAIL = "GET_ALL_SAVED_EXAM_SUBJECTS_FAIL";





export const CLEAR_ERRORS = "CLEAR_ERRORS";
