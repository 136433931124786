import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getCourseSubjects } from './../../actions/subjectActions';
import Loader from '../Loader';
import { useHistory } from 'react-router-dom';
import { getSavedExamSubjects, saveExamSubjects } from '../../actions/admin/examSubjectAction';
import { confirmAlert } from 'react-confirm-alert';
import { getUserSubscription } from '../../actions/subscriptionActions';

const CourseSubjects = ({ history, match }) => {

    const alert = useAlert();
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(true);
    const [slugs, setSlugs] = useState([]);
    const [isChecked, setIsChecked] = useState([]);
    const [regSubjects, setRegSubjects] = useState([]);
    const [checkedSubjects, setCheckedSubjects] = useState([]);
    const [proceedSubjects, setProceedSubjects] = useState([]);

    const examId = localStorage.getItem('examId');
    const examName = localStorage.getItem("examName");
    const examSlug = localStorage.getItem("examSlug");
    const courseId = localStorage.getItem('courseId');
    const categoryId = localStorage.getItem('categoryId');
    let ifRegSubjects = localStorage.getItem('RegSubjects');
    const maximumSubjects = localStorage.getItem("maximumSubjects");
    const universityId = localStorage.getItem("universityId");
    const [maxSubjects, setMaxSubjects] = useState([]);
    const userSubscription = localStorage.getItem('userSubscription');

    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const { savedSubjects, isExamSubjects } = useSelector((state) => state.examSubjects);
    const { isSaved } = useSelector((state) => state.courseSubjects);
    const { courseSubjects, csloading, isCourseSubject } = useSelector((state) => state.courseSubjects);
    const { userSubscription: user_subscription, isPlan, sloading } = useSelector((state) => state.examSubscriptions);
    const [subscriptionPlan, setSubscriptionPlan] = useState("");


    const userId = user?._id;

    useEffect(
        () => {
            dispatch(getCourseSubjects(courseId, examId));
            dispatch(getUserSubscription(userId, examId, universityId));
        }, [dispatch]);

    useEffect(() => {
        dispatch(getSavedExamSubjects(userId));

    }, [isCourseSubject])

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        if (isPlan) {
            // console.log(user_subscription?._id);
            setSubscriptionPlan(user_subscription?._id)
        }
        return () => {

        }
    }, [isPlan])


    // console.log("IsPlan", isPlan);


    useEffect(() => {
        if (savedSubjects && savedSubjects.length > 0) {

            savedSubjects?.forEach((item) => {
                if (item?.exam?._id == examId) {
                    setProceedSubjects(item.subjectsProcessed?.map((subject) => subject) || []);
                    setRegSubjects(item.subjects?.map((subject) => subject?.subject?._id) || []);
                }
            });


        }
    }, [isExamSubjects]);


    useEffect(() => {
        // console.log("useEff2");
        // console.log("ischecked", isChecked, courseSubjects);
        if (courseSubjects && courseSubjects.length > 0) {
            setMaxSubjects(courseSubjects[0]?.exam?.maximumSubjects);
            const mandatorySubjects = courseSubjects
                .filter(cs => cs.mandetory === true)
                .map(cs => {
                    return cs._id;
                });

            const uncheckedSubjects = regSubjects.filter(item => !item?.mandetory && !isChecked.includes(item));

            // console.log("mandatory sub", mandatorySubjects, isChecked)
            // const mergedSub = [...mandatorySubjects, ...uncheckedSubjects, ...isChecked];
            const mergedSubSet = new Set([...mandatorySubjects, ...uncheckedSubjects, ...isChecked]);
            const mergedSub = Array.from(mergedSubSet);
            // console.log("set1", mergedSub, uncheckedSubjects, mandatorySubjects, regSubjects, isChecked);
            setIsChecked(mergedSub);

            const SubjectsWithStyled = generateStyledSubjects(mergedSub);
            setCheckedSubjects(SubjectsWithStyled);
        }
    }, [isCourseSubject, regSubjects])

    console.log("corse subject", courseSubjects)

    const generateStyledSubjects = (subjectIds) => {
        return subjectIds.map((id, index) => {
            const subject = courseSubjects.find((c) => c?._id === id);
            const isMandatory = subject?.mandetory === true;

            return (
                <span key={id} style={{ fontWeight: isMandatory ? 'bold' : 'normal', color: isMandatory ? 'red' : 'black' }}>
                    <strong>({index + 1}) </strong>{subject ? subject.title : ''}{' '}
                </span>
            );
        });
    };

    // console.log("checkedSub", isChecked);
    // console.log("ischecked", isChecked);

    const handleCheckboxChange = (event) => {

        console.log("checked", maxSubjects, isChecked?.length);
        // Ensure that isChecked is an array (initially set to an empty array)
        const currentChecked = isChecked;
        // Check if the user is trying to select more subjects than the maximum
        if (currentChecked.length >= maxSubjects && !currentChecked.includes(event.target.value)) {
            console.log(typeof currentChecked?.length, typeof maxSubjects, Number(currentChecked?.length) == Number(maxSubjects))
            // if (currentChecked.length == maxSubjects) {

            const selectedSubjectsInfo = generateStyledSubjects(currentChecked);

            setCheckedSubjects(selectedSubjectsInfo)

            confirmAlert({
                title: 'Error',
                message: (
                    <div>
                        <p>
                            You have reached the maximum limit (<span style={{ fontWeight: 'bold', color: 'black' }}>{maximumSubjects}</span>) of selected subjects.
                        </p>
                        <p>
                            Selected Subjects: {selectedSubjectsInfo}
                        </p>
                        <p>
                            If you want to select another subject, please uncheck the selected subject(s) and choose another subject(s).
                        </p>
                        <p className="bg-danger" style={{ color: 'red' }}>
                            Note: Mandatory subjects cannot be deselected.
                        </p>
                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
            return; // Prevent further processing since the maximum limit is reached
        }

        let newArray = [...isChecked, event.target.value];
        if (isChecked.includes(event.target.value) && courseSubjects?.find((c) => c?._id === event.target.value)?.mandetory === false) {
            newArray = newArray.filter((day) => day !== event.target.value);
        }
        // console.log("set2", newArray);

        setIsChecked(newArray);

        const selectedSubjectsInfo = generateStyledSubjects(newArray);
        setCheckedSubjects(selectedSubjectsInfo);
        if (event.target.checked === true) {
            setDisabled(false);
        }

        //set slug
        let newArraySlug = [...slugs, event.target.dataset.slug];
        if (slugs.includes(event.target.dataset.slug)) {
            newArraySlug = newArraySlug.filter((day) => day !== event.target.dataset.slug);
        }
        setSlugs(newArraySlug);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const remainingSub = maximumSubjects - isChecked?.length;

        if (proceedSubjects?.length != maximumSubjects) {
            confirmAlert({
                title: 'Confirmation',
                message: (
                    <div>
                        <p>
                            Are you sure you want to select these <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>{checkedSubjects}</strong> subjects?
                        </p>

                        {remainingSub > 0 && <p>
                            You can choose another {remainingSub} more  subjects.
                        </p>}

                        {proceedSubjects?.length == 0 && <>
                            <p>You have not registred any subject yet, go for register</p>
                            <p>
                                Click "Yes" to confirm.
                            </p>
                        </>

                        }

                        {proceedSubjects?.length !== 0 && proceedSubjects?.length !== maximumSubjects &&
                            <>
                                <p>
                                    Click "Yes" to go to register.
                                </p>
                                <p>
                                    Click "No" to go to exam.
                                </p>
                            </>
                        }

                    </div>
                ),
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            console.log("subscriptionPlan at save", subscriptionPlan)
                            const payload = {
                                userId: user._id,
                                examId: examId,
                                categoryId: categoryId ? categoryId : null,
                                courseId: courseId ? courseId : null,
                                userSubscription: subscriptionPlan ? subscriptionPlan : userSubscription,
                                subjects: isChecked,
                                subjectsProcessed: proceedSubjects,
                                subjectsUpdate: [],
                                updateRequest: false
                            }
                            console.log("payload at add sub", payload)

                            dispatch(saveExamSubjects(
                                payload
                            ))
                            dispatch(getSavedExamSubjects(userId))
                            // if (proceedSubjects?.length == 0) {
                            history.push(`/saved-subjects`);
                            // }
                            // else {

                            //     history.push(`/exam-type`);
                            // }

                        },
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            if (proceedSubjects?.length !== 0) {
                                history.push(`/exam-type`);
                            }

                        },
                    },
                ],
            });
        } else {
            history.push(`/exam-type`);

        }

        localStorage.setItem('subjects', isChecked);
        localStorage.setItem('subjectMsg', 'Subject registered successfully.');

    };

    const goToPreviousPage = (e) => {
        e.preventDefault();
        setIsChecked('');
        if (examName === "JSCE (BECE)") {
            history.push(`/exams`);
        } else {

            history.push(`/category-courses`);
        }
    };

    // console.log("csloading and sloading",csloading, sloading, csloading || sloading)

    console.log("ischecked", isChecked, courseSubjects)
    return (
        <Fragment>
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to={`/category-courses`}>Intended Courses Of Study</Link>
                        </li>
                    </ul>
                    <h1>Subject of Study</h1>
                    {examSlug === "jamb-utme" ? (
                        <h3>Subject registration - select any {maximumSubjects} subjects</h3>
                    ) : (
                        <h3>Select {maximumSubjects} Subjects of Choice Required for Intended Course of Study for University/Tertiary Education</h3>
                    )}
                </div>
            </section>

            <section className="subscription-page">
                <div className="container">
                    <div className="end-plan">
                        <h1>Suggested {examName} Subject Combination</h1>
                    </div>
                    <div className="custom-radios-plan custom-check-box course-spaner">
                        {csloading || sloading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {courseSubjects !== undefined && courseSubjects.length > 0 ? (
                                    courseSubjects?.map((item, key) => {
                                        const indexId = key + 1;
                                        return (
                                            <Fragment key={indexId}>
                                                <input
                                                    type="checkbox"
                                                    name="subjects"
                                                    id={`option-${indexId}`}
                                                    value={item._id}
                                                    checked={
                                                        isChecked !== undefined &&
                                                            isChecked !== null &&
                                                            isChecked.length > 0 &&
                                                            isChecked.includes(item._id) ? (
                                                            true
                                                        ) : (
                                                            false
                                                        )
                                                    }
                                                    onChange={handleCheckboxChange}
                                                    data-slug={item.slug}
                                                    disabled={proceedSubjects.includes(item._id) ? true : false}
                                                />

                                                <label
                                                    htmlFor={`option-${indexId}`}
                                                    // className={regSubjects.includes(item._id) ? `option option-${indexId} checked` : `option option-${indexId}`}
                                                    className={`option option-${indexId}`}
                                                >
                                                    <div className="dot" />
                                                    <div className="extype">
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_PUBLIC_URL +
                                                                '/assets/img/subjects/' +
                                                                item.logo
                                                            }
                                                            alt="subject logo"
                                                        />
                                                    </div>
                                                    <span>{item.title}{proceedSubjects.includes(item._id) || item?.mandetory && <span>Already Registered <h5 className='bg-red'>{item?.mandetory && "Mandatory"}</h5></span>}</span>

                                                </label>
                                            </Fragment>
                                        );
                                    })
                                ) : (
                                    <span className='text-center'>Subjects not found</span>
                                )}
                            </div>
                        )}
                    </div>
                    {ifRegSubjects !== undefined && ifRegSubjects !== null ?
                        <div className="next-prev-steps text-center">
                            <button className="chose-more" onClick={submitHandler}>
                                Update
                            </button>
                        </div>
                        :
                        <div className="next-prev-steps text-center">
                            <button onClick={goToPreviousPage} className="chose-more">
                                <i className="fas fa-arrow-left" /> Prev
                            </button>{' '}
                            <button
                                type="submit"
                                className="chose-more"
                                onClick={submitHandler}
                            >
                                Next <i className="fas fa-arrow-right" />
                            </button>
                        </div>}
                </div>
            </section>
        </Fragment>
    );
};

export default CourseSubjects;
