import axios from "axios";
import {
  GET_TESTIMONIALS_REQUEST,
  GET_TESTIMONIALS_SUCCESS,
  GET_TESTIMONIALS_FAIL,
  CLEAR_ERRORS,
} from "../constants/homeConstants";

const _api_path = "/api/v1";

//get all news
export const getTestimonials = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TESTIMONIALS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/get-testimonials`);

    dispatch({
      type: GET_TESTIMONIALS_SUCCESS,
      payload: data.testimonials,
    });
  } catch (error) {
    dispatch({
      type: GET_TESTIMONIALS_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
