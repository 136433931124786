import axios from "axios";
import {
  GET_YEAR_QUESTIONS_REQUEST,
  GET_YEAR_QUESTIONS_SUCCESS,
  GET_YEAR_QUESTIONS_FAIL,
  CLEAR_ERRORS,
} from "../constants/questionConstants";

const _api_path = "/api/v1";

//get year all questions
export const getYearQuestions =
  (examId, examType, subjects, year, shuffleQuestions, shuffleOptions, selectedTopics, questionLimit, university) => async (dispatch) => {
    try {
      dispatch({
        type: GET_YEAR_QUESTIONS_REQUEST,
      });
      // const { data } = await axios.get(
      //   `${_api_path}/get-year-questions/${subjectId}/${year}`
      // );

      const { data } = await axios.post(`${_api_path}/get-year-questions`, {
        examId,
        examType,
        subjects: subjects,
        year: year,
        shuffleQuestions,
        shuffleOptions,
        selectedTopics,
        questionLimit,
        university
      });
      // console.log("data", data)
      dispatch({
        type: GET_YEAR_QUESTIONS_SUCCESS,
        payload: data,
        questionCount: data.totalQuestions,
        currentExam: data.exam
      });
    } catch (error) {
      dispatch({
        type: GET_YEAR_QUESTIONS_FAIL,
        payload: error,
      });
    }
  };
