import axios from "axios";
import {
  LOAD_ALL_TOPICS_REQUEST,
  LOAD_ALL_TOPICS_SUCCESS,
  LOAD_ALL_TOPICS_FAIL,
  ADD_TOPIC_REQUEST,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_RESET,
  ADD_TOPIC_FAIL,
  TOPIC_DETAIL_REQUEST,
  TOPIC_DETAIL_SUCCESS,
  TOPIC_DETAIL_FAIL,
  UPDATE_TOPIC_REQUEST,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_RESET,
  UPDATE_TOPIC_FAIL,
  DELETE_TOPIC_REQUEST,
  DELETE_TOPIC_SUCCESS,
  DELETE_TOPIC_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminTopicConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all topics
export const getTopics = (subjectId) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_TOPICS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/topics/${subjectId}`);

    dispatch({
      type: LOAD_ALL_TOPICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_TOPICS_FAIL,
      payload: error,
    });
  }
};

//get topic detail
export const getTopicDetail = (subjectId, id) => async (dispatch) => {
  try {
    dispatch({
      type: TOPIC_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/topics/${subjectId}/${id}`);
    // console.log('data ', data)
    dispatch({
      type: TOPIC_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: TOPIC_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// add topic
export const addTopic = (title, subjectId, status) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_TOPIC_REQUEST,
    });
    const config = {
      headers: {
        //"content-type": "multipart/form-data",
        // "Accept": "application/json",
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/admin/topics/add",
      {
        title,
        subjectId,
        status,
      },
      config
    );
    // console.log("data ", data);
    dispatch({
      type: ADD_TOPIC_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: ADD_TOPIC_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Update topic
export const updateTopic = (topicId, subjectId, title, status) => async (
  dispatch
) => {
  // console.log('status ', status);
  try {
    dispatch({
      type: UPDATE_TOPIC_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/topics/update/${topicId}`,
      {
        topicId,
        subjectId,
        title,
        status,
      },
      config
    );

    dispatch({
      type: UPDATE_TOPIC_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_TOPIC_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Delete topic
export const deleteTopicData = (topicId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_TOPIC_REQUEST,
    });

    const { data } = await axios.delete(_api_path + `/admin/topics/${topicId}`);

    dispatch({
      type: DELETE_TOPIC_SUCCESS,
      payload: topicId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_TOPIC_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
