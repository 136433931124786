import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { addTopic, clearErrors } from "../../../actions/adminTopicActions";
import { ADD_TOPIC_RESET } from "./../../../constants/adminTopicConstants";

import Loader from "../../Loader";

import "./Topic.css";

const AddTopic = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const subjectId = match.params.subjectId;

  const [topic, setTopic] = useState({
    title: "",
    status: false,
  });
  const [errorData, setErrorData] = useState({});

  const { title, status } = topic;

  const { isTopicAdded, user_loading, error, errors } = useSelector(
    (state) => state.topicDetail
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isTopicAdded === true && isTopicAdded != undefined) {
      alert.success("Topic successfully added.");
      history.push(`/admin/topics/${subjectId}`);
      dispatch({
        type: ADD_TOPIC_RESET,
      });
    }
  }, [dispatch, isTopicAdded, alert, error, errors]);

  const onChange = (e) => {
    if (e.target.name === "status") {
      setTopic({
        ...topic,
        status: !status,
      });
    } else {
      setTopic({
        ...topic,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addTopic(title, subjectId, status));
  };

  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Add
            <small>Topic</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li>
              <Link to={`/admin/subjects`}>
                <i className="fa fa-table"></i> Subjects
              </Link>
            </li>
            <li>
              <Link to={`/admin/topics/${subjectId}`}>
                <i className="fa fa-table"></i> Topics
              </Link>
            </li>
            <li className="active">Add Topic</li>
          </ol>
        </section>

        <section className="content">
          <div className="box box-default">
            <div className="box-header with-border">
              <h3 className="box-title">Add Topic</h3>

              {/*<div className="box-tools pull-right">

                                    </div>*/}
            </div>

            <form
              id="quickForm"
              onSubmit={submitHandler}
              encType="multipart/form-data"
            >
              <div className="box-body">
                <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      id="title"
                      placeholder="Enter Title"
                      value={title}
                      onChange={onChange}
                    />
                    {errorData.title ? (
                      <span className="text-danger">{errorData.title}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="status">Status</label>
                    <input
                      style={{ marginRight: "5px" }}
                      type="checkbox"
                      className="form-check-input"
                      id="status"
                      name="status"
                      checked={status}
                      value={status}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default AddTopic;
