import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  addTopicSection,
  clearErrors,
} from "../../../actions/adminTopicSectionActions";
import { ADD_TOPIC_SECTION_RESET } from "./../../../constants/adminTopicSectionConstants";

import Loader from "../../Loader";

import "./TopicSection.css";

const AddTopicSection = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const topicId = match.params.topicId;

  const [topicSection, setTopicSection] = useState({
    section_name: "",
    topic_count: "",
    status: false,
  });
  const [errorData, setErrorData] = useState({});

  const { section_name, topic_count, status } = topicSection;

  const { isTopicAdded, user_loading, error, errors } = useSelector(
    (state) => state.topicSectionDetail
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isTopicAdded === true && isTopicAdded != undefined) {
      alert.success("Topic successfully added.");
      history.push(`/admin/topic-sections/${topicId}`);
      dispatch({
        type: ADD_TOPIC_SECTION_RESET,
      });
    }
  }, [dispatch, isTopicAdded, alert, error, errors]);

  const onChange = (e) => {
    if (e.target.name === "status") {
      setTopicSection({
        ...topicSection,
        status: !status,
      });
    } else {
      setTopicSection({
        ...topicSection,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addTopicSection(topicId, section_name, topic_count, status));
  };

  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Add
            <small>Section</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li>
              <Link to={`/admin/topic-sections/${topicId}`}>
                <i className="fa fa-table"></i> Topics
              </Link>
            </li>
            <li className="active">Add Section</li>
          </ol>
        </section>

        <section className="content">
          <div className="box box-default">
            <div className="box-header with-border">
              <h3 className="box-title">Add Section</h3>

              {/*<div className="box-tools pull-right">

                                    </div>*/}
            </div>

            <form
              id="quickForm"
              onSubmit={submitHandler}
              encType="multipart/form-data"
            >
              <div className="box-body">
                <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="section_name">Section Name</label>
                    <input
                      type="text"
                      name="section_name"
                      className="form-control"
                      id="section_name"
                      placeholder="Enter Section Name"
                      value={section_name}
                      onChange={onChange}
                    />
                    {errorData.section_name ? (
                      <span className="text-danger">
                        {errorData.section_name}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="topic_count">Topic Count</label>
                    <input
                      type="text"
                      name="topic_count"
                      className="form-control"
                      id="topic_count"
                      placeholder="Enter Topic Count"
                      value={topic_count}
                      onChange={onChange}
                    />
                    {errorData.topic_count ? (
                      <span className="text-danger">
                        {errorData.topic_count}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="status">Status</label>
                    <input
                      style={{ marginRight: "5px" }}
                      type="checkbox"
                      className="form-check-input"
                      id="status"
                      name="status"
                      checked={status}
                      value={status}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default AddTopicSection;
