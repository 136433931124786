import axios from "axios";
import {
  LOAD_ALL_COURSES_REQUEST,
  LOAD_ALL_COURSES_SUCCESS,
  LOAD_ALL_COURSES_FAIL,
  ADD_COURSE_REQUEST,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_RESET,
  ADD_COURSE_FAIL,
  COURSE_DETAIL_REQUEST,
  COURSE_DETAIL_SUCCESS,
  COURSE_DETAIL_FAIL,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_RESET,
  UPDATE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminCourseConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all courses
export const getCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_COURSES_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/courses`);

    dispatch({
      type: LOAD_ALL_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_COURSES_FAIL,
      payload: error,
    });
  }
};

//get course detail
export const getCourseDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/courses/${id}`);
    // console.log('data ', data)
    dispatch({
      type: COURSE_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// add course
export const addCourse = (category, title, status) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_COURSE_REQUEST,
    });
    const config = {
      headers: {
        //"content-type": "multipart/form-data",
        // "Accept": "application/json",
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/admin/courses/add",
      {
        category,
        title,
        status,
      },
      config
    );

    dispatch({
      type: ADD_COURSE_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: ADD_COURSE_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Update course
export const updateCourse = (courseId, category, title, status) => async (
  dispatch
) => {
  // console.log('status ', status);
  try {
    dispatch({
      type: UPDATE_COURSE_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/courses/update/${courseId}`,
      {
        courseId,
        category,
        title,
        status,
      },
      config
    );

    dispatch({
      type: UPDATE_COURSE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_COURSE_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Delete course
export const deleteCourseData = (courseId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_COURSE_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/courses/${courseId}`
    );

    dispatch({
      type: DELETE_COURSE_SUCCESS,
      payload: courseId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COURSE_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
