import React, {Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAlert } from 'react-alert';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { useDispatch, useSelector } from 'react-redux';
import { getContactus, deleteContactusData } from '../../../actions/adminContactusActions';
//import { deleteUserData } from '../../../actions/commonActions';

import Loader from "../../Loader";
// import Loader from "react-loader-spinner";

const ListContactus = ({ history }) => {

    // const [users, setUsersData] = useState({});
    const alert = useAlert();
    const dispatch  = useDispatch();

    
    
    useEffect(() => {
        dispatch(
            getContactus()
        )
    }, [dispatch]);

    const { contactusList, user_loading } = useSelector(state => state);

    const handleDeleteContactus = (id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(deleteContactusData(id));
                        alert.success('Contact us deleted successfully');
                        dispatch(getContactus());
                        window.location.reload();
                    }
                },
                {
                    label: 'No',
                    onClick: () => alert.success('No data deleted')
                }
            ]
        });
    }

    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Manage
                                <small>Contact Us</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li><Link to="/admin"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
                                <li className="active">Contact Us</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="box">
                                        <div className="box-header">
                                            <h3 className="box-title">Contact Us</h3>                                            
                                        </div>
                                        <div className="box-body table-responsive no-padding">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Email</th>
                                                    <th>Subject</th>
                                                    <th>Message</th>
                                                    <th>Is Replied</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {contactusList.user_loading ? <Loader /> : ((contactusList.contactus.length)? contactusList.contactus.map(catItem => (
                                                    <tr key={catItem._id}>
                                                        <td>{catItem.firstName}</td>
                                                        <td>{catItem.mobileNo}</td>
                                                        <td>{catItem.email}</td>
                                                        <td>{catItem.subject}</td>
                                                        <td>{catItem.message}</td>
                                                        <td>
                                                            {catItem.isReply === true && (
                                                                <span className="badge bg-green">Yes</span>
                                                            )}
                                                            {catItem.isReply === false && (
                                                                <span className="badge bg-yellow">No</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Link to={`/admin/contactus/detail/${catItem._id}`} className="btn btn-success btn-sm" title="View" ><i className="fa fa-eye"></i></Link>
                                                            &nbsp;
                                                            <a className="btn btn-danger btn-sm"
                                                               title="Delete"
                                                               onClick={() => handleDeleteContactus(catItem._id)}
                                                               key={catItem._id}
                                                            ><i className="fa fa-trash"></i></a>
                                                        </td>
                                                    </tr>
                                                )): <tr>
                                                    <td colSpan="2">
                                                        No records found
                                                    </td>
                                                </tr>)}

                                                </tbody>
                                                <tfoot>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default ListContactus;
