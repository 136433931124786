import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { addBanner, clearErrors } from "../../../actions/admin/bannerActions";
import { ADD_BANNER_RESET } from "./../../../constants/admin/bannerConstants";

import Loader from "../../Loader";

import "./Banner.css";

const AddBanner = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [banner, setBanner] = useState({
    title: "",
    description: "",
    image: "",
    btnUrl: "",
    btnName: "",
    status: false,
    imageName: "",
  });
  const [errorData, setErrorData] = useState({});

  const { title, description, btnUrl, btnName, status, image, imageName } = banner;

  const { isAdded, bannerLoading, error, errors } = useSelector(
    (state) => state.banners
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isAdded === true && isAdded != undefined) {
      alert.success("Banner successfully added.");
      history.push("/admin/banners");
      dispatch({
        type: ADD_BANNER_RESET,
      });
    }
  }, [dispatch, isAdded, alert, error, errors]);

  const onChange = (e) => {
    if (e.target.name === "image") {
      let file = e.target.files[0];

      if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setBanner({
          ...banner,
          image: "",
          imageName: "",
        });
        alert.error("Please select valid image.");
        //remove preview picture if any
        const preview = document.getElementById("img-preview");
        preview.src = "";
        return false;
      }

      const reader = new FileReader();

      reader.onload = function (readerEvt) {
        // The file's text will be printed here
        //console.log(readerEvt.target.result);
        let binaryString = readerEvt.target.result;
        let base64String = btoa(binaryString);
        setBanner({
          ...banner,
          image: base64String,
          imageName: file.name,
        });

        //preview picture
        const preview = document.getElementById("img-preview");
        // console.log('banary image', base64String);
        preview.src = "data:image/png;base64," + base64String;
      };
      reader.readAsBinaryString(file);
    } else if (e.target.name === "status") {
      setBanner({
        ...banner,
        status: !status,
      });
    } else {
      setBanner({
        ...banner,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      addBanner(title, description, btnUrl, btnName, status, image, imageName)
    );
  };

  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Add
            <small>Banner</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li>
              <Link to="/admin/banners">
                <i className="fa fa-table"></i> Banner
              </Link>
            </li>
            <li className="active">Add Banner</li>
          </ol>
        </section>

        <section className="content">
          <div className="box box-default">
            <div className="box-header with-border">
              <h3 className="box-title">Add Banner</h3>

              {/*<div className="box-tools pull-right">

                                    </div>*/}
            </div>

            <form
              id="quickForm"
              onSubmit={submitHandler}
              encType="multipart/form-data"
            >
              <div className="box-body">
                <div className="form-group row">
                  <div className="col-md-12">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      id="title"
                      placeholder="Enter the banner title"
                      value={title}
                      onChange={onChange}
                    />
                    {errorData.title ? (
                      <span className="text-danger">{errorData.title}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-12">
                    <label htmlFor="description">Description</label>
                    <textarea
                      type="text"
                      name="description"
                      className="form-control"
                      id="description"
                      placeholder="Enter the banner decription"
                      value={description}
                      onChange={onChange}
                    ></textarea>
                    {errorData.description ? (
                      <span className="text-danger">
                        {errorData.description}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="image">Image</label>
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      name="image"
                      onChange={onChange}
                      accept={`.jpeg, .jpg, .png`}
                    />
                    {errorData.image ? (
                      <span className="text-danger">{errorData.image}</span>
                    ) : (
                      ""
                    )}
                    <div>
                      <img id="img-preview" src="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="btnUrl">Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="btnUrl"
                      name="btnUrl"
                      onChange={onChange}
                      value={btnUrl}
                    />
                    {errorData.btnUrl ? (
                      <span className="text-danger">{errorData.btnUrl}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="btnName">Button Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="btnName"
                      name="btnName"
                      onChange={onChange}
                      value={btnName}
                    />
                    {errorData.btnName ? (
                      <span className="text-danger">{errorData.btnName}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 mt-30">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="status"
                      name="status"
                      checked={status}
                      value={status}
                      onChange={onChange}
                    />
                    <label htmlFor="status">Status</label>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              {/* <div className="box-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div> */}
            </form>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default AddBanner;
