import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { getQuestionSubjects } from "../../../actions/adminQuestionActions";

import Loader from "../../Loader";

const ListQuestionSubjects = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestionSubjects());
  }, [dispatch]);

  const { questionSubjects, loading } = useSelector((state) => state.questions);

  const handleOnClickSubject = (e, data) => {
    e.preventDefault();

    // console.log(data);
    localStorage.setItem("subjectName", data.slug);
    history.push(`/admin/subject-years/${data._id}`);
  };

  // console.log(questionSubjects);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Question Subjects</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li className="active">Question Subjects</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Question Subjects</h3>
                      <div className="box-tools">
                        <Link
                          to={"/admin/question-subjects/add"}
                          className="btn btn-success btn-sm"
                        >
                          + Add Question Subject
                        </Link>
                      </div>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Subject</th>
                            <th>Total Questions</th>
                            <th>Years</th>
                          </tr>
                        </thead>
                        <tbody>
                          {questionSubjects != undefined &&
                          questionSubjects.length ? (
                            questionSubjects.map((item) => (
                              <>
                              <h4 className="ml-1">Exam Name : {item?.examName}</h4>
                              {
                                item?.subjects && item?.subjects?.length > 0 && item?.subjects?.map((sub) => {
                                return <tr key={sub?._id}>
                                <td>{sub?.title}</td>
                                <td>{sub.totalQuestions}</td>
                                <td>
                                  <Link
                                  to="#"
                                    //to={`/admin/subject-years/${item._id._id}`}
                                    onClick={(e) => handleOnClickSubject(e, sub)}
                                    // data-subject={item}
                                    className="btn btn-success btn-sm"
                                    title="View"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link>
                                </td>
                              </tr>
                                })
                              }
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListQuestionSubjects;
