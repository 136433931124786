import React, { Fragment } from "react";
import DashHeader from "../components/Includes/DashHeader";

const ExamLayout = (props) => {
  return (
    <Fragment>
      {/* <DashHeader /> */}
      {props.children}
    </Fragment>
  );
};

export default ExamLayout;
