export const COURSE_SUBJECTS_REQUEST = "COURSE_SUBJECTS_REQUEST";
export const COURSE_SUBJECTS_SUCCESS = "COURSE_SUBJECTS_SUCCESS";
export const COURSE_SUBJECTS_FAIL = "COURSE_SUBJECTS_FAIL";

export const REGISTERED_SUBJECTS_REQUEST = "REGISTERED_SUBJECTS_REQUEST";
export const REGISTERED_SUBJECTS_SUCCESS = "REGISTERED_SUBJECTS_SUCCESS";
export const REGISTERED_SUBJECTS_FAIL = "REGISTERED_SUBJECTS_FAIL";

export const GET_SUBJECT_REQUEST = "GET_SUBJECT_REQUEST";
export const GET_SUBJECT_SUCCESS = "GET_SUBJECT_SUCCESS";
export const GET_SUBJECT_FAIL = "GET_SUBJECT_FAIL";

export const SAVE_SUBJECT_REQUEST = "SAVE_SUBJECT_REQUEST";
export const SAVE_SUBJECT_SUCCESS = "SAVE_SUBJECT_SUCCESS";
export const SAVE_SUBJECT_RESET = "SAVE_SUBJECT_RESET";
export const SAVE_SUBJECT_FAIL    = "SAVE_SUBJECT_FAIL";

export const GET_SAVED_SUBJECTS_REQUEST = "GET_SAVED_SUBJECTS_REQUEST";
export const GET_SAVED_SUBJECTS_SUCCESS = "GET_SAVED_SUBJECTS_SUCCESS";
export const GET_SAVED_SUBJECTS_FAIL    = "GET_SAVED_SUBJECTS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
