import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import moment from "moment";
import Loader from "../Loader";

import CustomDataTable from "../Datatable/CustomDataTable";
import { getResults } from "./../../actions/resultActions";

import "./result.css";

const ListResults = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.auth);
  const userId = user._id;
  useEffect(() => {
    dispatch(getResults(userId));
  }, [dispatch, alert]);

  const { rLoading, results } = useSelector((state) => state.results);

  const tableHeader = [
    {
      title: "ID",
      prop: "id",
      sortable: true,
      filterable: true,
    },
    {
      title: "Exam",
      prop: "exam",
      sortable: true,
      filterable: true,
    },
    {
      title: "Assessment",
      prop: "assessment",
      sortable: true,
      filterable: true,
    },
    {
      title: "Exam Type",
      prop: "examType",
      sortable: true,
      filterable: true,
    },
    {
      title: "Year",
      prop: "year",
      sortable: true,
      filterable: true,
    },
    {
      title: "Date",
      prop: "date",
      sortable: true,
      filterable: true,
    },
    { title: "Action", prop: "action" },
  ];

  var tableRowData = [];
  if (
    results !== undefined &&
    results.quizes !== undefined &&
    results.quizes.length > 0
  ) {
    results.quizes.map((item, key) => {
      let itemData = {
        id: key + 1,
        exam: item?.exam?.title,
        assessment:
          item.assessment !== undefined ? item?.assessment?.title : "--",
        examType: item?.examType,
        year: item?.year,
        date: moment(item.createdAt).format("YYYY-MM-DD"),
        action: (
          <Link
            to={
              item.examType === "theory"
                ? `/theories-results/${item._id}`
                : `/practice-results/${item._id}`
            }
          >
            View Result
          </Link>
        ),
      };
      tableRowData.push(itemData);
    });
  }

  const onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, "Do MMMM YYYY").valueOf();
    },
  };

  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display",
    entries: "rows",
    noResults: "There are no data to be displayed",
  };

  return (
    <Fragment>
      {rLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="right-auth-landing">
            <div className="main-view-content">
              <div className="all-ourt-style w-100-cust  work-info">
                <div className="all-heads">
                  <h3>Results</h3>
                </div>

                <CustomDataTable
                  tableHeaders={tableHeader}
                  tableBody={tableRowData}
                  rowsPerPage={10}
                  rowsPerPageOption={[10, 20, 30, 40]}
                  initialSort={{ prop: "username", isAscending: true }}
                  onSort={onSortFunction}
                  labels={customLabels}
                />

                {/*<Datatable
									tableHeaders={header}
									tableBody={rowData}
									rowsPerPage={5}
									rowsPerPageOption={[ 5, 10, 15, 20 ]}
                />*/}
                {/*<table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Exam Type</th>
                      <th>Practice Date</th>
                      <th>Practice Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results !== undefined &&
                    results.quizes !== undefined &&
                    results.quizes.length > 0
                      ? results.quizes.map((item, key) => {
                          return (
                            <Fragment>
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.exam.title}</td>
                                <td>
                                  {Moment(item.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                  <Link to={`/practice-results/${item._id}`}>
                                    View Result
                                  </Link>
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })
                      : ""}
                      </tbody>
                </table>*/}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

const actionBtn = (id) => {
  return <Link to={`/practice-results/${id}`}>View Result</Link>;
};

export default ListResults;
