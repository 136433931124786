import React, { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { forgotPassword, clearErrors } from "./../../actions/authActions";
import AuthCommonBanner from "./AuthCommonBanner";

const ForgotPassword = ({ history }) => {
  const alert = useAlert();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const [errorData, setErrorData] = useState({});

  const { error, loading, message, passwordErrors } = useSelector(
    (state) => state.forgotPassword
  );

  useEffect(() => {
    if (passwordErrors) {
      setErrorData(passwordErrors);
      // console.log("loginErrors ", loginErrors); return;
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (message) {
      alert.success(message);
    }
  }, [dispatch, alert, error, message, passwordErrors]);

  const submitHandler = (e) => {
    e.preventDefault();
    //submit the form
    //const formData = new FormData();
    //formData.append('email', email);

    dispatch(forgotPassword(email));
  };

  return (
    <section className="registration-page clearfix fullHt">
      <div className="right-regi-step login-page">
        <div className="vh-center">
          <h1>
            Forgot <span>Password</span>
          </h1>
          <p>
            Enter your registered email address to send the reset password link.{" "}
          </p>
          <div className="inner-que">
            <form onSubmit={submitHandler}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="email">
                      Email<span className="sub">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errorData.email ? (
                      <span className="text-danger">{errorData.email}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <ul className="log-btn-main clearfix">
                <li className="pull-left dont-have-ac">
                  <Link to="/login">Login Here</Link>
                </li>
                <li className="pull-right">
                  <button type="submit" className="btn btn-primary">
                    Send Link
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
      <AuthCommonBanner></AuthCommonBanner>
    </section>
  );
};

export default ForgotPassword;
