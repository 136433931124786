export const LOAD_ALL_CONTACTUS_REQUEST = "LOAD_ALL_CONTACTUS_REQUEST";
export const LOAD_ALL_CONTACTUS_SUCCESS = "LOAD_ALL_CONTACTUS_SUCCESS";
export const LOAD_ALL_CONTACTUS_FAIL = "LOAD_ALL_CONTACTUS_FAIL";

export const CONTACTUS_DETAIL_REQUEST = "CONTACTUS_DETAIL_REQUEST";
export const CONTACTUS_DETAIL_SUCCESS = "CONTACTUS_DETAIL_SUCCESS";
export const CONTACTUS_DETAIL_FAIL = "CONTACTUS_DETAIL_FAIL";

export const CONTACTUS_REPLY_DETAIL_REQUEST = "CONTACTUS_REPLY_DETAIL_REQUEST";
export const CONTACTUS_REPLY_DETAIL_SUCCESS = "CONTACTUS_REPLY_DETAIL_SUCCESS";
export const CONTACTUS_REPLY_DETAIL_FAIL = "CONTACTUS_REPLY_DETAIL_FAIL";

export const ADD_CONTACTUS_REQUEST = "ADD_CONTACTUS_REQUEST";
export const ADD_CONTACTUS_SUCCESS = "ADD_CONTACTUS_SUCCESS";
export const ADD_CONTACTUS_RESET = "ADD_CONTACTUS_RESET";
export const ADD_CONTACTUS_FAIL = "ADD_CONTACTUS_FAIL";

export const UPDATE_CONTACTUS_REQUEST = "UPDATE_CONTACTUS_REQUEST";
export const UPDATE_CONTACTUS_SUCCESS = "UPDATE_CONTACTUS_SUCCESS";
export const UPDATE_CONTACTUS_RESET = "UPDATE_CONTACTUS_RESET";
export const UPDATE_CONTACTUS_FAIL = "UPDATE_CONTACTUS_FAIL";

export const REPLY_CONTACTUS_REQUEST = "REPLY_CONTACTUS_REQUEST";
export const REPLY_CONTACTUS_SUCCESS = "REPLY_CONTACTUS_SUCCESS";
export const REPLY_CONTACTUS_FAIL = "REPLY_CONTACTUS_FAIL";

export const DELETE_CONTACTUS_REQUEST = "DELETE_CONTACTUS_REQUEST";
export const DELETE_CONTACTUS_SUCCESS = "DELETE_CONTACTUS_SUCCESS";
export const DELETE_CONTACTUS_FAIL = "DELETE_CONTACTUS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
