import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import Loader from "../Loader";
import { getUniversityList } from "../../actions/adminUniversityAction";
import { getUserSubscription } from "../../actions/subscriptionActions";

const University = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [examType, setExamType] = useState("");
  const [examId, setExamId] = useState("");
  const [examName, setExamName] = useState("");
  const [examSlug, setExamSlug] = useState("");
  const [examLogo, setExamLogo] = useState("");
  const [univeristy, setUniversity] = useState("");

  useEffect(() => {
    dispatch(getUniversityList());
    setExamType(localStorage.getItem("examinationType"));
    setExamId(localStorage.getItem("examId"));
    setExamName(localStorage.getItem("examName"));
    setExamSlug(localStorage.getItem("examSlug"));
    setExamLogo(localStorage.getItem("logo"));
  }, [dispatch, alert]);

  const { universityList, loading } = useSelector(
    (state) => state.university
  );

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);


  const onChange = (e) => {
    const universityId = e.target.value;
    setUniversity(universityId);
    localStorage.setItem('universityId', universityId);
    localStorage.setItem('selectedUniversity', universityId);
    dispatch(getUserSubscription(user._id, examId, universityId));
  };

  const { userSubscription } = useSelector((state) => state.examSubscriptions);

  const submitHandler = (e) => {
    e.preventDefault();
    if (univeristy !== "" && univeristy !== null && univeristy !== undefined) {
      console.log('userSubscription:', userSubscription);
      
      if (userSubscription !== undefined && userSubscription !== null) {
        history.push(`/categories`);
      } else {
        history.push(`/subscription`);
      }
    } else {
      alert.error("Please select university to proceed.");
      return false;
    }
  };

  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <ul className="clearfix paginations-cust">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/exams">Exams</Link>
            </li>
          </ul>
          <h1>Universities</h1>
        </div>
      </section>

      <section className="subscription-page">
        <div className="container">
          <div className="end-plan">
            <h1>Select University</h1>
          </div>
          <div className="custom-radios-plan examtype-spaner">
            {loading ? (
              <Loader />
            ) : (
              <div className="wrapper clearfix">
                {universityList !== undefined && universityList.length > 0
                  ? universityList.map((item, key) => {
                    //   alert.success(item._id);
                    const indexId = key + 1;
                    return (
                      <Fragment key={indexId}>
                        <input
                          type="radio"
                          name="select"
                          id={`option-${indexId}`}
                          value={item._id}
                          data-name={item.title}
                          // checked={item._id === categoryType}
                          onChange={onChange}
                        />

                        <label
                          htmlFor={`option-${indexId}`}
                          className={`option option-${indexId}`}
                        >
                          <div className="dot"></div>
                          <div className="extype">
                            <img
                              src={
                                process.env.REACT_APP_PUBLIC_URL +
                                "/assets/img/subjects/" +
                                item.logo
                              }
                              alt="exam-logo"
                            />
                          </div>
                          <span>{item.title}</span>
                        </label>
                      </Fragment>
                    );
                  })
                  : ""}
              </div>
            )}
          </div>
          <div className="next-prev-steps text-center">
            <Link to={`/exams`} className="chose-more">
              <i className="fas fa-arrow-left"></i> Prev
            </Link>{" "}
            <button
              type="submit"
              //disabled={!categoryType}
              className="chose-more"
              onClick={submitHandler}
            >
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default University;
