import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";
import "../CourseSubjects/course-subjects.css"
import { getCategoryCourses } from "./../../actions/courseActions";
import { getCourseSubjects } from './../../actions/subjectActions';
import Loader from "../Loader";
import { confirmAlert } from "react-confirm-alert";
import './course.css'

const selectedStyle = {
    position: "absolute",
    content: "",
    top: "4px",
    left: " 4px",
    width: "12px",
    height: "12px",
    background: "red",
    borderRadius: "50%",
    opacity: "0",
    transform: " scale(1.5)",
    transition: "all 0.3s ease"
}

const CategoryCourses = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [courseType, setCourseType] = useState("");

    // const examId = match.params.examId;
    // const categoryId = match.params.catId;
    const examId = localStorage.getItem("examId");
    const examSlug = localStorage.getItem("examSlug");
    const categoryId = localStorage.getItem("categoryId");
    const logo = localStorage.getItem("logo");
    const [courseName, setCourseName] = useState("");

    const examName = localStorage.getItem("examName");

    useEffect(() => {
        dispatch(getCategoryCourses(categoryId));
        setCourseType(localStorage.getItem("courseId"));
    }, [dispatch, alert]);

    const { courses, cloading } = useSelector((state) => state.categoryCourses);
    //console.log("courses ", courses);
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);



    const handleCourseChange = (e, index, item) => {
        setCourseName(item.title);
        // e.preventDefault();
        document.getElementById(`option-${index}-label`).classList.add('checked');

        courses?.map((course, i) => {
            const indexId = i + 1;
            if (parseInt(index) !== indexId) {
                document.getElementById(`option-${indexId}-label`).classList.remove('checked')
            }
        })

        setCourseType(e.target.value);
        localStorage.setItem("courseId", e.target.value);
        if (examSlug === "post-utme") {
            dispatch(getCourseSubjects(e.target.value));
        }
    }

    const { courseSubjects, csloading } = useSelector((state) => state.courseSubjects);

    const submitHandler = (e) => {
        e.preventDefault();
        // alert.success(courseType);
        if (courseType !== "" && courseType !== null && courseType !== undefined) {


            // confirmAlert({
            //     title: 'Confirmation',
            //     message: (
            //         <div>
            //             <p>
            //                 Are you sure you want to select the <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>{courseName}</strong> course?
            //             </p>
            //             {/* <p>
            //           You can choose a maximum of <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}> {maxSubjects}</strong> subjects. Click "Yes" to confirm.
            //         </p> */}
            //             <p className="bg-danger" style={{ color: 'red' }}>
            //                 Note: You cannot change the course later.
            //             </p>
            //         </div>
            //     ),
            //     buttons: [
            //         {
            //             label: 'Yes',
            //             onClick: () => {

            //                 localStorage.setItem("courseId", courseType);
            //                 if (examSlug === "post-utme") {
            //                     var res = courseSubjects.map(function (item) {
            //                         return item._id;
            //                     });

            //                     localStorage.setItem("subjects", res);
            //                     localStorage.setItem("utmeSubjects", JSON.stringify(courseSubjects));
            //                 }
            //                 history.push(`/course-subjects`);

            //             },
            //         },
            //         {
            //             label: 'No',
            //             onClick: () => {
            //                 // Handle user's cancellation
            //                 console.log('User canceled exam selection.');
            //             },
            //         },
            //     ],
            // });

            localStorage.setItem("courseId", courseType);
            if (examSlug === "post-utme") {
                var res = courseSubjects.map(function (item) {
                    return item._id;
                });

                localStorage.setItem("subjects", res);
                localStorage.setItem("utmeSubjects", JSON.stringify(courseSubjects));
            }
            history.push(`/course-subjects`);


        } else {
            alert.error("Please select course type to proceed.");
            return false;
        }
    };

    return (
        <Fragment>
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to={`/categories/${examId}`}>Intended Courses Types</Link>
                        </li>
                    </ul>
                    <h1>Course of Study</h1>
                </div>
            </section>

            <section className="subscription-page">
                <div className="container">
                    <div className="end-plan">
                        {/*<h1>Suggested {examName} Subject Combination</h1>*/}
                        <h1>Select intended course of study at university</h1>
                    </div>
                    <div className="custom-radios-plan course-spaner">
                        {cloading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {courses !== undefined && courses.length > 0
                                    ? courses.map((item, key) => {
                                        //   alert.success(item._id);
                                        const indexId = key + 1;
                                        return (
                                            <Fragment key={indexId}>
                                                <div className="course_Card">

                                                <input
                                                    type="radio"
                                                    name="select"
                                                    value={item._id}
                                                    checked={item._id === courseType}
                                                    // onClick={handleCourseChange}
                                                    id={`option-${indexId}`}
                                                    onChange={(e) => handleCourseChange(e, indexId, item)}
                                                />

                                                <label
                                                    htmlFor={`option-${indexId}`}
                                                    className={`option option-${indexId}`}
                                                    id={`option-${indexId}-label`}
                                                // style={{
                                                //     backgroundColor: item._id === courseType ? "#008753" : "",
                                                //     borderColor: item._id === courseType ? "#008753" : "",
                                                //     opacity: item._id === courseType ? 1 : "",
                                                //     transform: item._id === courseType ? "scale(1)" : "",
                                                // }}
                                                >
                                                    <div
                                                        className="dot"
                                                    // style={{ background: item._id === courseType ? 'red' : "", border : "3px solid white", borderRadius : "50%", top : "4px", left : "4px", position : "absolute", height : "20px", width : "20px" }}
                                                    ></div>
                                                    
                                                    <span
                                                    // style={{
                                                    //     color: item._id === courseType ? "#fff" : ""
                                                    // }}
                                                    >{item.title}</span>
                                                </label>
                                                </div>
                                            </Fragment>
                                        );
                                    })
                                    : ""}
                            </div>
                        )}
                    </div>
                    <div className="next-prev-steps text-center">
                        <Link to={`/categories`} className="chose-more">
                            <i className="fas fa-arrow-left"></i> Prev
                        </Link>{" "}
                        <button
                            type="submit"
                            // disabled={!courseType}
                            className="chose-more"
                            onClick={submitHandler}
                        >
                            Next <i className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default CategoryCourses;
