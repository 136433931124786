import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";

import { getAllCategories } from "./../../actions/commonActions";
import Loader from "../Loader";
import { confirmAlert } from "react-confirm-alert";

const Categories = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [categoryType, setCategoryType] = useState("");
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    dispatch(getAllCategories());
    setCategoryType(localStorage.getItem("categoryId"));
  }, [dispatch, alert]);

  const { allCategories, loading } = useSelector(
    (state) => state.commonCategories
  );

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
}, []);


  const onChange = (e) => {
    const subId = e.target.value;
    setCategoryType(subId);
    setCategoryName(e.target.dataset.name);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // alert.success(categoryType);
    if (
      categoryType !== "" &&
      categoryType !== null &&
      categoryType !== undefined
    ) {

      // confirmAlert({
      //   title: 'Confirmation',
      //   message: (
      //     <div>
      //       <p>
      //         Are you sure you want to select the <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>{categoryName}</strong> course?
      //       </p>
      //       {/* <p>
      //         You can choose a maximum of <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}> {maxSubjects}</strong> subjects. Click "Yes" to confirm.
      //       </p> */}
      //       <p className="bg-danger" style={{ color: 'red' }}>
      //         Note: You cannot change the course later.
      //       </p>
      //     </div>
      //   ),
      //   buttons: [
      //     {
      //       label: 'Yes',
      //       onClick: () => {
      //         localStorage.setItem("categoryId", categoryType);
      //         history.push(`/category-courses`);
      //         // history.push(`/categories`);
      //       },
      //     },
      //     {
      //       label: 'No',
      //       onClick: () => {
      //         // Handle user's cancellation
      //         console.log('User canceled exam selection.');
      //       },
      //     },
      //   ],
      // });

      localStorage.setItem("categoryId", categoryType);
      history.push(`/category-courses`);

    } else {
      alert.error("Please select category to proceed.");
      return false;
    }
  };

  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <ul className="clearfix paginations-cust">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/exams">Exams</Link>
            </li>
          </ul>
          <h1>Intended Courses Types</h1>
        </div>
      </section>

      <section className="subscription-page">
        <div className="container">
          <div className="end-plan">
            <h1>Select Intended Courses Types</h1>
          </div>
          <div className="custom-radios-plan examtype-spaner">
            {loading ? (
              <Loader />
            ) : (
              <div className="wrapper clearfix">
                {allCategories !== undefined && allCategories.length > 0
                  ? allCategories.map((item, key) => {
                    //   alert.success(item._id);
                    const indexId = key + 1;
                    return (
                      <Fragment key={indexId}>
                        <input
                          type="radio"
                          name="select"
                          id={`option-${indexId}`}
                          value={item._id}
                          data-name={item.title}
                          checked={item._id === categoryType}
                          onChange={onChange}
                        />

                        <label
                          htmlFor={`option-${indexId}`}
                          className={`option option-${indexId}`}
                        >
                          <div className="dot"></div>
                          <div className="extype">
                            <img
                              src={
                                process.env.REACT_APP_PUBLIC_URL +
                                "/assets/img/categories/" +
                                item.image
                              }
                              alt="exam-logo"
                            />
                          </div>
                          <span>{item.title}</span>
                        </label>
                      </Fragment>
                    );
                  })
                  : ""}
              </div>
            )}
          </div>
          <div className="next-prev-steps text-center">
            <Link to={`/exams`} className="chose-more">
              <i className="fas fa-arrow-left"></i> Prev
            </Link>{" "}
            <button
              type="submit"
              //disabled={!categoryType}
              className="chose-more"
              onClick={submitHandler}
            >
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Categories;
