import {
  LOAD_ALL_TOPIC_SECTIONS_REQUEST,
  LOAD_ALL_TOPIC_SECTIONS_SUCCESS,
  LOAD_ALL_TOPIC_SECTIONS_FAIL,
  ADD_TOPIC_SECTION_REQUEST,
  ADD_TOPIC_SECTION_SUCCESS,
  ADD_TOPIC_SECTION_RESET,
  ADD_TOPIC_SECTION_FAIL,
  TOPIC_SECTION_DETAIL_REQUEST,
  TOPIC_SECTION_DETAIL_SUCCESS,
  TOPIC_SECTION_DETAIL_FAIL,
  UPDATE_TOPIC_SECTION_REQUEST,
  UPDATE_TOPIC_SECTION_SUCCESS,
  UPDATE_TOPIC_SECTION_RESET,
  UPDATE_TOPIC_SECTION_FAIL,
  DELETE_TOPIC_SECTION_REQUEST,
  DELETE_TOPIC_SECTION_SUCCESS,
  DELETE_TOPIC_SECTION_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminTopicSectionConstants";

export const topicSectionsReducer = (state = { topicSections: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_TOPIC_SECTIONS_REQUEST:
      return {
        user_loading: true,
        topicSections: [],
      };

    case LOAD_ALL_TOPIC_SECTIONS_SUCCESS:
      return {
        user_loading: false,
        topicSections: action.payload.data,
      };

    case LOAD_ALL_TOPIC_SECTIONS_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case UPDATE_TOPIC_SECTION_REQUEST:
      return {
        ...state,
        user_loading: true,
        errors: null,
      };

    case UPDATE_TOPIC_SECTION_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_TOPIC_SECTION_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_TOPIC_SECTION_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const topicSectionDetailReducer = (
  state = { topicSection: {} },
  action
) => {
  switch (action.type) {
    case TOPIC_SECTION_DETAIL_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case TOPIC_SECTION_DETAIL_SUCCESS:
      return {
        user_loading: true,
        topicSection: action.payload,
      };

    case TOPIC_SECTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_TOPIC_SECTION_REQUEST:
      return {
        user_loading: true,
        isTopicAdded: false,
        errors: null,
      };

    case ADD_TOPIC_SECTION_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isTopicAdded: true,
        topicSection: action.payload,
        errors: null,
      };

    case ADD_TOPIC_SECTION_RESET:
      return {
        ...state,
        isTopicAdded: false,
        errors: null,
      };

    case ADD_TOPIC_SECTION_FAIL:
      return {
        ...state,
        user_loading: false,
        isTopicAdded: false,
        topicSection: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteTopicSectionReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TOPIC_SECTION_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_TOPIC_SECTION_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_TOPIC_SECTION_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
