import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import DatePicker from "react-date-picker";
import { addDays } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../../actions/commonActions";
import {
  clearErrors,
  addStudentUser,
} from "../../../../actions/adminStudentActions";

import { ADD_STUDENT_RESET } from "./../../../../constants/adminStudentsConstants";

import Loader from "../../../Loader";
import nationalityData from "../../../../nationality.json";

const AddStudent = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    mobile_code: "234",
    nationality: "",
    gender: "",
    accountStatus: "",
    password: "",
    confirm_password: "",
  });
  const [errorData, setErrorData] = useState({});

  const {
    first_name,
    last_name,
    email,
    password,
    mobile_number,
    mobile_code,
    nationality,
    gender,
    accountStatus,
    confirm_password,
  } = user;

  const { isAdded, user_loading, error, errors } = useSelector(
    (state) => state.addStudent
  );

  // useEffect(() => {
  //   dispatch(getUserData(currentUserId));
  // }, [dispatch]);

  //const { userData } = useSelector((state) => state.commonData);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isAdded === true && isAdded != undefined) {
      alert.success("Student successfully added.");
      history.push("/admin/students");

      dispatch({
        type: ADD_STUDENT_RESET,
      });
    }
  }, [dispatch, isAdded, alert, error, errors]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      addStudentUser(
        first_name,
        last_name,
        email,
        DOBvalue,
        mobile_code ? mobile_code : "234",
        mobile_number,
        nationality,
        gender,
        accountStatus,
        password,
        confirm_password
      )
    );
  };

  const onChange = (e) => {
    if (e.target.name === "avatar") {
    } else {
      setUser({
        ...user,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onPhoneInputChnage = (value, data, event, formattedValue) => {
    setUser({
      ...user,
      mobile_code: value,
    });
  };

  const [DOBvalue, setDate] = useState(new Date());

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Add
                <small>Student</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/students">
                    <i className="fa fa-users"></i> Students
                  </Link>
                </li>
                <li className="active">Add Student</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Student</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          id="first_name"
                          placeholder="Enter First Name"
                          value={first_name}
                          onChange={onChange}
                        />
                        {errorData.first_name ? (
                          <span className="text-danger">
                            {errorData.first_name}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          id="last_name"
                          placeholder="Enter Last Name"
                          value={last_name}
                          onChange={onChange}
                        />
                        {errorData.last_name ? (
                          <span className="text-danger">
                            {errorData.last_name}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="mobile_code">Mobile Code</label>
                        <PhoneInput
                          inputProps={{
                            name: "mobile_code",
                            id: "mobile_code",
                            required: true,
                            autoFocus: true,
                            readOnly: true,
                          }}
                          value={mobile_code}
                          onChange={onPhoneInputChnage}
                          enableSearch
                          country={"ng"}
                          className="form-control"
                        />
                        {errorData.mobile_code ? (
                          <span className="text-danger">
                            {errorData.mobile_code}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="mobile_number">Mobile Number</label>
                        <input
                          type="text"
                          name="mobile_number"
                          className="form-control"
                          id="mobile_number"
                          placeholder="Mobile Number"
                          value={mobile_number}
                          onChange={onChange}
                        />
                        {errorData.mobile_number ? (
                          <span className="text-danger">
                            {errorData.mobile_number}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="nationality">Nationality</label>
                        <select
                          className="form-control"
                          name="nationality"
                          id="nationality"
                          value={nationality}
                          onChange={onChange}
                        >
                          <option value="">Select Nationality</option>
                          {user_loading ? (
                            <Loader />
                          ) : (
                            <Fragment>
                              {nationalityData.length
                                ? nationalityData.map((e, key) => {
                                    return (
                                      <option key={key} value={e.nationality}>
                                        {e.nationality}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Fragment>
                          )}
                        </select>
                        {errorData.nationality ? (
                          <span className="text-danger">
                            {errorData.nationality}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="gender">Gender</label>
                        <select
                          className="form-control"
                          name="gender"
                          id="gender"
                          value={gender}
                          onChange={onChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                        {errorData.gender ? (
                          <span className="text-danger">
                            {errorData.gender}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="dob">DOB</label>
                        <DatePicker
                          name="dob"
                          value={DOBvalue}
                          selected={DOBvalue}
                          onChange={(date) => setDate(date)}
                          dateFormat="YYYY-MM-DD"
                          maxDate={addDays(new Date(), -6500)} //current date
                          placeholderText="Select your date of birth."
                          className="form-control"
                        />
                        {errorData.dob ? (
                          <span className="text-danger">{errorData.dob}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">Email address</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={onChange}
                        />
                        {errorData.email ? (
                          <span className="text-danger">{errorData.email}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="password"
                          autoComplete="new-password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={onChange}
                        />
                        {errorData.password ? (
                          <span className="text-danger">
                            {errorData.password}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="confirm_password">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          name="confirm_password"
                          className="form-control"
                          id="confirm_password"
                          autoComplete="new-password"
                          placeholder="Enter Confirm Password"
                          value={confirm_password}
                          onChange={onChange}
                        />
                        {errorData.confirm_password ? (
                          <span className="text-danger">
                            {errorData.confirm_password}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="accountStatus">Account Status</label>
                        <select
                          className="form-control"
                          name="accountStatus"
                          value={accountStatus}
                          onChange={onChange}
                        >
                          <option value="">Select Status</option>
                          <option value="Active">Active</option>
                          <option value="InActive">In Active</option>
                          <option value="Blocked">Blocked</option>
                          <option value="Suspended">Suspended</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddStudent;
