/*import React from 'react';
import { Route } from 'react-router-dom'

const RootRouter = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
);

export default RootRouter;*/

import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const RootRouter = ({
  component: Component,
  layout: Layout,
  protectedUrl: protectedUrl,
  ...rest
}) => {
  const { user, isAuthenticated, loading } = useSelector((state) => state.auth);

  return (
    <Fragment>
      {loading === false && (
        <Route
          {...rest}
          render={(props) => {
            const pathname = window.location.pathname.split("/")[1];
            // console.log('path ', pathname)
            // console.log('protectedUrl ', protectedUrl)
            if (isAuthenticated === false && protectedUrl == true) {
              if (pathname == "admin") {
                return <Redirect to="/admin/login" />;
              } else {
                return <Redirect to="/login" />;
              }
            }
            // else {
            //     console.log('user', user)
            // }
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            );
          }}
        />
      )}
    </Fragment>
  );
};

export default RootRouter;
