import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const _api_path = "/api/v1";

export default function Banner() {
  const [banners, setBanners] = useState({});

  useEffect(() => {
    axios.get(_api_path + `/admin/banners`).then((bannerItem) => {
      var bannerItems = [];
      if (bannerItem.data.banners.length > 0) {
        bannerItems = bannerItem.data.banners.filter(function (item) {
          return item.status === true;
        });
      }
      setBanners(bannerItems);
    });
  }, []);
  return (
    <section className="banner-sec">
      {banners !== undefined ? (
        <OwlCarousel
          className="owl-carousel owl-theme"
          items={1}
          loop
          margin={10}
        >
          {banners !== undefined &&
            banners.length > 0 &&
            banners.map((item) => {
              return (
                <div
                  className="item fullHt"
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                >
                  <div className="inner-banner-content">
                    <div className="vh-center">
                      <div className="container">
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                        <Link to={item.btnUrl} className="foot-btn">
                          {item.btnName}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div
          className="item fullHt"
          style={{
            backgroundImage: `url("../assets/frontend/img/blog-3.jpg")`,
          }}
        >
          <div className="inner-banner-content">
            <div className="vh-center">
              <div className="container">
                <h1>One Time Success</h1>
                <p>
                  Your Academic Success is Your Passport to a Bright and
                  Fulfilling Future. There is only one true and tried way to be
                  successful in life and that is to start preparing for it
                  today. Tomorrow belongs to those who prepared for it today.
                </p>
                <Link to="/exams" className="foot-btn">
                  Explore Subject
                </Link>
              </div>
            </div>
          </div>
        </div>
        
        <div
          className="item fullHt"
          style={{
            backgroundImage: `url("../assets/frontend/img/banner-3.jpg")`,
          }}
        >
          <div className="inner-banner-content">
            <div className="vh-center">
              <div className="container">
                <h1>One Time Success</h1>
                <p>
                  Your Academic Success is Your Passport to a Bright and
                  Fulfilling Future. There is only one true and tried way to be
                  successful in life and that is to start preparing for it
                  today. Tomorrow belongs to those who prepared for it today.
                </p>
                <Link to="/exams" className="foot-btn">
                  Explore Subject
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="item fullHt"
          style={{
            backgroundImage: `url("../assets/frontend/img/banner-2.jpg")`,
          }}
        >
          <div className="inner-banner-content">
            <div className="vh-center">
              <div className="container">
                <h1>One Time Success</h1>
                <p>
                  Your Academic Success is Your Passport to a Bright and
                  Fulfilling Future. There is only one true and tried way to be
                  successful in life and that is to start preparing for it
                  today. Tomorrow belongs to those who prepared for it today.
                </p>
                <Link to="/exams" className="foot-btn">
                  Explore Subject
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        </OwlCarousel>
      ) : (
        ""
      )}
    </section>
  );
}
