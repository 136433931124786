import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import { getNews, deleteNewsData } from "../../../actions/adminNewsActions";

import Loader from "../../Loader";

const ListNews = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const handleDeleteNews = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteNewsData(id));
            // history.push(`/admin/news-list`);
            alert.success("News deleted successfully");
            dispatch(getNews());
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);

  const { news, nloading } = useSelector((state) => state.newsList);

  return (
    <Fragment>
      {nloading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>News</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li className="active">News</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">News</h3>
                      <div className="box-tools">
                        <Link
                          to={"/admin/news-list/add"}
                          className="btn btn-success btn-sm"
                        >
                          + Add News
                        </Link>
                      </div>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>News</th>
                            <th>Image</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {news.nloading ? (
                            <Loader />
                          ) : news !== undefined && news.length ? (
                            news.map((item) => (
                              <tr key={item._id}>
                                <td>{item.description}</td>
                                <td>
                                  <img
                                    style={{ width: "150px" }}
                                    src={
                                      process.env.REACT_APP_PUBLIC_URL +
                                      "/assets/img/news/" +
                                      item.logo
                                    }
                                  />
                                </td>
                                <td>
                                  {item.status === true && (
                                    <span className="badge bg-green">
                                      Active
                                    </span>
                                  )}
                                  {item.status === false && (
                                    <span className="badge bg-yellow">
                                      InActive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/news-list/edit/${item._id}`}
                                    className="btn btn-success btn-sm"
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                  &nbsp;
                                  <a
                                    className="btn btn-danger btn-sm"
                                    title="Delete"
                                    onClick={() => handleDeleteNews(item._id)}
                                    key={item._id}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListNews;
