import React, { Fragment } from 'react';
import Header from "../../components/Admin/Includes/Header";
import LeftSide from "../../components/Admin/Includes/LeftSide";
import Footer from "../../components/Admin/Includes/Footer";

const AdminLayout = props => {
    return (
        <Fragment>
            <div className="wrapper">
                <Header/>
                <LeftSide />
                {props.children}
                <Footer/>
            </div>
        </Fragment>
    )
};

export default AdminLayout;
