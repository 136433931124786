import axios from "axios";
import {
  GET_HOME_CONTENT,
  UPDATE_HOME_CONTENT_REQUEST,
  UPDATE_HOME_CONTENT_SUCCESS,
  UPDATE_HOME_CONTENT_FAILED,
  CLEAR_ERRORS,
} from "./../../constants/admin/homeSettingConstants";

const _api_path = "/api/v1";
//get all home-setting
export const getHomeContent = () => async (dispatch) => {
  const { data } = await axios.get(_api_path + "/admin/home-setting");
  // console.log('data', data);
  dispatch({
    type: GET_HOME_CONTENT,
    payload: data,
  });
};

// Update homeSetting
export const updateHomeContent =
  (
    homeId,
    wcHeading,
    wcSubheading,
    wcuTitle1,
    wcuDescription1,
    wcuTitle2,
    wcuDescription2,
    wcuTitle3,
    wcuDescription3,
    wcuTitle4,
    wcuDescription4,
    wcuTitle5,
    wcuDescription5,
    examHeading,
    examSubHeading,
    aygHeading,
    aygSubheading,
    aygTitle1,
    aygDescription1,
    aygTitle2,
    aygDescription2,
    aygTitle3,
    aygDescription3,
    aygTitle4,
    aygDescription4,
    aygTitle5,
    aygDescription5,
    aygTitle6,
    aygDescription6,
    testimonialHeading,
    newsHeading,
    newsSubHeading
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_HOME_CONTENT_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.put(
        _api_path + `/admin/home-setting/update/${homeId}`,
        {
          homeId,
          wcHeading,
          wcSubheading,
          wcuTitle1,
          wcuDescription1,
          wcuTitle2,
          wcuDescription2,
          wcuTitle3,
          wcuDescription3,
          wcuTitle4,
          wcuDescription4,
          wcuTitle5,
          wcuDescription5,
          examHeading,
          examSubHeading,
          aygHeading,
          aygSubheading,
          aygTitle1,
          aygDescription1,
          aygTitle2,
          aygDescription2,
          aygTitle3,
          aygDescription3,
          aygTitle4,
          aygDescription4,
          aygTitle5,
          aygDescription5,
          aygTitle6,
          aygDescription6,
          testimonialHeading,
          newsHeading,
          newsSubHeading,
        },
        config
      );

      dispatch({
        type: UPDATE_HOME_CONTENT_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      var errorMsg = error.response.data.errMessage;
      if (
        error.response.data.error !== undefined &&
        error.response.data.error.code === 11000
      ) {
        errorMsg = "Entered email already exist, please try another.";
      }
      dispatch({
        type: UPDATE_HOME_CONTENT_FAILED,
        payload: errorMsg,
        errors: error.response.data,
      });
    }
  };

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
