import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Banner from "./Banner";
import HomeCategory from "./HomeCategory";
import Testimonial from "./Testimonial";
import HomeNews from "./HomeNews";
import Loader from "../Loader";

const _api_path = "/api/v1";

const Home = () => {
  const [homeContents, setHomeContents] = useState({});

  useEffect(() => {
    axios.get(_api_path + `/admin/home-setting`).then((item) => {
      setHomeContents(item.data.data);
    });
  }, []);

  return (
    <Fragment>
      {homeContents !== undefined ? (
        <Fragment>
          <Banner></Banner>
          <section
            className="why-choose-sec"
            style={{
              backgroundImage: `url("../assets/frontend/img/why-bg.png")`,
            }}
          >
            <div className="container">
              <div className="section-head">
                {/* <h6>Sub Heading</h6> */}
                <h1>
                  {homeContents.whyChooseUs !== undefined
                    ? homeContents.whyChooseUs.heading
                    : "Why Choose Us?"}
                </h1>
                <p>
                  {homeContents.whyChooseUs !== undefined
                    ? homeContents.whyChooseUs.subheading
                    : `An Iroko tree does not become a giant until it is first rooted
                  in good soil. One Time Success is an immersive Computer Based
                  Test Prep that provides the launch pad for the giants of
                  success. Our preparatory CBT software gives you the best
                  training, most accurate information, and the confidence you
                  need to ‘ACE’ all your certificate examinations and excel with
                  highest scores in JAMB and Post UTME. The Smartest Way to
                  Practice is with Us. The Answers to our Past Questions are
                  provided by seasoned real JAMB and WAEC questions setters.
                  With our program, you Enter the Mindset of the Question
                  Setters and Understand the Answers they Seek. Our Computer
                  Based Tests (CBT) are designed by prior students of One Time
                  Success who excelled in both WAEC, and JAMB using One Time
                  Success and are now enjoying success careers. We’ll Stop at
                  Nothing to Ensure You Achieve your Highest Possible Score. So,
                  Prepare to Success with One Time Success!
                  `}
                </p>
              </div>
              <div className="why-choos-list">
                <ul className="clearfix">
                  <li>
                    <div className="main-choose-outer">
                      <div className="vh-center">
                        <div className="icon-chose">
                          <i className="ots-open-book"></i>
                        </div>
                        <h5>01</h5>
                        <h3>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[0].title
                            : "Study Sessions"}
                        </h3>
                        <p>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[0].description
                            : `The Study Exam Mode is meant to be used as a Go at
                    your own pace study tool`}
                        </p>
                        {/*<a href="#!" className="chose-more">
                                            <i className="fas fa-arrow-right"></i>
                                        </a>*/}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="main-choose-outer">
                      <div className="vh-center">
                        <div className="icon-chose">
                          <i className="ots-play-button-on-monitor-screen"></i>
                        </div>
                        <h5>02</h5>
                        <h3>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[1].title
                            : "Practise Sessions"}
                        </h3>
                        <p>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[1].description
                            : `The Practice Exam Mode is meant to be to be used as a
                    timed study tool`}
                        </p>
                        {/*<a href="#!" className="chose-more">
                                            <i className="fas fa-arrow-right"></i>
                                        </a>*/}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="main-choose-outer">
                      <div className="vh-center">
                        <div className="icon-chose">
                          <i className="ots-exam"></i>
                        </div>
                        <h5>03</h5>
                        <h3>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[2].title
                            : "Mock Test"}
                        </h3>
                        <p>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[2].description
                            : `The Mock Exam Mode reflects as close as possible the
                    actual Examination`}
                        </p>
                        {/*<a href="#!" className="chose-more">
                                            <i className="fas fa-arrow-right"></i>
                                        </a>*/}
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="main-choose-outer">
                      <div className="vh-center">
                        <div className="icon-chose">
                          <i className="ots-exam"></i>
                        </div>
                        <h5>04</h5>
                        <h3>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[3].title
                            : "Arts, Social Sciences or Sciences?"}
                        </h3>
                        <p>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[3].description
                            : `What do you want to be when you grow up? Explore our
                    Courses and Subject combinations to ensure your dream
                    is on tract.`}
                        </p>
                        {/*<a href="#!" className="chose-more">
                                            <i className="fas fa-arrow-right"></i>
                                        </a>*/}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="main-choose-outer">
                      <div className="vh-center">
                        <div className="icon-chose">
                          <i className="ots-exam"></i>
                        </div>
                        <h5>05</h5>
                        <h3>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[4].title
                            : "Subject Selection?"}
                        </h3>
                        <p>
                          {homeContents.whyChooseUs !== undefined
                            ? homeContents.whyChooseUs.sections[4].description
                            : `Follow our subject recommendations for tertiary
                            Institution courses. Do what you love, and you would
                            never have to work a day!`}
                        </p>
                        {/*<a href="#!" className="chose-more">
                                            <i className="fas fa-arrow-right"></i>
                                        </a>*/}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <HomeCategory
            heading={
              homeContents.examCategories !== undefined
                ? homeContents.examCategories.heading
                : "Examination Categories"
            }
            subheading={
              homeContents.examCategories !== undefined
                ? homeContents.examCategories.subheading
                : "WHICH EXAM ARE YOU PREPPING FOR?"
            }
          ></HomeCategory>

          <section className="achive-goal-sec">
            <div className="container">
              <div className="section-head">
                {/* <h6>Sub Heading</h6> */}
                <h1>
                  {homeContents.achieveYourGoal !== undefined
                    ? homeContents.achieveYourGoal.heading
                    : "Achieve your goal"}
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      homeContents.achieveYourGoal !== undefined ? (
                        homeContents.achieveYourGoal.subheading
                      ) : (
                        <Fragment>
                          <p>
                            “One today is worth two tomorrows; What I am to be I
                            am now becoming.” -Benjamin Franklin
                          </p>
                          <p>
                            “The greatest thing in this world in not so much
                            where we are but in what direction we are moving”
                            -Oliver Wendell Holmes
                          </p>
                        </Fragment>
                      ),
                  }}
                ></p>
                {/* <p>
                  “One today is worth two tomorrows; What I am to be I am now
                  becoming.” -Benjamin Franklin
                </p>
                <p>
                  “The greatest thing in this world in not so much where we are
                  but in what direction we are moving” -Oliver Wendell Holmes
                </p> */}
              </div>
              <div className="cust-tab">
                <ul className="nav nav-tabs" role="tablist">
                  <li role="presentation" className="active">
                    <a
                      href="#tab1"
                      aria-controls="tab1"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>
                        <i className="ots-skill"></i>
                      </span>
                      <h4>
                        {homeContents.achieveYourGoal !== undefined
                          ? homeContents.achieveYourGoal.sections[0].title
                          : "Expand Skill Development"}
                      </h4>
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tab2"
                      aria-controls="tab2"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>
                        <i className="ots-book"></i>
                      </span>
                      <h4>
                        {homeContents.achieveYourGoal !== undefined
                          ? homeContents.achieveYourGoal.sections[1].title
                          : "Self Learn"}
                      </h4>
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tab3"
                      aria-controls="tab3"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>
                        <i className="ots-test-1"></i>
                      </span>
                      <h4>
                        {homeContents.achieveYourGoal !== undefined
                          ? homeContents.achieveYourGoal.sections[2].title
                          : "Online Mock"}
                      </h4>
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tab4"
                      aria-controls="tab4"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>
                        <i className="ots-passkey"></i>
                      </span>
                      <h4>
                        {homeContents.achieveYourGoal !== undefined
                          ? homeContents.achieveYourGoal.sections[3].title
                          : "Unlock Your Potential"}
                      </h4>
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tab5"
                      aria-controls="tab5"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>
                        <i className="ots-camera"></i>
                      </span>
                      <h4>
                        {homeContents.achieveYourGoal !== undefined
                          ? homeContents.achieveYourGoal.sections[4].title
                          : "Explore Your Passion"}
                      </h4>
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tab6"
                      aria-controls="tab6"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>
                        <i className="ots-analysis"></i>
                      </span>
                      <h4>
                        {homeContents.achieveYourGoal !== undefined
                          ? homeContents.achieveYourGoal.sections[5].title
                          : "Analytics Work"}
                      </h4>
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="tab1">
                    <div className="media">
                      <div className="media-left">
                        <i className="ots-skill"></i>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[0].title
                            : "Expand Skill Development"}
                        </h4>
                        <p>
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[0]
                                .description
                            : `In our age of technology and discovery, to learn more
                            is to earn more. Trained brains are always in demand
                            and to lack in knowledge is a fatal error. Most life’s
                            failure are the results of inadequate preparation.
                            Therefore, Establish What You Know Today, Learn What
                            you Don’t for Tomorrow.`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="tab2">
                    <div className="media">
                      <div className="media-left">
                        <i className="ots-book"></i>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[1].title
                            : "Self Learn"}
                        </h4>
                        <p>
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[1]
                                .description
                            : `With One Time Success, it is easy to form the habit of
                            learning`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="tab3">
                    <div className="media">
                      <div className="media-left">
                        <i className="ots-test-1"></i>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[2].title
                            : "Online Mock"}
                        </h4>
                        <p>
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[2]
                                .description
                            : `Practice Makes Perfect`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="tab4">
                    <div className="media">
                      <div className="media-left">
                        <i className="ots-passkey"></i>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[3].title
                            : "Unlock Your Potential"}
                        </h4>
                        <p>
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[3]
                                .description
                            : `Consider using getting One Time Success and investment
                            in your Success`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="tab5">
                    <div className="media">
                      <div className="media-left">
                        <i className="ots-camera"></i>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[4].title
                            : "Explore Your Passion"}
                        </h4>
                        <p>
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[4]
                                .description
                            : `Do what you love, and you would never have to work a
                            day!`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="tab6">
                    <div className="media">
                      <div className="media-left">
                        <i className="ots-analysis"></i>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[5].title
                            : "Analytics Work"}
                        </h4>
                        <p>
                          {homeContents.achieveYourGoal !== undefined
                            ? homeContents.achieveYourGoal.sections[5]
                                .description
                            : `A clear analysis of your performance will guide your
                            learning growth to you higher performance`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Testimonial
            heading={
              homeContents.testimonial !== undefined
                ? homeContents.testimonial
                : "Client Words :)"
            }
          ></Testimonial>

          <HomeNews
            heading={
              homeContents.latestNews !== undefined
                ? homeContents.latestNews.heading
                : "Latest News"
            }
            subheading={
              homeContents.latestNews !== undefined
                ? homeContents.latestNews.subheading
                : `Our strong sense of identification with client projects means that we are constantly striving to provide solutions, even for issues they aren’t yet aware of. To this end, we adopt a progressive approach to technology and marketing techniques.`
            }
          />
        </Fragment>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};

export default Home;
