import React, { Fragment, useState } from 'react';
import DashHeader from "../components/Includes/DashHeader";
import UserLeftSide from "../components/Includes/UserLeftSide";

const UserLayout = props => {

    const [isActive, setActive] = useState(false);

    const handleToggleMenu = () => {
        setActive(!isActive);
    };

    return (
        <Fragment>
            <div className={`App ${isActive ? "menu-open" : ""}`}>
                <DashHeader/>
                <div className="nav-togg" onClick={handleToggleMenu}>
                    <span></span>
                </div>
                <section className="auth-dash-sec fullHt">
                    <UserLeftSide />
                    {props.children}
                </section>
            </div>
        </Fragment>
    );
};

export default UserLayout;