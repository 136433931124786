export const LOAD_ALL_TOPICS_REQUEST = "LOAD_ALL_TOPICS_REQUEST";
export const LOAD_ALL_TOPICS_SUCCESS = "LOAD_ALL_TOPICS_SUCCESS";
export const LOAD_ALL_TOPICS_FAIL = "LOAD_ALL_TOPICS_FAIL";

export const TOPIC_DETAIL_REQUEST = "TOPIC_DETAIL_REQUEST";
export const TOPIC_DETAIL_SUCCESS = "TOPIC_DETAIL_SUCCESS";
export const TOPIC_DETAIL_FAIL = "TOPIC_DETAIL_FAIL";

export const ADD_TOPIC_REQUEST = "ADD_TOPIC_REQUEST";
export const ADD_TOPIC_SUCCESS = "ADD_TOPIC_SUCCESS";
export const ADD_TOPIC_RESET = "ADD_TOPIC_RESET";
export const ADD_TOPIC_FAIL = "ADD_TOPIC_FAIL";

export const UPDATE_TOPIC_REQUEST = "UPDATE_TOPIC_REQUEST";
export const UPDATE_TOPIC_SUCCESS = "UPDATE_TOPIC_SUCCESS";
export const UPDATE_TOPIC_RESET = "UPDATE_TOPIC_RESET";
export const UPDATE_TOPIC_FAIL = "UPDATE_TOPIC_FAIL";
export const DELETE_TOPIC_REQUEST = "DELETE_TOPIC_REQUEST";
export const DELETE_TOPIC_SUCCESS = "DELETE_TOPIC_SUCCESS";
export const DELETE_TOPIC_FAIL = "DELETE_TOPIC_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
