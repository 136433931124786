export const LOAD_ALL_ADVICE_REQUEST = "LOAD_ALL_ADVICE_REQUEST";
export const LOAD_ALL_ADVICE_SUCCESS = "LOAD_ALL_ADVICE_SUCCESS";
export const LOAD_ALL_ADVICE_FAIL = "LOAD_ALL_ADVICE_FAIL";

export const ADVICE_DETAIL_REQUEST = "ADVICE_DETAIL_REQUEST";
export const ADVICE_DETAIL_SUCCESS = "ADVICE_DETAIL_SUCCESS";
export const ADVICE_DETAIL_FAIL = "ADVICE_DETAIL_FAIL";

export const ADD_ADVICE_REQUEST = "ADD_ADVICE_REQUEST";
export const ADD_ADVICE_SUCCESS = "ADD_ADVICE_SUCCESS";
export const ADD_ADVICE_RESET = "ADD_ADVICE_RESET";
export const ADD_ADVICE_FAIL = "ADD_ADVICE_FAIL";

export const UPDATE_ADVICE_REQUEST = "UPDATE_ADVICE_REQUEST";
export const UPDATE_ADVICE_SUCCESS = "UPDATE_ADVICE_SUCCESS";
export const UPDATE_ADVICE_RESET = "UPDATE_ADVICE_RESET";
export const UPDATE_ADVICE_FAIL = "UPDATE_ADVICE_FAIL";
export const DELETE_ADVICE_REQUEST = "DELETE_ADVICE_REQUEST";
export const DELETE_ADVICE_SUCCESS = "DELETE_ADVICE_SUCCESS";
export const DELETE_ADVICE_FAIL = "DELETE_ADVICE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
