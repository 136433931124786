import axios from "axios";
import {
  LOAD_ALL_CUSTOMERS_REQUEST,
  LOAD_ALL_CUSTOMERS_SUCCESS,
  LOAD_ALL_CUSTOMERS_FAIL,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_REQUEST,
  DELETE_STUDENT_FAIL,
  ADD_STUDENT_REQUEST,
  ADD_STUDENT_SUCCESS,
  ADD_STUDENT_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminStudentsConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";
//get all customers
export const getStudents = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_CUSTOMERS_REQUEST,
    });

    const { data } = await axios.get(_api_path + "/admin/customers");

    dispatch({
      type: LOAD_ALL_CUSTOMERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: LOAD_ALL_CUSTOMERS_FAIL,
      payload: err,
    });
  }
};

//Add User
export const addStudentUser =
  (
    first_name,
    last_name,
    email,
    dob,
    mobile_code,
    mobile_number,
    nationality,
    gender,
    accountStatus,
    password,
    confirm_password
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_STUDENT_REQUEST,
      });
      const config = {
        headers: {
          //"content-type": "multipart/form-data",
          "content-type": "application/json",
        },
      };
      // console.log("userData ", userData.getAll());
      // return;
      const { data } = await axios.post(
        _api_path + "/admin/customers/add",
        // userData,
        {
          first_name,
          last_name,
          email,
          dob,
          mobile_code,
          mobile_number,
          nationality,
          gender,
          accountStatus,
          password,
          confirm_password,
        },
        config
      );
      if (!data) {
        dispatch({
          type: ADD_STUDENT_FAIL,
          payload: "Invalid email or password",
        });
      }
      dispatch({
        type: ADD_STUDENT_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      var errorMsg = error.response.data.errMessage;
      if (
        error.response.data.error !== undefined &&
        error.response.data.error.code === 11000
      ) {
        errorMsg = "Entered email already exist, please try another.";
      }
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload: errorMsg,
        errors: error.response.data,
      });
    }
  };

// Delete user
export const deleteStudentData = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_STUDENT_REQUEST,
    });

    const { data } = await axios.delete(_api_path + `/admin/users/${userId}`);

    dispatch({
      type: DELETE_STUDENT_SUCCESS,
      payload: userId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_STUDENT_FAIL,
      payload: error.response,
      //payload: error.response.data.errMessage,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
