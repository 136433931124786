import {

    LOAD_ALL_CATEGORIES_REQUEST,
    LOAD_ALL_CATEGORIES_SUCCESS,
    LOAD_ALL_CATEGORIES_FAIL,

    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_RESET,
    ADD_CATEGORY_FAIL,

    CATEGORY_DETAIL_REQUEST,
    CATEGORY_DETAIL_SUCCESS,
    CATEGORY_DETAIL_FAIL,

    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_RESET,
    UPDATE_CATEGORY_FAIL,

    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,

    CLEAR_ERRORS
} from './../constants/adminCategoryConstants';

export const categoriesReducer = (state = { categories: [] }, action) => {

    switch (action.type) {

        case LOAD_ALL_CATEGORIES_REQUEST:
            return {
                user_loading: true,
                categories: []
            }

        case LOAD_ALL_CATEGORIES_SUCCESS:
            return {
                user_loading: false,
                categories: action.payload.data
            }

        case LOAD_ALL_CATEGORIES_FAIL:
            return {
                user_loading: false,
                error: action.payload
            }

        case UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                user_loading: true,
            }

        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                user_loading: false,
                isUpdated: action.payload
            }

        case UPDATE_CATEGORY_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_CATEGORY_FAIL:
            return {
                ...state,
                user_loading: false,
                error: action.payload
            }


        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}

export const categoryDetailReducer = (state = { category: {} }, action) => {

    switch (action.type) {

        case CATEGORY_DETAIL_REQUEST:
            return {
                ...state,
                user_loading: true
            }

        case CATEGORY_DETAIL_SUCCESS:
            return {
                user_loading: true,
                category: action.payload
            }

        case CATEGORY_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }


        case ADD_CATEGORY_REQUEST:
            return {
                user_loading: true,
                isCategoryAdded: false,
            };

        case ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                user_loading: false,
                isCategoryAdded: true,
                category: action.payload,
            };

        case ADD_CATEGORY_RESET:
            return {
                ...state,
                isCategoryAdded: false,
            };

        case ADD_CATEGORY_FAIL:
            return {
                ...state,
                user_loading: false,
                isCategoryAdded: false,
                category: null,
                error: action.payload,
            };



        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

};

export const deleteCategoryReducer = (state = {}, action) => {

    switch (action.type) {

        case DELETE_CATEGORY_REQUEST:
            return {
                user_loading: true
            }

        case DELETE_CATEGORY_SUCCESS:
            return state.categories.filter(item => String(item.id) !== String(action.payload));

        case DELETE_CATEGORY_FAIL:
            return {
                user_loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}