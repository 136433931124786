import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getAssessmentDetail,
  updateAssessment,
  clearErrors,
} from "../../../actions/adminAssessmentActions";
import { UPDATE_ASSESSMENT_RESET } from "./../../../constants/adminAssessmentConstants";

import Loader from "../../Loader";

import "./Assessment.css";

const EditAssessment = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [assessmentId, setAssessmentId] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [imageName, setImageName] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const [errorData, setErrorData] = useState({});

  const { isUpdated, user_loading, error, errors } = useSelector(
    (state) => state.assessments
  );

  const currentUserId = match.params.id;
  useEffect(() => {
    dispatch(getAssessmentDetail(currentUserId));
  }, [dispatch]);

  const { assessment } = useSelector((state) => state.assessmentDetail);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (assessment) {
      setAssessmentId(assessment._id);
      setTitle(assessment.title);
      setStatus(assessment.status);
      setLogo(assessment.logo);
      setOldImage(assessment.logo);
      setImagePreview(
        process.env.REACT_APP_PUBLIC_URL + "/assets/img/assessments/" + assessment.logo
      );
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Assessment updated successfully");
      dispatch(getAssessmentDetail(assessment._id));
      history.push(`/admin/assessments`);
      dispatch({
        type: UPDATE_ASSESSMENT_RESET,
      });
    }
  }, [dispatch, assessment, alert, error, errors, history, isUpdated]);

  const onChange = (e) => {
    let file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setLogo("");
      setImageName("");
      alert.error("Please select valid image.");
      //remove preview picture if any
      const preview = document.getElementById("img-preview");
      preview.src = "";
      return false;
    }

    const reader = new FileReader();

    reader.onload = function (readerEvt) {
      // The file's text will be printed here
      //console.log(readerEvt.target.result);
      let binaryString = readerEvt.target.result;
      let base64String = btoa(binaryString);
      setLogo(base64String);
      setImageName(file.name);

      //preview picture
      const preview = document.getElementById("img-preview");
      // console.log('banary image', base64String);
      preview.src = "data:image/png;base64," + base64String;
    };
    reader.readAsBinaryString(file);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateAssessment(assessmentId, title, status, oldImage, logo, imageName)
    );
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Assessment</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/assessments">
                    <i className="fa fa-users"></i> Assessments
                  </Link>
                </li>
                <li className="active">Edit Assessment</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Assessment</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {errorData.title ? (
                          <span className="text-danger">{errorData.title}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="status">Status </label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          checked={status}
                          value={status}
                          onChange={(e) => setStatus(!status)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="logo">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          onChange={onChange}
                          accept={`.jpeg, .jpg, .png`}
                        />
                        {errorData.logo ? (
                          <span className="text-danger">{errorData.logo}</span>
                        ) : (
                          ""
                        )}
                        <div>
                          <img id="img-preview" src={imagePreview} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditAssessment;
