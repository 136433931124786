import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { addAdvice, clearErrors } from "../../../actions/adminAdviceActions";
import { ADD_ADVICE_RESET } from "./../../../constants/adminAdviceConstants";

import Loader from "../../Loader";

import "./Advice.css";

const AddAdvice = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [advice, setAdvice] = useState({
    title: "",
    min_percentage: "",
    max_percentage: "",
    description: "",
    status: false,
  });
  const [errorData, setErrorData] = useState({});

  const { title, min_percentage, max_percentage, description, status } = advice;

  const { isAdviceAdded, user_loading, error, errors } = useSelector(
    (state) => state.adviceDetail
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isAdviceAdded === true && isAdviceAdded != undefined) {
      alert.success("Advice successfully added.");
      history.push("/admin/advice-list");
      dispatch({
        type: ADD_ADVICE_RESET,
      });
    }
  }, [dispatch, isAdviceAdded, alert, error, errors]);

  const onChange = (e) => {
    if (e.target.name === "min_percentage") {
      setAdvice({
        ...advice,
        min_percentage: e.target.value,
        max_percentage: e.target.value,
      });
    } else if (e.target.name === "status") {
      setAdvice({
        ...advice,
        status: !status,
      });
    } else {
      setAdvice({
        ...advice,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      addAdvice(title, min_percentage, max_percentage, description, status)
    );
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Add
                <small>Advice</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/advice-list">
                    <i className="fa fa-table"></i> Advice
                  </Link>
                </li>
                <li className="active">Add Advice</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Advice</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form id="quickForm" onSubmit={submitHandler}>
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter the advice title"
                          value={title}
                          onChange={onChange}
                        />
                        {errorData.title ? (
                          <span className="text-danger">{errorData.title}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="min_percentage">Min percentage</label>
                        <select
                          name="min_percentage"
                          className="form-control"
                          id="min_percentage"
                          placeholder="Enter the advice min percentage"
                          value={min_percentage}
                          onChange={onChange}
                        >
                          <option value="">Min Value</option>
                          {[...Array(101)].map((_, i) => (
                            <option value={i}>{i}</option>
                          ))}
                        </select>
                        {errorData.min_percentage ? (
                          <span className="text-danger">
                            {errorData.min_percentage}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="max_percentage">Max percentage</label>
                        <select
                          name="max_percentage"
                          className="form-control"
                          id="max_percentage"
                          placeholder="Enter the advice max percentage"
                          value={max_percentage}
                          onChange={onChange}
                        >
                          <option value="">Max Value</option>
                          {[...Array(101)].map((e, i) => (
                            <option
                              disabled={min_percentage >= i ? true : false}
                              value={i}
                            >
                              {i}
                            </option>
                          ))}
                        </select>
                        {errorData.max_percentage ? (
                          <span className="text-danger">
                            {errorData.max_percentage}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="description">Description</label>
                        <textarea
                          className="form-control"
                          id="description"
                          name="description"
                          placeholder="Enter the advice description"
                          value={description}
                          onChange={onChange}
                        ></textarea>
                        {errorData.description ? (
                          <span className="text-danger">
                            {errorData.description}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status</label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          name="status"
                          checked={status}
                          value={status}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddAdvice;
