import axios from "axios";
import {
  GET_ASSESSMENTS_REQUEST,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_FAIL,
  ASSESSMENT_REQUEST,
  ASSESSMENT_SUCCESS,
  ASSESSMENT_FAIL,
  CLEAR_ERRORS,
} from "../constants/assessmentConstants";

const _api_path = "/api/v1";

//get all assessments
export const getAssessmentList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSESSMENTS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/get-assessments`);

    dispatch({
      type: GET_ASSESSMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ASSESSMENTS_FAIL,
      payload: error,
    });
  }
};

//get assessment by id
export const getAssessmentById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_REQUEST,
    });

    const { data } = await axios.get(
      `${_api_path}/get-assessment-detail/${id}`
    );

    dispatch({
      type: ASSESSMENT_SUCCESS,
      payload: data.assessment,
    });
  } catch (error) {
    dispatch({
      type: ASSESSMENT_FAIL,
      payload: error,
    });
  }
};
