import axios from "axios";
import {
  CATEGORY_COURSES_REQUEST,
  CATEGORY_COURSES_SUCCESS,
  CATEGORY_COURSES_FAIL,
  CLEAR_ERRORS,
} from "../constants/courseConstants";

const _api_path = "/api/v1";

//get category all courses
export const getCategoryCourses = (categoryId) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_COURSES_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/get-courses/${categoryId}`);

    dispatch({
      type: CATEGORY_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_COURSES_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
