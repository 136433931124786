import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import { getEmailtemplates } from "../../../actions/admin/emailTemplateActions";

import Loader from "../../Loader";
// import Loader from "react-loader-spinner";

const ListEmailTemplates = ({ history }) => {
  // const [users, setUsersData] = useState({});
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmailtemplates());
  }, [dispatch]);

  const { emailtemplates, etemplateLoading } = useSelector(
    (state) => state.emailtemplates
  );

  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Manage
            <small>Email Templates</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li className="active">Email Templates</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">Email Templates</h3>
                  {/*<div className="box-tools">
                        <Link
                          to={"/admin/emailtemplates/add"}
                          className="btn btn-success btn-sm"
                        >
                          + Add EmailTemplate
                        </Link>
      </div>*/}
                </div>
                <div className="box-body table-responsive no-padding">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Content</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {etemplateLoading ? (
                        <Loader />
                      ) : emailtemplates.length ? (
                        emailtemplates.map((item) => (
                          <tr key={item._id}>
                            <td>{item.subject}</td>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content
                                }}
                              ></div>
                            </td>
                            <td>
                              <Link
                                to={`/admin/emailtemplates/edit/${item._id}`}
                                className="btn btn-success btn-sm"
                                title="Edit"
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No records found</td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default ListEmailTemplates;
