import axios from "axios";
import {
  LOAD_ALL_USERS_REQUEST,
  LOAD_ALL_USERS_SUCCESS,
  LOAD_ALL_USERS_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_RESET,
  UPDATE_PROFILE_FAIL,
  CLEAR_ERRORS,
} from "../constants/userConstants";
const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";
//get all users
export const getUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_USERS_REQUEST,
    });
    const { data } = await axios.get(_api_path + "/admin/users");
    dispatch({
      type: LOAD_ALL_USERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: LOAD_ALL_USERS_FAIL,
      payload: err,
    });
  }
};

//Add User
export const addUser =
  (
    first_name,
    last_name,
    email,
    dob,
    mobile_code,
    mobile_number,
    nationality,
    gender,
    accountStatus,
    password,
    confirm_password
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_USER_REQUEST,
      });
      const config = {
        headers: {
          //"content-type": "multipart/form-data",
          "content-type": "application/json",
        },
      };
      // console.log("userData ", userData.getAll());
      // return;
      const { data } = await axios.post(
        _api_path + "/admin/users/add",
        // userData,
        {
          first_name,
          last_name,
          email,
          dob,
          mobile_code,
          mobile_number,
          nationality,
          gender,
          accountStatus,
          password,
          confirm_password,
        },
        config
      );
      if (!data) {
        dispatch({
          type: ADD_USER_FAIL,
          payload: "Invalid email or password",
        });
      }
      dispatch({
        type: ADD_USER_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      var errorMsg = error.response.data.errMessage;
      if (
        error.response.data.error !== undefined &&
        error.response.data.error.code === 11000
      ) {
        errorMsg = "Entered email already exist, please try another.";
      }
      dispatch({
        type: ADD_USER_FAIL,
        payload: errorMsg,
        errors: error.response.data,
      });
    }
  };

// Update user profile
export const updateUserProfile =
  (
    userId,
    first_name,
    last_name,
    mobile_code,
    mobile_number,
    email,
    dob,
    gender,
    nationality,
    accountStatus,
    password,
    confirm_password
  ) =>
  async (dispatch) => {
    //console.log('userData ', userData);
    try {
      dispatch({
        type: UPDATE_PROFILE_REQUEST,
      });

      const config = {
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        // }
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.put(
        _api_path + `/admin/users/${userId}`,
        {
          first_name,
          last_name,
          mobile_code,
          mobile_number,
          email,
          dob,
          gender,
          nationality,
          accountStatus,
          password,
          confirm_password,
        },
        config
      );

      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      var errorMsg = error.response.data.errMessage;
      if (
        error.response.data.error !== undefined &&
        error.response.data.error.code === 11000
      ) {
        errorMsg = "Entered email already exist, please try another.";
      }
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: errorMsg,
        errors: error.response.data,
      });
    }
  };

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
