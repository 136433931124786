import axios from "axios";
import {
  SUBMIT_RESULT_REQUEST,
  SUBMIT_RESULT_SUCCESS,
  SUBMIT_RESULT_FAIL,
  SUBMIT_GRADE_RESULT_REQUEST,
  SUBMIT_GRADE_RESULT_SUCCESS,
  SUBMIT_GRADE_RESULT_RESET,
  SUBMIT_GRADE_RESULT_FAIL,
  GET_USER_RESULTS_REQUEST,
  GET_USER_RESULTS_SUCCESS,
  GET_USER_RESULTS_FAIL,
  GET_RESULT_REQUEST,
  GET_RESULT_SUCCESS,
  GET_RESULT_FAIL,
  GET_RESULT_VIEW_REQUEST,
  GET_RESULT_VIEW_SUCCESS,
  GET_RESULT_VIEW_FAIL,
  CLEAR_ERRORS,
  GET_MOCK_RESULT_SUCCESS,
} from "../constants/resultConstants";

const _api_path = "/api/v1";

//submit result
export const submitExamResult =
  (
    userId,
    examId,
    answered,
    assessment,
    subjectIds,
    year,
    totalQuestions,
    timeDuration,
    remainingTime,
    takenTime,
    savedSubjectId = "",
    examType
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SUBMIT_RESULT_REQUEST,
      });
      const { data } = await axios.post(`${_api_path}/submit-result`, {
        userId,
        examId,
        answered,
        assessment,
        subjectIds,
        year,
        totalQuestions,
        timeDuration,
        remainingTime,
        takenTime,
        savedSubjectId,
        examType,
      });
      // console.log("data", data);
      // return;
      dispatch({
        type: SUBMIT_RESULT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUBMIT_RESULT_FAIL,
        payload: error,
      });
    }
  };

//review and grade
export const reviewAndGradeResult =
  (userId, quizId, reviewed) => async (dispatch) => {
    try {
      dispatch({
        type: SUBMIT_GRADE_RESULT_REQUEST,
      });
      const { data } = await axios.put(`${_api_path}/submit-grade-result`, {
        userId,
        quizId,
        reviewed,
      });
      // console.log("data", data);
      // return;
      dispatch({
        type: SUBMIT_GRADE_RESULT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUBMIT_GRADE_RESULT_FAIL,
        payload: error,
      });
    }
  };

/**
 * Get all results for an user
 */
export const getResults = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_RESULTS_REQUEST,
    });
    const { data } = await axios.get(`${_api_path}/get-results/${userId}`);
    // console.log("data ", data);
    // return;
    dispatch({
      type: GET_USER_RESULTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_RESULTS_FAIL,
      payload: err,
    });
  }
};

/**
 * Get a particular result
 */
export const getResultData = (quizId) => async (dispatch) => {
  // alert(quizId);
  try {
    dispatch({
      type: GET_RESULT_REQUEST,
    });
    const { data } = await axios.get(`${_api_path}/get-result-data/${quizId}`);
    // console.log("data ", data);
    // return;
    dispatch({
      type: GET_RESULT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_RESULT_FAIL,
      payload: err,
    });
  }
};

export const getResultMockAnalyticsData = (userId) => async (dispatch) => {
  // alert(quizId);
  try {
    dispatch({
      type: GET_RESULT_REQUEST,
    });
    const { data } = await axios.get(`${_api_path}/get-mock-results-analytics/${userId}`);
    dispatch({
      type: GET_MOCK_RESULT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_RESULT_FAIL,
      payload: err,
    });
  }
};

/**
 * Get a particular result view
 */
export const getResultDataView = (quizId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RESULT_VIEW_REQUEST,
    });
    const { data } = await axios.get(
      `${_api_path}/get-result-data-view/${quizId}`
    );
    // console.log("data ", data);
    // return;
    dispatch({
      type: GET_RESULT_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_RESULT_VIEW_FAIL,
      payload: err,
    });
  }
};
