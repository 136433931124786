import axios from "axios";
import {
  LOAD_ALL_ASSESSMENTS_REQUEST,
  LOAD_ALL_ASSESSMENTS_SUCCESS,
  LOAD_ALL_ASSESSMENTS_FAIL,
  ADD_ASSESSMENT_REQUEST,
  ADD_ASSESSMENT_SUCCESS,
  ADD_ASSESSMENT_RESET,
  ADD_ASSESSMENT_FAIL,
  ASSESSMENT_DETAIL_REQUEST,
  ASSESSMENT_DETAIL_SUCCESS,
  ASSESSMENT_DETAIL_FAIL,
  UPDATE_ASSESSMENT_REQUEST,
  UPDATE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_RESET,
  UPDATE_ASSESSMENT_FAIL,
  DELETE_ASSESSMENT_REQUEST,
  DELETE_ASSESSMENT_SUCCESS,
  DELETE_ASSESSMENT_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminAssessmentConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all assessments
export const getAssessments = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_ASSESSMENTS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/assessments`);

    dispatch({
      type: LOAD_ALL_ASSESSMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_ASSESSMENTS_FAIL,
      payload: error,
    });
  }
};

//get assessment detail
export const getAssessmentDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/assessments/${id}`);
    // console.log('data ', data)
    dispatch({
      type: ASSESSMENT_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: ASSESSMENT_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// add assessment
export const addAssessment = (title, status, logo, imageName) => async (
  dispatch
) => {
  try {
    dispatch({
      type: ADD_ASSESSMENT_REQUEST,
    });
    const config = {
      headers: {
        //"content-type": "multipart/form-data",
        // "Accept": "application/json",
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/admin/assessments/add",
      {
        title,
        status,
        logo,
        imageName,
      },
      config
    );
    if (!data) {
      dispatch({
        type: ADD_ASSESSMENT_FAIL,
        payload: "Invalid email or password",
      });
    }
    // console.log("data ", data);
    dispatch({
      type: ADD_ASSESSMENT_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: ADD_ASSESSMENT_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Update assessment
export const updateAssessment = (
  assessmentId,
  title,
  status,
  oldImage,
  logo,
  imageName
) => async (dispatch) => {
  // console.log('status ', status);
  try {
    dispatch({
      type: UPDATE_ASSESSMENT_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/assessments/update/${assessmentId}`,
      {
        assessmentId,
        title,
        status,
        oldImage,
        logo,
        imageName,
      },
      config
    );

    dispatch({
      type: UPDATE_ASSESSMENT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ASSESSMENT_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Delete assessment
export const deleteAssessmentData = (assessmentId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_ASSESSMENT_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/assessments/${assessmentId}`
    );

    dispatch({
      type: DELETE_ASSESSMENT_SUCCESS,
      payload: assessmentId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ASSESSMENT_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
