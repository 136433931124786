import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Loader from "../Loader";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { updatePassword, clearErrors } from "../../actions/userActions";
import { loadUser } from "./../../actions/authActions";
import { UPDATE_PASSWORD_RESET } from "./../../constants/userConstants";

const UpdatePassword = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorData, setErrorData] = useState({});

  const { loading, isUpdated, error, errors } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Password updated successfully");
      history.push(`/profile`);

      dispatch({
        type: UPDATE_PASSWORD_RESET,
      });
    }
  }, [dispatch, isUpdated, alert, error, errors, history]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(updatePassword(oldPassword, newPassword, confirmPassword));
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="right-auth-landing">
            <div className="main-view-content">
              <div className="all-ourt-style w-80-cust  work-info">
                <div className="all-heads">
                  <h3>Account Setting</h3>
                </div>
                <div className="editable-forms-users">
                  <form id="quickForm" onSubmit={submitHandler}>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="oldPassword">
                            Old Password <span className="impot-star">*</span>
                          </label>
                          <input
                            type="password"
                            name="oldPassword"
                            className="form-control"
                            id="oldPassword"
                            placeholder="Enter Old Password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                          />
                          {errorData.oldPassword ? (
                            <span className="text-danger">
                              {errorData.oldPassword}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="newPassword">
                            New Password <span className="impot-star">*</span>
                          </label>
                          <input
                            type="password"
                            name="newPassword"
                            className="form-control"
                            id="newPassword"
                            placeholder="Enter Old Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          {errorData.newPassword ? (
                            <span className="text-danger">
                              {errorData.newPassword}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="confirmPassword">
                            Confirm Password <span className="impot-star">*</span>
                          </label>
                          <input
                            type="password"
                            name="confirmPassword"
                            className="form-control"
                            id="confirmPassword"
                            placeholder="Enter Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          {errorData.confirmPassword ? (
                            <span className="text-danger">
                              {errorData.confirmPassword}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="text-right foot-btns">
                      <button
                        type="submit"
                        className="btn btn-primary scoler-details"
                        disabled={loading ? true : false}
                      >
                        Update Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UpdatePassword;
