import {
  LOAD_ALL_USERS_REQUEST,
  LOAD_ALL_USERS_SUCCESS,
  LOAD_ALL_USERS_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_RESET,
  ADD_USER_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_RESET,
  UPDATE_PROFILE_FAIL,
  CLEAR_ERRORS,
} from "../constants/userConstants";

export const usersReducer = (state = { users: {} }, action) => {
  switch (action.type) {
    case LOAD_ALL_USERS_REQUEST:
      return {
        user_loading: true,
        users: [],
      };

    case LOAD_ALL_USERS_SUCCESS:
      return {
        user_loading: false,
        users: action.payload.users,
      };

    case LOAD_ALL_USERS_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        user_loading: true,
        errors: null,
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user_loading: false,
        //isUpdated: true
        isUpdated: action.payload,
        errors: null,
      };

    case UPDATE_PROFILE_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const addUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return {
        user_loading: true,
        isAdded: false,
        errors: null,
      };

    case ADD_USER_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isAdded: true,
        user: action.payload,
        errors: null,
      };

    case ADD_USER_RESET:
      return {
        ...state,
        isAdded: false,
        errors: null,
      };

    case ADD_USER_FAIL:
      return {
        ...state,
        user_loading: false,
        isAdded: false,
        user: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
