import React from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";
import { useEffect, useState } from 'react';
import { getQuestionTopicSections } from '../../actions/adminQuestionActions';

const SelectSubjectTopic = ({ history, match }) => {


    const alert = useAlert();
    const dispatch = useDispatch();
    const [selectedTopics, setSelectedTopics] = useState([]);
    const subjectId = match.params.selectedsubjectId;
    const checkedSubject = localStorage.getItem("checkedSubjects");
    const yearId = match.params.id;

    const objectIdArray = checkedSubject.split(',').map(id => id.trim());

    const { qTopicSections, loading } = useSelector((state) => state.questions);
    // console.log("year", yearId)

    useEffect(() => {
        let temp = [];
        objectIdArray?.map((item) => temp.push(item));
        dispatch(getQuestionTopicSections(temp));
    }, [dispatch]);

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        // Initialize selectedTopics with all topics by default
        const defaultSelectedTopics = qTopicSections.reduce((acc, item) => {
            // Add all topic IDs to the selectedTopics array
            acc.push(...item.topics.map(topic => topic._id));
            return acc;
        }, []);

        setSelectedTopics(defaultSelectedTopics);
    }, [qTopicSections]);


    const submitHandler = () => {
        if (selectedTopics?.length < 1) {
            alert.error(`Please select at least one topic to proceed.`);

        } else {
            localStorage.setItem("selectedTopics", selectedTopics);
            history.push(`/start-exam`)
        }
    }

    const goToPreviousPage = () => {
        history.push(`/registered-subjects`)
    }

    // const handleChange = (e) => {
    //     let subArray = [...selectedTopics, e.target.value];
    //     if (selectedTopics.includes(e.target.value)) {
    //         subArray = subArray.filter((sub) => sub !== e.target.value);
    //     }
    //     setSelectedTopics(subArray)
    // }

    const handleChange = (e) => {
        const topicId = e.target.value;
        if (selectedTopics.includes(topicId)) {
            // If the topic is already selected, remove it
            setSelectedTopics(selectedTopics.filter((id) => id !== topicId));
        } else {
            // If the topic is not selected, add it
            setSelectedTopics([...selectedTopics, topicId]);
        }
    };
    // console.log("selected topic", selectedTopics);

    return (
        <>
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            Select Topics
                        </li>
                        {qTopicSections.map((item, index) => (
                            <li key={index}>
                                {item.subject.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </section>


            <div className='TopicSection'>
                {qTopicSections?.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className="end-plan" style={{ marginTop: "20px" }}>
                                <h1 className='text-center'>{item?.subject?.title}</h1>
                            </div>
                            <ul className="grid">
                                {item?.topics?.map((item, index) => {
                                    return (<li className="card" key={index}>
                                        <div className="card__content">
                                            <div className="Topic_title"><span>Topic: </span><p>{item.title}</p></div>
                                            <div className="Topic_title">
                                                <span>Topic Section: </span>
                                                <ul>
                                                    {item?.topicSections.map((item, index) => {
                                                        return (
                                                            <li key={index}>{item?.section_name}</li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <label className="checkbox-control">
                                            <input type="checkbox" className="checkbox" checked={selectedTopics.includes(item._id)} value={item._id} onChange={handleChange} />
                                            <span className="checkbox-control__target">Card Label</span>
                                        </label>
                                    </li>)
                                })}
                            </ul>
                        </div>

                    )
                })}

                <div className="next-prev-steps text-center">&nbsp;<button type="submit" className="chose-more" onClick={goToPreviousPage}><i className="fas fa-arrow-left" />&nbsp;Previous</button>&nbsp;<button type="submit" className="chose-more" onClick={submitHandler}>Next <i className="fas fa-arrow-right"></i></button></div>
            </div>

        </>
    )
}

export default SelectSubjectTopic