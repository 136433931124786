import axios from 'axios';
import {

    SAVE_EXAM_SUBJECT_REQUEST,
    SAVE_EXAM_SUBJECT_SUCCESS,
    SAVE_EXAM_SUBJECT_FAIL,

    GET_SAVED_EXAM_SUBJECTS_REQUEST,
    GET_SAVED_EXAM_SUBJECTS_SUCCESS,
    GET_SAVED_EXAM_SUBJECTS_FAIL,

    GET_ALL_SAVED_EXAM_SUBJECTS_REQUEST,
    GET_ALL_SAVED_EXAM_SUBJECTS_SUCCESS,
    GET_ALL_SAVED_EXAM_SUBJECTS_FAIL,

    CLEAR_ERRORS
} from '../../constants/examSubjectConstants';


const _api_path = '/api/v1';


export const saveExamSubjects = (
    { userId,
        examId,
        categoryId,
        courseId,
        userSubscription,
        subjects,
        subjectsProcessed,
        subjectsUpdate,
        updateRequest }
) => async (
    dispatch
) => {
        try {
            dispatch({
                type: SAVE_EXAM_SUBJECT_REQUEST
            });

            const { data } = await axios.post(`${_api_path}/save-exam-subjects`, {
                userId,
                examId,
                categoryId,
                courseId,
                userSubscription,
                subjects,
                subjectsProcessed,
                subjectsUpdate,
                updateRequest
            });

            dispatch({
                type: SAVE_EXAM_SUBJECT_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: SAVE_EXAM_SUBJECT_FAIL,
                payload: error
            });
        }
    };

//get all selected subjects
export const getSavedExamSubjects = (userId ) => async (dispatch) => {
    try {
        dispatch({
            type: GET_SAVED_EXAM_SUBJECTS_REQUEST
        });

        const { data } = await axios.get(`${_api_path}/get-saved-exam-subjects/${userId}`);

        dispatch({
            type: GET_SAVED_EXAM_SUBJECTS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: GET_SAVED_EXAM_SUBJECTS_FAIL,
            payload: error
        });
    }
};


export const deleteSavedExamSubjects = (userId) => async (dispatch) => {
    try {
        // dispatch({
        //     type: GET_SAVED_EXAM_SUBJECTS_REQUEST
        // });

        const { data } = await axios.delete(`${_api_path}/delete-saved-exam-subjects/${userId}`);
        // // console.log("data", data); return false;
        // dispatch({
        //     type: GET_SAVED_EXAM_SUBJECTS_SUCCESS,
        //     payload: data
        // });

    } catch (error) {
        // dispatch({
        //     type: GET_SAVED_EXAM_SUBJECTS_FAIL,
        //     payload: error
        // });
    }
};

export const getAllSavedExamSubjects = (userId) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ALL_SAVED_EXAM_SUBJECTS_REQUEST
        });

        const { data } = await axios.get(`${_api_path}/get-students-exam-subjects/`);
        // console.log("data", data); return false;
        dispatch({
            type: GET_ALL_SAVED_EXAM_SUBJECTS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: GET_ALL_SAVED_EXAM_SUBJECTS_FAIL,
            payload: error
        });
    }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
};
