import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { getUniversityList } from "../../../actions/adminUniversityAction";

import { useDispatch, useSelector } from "react-redux";
import { getSubjectYears, deleteSubjectYears } from "../../../actions/adminQuestionActions";

import Loader from "../../Loader";

const ListSubjectYears = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { universityList, isUniversityDeleted } = useSelector((state) => state.university);

  const [examSlug, setExamSlug] = useState("");
  const slug = localStorage.getItem("examSlug");
  const subjectName = localStorage.getItem("subjectName");
  const { subjectYears, loading, isSubject } = useSelector((state) => state.questions);

  const subjectId = match.params.subjectId;
  useEffect(() => {
    const slug = localStorage.getItem("examSlug");
    setExamSlug(slug);
    dispatch(getSubjectYears(subjectId));
  }, [dispatch]);


  const [universitySubjects, setUniversitySubjects] = useState([]);

  localStorage.setItem("examSlug", subjectYears && subjectYears[0]?.subject?.exam?.slug);

  // console.log("universityList ", universityList);

  useEffect(() => {
    dispatch(getUniversityList())
  }, [])

  const handleDelete = (e, id) => {
    e.preventDefault();
    dispatch(deleteSubjectYears(id))
    dispatch(getSubjectYears(subjectId));

  }


  // useEffect(() => {
  //   if (universityList) {
  //     let temp = [];
  //     universityList?.forEach(university => {
  //       let obj;
  //       const subject = subjectYears.map((sub) => {
  //         sub.university.includes(university?._id)
  //         return sub;
  //       })
  //       obj = { university: university?.title, subject }
  //       temp.push(obj)
  //       console.log(university.title)


  //     })

  //     console.log("temp", temp);


  //   }


  //   return () => {

  //   }
  // }, [universityList])

  useEffect(() => {
    if (universityList && subjectYears?.length > 0) {
      let temp = [];
      universityList?.forEach((university) => {
        const subjectsForUniversity = subjectYears?.filter(
          (sub) => sub.university.includes(university?._id)
        );
        if (subjectsForUniversity?.length > 0) {
          const obj = { university: university?.title, universityId: university?._id, subject: subjectsForUniversity };
          temp.push(obj);
        }
      });
      setUniversitySubjects(temp);
      // console.log("temp", temp);
    }

    return () => {
      // Cleanup logic if needed
    };
  }, [universityList, subjectYears]);


  // console.log(examSlug);


  // return;
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Subject Years</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to={`/admin/question-subjects`}>
                    <i className="fa fa-table"></i> Question Subjects
                  </Link>
                </li>
                <li className="active">Subject Years [Exam:- {slug}, Subject:- {subjectName}]</li>
              </ol>
            </section>

            {slug !== "post-utme" ? <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Subject Years</h3>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Sr No</th>
                            <th>Year</th>
                            <th>Status</th>
                            <th>Total Questions</th>
                            <th>Topic and Sections</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subjectYears != undefined && subjectYears.length ? (
                            subjectYears.map((item, index) => (
                              <tr key={item._id}>
                                <td>{index+1}</td>
                                <td>{item.year}</td>

                                <td>
                                  {item.status === true && (
                                    <span className="badge bg-green">
                                      Active
                                    </span>
                                  )}
                                  {item.status === false && (
                                    <span className="badge bg-yellow">
                                      InActive
                                    </span>
                                  )}
                                </td>
                                <td>{item.totalQuestionsByYear}</td>

                                <td>
                                  {/* <Link
                                    to={`/admin/topicsection/${item.subject?._id}/${item._id}`}
                                    className="btn btn-success btn-sm"
                                    title="Topic and Section"
                                    onClick={() => localStorage.setItem("universityId", ""), localStorage.setItem("year", item.year)}

                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link> */}
                                  <Link
                                    to={`/admin/topicsection/${item.subject?._id}/${item._id}`}
                                    className="btn btn-success btn-sm"
                                    title="Topic and Section"
                                    onClick={() => {
                                      localStorage.setItem("universityId", "");
                                      localStorage.setItem("year", item.year);
                                    }}
                                  >
                                    <i className="fa fa-eye"></i>

                                  </Link>

                                </td>
                                <td>
                                  <button
                                    // type="button"
                                    onClick={(e) => dispatch(deleteSubjectYears(item._id))}

                                    className="btn btn-danger btn-sm"
                                    title="Delete"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>

                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>

                  </div>

                </div>

              </div>

            </section> :
              <section className="content">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="box">
                      <div className="box-header">
                        <h3 className="box-title">Subject Years </h3>
                      </div>
                      <div className="box-body table-responsive no-padding">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Year</th>
                              <th>Status</th>
                              {/* <th>Total Questions</th> */}
                              <th>Topic and Sections</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {universitySubjects?.map((universityItem, universityIndex) => (
                              <React.Fragment key={universityIndex}>
                                <tr>
                                  <td colSpan="5"><strong>{universityItem.university}</strong></td>
                                </tr>
                                {universityItem.subject != undefined && universityItem.subject.length ? (
                                  universityItem.subject.map((subjectItem) => (
                                    <tr key={subjectItem._id}>
                                      <td></td>
                                      <td>{subjectItem.year}</td>
                                      <td>
                                        {subjectItem.status === true && (
                                          <span className="badge bg-green">Active</span>
                                        )}
                                        {subjectItem.status === false && (
                                          <span className="badge bg-yellow">InActive</span>
                                        )}
                                      </td>
                                      {/* <td>{subjectItem.totalQuestionsByYear}</td> */}

                                      <td>
                                        <Link
                                          to={`/admin/topicsection/${subjectItem.subject?._id}/${subjectItem._id}`}
                                          className="btn btn-success btn-sm"
                                          title="Topic and Section"
                                          onClick={() => localStorage.setItem("universityId", universityItem.universityId)}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                      </td>
                                      <td>
                                        <button
                                          // type=""
                                          className="btn btn-danger btn-sm"
                                          title="Delete"
                                          onClick={(e) => handleDelete(e, subjectItem._id)}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="5">No records found</td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                          <tfoot></tfoot>
                        </table>

                      </div>

                    </div>

                  </div>

                </div>

              </section>
            }

          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListSubjectYears;
