import axios from "axios";
import {
  LOAD_ALL_TOPIC_SECTIONS_REQUEST,
  LOAD_ALL_TOPIC_SECTIONS_SUCCESS,
  LOAD_ALL_TOPIC_SECTIONS_FAIL,
  ADD_TOPIC_SECTION_REQUEST,
  ADD_TOPIC_SECTION_SUCCESS,
  ADD_TOPIC_SECTION_RESET,
  ADD_TOPIC_SECTION_FAIL,
  TOPIC_SECTION_DETAIL_REQUEST,
  TOPIC_SECTION_DETAIL_SUCCESS,
  TOPIC_SECTION_DETAIL_FAIL,
  UPDATE_TOPIC_SECTION_REQUEST,
  UPDATE_TOPIC_SECTION_SUCCESS,
  UPDATE_TOPIC_SECTION_RESET,
  UPDATE_TOPIC_SECTION_FAIL,
  DELETE_TOPIC_SECTION_REQUEST,
  DELETE_TOPIC_SECTION_SUCCESS,
  DELETE_TOPIC_SECTION_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminTopicSectionConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all topics
export const getTopicSections = (topicId) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_TOPIC_SECTIONS_REQUEST,
    });

    const { data } = await axios.get(
      `${_api_path}/admin/topic-sections/${topicId}`
    );

    dispatch({
      type: LOAD_ALL_TOPIC_SECTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_TOPIC_SECTIONS_FAIL,
      payload: error,
    });
  }
};

//get topic detail
export const getTopicSectionDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TOPIC_SECTION_DETAIL_REQUEST,
    });

    const { data } = await axios.get(
      `${_api_path}/admin/topic-sections/detail/${id}`
    );
    dispatch({
      type: TOPIC_SECTION_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: TOPIC_SECTION_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// add topic
export const addTopicSection = (
  topicId,
  section_name,
  topic_count,
  status
) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_TOPIC_SECTION_REQUEST,
    });
    const config = {
      headers: {
        //"content-type": "multipart/form-data",
        // "Accept": "application/json",
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + `/admin/topic-sections/${topicId}/add`,
      {
        topicId,
        section_name,
        topic_count,
        status,
      },
      config
    );
    dispatch({
      type: ADD_TOPIC_SECTION_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    // console.log("error.response ", error.response);
    // return;
    dispatch({
      type: ADD_TOPIC_SECTION_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Update topic
export const updateTopicSection = (
  sectionId,
  section_name,
  topic_count,
  status
) => async (dispatch) => {
  // console.log('status ', status);
  try {
    dispatch({
      type: UPDATE_TOPIC_SECTION_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/topic-sections/update/${sectionId}`,
      {
        sectionId,
        section_name,
        topic_count,
        status,
      },
      config
    );

    dispatch({
      type: UPDATE_TOPIC_SECTION_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_TOPIC_SECTION_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Delete topic
export const deleteTopicSectionData = (sectionId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_TOPIC_SECTION_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/topic-sections/${sectionId}`
    );

    dispatch({
      type: DELETE_TOPIC_SECTION_SUCCESS,
      payload: sectionId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_TOPIC_SECTION_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
