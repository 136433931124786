import axios from "axios";
import {
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_EXAM_TYPES_REQUEST,
  GET_EXAM_TYPES_SUCCESS,
  GET_EXAM_TYPES_FAIL,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_ERRORS,
} from "../constants/commonConstants";
const _api_path = "/api/v1";
//get all countries
export const getCountries = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRIES_REQUEST,
    });
    const { data } = await axios.get(_api_path + "/country");
    // console.log("country ", data);
    // return;
    dispatch({
      type: GET_COUNTRIES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_COUNTRIES_FAIL,
      payload: err,
    });
  }
};

//get all categories
export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORIES_REQUEST,
    });
    const { data } = await axios.get(_api_path + "/get-categories");
    // console.log("country ", data);
    // return;
    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CATEGORIES_FAIL,
      payload: err,
    });
  }
};

//get all exams
export const getAllExamTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_EXAM_TYPES_REQUEST,
    });
    const { data } = await axios.get(_api_path + "/exam-types");
    // console.log("exams ", data);
    // return;
    dispatch({
      type: GET_EXAM_TYPES_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_EXAM_TYPES_FAIL,
      payload: err,
    });
  }
};

//get all courses
export const getAllCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COURSES_REQUEST,
    });
    const { data } = await axios.get(_api_path + "/courses");
    // console.log("country ", data);
    // return;
    dispatch({
      type: GET_COURSES_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_COURSES_FAIL,
      payload: err,
    });
  }
};

//get all subjects
export const getAllSubjects = (examId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUBJECTS_REQUEST,
    });
    const { data } = await axios.get(_api_path + "/subjects/"+examId);
    // console.log("country ", data);
    // return;
    dispatch({
      type: GET_SUBJECTS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUBJECTS_FAIL,
      payload: err,
    });
  }
};

// Update user profile
export const getUserData = (userId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: GET_USER_REQUEST,
    });

    const { data } = await axios.get(_api_path + `/admin/users/${userId}`);

    dispatch({
      type: GET_USER_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Delete user
export const deleteUserData = (userId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_USER_REQUEST,
    });

    const { data } = await axios.delete(_api_path + `/admin/users/${userId}`);

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: userId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response,
      //payload: error.response.data.errMessage,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
