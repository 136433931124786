import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../actions/commonActions";
import {
  addQuestion,
  clearErrors,
  getQuestions,
} from "../../../actions/adminQuestionActions";
import { ADD_QUESTION_RESET } from "./../../../constants/adminQuestionConstants";

import Loader from "../../Loader";

import "./Question.css";

import { Editor } from "@tinymce/tinymce-react";

import QuestionForm from "./QuestionForm";
import { getUniversityList } from "../../../actions/adminUniversityAction";

const ImportQuestions = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [university, setUniversity] = useState([]);
  const [universityLists, setuniversityLists] = useState([]);
  const [editorContents, setEditorContents] = React.useState([]);
  const { universityList, loading, isUniversityDeleted } = useSelector((state) => state.university);
  const universityId = localStorage.getItem("universityId");

  const [questionData, setQuestionData] = useState({
    title: "",
    passage_check: false,
    passage: "",
    question: "",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    correct_answer: "",
    description: "",
    logo: "",
    status: false,
    imageName: "",
  });
  useEffect(() => {
    dispatch(getUniversityList())
  }, [])

  useEffect(() => {
    if (universityList !== undefined && universityList?.length > 0 && universityList !== null && slug == 'post-utme') {
      const tmpArr = [];
      universityList?.map((course) => {
        tmpArr?.push({ label: course?.title, value: course?._id })
      })

      setuniversityLists(tmpArr)
    }
  }, [universityList])
  useEffect(() => {

    if (universityId && universityLists?.length > 0) {
      const temp = [];
      const local = universityLists.find((item) => item.value == universityId)
      temp.push(local)
      setUniversity(temp)
    }

    return () => {

    }
  }, [universityId, universityLists])


  const [formList, setFormList] = useState([
    // {
    //   index: Math.random(),
    //   questionTitle: "",
    //   option1: "",
    //   option2: "",
    //   option3: "",
    //   option4: "",
    //   option5: "",
    //   rightAnswer: "",
    //   answerDesc: "",
    // },
  ]);

  // const handleChange = (e) => {
  //   // e.preventDefault();
  //   if (
  //     [
  //       "questionTitle",
  //       "option1",
  //       "option2",
  //       "option3",
  //       "option4",
  //       "option5",
  //       "rightAnswer",
  //       "answerDesc",
  //     ].includes(e.target.name)
  //   ) {
  //     let quesList = [...formList];
  //     quesList[e.target.dataset.id][e.target.name] = e.target.value;
  //   } else {
  //     setFormList({ [e.target.name]: e.target.value });
  //   }
  // };

  // const handleAnswerChange = (e) => {
  //   // e.preventDefault();
  //   const id = e.target.dataset.id;
  //   const value = e.target.value;
  //   // console.log('e.target.value', e.target);
  //   // return false;

  //   let newArr = [...formList]; // copying the old datas array
  //   newArr[id].rightAnswer = e.target.value; // replace e.target.value with whatever you want to change it to

  //   setFormList(newArr);
  // };

  // const addNewRow = () => {
  //   setFormList([
  //     ...formList,
  //     {
  //       index: Math.random(),
  //       questionTitle: "",
  //       option1: "",
  //       option2: "",
  //       option3: "",
  //       option4: "",
  //       option5: "",
  //       rightAnswer: "",
  //       answerDesc: "",
  //     },
  //   ]);
  // };

  // const deteteRow = (index) => {
  //   setFormList(formList.filter((s, sindex) => index !== sindex));
  // };

  // const clickOnDelete = (record) => {
  //   setFormList(formList.filter((r) => r !== record));
  // };

  const [errorData, setErrorData] = useState({});

  // const { title, passage_check, passage, question, status, logo, imageName } =
  //   questionData;

  const subjectId = match.params.subjectId;
  const yearId = match.params.yearId;
  const sectionId = match.params.sectionId;
  const topicId = match.params.topicId;
  const slug = localStorage.getItem("examSlug");
  const subjectName = localStorage.getItem("subjectName");
  const subjectYear = localStorage.getItem("year");



  // "title",title,
  // "Passage chek",passage_check,
  // "Passage",passage,
  // "formList",formList,
  // "status",status,
  // "Logo",logo,
  // "image Name",imageName,
  // "Temp",temp,
  // "ExamName",examSlug









  const { isQuestionAdded, user_loading, error, errors } = useSelector(
    (state) => state.questionDetail
  );

  // const changePassageText = (txt) => {
  //   setQuestionData({
  //     ...questionData,
  //     passage: txt,
  //   });
  // };

  // const changeEditorText = (data) => {
  //   setQuestionData({
  //     ...questionData,
  //     question: data,
  //   });

  //   //new method to update question
  //   let newArr = [...formList]; // copying the old datas array
  //   newArr[0].questionTitle = data; // replace e.target.value with whatever you want to change it to

  //   setFormList(newArr);
  // };

  // useEffect(() => {
  //   dispatch(getAllCategories());

  //   if (errors) {
  //     setErrorData(errors);
  //   }

  //   if (error) {
  //     clearErrors();
  //     return alert.error(error);
  //   }

  //   if (isQuestionAdded === true && isQuestionAdded != undefined) {
  //     alert.success("Question successfully added.");
  //     history.push(`/admin/questions/${sectionId}/${subjectId}/${yearId}/${topicId}`);
  //     dispatch({
  //       type: ADD_QUESTION_RESET,
  //     });
  //   }
  // }, [dispatch, isQuestionAdded, alert, error, errors]);

  // const onChange = (e) => {
  //   if (e.target.name === "logo") {
  //     let file = e.target.files[0];

  //     if (!file.name.match(/\.(xlsx)$/)) {
  //       setQuestionData({
  //         ...questionData,
  //         logo: "",
  //         imageName: "",
  //       });
  //       alert.error("Please select valid file.");
  //       //remove preview picture if any
  //       const preview = document.getElementById("img-preview");
  //       preview.src = "";
  //       return false;
  //     }

  //     const reader = new FileReader();

  //     reader.onload = function (readerEvt) {
  //       // The file's text will be printed here
  //       //console.log(readerEvt.target.result);
  //       let binaryString = readerEvt.target.result;
  //       let base64String = btoa(binaryString);
  //       setQuestionData({
  //         ...questionData,
  //         logo: base64String,
  //         imageName: file.name,
  //       });

  //       //preview picture
  //       // const preview = document.getElementById("img-preview");
  //       // // console.log('banary image', base64String);
  //       // preview.src = "data:image/png;base64," + base64String;
  //     };
  //     reader.readAsBinaryString(file);
  //   } else if (e.target.name === "passage_check") {
  //     setQuestionData({
  //       ...questionData,
  //       passage_check: !passage_check,
  //     });
  //   } else if (e.target.name === "status") {
  //     setQuestionData({
  //       ...questionData,
  //       status: !status,
  //     });
  //   } else {
  //     setQuestionData({
  //       ...questionData,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  // };

  // const setCorrectAnswer = (answer) => {
  //   setQuestionData({
  //     ...questionData,
  //     correct_answer: answer,
  //   });
  // };

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log('event', excelData);


    // console.log('formList', formList);
    excelData?.forEach((question, index) => {
      // console.log("que", question);
      const match = question.anwser.match(/\d+$/); // Extracts the last digit from the option
      const answer = match ? match[0] : ""; // Returns the last digit or an empty string if not found

      //   setFormList((prevFormList) => [
      //     ...prevFormList,
      //     {
      //       index: Math.random(),
      //       questionTitle: question.title,
      //       option1: question.option1,
      //       option2: question.option2,
      //       option3: question.option3,
      //       option4: question.option4,
      //       option5: question.option5,
      //       rightAnswer: answer,
      //       answerDesc: question.description,
      //     },
      //   ]);
      // console.log("index", index);

      const passage_check = false;
      const passage = "";
      const logo = "";
      const imageName = "";
      const status = question.status == 'true'? true : false;
      const temp = [];


      // console.log("university", university);
      const queData = [{
        index: Math.random(),
        questionTitle: question.title,
        option1: question.option1,
        option2: question.option2,
        option3: question.option3,
        option4: question.option4,
        option5: question.option5,
        rightAnswer: answer,
        answerDesc: question.description,
      }]
        dispatch(
          addQuestion(
            subjectId,
            topicId,
            yearId,
            sectionId,
            question.Question,
            passage_check,
            passage,
            queData,
            status,
            logo,
            imageName,
            university,
            slug
          )
        );
     
    });

    alert.success("Question imported successfully");
    dispatch(getQuestions(subjectId, yearId, topicId, sectionId,universityId));

    history.push(`/admin/questions/${sectionId}/${subjectId}/${yearId}/${topicId}`);



  };

  const [excelData, setExcelData] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (!file.name.match(/\.(xlsx)$/)) {
      alert.error('Invalid file type. Please upload an Excel file.');
      e.target.value = null;
      return;
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      const binaryData = e.target.result;

      // Update state with extracted data
      const workbook = XLSX.read(binaryData, { type: 'buffer' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);



      setExcelData(data);
    };
    // reader.readAsBinaryString(file);
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Import
                <small>Questions</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard" /> Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/admin/questions/${sectionId}/${subjectId}/${yearId}/${topicId}`}
                  >
                    <i className="fa fa-list" /> Questions
                  </Link>
                </li>
                <li className="active">Import Questions [Exam:- {slug}, Subject:- {subjectName}-{subjectYear}]</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Import Questions</h3>
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="logo">Upload File</label>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          // onChange={onChange}
                          onChange={handleFileChange}

                        />
                        {errorData.logo ? (
                          <span className="text-danger">{errorData.logo}</span>
                        ) : (
                          ""
                        )}

                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ImportQuestions;
