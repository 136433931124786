import axios from "axios";
import {
  GET_ALL_NEWS_REQUEST,
  GET_ALL_NEWS_SUCCESS,
  GET_ALL_NEWS_FAIL,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  CLEAR_ERRORS,
} from "../constants/newsConstants";

const _api_path = "/api/v1";

//get all news
export const getNewsList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_NEWS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/get-news-list`);

    dispatch({
      type: GET_ALL_NEWS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_NEWS_FAIL,
      payload: error,
    });
  }
};

//get news detail
export const getSingleNews = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_NEWS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/get-news-detail/${id}`);
    // console.log('data ', data)
    dispatch({
      type: GET_NEWS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_NEWS_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
