import axios from "axios";
import {
  LOAD_ALL_INSTRUCTIONS_REQUEST,
  LOAD_ALL_INSTRUCTIONS_SUCCESS,
  LOAD_ALL_INSTRUCTIONS_FAIL,
  ADD_INSTRUCTION_REQUEST,
  ADD_INSTRUCTION_SUCCESS,
  ADD_INSTRUCTION_FAIL,
  INSTRUCTION_DETAIL_REQUEST,
  INSTRUCTION_DETAIL_SUCCESS,
  INSTRUCTION_DETAIL_FAIL,
  UPDATE_INSTRUCTION_REQUEST,
  UPDATE_INSTRUCTION_SUCCESS,
  UPDATE_INSTRUCTION_FAIL,
  
  CLEAR_ERRORS,
} from "../constants/adminInstructionConstants";

const _api_path = "/api/v1";

//get all instructions
export const getInstructions = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_INSTRUCTIONS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/instructions`);
// console.log("data", data); return;
    dispatch({
      type: LOAD_ALL_INSTRUCTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_INSTRUCTIONS_FAIL,
      payload: error,
    });
  }
};

//get instruction detail
export const getInstructionDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: INSTRUCTION_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/instructions/${id}`);
    // console.log('data ', data)
    dispatch({
      type: INSTRUCTION_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: INSTRUCTION_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// add instruction
export const addInstruction = (content, status) => async (
  dispatch
) => {
  try {
    dispatch({
      type: ADD_INSTRUCTION_REQUEST,
    });
    const config = {
      headers: {
        //"content-type": "multipart/form-data",
        // "Accept": "application/json",
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/admin/instructions/add",
      {
        content, status
      },
      config
    );
    // console.log("data ", data);
    dispatch({
      type: ADD_INSTRUCTION_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: ADD_INSTRUCTION_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Update instruction
export const updateInstruction = (
  instructionId, content, status
) => async (dispatch) => {
  // console.log('status ', status);
  try {
    dispatch({
      type: UPDATE_INSTRUCTION_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/instructions/update/${instructionId}`,
      {
        instructionId, content, status
      },
      config
    );

    dispatch({
      type: UPDATE_INSTRUCTION_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_INSTRUCTION_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
