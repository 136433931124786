import axios from "axios";
import {
  LOAD_ALL_NEWS_REQUEST,
  LOAD_ALL_NEWS_SUCCESS,
  LOAD_ALL_NEWS_FAIL,
  ADD_NEWS_REQUEST,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_RESET,
  ADD_NEWS_FAIL,
  NEWS_DETAIL_REQUEST,
  NEWS_DETAIL_SUCCESS,
  NEWS_DETAIL_FAIL,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_RESET,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminNewsConstants";

const _api_path = "/api/v1";

//get all news
export const getNews = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_NEWS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/news-list`);

    dispatch({
      type: LOAD_ALL_NEWS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_NEWS_FAIL,
      payload: error,
    });
  }
};

//get news detail
export const getNewsDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/news-list/${id}`);
    // console.log('data ', data)
    dispatch({
      type: NEWS_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: NEWS_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// add news
export const addNews =
  (title, description, status, logo, imageName) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_NEWS_REQUEST,
      });
      const config = {
        headers: {
          //"content-type": "multipart/form-data",
          // "Accept": "application/json",
          "content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        _api_path + "/admin/news-list/add",
        {
          title,
          description,
          status,
          logo,
          imageName,
        },
        config
      );
      // console.log("data ", data);
      dispatch({
        type: ADD_NEWS_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: ADD_NEWS_FAIL,
        payload: error.response.data.errMessage,
        errors: error.response.data,
      });
    }
  };

// Update news
export const updateNews =
  (newsId, title, description, status, oldImage, logo, imageName) =>
  async (dispatch) => {
    // console.log('status ', status);
    try {
      dispatch({
        type: UPDATE_NEWS_REQUEST,
      });

      const config = {
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        // }
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.put(
        _api_path + `/admin/news-list/update/${newsId}`,
        {
          newsId,
          title,
          description,
          status,
          oldImage,
          logo,
          imageName,
        },
        config
      );

      dispatch({
        type: UPDATE_NEWS_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_NEWS_FAIL,
        payload: error.response.data.errMessage,
        errors: error.response.data,
      });
    }
  };

// Delete news
export const deleteNewsData = (newsId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_NEWS_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/news-list/${newsId}`
    );

    dispatch({
      type: DELETE_NEWS_SUCCESS,
      payload: newsId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_NEWS_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
