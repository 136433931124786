import {
    SAVE_EXAM_SUBJECT_REQUEST,
    SAVE_EXAM_SUBJECT_SUCCESS,
    SAVE_EXAM_SUBJECT_FAIL,
    GET_SAVED_EXAM_SUBJECTS_REQUEST,
    GET_SAVED_EXAM_SUBJECTS_SUCCESS,
    GET_SAVED_EXAM_SUBJECTS_FAIL,

    GET_ALL_SAVED_EXAM_SUBJECTS_FAIL,
    GET_ALL_SAVED_EXAM_SUBJECTS_REQUEST,
    GET_ALL_SAVED_EXAM_SUBJECTS_SUCCESS,

    CLEAR_ERRORS
} from './../constants/examSubjectConstants';

const initialState = {
    sloading: false, // Consider setting this to false initially
    isSaved: false,
    subject: null,
    csloading: false,
    isExamSubjects: false,
    savedSubjects: [],
    savedAllSubjects: [],
    error: null
};

export const examSubjectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_EXAM_SUBJECT_REQUEST:
            return {
                ...state,
                sloading: true,
                isSaved: false
            };

        case SAVE_EXAM_SUBJECT_SUCCESS:
            return {
                ...state,
                sloading: false,
                isSaved: true,
                subject: action.payload
            };



        case SAVE_EXAM_SUBJECT_FAIL:
            return {
                ...state,
                sloading: false,
                isSaved: false,
                subject: null,
                error: action.payload
            };

        case GET_SAVED_EXAM_SUBJECTS_REQUEST:
            return {
                ...state,
                csloading: true,
                isExamSubjects: false,

                savedSubjects: [] // Clear savedSubjects when making a new request
            };

        case GET_SAVED_EXAM_SUBJECTS_SUCCESS:
           
            return {
                ...state,
                csloading: false,
                isExamSubjects: true,
                savedSubjects:  action.payload.subjects 

            };

        case GET_SAVED_EXAM_SUBJECTS_FAIL:
            return {
                ...state,
                csloading: false,
                isExamSubjects: false,

                error: action.payload
            };

        case GET_ALL_SAVED_EXAM_SUBJECTS_REQUEST:
            return {
                ...state,
                csloading: true,
                savedAllSubjects: [] // Clear savedSubjects when making a new request
            };

        case GET_ALL_SAVED_EXAM_SUBJECTS_SUCCESS:
            return {
                ...state,
                csloading: false,
                savedAllSubjects: action.payload.subjects
            };

        case GET_ALL_SAVED_EXAM_SUBJECTS_FAIL:
            return {
                ...state,
                csloading: false,
                error: action.payload
            };


        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
};
export default examSubjectsReducer;