import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import {
  getBanners,
  deleteBannerData,
} from "../../../actions/admin/bannerActions";

import Loader from "../../Loader";

const ListBanner = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const handleDeleteBanner = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteBannerData(id));
            alert.success("Banner deleted successfully");
            dispatch(getBanners());
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  const { banners, bannerLoading } = useSelector((state) => state.banners);

  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Manage
            <small>Banner</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li className="active">Banner</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">Banner</h3>
                  <div className="box-tools">
                    <Link
                      to={"/admin/banners/add"}
                      className="btn btn-success btn-sm"
                    >
                      + Add Banner
                    </Link>
                  </div>
                </div>
                <div className="box-body table-responsive no-padding">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Banner</th>
                        <th>Image</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bannerLoading ? (
                        <Loader />
                      ) : banners !== undefined && banners.length ? (
                        banners.map((item) => (
                          <tr key={item._id}>
                            <td>{item.title}</td>
                            <td>
                              <img
                                style={{ width: "150px" }}
                                src={item.image}
                              />
                            </td>
                            <td>
                              {item.status === true && (
                                <span className="badge bg-green">Active</span>
                              )}
                              {item.status === false && (
                                <span className="badge bg-yellow">
                                  InActive
                                </span>
                              )}
                            </td>
                            <td>
                              <Link
                                to={`/admin/banners/edit/${item._id}`}
                                className="btn btn-success btn-sm"
                                title="Edit"
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                              &nbsp;
                              <a
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={() => handleDeleteBanner(item._id)}
                                key={item._id}
                              >
                                <i className="fa fa-trash"></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No records found</td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default ListBanner;
