import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getNewsDetail,
  updateNews,
  clearErrors,
} from "../../../actions/adminNewsActions";
import { UPDATE_NEWS_RESET } from "./../../../constants/adminNewsConstants";

import Loader from "../../Loader";

import "./News.css";

const EditNews = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [newsId, setNewsId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [imageName, setImageName] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const [errorData, setErrorData] = useState({});

  const { isUpdated, nloading, error, errors } = useSelector(
    (state) => state.newsList
  );

  const currentItemId = match.params.id;
  useEffect(() => {
    dispatch(getNewsDetail(currentItemId));
  }, [dispatch]);

  const { news } = useSelector((state) => state.newsDetail);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (news) {
      setNewsId(news._id);
      setTitle(news.title);
      setDescription(news.description);
      setStatus(news.status);
      setLogo(news.logo);
      setOldImage(news.logo);
      setImagePreview(process.env.REACT_APP_PUBLIC_URL + "/assets/img/news/" + news.logo);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("News updated successfully");
      dispatch(getNewsDetail(news._id));
      history.push(`/admin/news-list`);
      dispatch({
        type: UPDATE_NEWS_RESET,
      });
    }
  }, [dispatch, news, alert, error, errors, history, isUpdated]);

  const onChange = (e) => {
    let file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setLogo("");
      setImageName("");
      alert.error("Please select valid image.");
      //remove preview picture if any
      const preview = document.getElementById("img-preview");
      preview.src = "";
      return false;
    }

    const reader = new FileReader();

    reader.onload = function (readerEvt) {
      // The file's text will be printed here
      //console.log(readerEvt.target.result);
      let binaryString = readerEvt.target.result;
      let base64String = btoa(binaryString);
      setLogo(base64String);
      setImageName(file.name);

      //preview picture
      const preview = document.getElementById("img-preview");
      // console.log('banary image', base64String);
      preview.src = "data:image/png;base64," + base64String;
    };
    reader.readAsBinaryString(file);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateNews(newsId, title, description, status, oldImage, logo, imageName)
    );
  };

  return (
    <Fragment>
      {nloading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>News</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/news-list">
                    <i className="fa fa-table"></i> News
                  </Link>
                </li>
                <li className="active">Edit News</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit News</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter the news title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {errorData.title ? (
                          <span className="text-danger">{errorData.title}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="description">Decription</label>
                        <textarea
                          type="text"
                          name="description"
                          className="form-control"
                          id="description"
                          placeholder="Enter the news decription"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        {errorData.description ? (
                          <span className="text-danger">{errorData.description}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="logo">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          onChange={onChange}
                          accept={`.jpeg, .jpg, .png`}
                        />
                        {errorData.logo ? (
                          <span className="text-danger">{errorData.logo}</span>
                        ) : (
                          ""
                        )}
                        <div>
                          <img id="img-preview" src={imagePreview} />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status </label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          checked={status}
                          value={status}
                          onChange={(e) => setStatus(!status)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditNews;
