import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import {
    getSubscriptions,
    deleteSubscriptionData,
} from "../../../actions/adminSubscriptionActions";
//import { deleteUserData } from '../../../actions/commonActions';

import Loader from "../../Loader";

const ListSubscriptions = ({ history }) => {
    // const [users, setUsersData] = useState({});
    const alert = useAlert();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSubscriptions());
    }, [dispatch]);

    const { subscriptions, user_loading } = useSelector((state) => state.subscriptions);
    // console.log("subscriptions", subscriptions);

    const handleDeleteSubscription = (id) => {
        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        dispatch(deleteSubscriptionData(id));
                        dispatch(getSubscriptions());
                        alert.success("Subscription deleted successfully");
                        // history.push(`/admin/subscriptions`);
                    },
                },
                {
                    label: "No",
                    onClick: () => alert.success("No data deleted"),
                },
            ],
        });
    };

    return (
        <Fragment>
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Manage
                        <small>Subscriptions</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/admin">
                                <i className="fa fa-dashboard"></i> Dashboard
                            </Link>
                        </li>
                        <li className="active">Subscriptions</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">Subscriptions</h3>
                                    <div className="box-tools">
                                        <Link
                                            to={"/admin/subscriptions/add"}
                                            className="btn btn-success btn-sm"
                                        >
                                            + Add Subscription
                                        </Link>
                                    </div>
                                </div>
                                <div className="box-body table-responsive no-padding">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th>Exam</th>
                                            <th>Name</th>
                                            <th>Subscription Type</th>
                                            <th>Duration</th>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {user_loading ? (
                                            <Loader
                                                type="Puff"
                                                color="#00BFFF"
                                                height={50}
                                                width={50}
                                            />
                                        ) : subscriptions?.length ? (
                                            subscriptions?.map((sItem) => (
                                                <tr key={sItem._id}>
                                                    <td>{sItem?.examType !== undefined && sItem?.examType?.title !== undefined ? sItem?.examType?.title : '--'}</td>
                                                    <td>{sItem?.title}</td>
                                                    <td>{sItem.subscriptionType}</td>
                                                    <td>{sItem.duration}</td>
                                                    <td>₦{sItem.price}</td>
                                                    <td>{sItem.description}</td>
                                                    <td>
                                                        {sItem.status === true && (
                                                            <span className="badge bg-green">Active</span>
                                                        )}
                                                        {sItem.status === false && (
                                                            <span className="badge bg-yellow">
                                  InActive
                                </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={`/admin/subscriptions/edit/${sItem._id}`}
                                                            className="btn btn-success btn-sm"
                                                            title="Edit"
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </Link>
                                                        &nbsp;
                                                        <a
                                                            className="btn btn-danger btn-sm"
                                                            title="Delete"
                                                            onClick={() =>
                                                                handleDeleteSubscription(sItem._id)
                                                            }
                                                            key={sItem._id}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" style={{ textAlign: "center" }}>
                                                    No records found
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
};

export default ListSubscriptions;
