import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { getAllSubjects } from "../../../actions/commonActions";
import {
  getSubjectYearDetail,
  updateSubjectYear,
  clearErrors,
} from "../../../actions/adminQuestionActions";
import { UPDATE_SUBJECT_YEAR_RESET } from "./../../../constants/adminQuestionConstants";

import Loader from "../../Loader";

const EditSubjectYear = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const url = useParams()
  const location = useLocation();
  const extra = new URLSearchParams(location.search)
  const subId = extra.get('subId')
  const currentYear = new Date().getFullYear();
  // alert.success(currentYear);
  const [subject, setSubject] = useState("");
  const [year, setYear] = useState("");

  const [errorData, setErrorData] = useState({});

  const currentItemId = match.params.syId;

  useEffect(() => {
    if (url?.examId && url?.subId) {
      dispatch(getAllSubjects(url.examId));
      dispatch(getSubjectYearDetail(url?.subId));
    }
  }, [dispatch]);

  const { allSubjects, loading } = useSelector((state) => state.commonSubjects);

  const { subjectYear, isUpdated, syLoading, yloading, error, errors } =
    useSelector((state) => state.questionSubjectDetail);

  // console.log("subjectYear", subjectYear);
  useEffect(() => {
    if (subjectYear !== undefined && subjectYear.subject !== undefined) {
      // console.log("subjectYear ", subjectYear.subject._id);
      setSubject(subjectYear.subject._id);
      setYear(subjectYear.year);
    }

    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isUpdated === true && isUpdated != undefined) {
      alert.success("Subject year successfully update.");
      history.push("/admin/question-subjects");
      dispatch({
        type: UPDATE_SUBJECT_YEAR_RESET,
      });
    }
  }, [
    dispatch,
    isUpdated,
    subjectYear,
    syLoading,
    yloading,
    alert,
    error,
    errors,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateSubjectYear(currentItemId, subject, year));
  };

  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Edit
            <small>Subject Year</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li>
              <Link to={`/admin/subject-years/${subId}`}>
                <i className="fa fa-users"></i> Subject Year
              </Link>
            </li>
            <li className="active">Edit Subject and Year</li>
          </ol>
        </section>

        <section className="content">
          <div className="box box-default">
            <div className="box-header with-border">
              <h3 className="box-title">Edit Subject and Year</h3>

              {/*<div className="box-tools pull-right">

                                    </div>*/}
            </div>

            <form
              id="quickForm"
              onSubmit={submitHandler}
              encType="multipart/form-data"
            >
              <div className="box-body">
                <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="subjectName">Subject Name</label>
                    <select
                      className="form-control"
                      name="subjectName"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    >
                      <option value="">Select</option>
                      {loading ? (
                        <Loader />
                      ) : (
                        <Fragment>
                          {allSubjects?.length > 0
                            ? allSubjects.map((e, key) => {
                                return (
                                  <option key={key} value={e._id}>
                                    {e.title}
                                  </option>
                                );
                              })
                            : ""}
                        </Fragment>
                      )}
                    </select>
                    {errorData.subject ? (
                      <span className="text-danger">{errorData.subject}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="year">Year</label>
                    <select
                      className="form-control"
                      name="year"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value="">Select</option>
                      {Array.from(new Array(40), (v, i) => (
                        <option key={i} value={currentYear - i}>
                          {currentYear - i}
                        </option>
                      ))}
                    </select>
                    {errorData.year ? (
                      <span className="text-danger">{errorData.year}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default EditSubjectYear;
