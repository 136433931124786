import React, { Fragment } from 'react';

const AuthLayout = props => {
    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
};

export default AuthLayout;
