import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  addAssessment,
  clearErrors,
} from "../../../actions/adminAssessmentActions";
import { ADD_ASSESSMENT_RESET } from "./../../../constants/adminAssessmentConstants";

import Loader from "../../Loader";

import "./Assessment.css";

const AddAssessment = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [assessment, setAssessment] = useState({
    title: "",
    logo: "",
    status: false,
    imageName: "",
  });
  const [errorData, setErrorData] = useState({});

  const { title, status, logo, imageName } = assessment;

  const { isAssessmentAdded, user_loading, error, errors } = useSelector(
    (state) => state.assessmentDetail
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isAssessmentAdded === true && isAssessmentAdded != undefined) {
      alert.success("Assessment successfully added.");
      history.push("/admin/assessments");
      dispatch({
        type: ADD_ASSESSMENT_RESET,
      });
    }
  }, [dispatch, isAssessmentAdded, alert, error, errors]);

  const onChange = (e) => {
    if (e.target.name === "logo") {
      let file = e.target.files[0];

      if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setAssessment({
          ...assessment,
          logo: "",
          imageName: "",
        });
        alert.error("Please select valid logo.");
        //remove preview picture if any
        const preview = document.getElementById("img-preview");
        preview.src = "";
        return false;
      }

      const reader = new FileReader();

      reader.onload = function (readerEvt) {
        // The file's text will be printed here
        //console.log(readerEvt.target.result);
        let binaryString = readerEvt.target.result;
        let base64String = btoa(binaryString);
        setAssessment({
          ...assessment,
          logo: base64String,
          imageName: file.name,
        });

        //preview picture
        const preview = document.getElementById("img-preview");
        // console.log('banary image', base64String);
        preview.src = "data:image/png;base64," + base64String;
      };
      reader.readAsBinaryString(file);
    } else if (e.target.name === "status") {
      setAssessment({
        ...assessment,
        status: !status,
      });
    } else {
      setAssessment({
        ...assessment,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addAssessment(title, status, logo, imageName));
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Add
                <small>Assessment</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/assessments">
                    <i className="fa fa-users"></i> Assessments
                  </Link>
                </li>
                <li className="active">Add Assessment</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Assessment</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={onChange}
                        />
                        {errorData.title ? (
                          <span className="text-danger">{errorData.title}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="logo">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          onChange={onChange}
                          accept={`.jpeg, .jpg, .png`}
                        />
                        {errorData.logo ? (
                          <span className="text-danger">{errorData.logo}</span>
                        ) : (
                          ""
                        )}
                        <div>
                          <img id="img-preview" src="" />
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status</label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          name="status"
                          checked={status}
                          value={status}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddAssessment;
