import axios from 'axios';
import {
    LOAD_ALL_SUBJECTS_REQUEST,
    LOAD_ALL_SUBJECTS_SUCCESS,
    LOAD_ALL_SUBJECTS_FAIL,
    ADD_SUBJECT_REQUEST,
    ADD_SUBJECT_SUCCESS,
    ADD_SUBJECT_FAIL,
    SUBJECT_DETAIL_REQUEST,
    SUBJECT_DETAIL_SUCCESS,
    SUBJECT_DETAIL_FAIL,
    UPDATE_SUBJECT_REQUEST,
    UPDATE_SUBJECT_SUCCESS,
    UPDATE_SUBJECT_FAIL,
    DELETE_SUBJECT_REQUEST,
    DELETE_SUBJECT_SUCCESS,
    DELETE_SUBJECT_FAIL,
    CLEAR_ERRORS
} from '../constants/adminSubjectConstants';

const _api_path = '/api/v1';

//get all subjects
export const getSubjects = () => async (dispatch) => {
    try {
        dispatch({
            type: LOAD_ALL_SUBJECTS_REQUEST
        });

        const { data } = await axios.get(`${_api_path}/admin/subjects`);

        dispatch({
            type: LOAD_ALL_SUBJECTS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: LOAD_ALL_SUBJECTS_FAIL,
            payload: error
        });
    }
};

//get subject detail
export const getSubjectDetail = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SUBJECT_DETAIL_REQUEST
        });

        const { data } = await axios.get(`/api/v1/admin/subjects/${id}`);
        // console.log('data ', data)
        dispatch({
            type: SUBJECT_DETAIL_SUCCESS,
            payload: data.data
        });
    } catch (error) {
        dispatch({
            type: SUBJECT_DETAIL_FAIL,
            payload: error.response.data.errMessage
        });
    }
};

// add subject
export const addSubject = (course, title, subjectHour, subjectTime, logo, imageName, status, examId, mandetory,university) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_SUBJECT_REQUEST
        });
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        };
        const { data } = await axios.post(
            _api_path + '/admin/subjects/add',
            {
                course,
                title,
                subjectHour,
                subjectTime,
                logo,
                imageName,
                status,
                examId,
                mandetory,
                university
            },
            config
        );

        // console.log("data", data); return false;
        dispatch({
            type: ADD_SUBJECT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: ADD_SUBJECT_FAIL,
            payload: error.response.data.errMessage,
            errors: error.response.data
        });
    }
};

// Update subject
export const updateSubject = (
    subjectId,
    course,
    title,
    subjectHour,
    subjectTime,
    logo,
    oldImage,
    imageName,
    status,
    exam,
    mandetory,
    university
) => async (dispatch) => {
    // console.log('status ', status);
    try {
        dispatch({
            type: UPDATE_SUBJECT_REQUEST
        });

        const config = {
            // headers: {
            //     'Content-Type': 'multipart/form-data'
            // }
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const { data } = await axios.put(
            _api_path + `/admin/subjects/update/${subjectId}`,
            {
                subjectId,
                course,
                title,
                subjectHour,
                subjectTime,
                logo,
                oldImage,
                imageName,
                status,
                exam,
                mandetory,
                university
            },
            config
        );

        dispatch({
            type: UPDATE_SUBJECT_SUCCESS,
            payload: data.success
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SUBJECT_FAIL,
            payload: error.response.data.errMessage,
            errors: error.response.data
        });
    }
};

// Delete subject
export const deleteSubjectData = (subjectId) => async (dispatch) => {
    //console.log('userData ', userData);
    try {
        dispatch({
            type: DELETE_SUBJECT_REQUEST
        });

        const { data } = await axios.delete(_api_path + `/admin/subjects/${subjectId}`);

        dispatch({
            type: DELETE_SUBJECT_SUCCESS,
            payload: subjectId
        });

        dispatch(getSubjects());

    } catch (error) {
        dispatch({
            type: DELETE_SUBJECT_FAIL,
            payload: error
        });
    }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
};
