import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { useDispatch, useSelector } from "react-redux";
import { getExams } from '../../../actions/adminExamActions';
import { getAllCourses } from "../../../actions/commonActions";
import {
    getSubjectDetail,
    updateSubject,
    clearErrors,
} from "../../../actions/adminSubjectActions";
import { UPDATE_SUBJECT_RESET } from "./../../../constants/adminSubjectConstants";

import Loader from "../../Loader";

import "./Subject.css";
import { getUniversityList } from "../../../actions/adminUniversityAction";

const EditSubject = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const [subjectId, setSubjectId] = useState("");
    //const [course, setCourse] = useState([]);
    const [exam, setExam] = useState("");
    const [course, setCourse] = useState("");
    const [title, setTitle] = useState("");
    const [subjectHour, setSubjectHour] = useState("");
    const [subjectTime, setSubjectTime] = useState("");
    const [status, setStatus] = useState(false);
    const [mandetory, setMandetory] = useState(false);
    const [courses, setCourses] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [fetchedCourses, setFetchedCourses] = useState([]);
    const [logo, setLogo] = useState("");
    const [imageName, setImageName] = useState("");
    const [oldImage, setOldImage] = useState("");
    const [imagePreview, setImagePreview] = useState("");
    const [errorData, setErrorData] = useState({});
    const animatedComponents = makeAnimated();
    const [isPostUtme, setIsPostUtme] = useState(false);
    const [university, setUniversity] = useState([]);
    const [universityLists, setuniversityLists] = useState([]);
    const { isUpdated, error, errors } = useSelector(
        (state) => state.subjects
    );
    const { exams } = useSelector((state) => state.exams);
    const { allCourses, loading } = useSelector((state) => state.commonCourses);
    const { subject,user_loading } = useSelector((state) => state.subjectDetail);

    const currentUserId = match.params.id;

    const { universityList, isUniversityDeleted } = useSelector((state) => state.university);


    useEffect(() => {
        dispatch(getUniversityList())
    }, [])


    useEffect(() => {
        dispatch(getExams());
        dispatch(getAllCourses());
        dispatch(getSubjectDetail(currentUserId));
    }, [dispatch, isUpdated]);

    useEffect(() => {
        if (allCourses !== undefined && allCourses?.length > 0 && allCourses !== null) {
            const tmpArr = [{ label: "Select All", value: "select_all" }];
            allCourses?.map((course) => {
                tmpArr?.push({ label: course?.title, value: course?._id })
            })

            setFetchedCourses(tmpArr)
        }



    }, [allCourses])

    useEffect(() => {
        if (subject && subject?.course && subject?.course?.length > 0 && allCourses && allCourses?.length > 0) {
            const tmpArr = [];
            subject?.course?.map((id) => {
                if (allCourses?.some((c) => c._id === id)) {
                    const course = allCourses?.find((c) => c?._id === id)
                    tmpArr?.push({ label: course?.title, value: course?._id })
                }
            })
            setCourses(tmpArr)
        }
    }, [subject, allCourses])

    useEffect(() => {
        if (universityList !== undefined && universityList?.length > 0 && universityList !== null) {
            const tmpArr = [];
            universityList?.map((course) => {
                tmpArr?.push({ label: course?.title, value: course?._id })
            })

            setuniversityLists(tmpArr)
        }
    }, [universityList])

    useEffect(() => {
        if (errors) {
            setErrorData(errors);
        }

        if (subject) {
            //console.log("subject.course ", subject.course);
            setSubjectId(subject._id);
            setExam(subject.exam);
            setCourse(subject.course);
            setTitle(subject.title);
            setSubjectHour(subject.subjectHour);
            setSubjectTime(subject.subjectTime);
            setStatus(subject.status);
            setMandetory(subject.mandetory);
            setLogo(subject.logo);
            setOldImage(subject.logo);
            setImagePreview(
                process.env.REACT_APP_PUBLIC_URL + "/assets/img/subjects/" + subject.logo
            );

            // const tmpArr = [];
            // subject.university?.map((course) => {
            //     tmpArr?.push({ label: course?.title, value: course?._id })
            // })
            // universityList?.map((course) => {
            //     tmpArr?.push({ label: course?.title, value: course?._id })
            // })
            // console.log("sub", subject.university,tmpArr)
            const tmpArr = universityList
                ?.filter((course) => subject.university?.includes(course._id))
                .map((course) => ({ label: course.title, value: course._id }));
            setUniversity(tmpArr)

            if (exams.length > 0) {
                const slug = exams.filter(function (item) {
                    if (item._id == subject.exam) {
                        return item;
                    }
                });
                if (slug && slug?.length > 0 && slug[0].slug == "post-utme") {
                    setIsPostUtme(true);
                } else {
                    // setIsPostUtme(false);
                }
            }
        }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

    }, [dispatch, subject, alert, error, errors, history]);


    useEffect(() => {
        if (isUpdated) {
            alert.success("Subject updated successfully");
            dispatch(getSubjectDetail(subject._id));
            history.push(`/admin/subjects`);
            dispatch({
                type: UPDATE_SUBJECT_RESET,
            });
        }
        else if (isUpdated === false) {
            alert.error("Maximum mandetory subjects limit exceeded");
            dispatch({
                type: UPDATE_SUBJECT_RESET,
            });
        }
    }, [isUpdated])

    const onChange = (e) => {
        let file = e.target.files[0];

        if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setLogo("");
            setImageName("");
            alert.error("Please select valid image.");
            //remove preview picture if any
            const preview = document.getElementById("img-preview");
            preview.src = "";
            return false;
        }

        const reader = new FileReader();

        reader.onload = function (readerEvt) {
            // The file's text will be printed here
            //console.log(readerEvt.target.result);
            let binaryString = readerEvt.target.result;
            let base64String = btoa(binaryString);
            setLogo(base64String);
            setImageName(file.name);

            //preview picture
            const preview = document.getElementById("img-preview");
            // console.log('banary image', base64String);
            preview.src = "data:image/png;base64," + base64String;
        };
        reader.readAsBinaryString(file);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            updateSubject(
                subjectId,
                courses,
                title,
                subjectHour,
                subjectTime,
                logo,
                oldImage,
                imageName,
                status,
                exam,
                mandetory,
                university
            )
        )
    };

    var activeExams = [];
    if (exams.length > 0) {
        activeExams = exams.filter(function (item) {
            return item.status === true;
        });
    }

    const handleCourseSelect = (selectedOptions) => {        
        if (selectedOptions.some(option => option.value === "select_all")) {
            setCourses(fetchedCourses.slice(1)); // Select all except the "Select All" option
        } else {
            setCourses(selectedOptions);
        }
    };
    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Edit
                                <small>Subject</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard"></i> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/subjects">
                                        <i className="fa fa-users"></i> Subjects
                                    </Link>
                                </li>
                                <li className="active">Edit Subject</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Edit Subject</h3>

                                    {/*<div className="box-tools pull-right">

                                    </div>*/}
                                </div>
                                <form
                                    id="quickForm"
                                    onSubmit={submitHandler}
                                    encType="multipart/form-data"
                                >
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="exam">Exam</label>

                                                {activeExams.length > 0 && (
                                                    <select
                                                        className="form-control"
                                                        name="exam"
                                                        value={exam}
                                                        onChange={(e) => setExam(e.target.value)}
                                                    >
                                                        <option value="">Select</option>
                                                        {loading ? (
                                                            <Loader />
                                                        ) : (
                                                            <Fragment>
                                                                {activeExams.length > 0
                                                                    ? activeExams.map((e, key) => {
                                                                        return (
                                                                            <option key={key} value={e._id}>
                                                                                {e.title}
                                                                            </option>
                                                                        );
                                                                    })
                                                                    : ""}
                                                            </Fragment>
                                                        )}
                                                    </select>
                                                )}

                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="course">Course</label>
                                                <Select
                                                    value={courses}
                                                    onChange={handleCourseSelect}
                                                    options={fetchedCourses}
                                                    isMulti
                                                    components={animatedComponents}
                                                    isSearchable={true}
                                                />
                                                {/* {allCourses.length > 0 && course !== undefined && (
                                                    <select
                                                        className="form-control"
                                                        name="course"
                                                        value={course}
                                                        onChange={(e) => setCourse(e.target.value)}
                                                    >
                                                        <option value="">Select</option>
                                                        {loading ? (
                                                            <Loader />
                                                        ) : (
                                                            <Fragment>
                                                                {allCourses.length > 0
                                                                    ? allCourses.map((e, key) => {
                                                                        return (
                                                                            <option key={key} value={e._id}>
                                                                                {e.title}
                                                                            </option>
                                                                        );
                                                                    })
                                                                    : ""}
                                                            </Fragment>
                                                        )}
                                                    </select>
                                                )}

                                                {errorData.course ? (
                                                    <span className="text-danger">{errorData.course}</span>
                                                ) : (
                                                    ""
                                                )} */}
                                            </div>

                                            {isPostUtme &&
                                                <div className="form-group row ">
                                                    <div className="col-md-12">
                                                        <label htmlFor="title">Select University</label>

                                                        <Select
                                                            value={university}
                                                            onChange={setUniversity}
                                                            options={universityLists}
                                                            // isMulti
                                                            components={animatedComponents}
                                                            isSearchable={true}
                                                        />

                                                    </div>
                                                </div>}

                                            <div className="col-md-6">
                                                <label htmlFor="title">Subject Name</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Enter Subject Name"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                                {errorData.title ? (
                                                    <span className="text-danger">{errorData.title}</span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="logo">Subject Logo</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="logo"
                                                    name="logo"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                />
                                                <div>
                                                    <img id="img-preview" src={imagePreview} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="timeLimit">Time Limit</label>

                                                <select
                                                    className="form-control"
                                                    name="subjectHour"
                                                    value={subjectHour}
                                                    onChange={(e) => setSubjectHour(e.target.value)}
                                                >
                                                    <option value="">Hour</option>
                                                    {[...Array(24)].map((e, i) => (
                                                        <option value={i}>{i}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <label htmlFor="timeLimit">&nbsp;</label>
                                                <select
                                                    className="form-control"
                                                    name="subjectTime"
                                                    value={subjectTime}
                                                    onChange={(e) => setSubjectTime(e.target.value)}
                                                >
                                                    <option value="">Time</option>
                                                    <option value="00">00</option>
                                                    <option value="15">15</option>
                                                    <option value="30">30</option>
                                                    <option value="45">45</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="status">Status </label>
                                                <input
                                                    style={{ marginRight: "5px" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    checked={status}
                                                    value={status}
                                                    onChange={(e) => setStatus(!status)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="status">Mandetory </label>
                                                <input
                                                    style={{ marginRight: "5px" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    checked={mandetory}
                                                    value={mandetory}
                                                    onChange={(e) => setMandetory(!mandetory)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default EditSubject;
