import {
  LOAD_ALL_QUESTIONS_REQUEST,
  LOAD_ALL_QUESTIONS_SUCCESS,
  LOAD_ALL_QUESTIONS_FAIL,
  ADD_QUESTION_REQUEST,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_RESET,
  ADD_QUESTION_FAIL,
  QUESTION_DETAIL_REQUEST,
  QUESTION_DETAIL_SUCCESS,
  QUESTION_DETAIL_FAIL,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_RESET,
  UPDATE_QUESTION_FAIL,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  //question subject
  LOAD_QUESTION_SUBJECTS_REQUEST,
  LOAD_QUESTION_SUBJECTS_SUCCESS,
  LOAD_QUESTION_SUBJECTS_FAIL,
  SUBJECT_YEARS_REQUEST,
  SUBJECT_YEARS_SUCCESS,
  SUBJECT_YEARS_FAIL,
  ADD_QUESTION_SUBJECT_REQUEST,
  ADD_QUESTION_SUBJECT_SUCCESS,
  ADD_QUESTION_SUBJECT_RESET,
  ADD_QUESTION_SUBJECT_FAIL,
  SUBJECT_YEAR_DETAIL_REQUEST,
  SUBJECT_YEAR_DETAIL_SUCCESS,
  SUBJECT_YEAR_DETAIL_FAIL,
  UPDATE_SUBJECT_YEAR_REQUEST,
  UPDATE_SUBJECT_YEAR_SUCCESS,
  UPDATE_SUBJECT_YEAR_RESET,
  UPDATE_SUBJECT_YEAR_FAIL,
  //question topic sections
  GET_TOPIC_SECTIONS_REQUEST,
  GET_TOPIC_SECTIONS_SUCCESS,
  GET_TOPIC_SECTIONS_FAIL,
  QUESTION_DETAIL_RESET,
  // question topic
  GET_ALL_TOPIC_REQUEST,
  GET_ALL_TOPIC_SUCCESS,
  GET_ALL_TOPIC_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminQuestionConstants";

export const questionsReducer = (
  state = {
    questions: [],
    questionSubjects: [],
    subjectYears: [],
    qTopicSections: [],
    allTopics:[],
    isSubject: false
  },
  action
) => {
  switch (action.type) {
    case LOAD_ALL_QUESTIONS_REQUEST:
      return {
        ...state,
        user_loading: true,

        questions: [],
      };

    case LOAD_QUESTION_SUBJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        questionSubjects: [],
      };

    case LOAD_ALL_QUESTIONS_SUCCESS:
      return {
        ...state,
        user_loading: false,
        questions: action.payload.questions,
      };

    case LOAD_QUESTION_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        questionSubjects: action.payload.data,
      };

    case LOAD_ALL_QUESTIONS_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
      };

    case LOAD_QUESTION_SUBJECTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUBJECT_YEARS_REQUEST:
      return {
        ...state,
        loading: true,
        isSubject: false,
        subjectYears: [],
      };

    case SUBJECT_YEARS_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubject: true,

        subjectYears: action.payload.data,
      };

    case SUBJECT_YEARS_FAIL:
      return {
        ...state,
        loading: false,
        isSubject: false,
        error: action.payload,
      };

    //topic
    case GET_ALL_TOPIC_REQUEST:
      return {
        ...state,
        loading: true,
        allTopics: [],
      };

    case GET_ALL_TOPIC_SUCCESS:      
      return {
        ...state,
        loading: false,
        allTopics: action.payload.data
        ,
      };

    case GET_ALL_TOPIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    //topic sections
    case GET_TOPIC_SECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        qTopicSections: [],
      };

    case GET_TOPIC_SECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        qTopicSections: action.payload.groupedSubjects
        ,
      };

    case GET_TOPIC_SECTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //topic sections

    case UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        user_loading: true,
        errors: null,
      };

    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_QUESTION_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_QUESTION_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const questionDetailReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case QUESTION_DETAIL_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case QUESTION_DETAIL_SUCCESS:
      return {
        ...state,
        user_loading: false,
        question: action.payload,
      };

    case QUESTION_DETAIL_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
      };

      case QUESTION_DETAIL_RESET:
        return {
          ...state,
          question: {},
          user_loading: false,
        };

    case ADD_QUESTION_REQUEST:
      return {
        ...state,
        user_loading: true,
        isQuestionAdded: false,
        errors: null,
      };

    case ADD_QUESTION_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isQuestionAdded: true,
        question: action.payload,
        errors: null,
      };

    case ADD_QUESTION_RESET:
      return {
        ...state,
        isQuestionAdded: false,
        errors: null,
      };

    case ADD_QUESTION_FAIL:
      return {
        ...state,
        user_loading: false,
        isQuestionAdded: false,
        question: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_QUESTION_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case DELETE_QUESTION_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_QUESTION_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

/**
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const questionSubjectDetailReducer = (
  state = { questionSubject: {}, subjectYear: {} },
  action
) => {
  switch (action.type) {
    case SUBJECT_YEAR_DETAIL_REQUEST:
      return {
        ...state,
        syLoading: true,
      };

    case SUBJECT_YEAR_DETAIL_SUCCESS:
      return {
        ...state,
        syLoading: true,
        subjectYear: action.payload,
      };

    case SUBJECT_YEAR_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_QUESTION_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
        isQSAdded: false,
        errors: null,
      };

    case ADD_QUESTION_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        isQSAdded: true,
        question: action.payload,
        errors: null,
      };

    case ADD_QUESTION_SUBJECT_RESET:
      return {
        ...state,
        isQSAdded: false,
        errors: null,
      };

    case ADD_QUESTION_SUBJECT_FAIL:
      return {
        ...state,
        loading: false,
        isQSAdded: false,
        question: null,
        error: action.payload,
        errors: action.errors,
      };

    //subject year update
    case UPDATE_SUBJECT_YEAR_REQUEST:
      return {
        ...state,
        yloading: true,
        errors: null,
      };

    case UPDATE_SUBJECT_YEAR_SUCCESS:
      return {
        ...state,
        yloading: false,
        isUpdated: action.payload,
      };

    case UPDATE_SUBJECT_YEAR_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_SUBJECT_YEAR_FAIL:
      return {
        ...state,
        yloading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
