export const LOAD_ALL_SUBJECTS_REQUEST = "LOAD_ALL_SUBJECTS_REQUEST";
export const LOAD_ALL_SUBJECTS_SUCCESS = "LOAD_ALL_SUBJECTS_SUCCESS";
export const LOAD_ALL_SUBJECTS_FAIL = "LOAD_ALL_SUBJECTS_FAIL";

export const SUBJECT_DETAIL_REQUEST = "SUBJECT_DETAIL_REQUEST";
export const SUBJECT_DETAIL_SUCCESS = "SUBJECT_DETAIL_SUCCESS";
export const SUBJECT_DETAIL_FAIL = "SUBJECT_DETAIL_FAIL";

export const ADD_SUBJECT_REQUEST = "ADD_SUBJECT_REQUEST";
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS";
export const ADD_SUBJECT_RESET = "ADD_SUBJECT_RESET";
export const ADD_SUBJECT_FAIL = "ADD_SUBJECT_FAIL";

export const UPDATE_SUBJECT_REQUEST = "UPDATE_SUBJECT_REQUEST";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";
export const UPDATE_SUBJECT_RESET = "UPDATE_SUBJECT_RESET";
export const UPDATE_SUBJECT_FAIL = "UPDATE_SUBJECT_FAIL";
export const DELETE_SUBJECT_REQUEST = "DELETE_SUBJECT_REQUEST";
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCCESS";
export const DELETE_SUBJECT_FAIL = "DELETE_SUBJECT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
