export const GET_ALL_BANNERS = 'GET_ALL_BANNERS';
export const ADD_BANNER_REQUEST = "ADD_BANNER_REQUEST";
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS";
export const ADD_BANNER_RESET = "ADD_BANNER_RESET";
export const ADD_BANNER_FAILED = "ADD_BANNER_FAILED";
export const GET_SINGLE_BANNER = "GET_SINGLE_BANNER";
export const UPDATE_BANNER_REQUEST = "UPDATE_BANNER_REQUEST";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_RESET = "UPDATE_BANNER_RESET";
export const UPDATE_BANNER_FAILED = "UPDATE_BANNER_FAILED";

export const DELETE_BANNER = "DELETE_BANNER";

export const CLEAR_ERRORS = 'CLEAR_ERRORS';