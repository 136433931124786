import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import {
  getCourses,
  deleteCourseData,
} from "../../../actions/adminCourseActions";
//import { deleteUserData } from '../../../actions/commonActions';

import Loader from "../../Loader";
// import Loader from "react-loader-spinner";

const ListCourses = ({ history }) => {
  // const [users, setUsersData] = useState({});
  const alert = useAlert();
  const dispatch = useDispatch();

  const handleDeleteCourse = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteCourseData(id));
            // history.push(`/admin/courses`);
            alert.success("Course deleted successfully");
            dispatch(getCourses());
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(getCourses());
  }, [dispatch]);

  const { courses, user_loading } = useSelector((state) => state);

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Courses</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li className="active">Courses</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Courses</h3>
                      <div className="box-tools">
                        <Link
                          to={"/admin/courses/add"}
                          className="btn btn-success btn-sm"
                        >
                          + Add Course
                        </Link>
                      </div>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {courses.user_loading ? (
                            <Loader />
                          ) : courses.courses.length ? (
                            courses.courses.map((item) => (
                              <tr key={item._id}>
                                <td>{item.category.title}</td>
                                <td>{item.title}</td>
                                <td>
                                  {item.status === true && (
                                    <span className="badge bg-green">
                                      Active
                                    </span>
                                  )}
                                  {item.status === false && (
                                    <span className="badge bg-yellow">
                                      InActive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/courses/edit/${item._id}`}
                                    className="btn btn-success btn-sm"
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                  &nbsp;
                                  <a
                                    className="btn btn-danger btn-sm"
                                    title="Delete"
                                    onClick={() => handleDeleteCourse(item._id)}
                                    key={item._id}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListCourses;
