import React, { Fragement, Fragment } from "react";
import { Line } from "react-chartjs-2";

const LineChart = (props) => {
  const data = {
    // labels: ["1", "2", "3", "4", "5", "6"],
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: " Students",
        //data: [12, 19, 3, 5, 2, 3],
        data: props.studentData,
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <Fragment>
      <Line data={data} options={options} />
    </Fragment>
  );
};

export default LineChart;
