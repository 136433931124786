import {
  LOAD_ALL_TESTIMONIAL_REQUEST,
  LOAD_ALL_TESTIMONIAL_SUCCESS,
  LOAD_ALL_TESTIMONIAL_FAIL,
  ADD_TESTIMONIAL_REQUEST,
  ADD_TESTIMONIAL_SUCCESS,
  ADD_TESTIMONIAL_RESET,
  ADD_TESTIMONIAL_FAIL,
  TESTIMONIAL_DETAIL_REQUEST,
  TESTIMONIAL_DETAIL_SUCCESS,
  TESTIMONIAL_DETAIL_FAIL,
  UPDATE_TESTIMONIAL_REQUEST,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_RESET,
  UPDATE_TESTIMONIAL_FAIL,
  DELETE_TESTIMONIAL_REQUEST,
  DELETE_TESTIMONIAL_SUCCESS,
  DELETE_TESTIMONIAL_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminTestimonialConstants";

export const testimonialListReducer = (state = { testimonials: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_TESTIMONIAL_REQUEST:
      return {
        aloading: true,
        testimonials: [],
      };

    case LOAD_ALL_TESTIMONIAL_SUCCESS:
      return {
        aloading: false,
        testimonials: action.payload.testimonials,
      };

    case LOAD_ALL_TESTIMONIAL_FAIL:
      return {
        aloading: false,
        error: action.payload,
      };

    case UPDATE_TESTIMONIAL_REQUEST:
      return {
        ...state,
        aloading: true,
        errors: null,
      };

    case UPDATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        aloading: false,
        isUpdated: action.payload,
      };

    case UPDATE_TESTIMONIAL_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_TESTIMONIAL_FAIL:
      return {
        ...state,
        aloading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const testimonialDetailReducer = (state = { testimonial: {} }, action) => {
  switch (action.type) {
    case TESTIMONIAL_DETAIL_REQUEST:
      return {
        ...state,
        aloading: true,
      };

    case TESTIMONIAL_DETAIL_SUCCESS:
      return {
        aloading: true,
        testimonial: action.payload,
      };

    case TESTIMONIAL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_TESTIMONIAL_REQUEST:
      return {
        aloading: true,
        isTestimonialAdded: false,
        errors: null,
      };

    case ADD_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        aloading: false,
        isTestimonialAdded: true,
        testimonial: action.payload,
        errors: null,
      };

    case ADD_TESTIMONIAL_RESET:
      return {
        ...state,
        isTestimonialAdded: false,
        errors: null,
      };

    case ADD_TESTIMONIAL_FAIL:
      return {
        ...state,
        aloading: false,
        isTestimonialAdded: false,
        testimonial: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteTestimonialReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TESTIMONIAL_REQUEST:
      return {
        aloading: true,
      };

    case DELETE_TESTIMONIAL_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_TESTIMONIAL_FAIL:
      return {
        aloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
