import React, {useEffect} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonials } from "./../../actions/homeActions";
import Loader from "../Loader";
import {getNewsList} from "../../actions/newsActions";

export default function Testimonial(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTestimonials());
    }, [dispatch]);

    const { testimonials, tloading } = useSelector((state) => state.homeTestimonials);
    //console.log("testimonials ", testimonials);

    return (
        <>
            {testimonials !== undefined && testimonials.length > 0 && (
                <section
                    className="test-sec"
                    style={{
                        backgroundImage: `url("../assets/frontend/img/testi-bg.jpg")`,
                    }}
                >
                    <div className="container">
                        <div className="section-head text-center">
                            {/* <h6>Sub Heading</h6> */}
                            <h1>{props.heading}</h1>
                        </div>
                        <div className="testi-main">

                            {tloading ? (
                                <Loader />
                            ) : (
                                <OwlCarousel
                                    className="owl-carousel owl-theme"
                                    items={1}
                                    loop
                                    margin={10}
                                >
                                    {testimonials !== undefined && testimonials.length > 0
                                        ? testimonials.map((item, key) => {
                                            const indexId = key + 1;
                                            return (
                                                <div key={indexId} className="item">
                                                    <div className="testi-inner">
                                                        <div className="user-pros">
                                                            <img
                                                                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/testimonial/${item.image}`}
                                                                alt="client image"
                                                            />
                                                        </div>
                                                        <h4>{item.name}</h4>
                                                        {/*<h6>Manager</h6>*/}
                                                        <p>
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : ""}
                                </OwlCarousel>
                            )}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}
