import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import { getQuestionTopicSections } from "../../../actions/adminQuestionActions";

import Loader from "../../Loader";

const ListQuestionTopicSections = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const subjectId = match.params.subjectId;
  const yearId = match.params.id;
  useEffect(() => {
    dispatch(getQuestionTopicSections([subjectId], yearId));
  }, [dispatch]);

  const { qTopicSections, loading } = useSelector((state) => state.questions);
  const slug = localStorage.getItem("examSlug");
  const subjectName = localStorage.getItem("subjectName");
  const subjectYear = localStorage.getItem("year");

  // console.log("qTopicSections ", qTopicSections);
  // return;
  return (
    <Fragment>
      {loading ? (
        // <Loader />
        <div>Loading..</div>
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Topic and Section</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to={`/admin/subject-years/${subjectId}`}>
                    <i className="fa fa-table"></i> Subject Years
                  </Link>
                </li>
                <li className="active">Topic and Section [Exam:- {slug}, Subject:- {subjectName}- {subjectYear}]</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Topic and Section</h3>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover border">
                        <thead>
                          <tr>
                            <th>Sr.</th>
                            <th>Topic <small>(Total Questions)</small></th>
                            <th>Topic Section <small>(Total Questions)</small></th>
                            <th>Question</th>
                            <th>Theory</th>
                          </tr>
                        </thead>
                        <tbody>
                          {qTopicSections && qTopicSections[0]?.topics != undefined &&
                            qTopicSections[0]?.topics?.length ? (
                            qTopicSections[0]?.topics?.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.title}{" "}<small>({item.totalQuestions})</small></td>
                                <td>
                                  {item?.topicSections != undefined &&
                                    item.topicSections.map((cItem, index) => (
                                      <Fragment key={index} >
                                        <span className="btn" style={{ padding: '4px 12px' }}>{cItem.section_name}{" "}<small>({cItem.totalQuestions})</small></span>
                                        <br />
                                        <br />
                                      </Fragment>
                                    ))}
                                </td>
                                <td>
                                  {item?.topicSections != undefined &&
                                    item.topicSections.map((cItem, index) => (
                                      <Fragment key={index} >
                                        <Link
                                          to={`/admin/questions/${cItem._id}/${subjectId}/${yearId}/${cItem.topic}`}
                                          className="btn btn-success btn-sm"
                                          title="Questions"
                                        >
                                          <i className="fa fa-eye"></i> View
                                        </Link>
                                        <br />
                                        <br />
                                      </Fragment>
                                    ))}
                                </td>
                                <td>
                                  {item?.topicSections != undefined &&
                                    item?.topicSections.map((cItem, index) => (
                                      <Fragment key={index}>
                                        <Link
                                          to={`/admin/theories/${cItem._id}/${subjectId}/${yearId}`}
                                          className="btn btn-success btn-sm"
                                          title="Theories"
                                        >
                                          <i className="fa fa-eye"></i> View
                                        </Link>
                                        <br />
                                        <br />
                                      </Fragment>
                                    ))}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListQuestionTopicSections;
