export const LOAD_ALL_TOPIC_SECTIONS_REQUEST =
  "LOAD_ALL_TOPIC_SECTIONS_REQUEST";
export const LOAD_ALL_TOPIC_SECTIONS_SUCCESS =
  "LOAD_ALL_TOPIC_SECTIONS_SUCCESS";
export const LOAD_ALL_TOPIC_SECTIONS_FAIL = "LOAD_ALL_TOPIC_SECTIONS_FAIL";

export const TOPIC_SECTION_DETAIL_REQUEST = "TOPIC_SECTION_DETAIL_REQUEST";
export const TOPIC_SECTION_DETAIL_SUCCESS = "TOPIC_SECTION_DETAIL_SUCCESS";
export const TOPIC_SECTION_DETAIL_FAIL = "TOPIC_SECTION_DETAIL_FAIL";

export const ADD_TOPIC_SECTION_REQUEST = "ADD_TOPIC_SECTION_REQUEST";
export const ADD_TOPIC_SECTION_SUCCESS = "ADD_TOPIC_SECTION_SUCCESS";
export const ADD_TOPIC_SECTION_RESET = "ADD_TOPIC_SECTION_RESET";
export const ADD_TOPIC_SECTION_FAIL = "ADD_TOPIC_SECTION_FAIL";

export const UPDATE_TOPIC_SECTION_REQUEST = "UPDATE_TOPIC_SECTION_REQUEST";
export const UPDATE_TOPIC_SECTION_SUCCESS = "UPDATE_TOPIC_SECTION_SUCCESS";
export const UPDATE_TOPIC_SECTION_RESET = "UPDATE_TOPIC_SECTION_RESET";
export const UPDATE_TOPIC_SECTION_FAIL = "UPDATE_TOPIC_SECTION_FAIL";
export const DELETE_TOPIC_SECTION_REQUEST = "DELETE_TOPIC_SECTION_REQUEST";
export const DELETE_TOPIC_SECTION_SUCCESS = "DELETE_TOPIC_SECTION_SUCCESS";
export const DELETE_TOPIC_SECTION_FAIL = "DELETE_TOPIC_SECTION_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
