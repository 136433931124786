import axios from "axios";
import {
  LOAD_ALL_CONTACTUS_REQUEST,
  LOAD_ALL_CONTACTUS_SUCCESS,
  LOAD_ALL_CONTACTUS_FAIL,
  ADD_CONTACTUS_REQUEST,
  ADD_CONTACTUS_SUCCESS,
  ADD_CONTACTUS_FAIL,
  CONTACTUS_DETAIL_REQUEST,
  CONTACTUS_DETAIL_SUCCESS,
  CONTACTUS_DETAIL_FAIL,
  CONTACTUS_REPLY_DETAIL_REQUEST,
  CONTACTUS_REPLY_DETAIL_SUCCESS,
  CONTACTUS_REPLY_DETAIL_FAIL,
  REPLY_CONTACTUS_REQUEST,
  REPLY_CONTACTUS_SUCCESS,
  REPLY_CONTACTUS_FAIL,
  UPDATE_CONTACTUS_REQUEST,
  UPDATE_CONTACTUS_SUCCESS,
  UPDATE_CONTACTUS_FAIL,
  DELETE_CONTACTUS_REQUEST,
  DELETE_CONTACTUS_SUCCESS,
  DELETE_CONTACTUS_FAIL,
  CLEAR_ERRORS,
} from "../constants/adminContactusConstants";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = "/api/v1";

//get all contactus
export const getContactus = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_CONTACTUS_REQUEST,
    });

    const { data } = await axios.get(`${_api_path}/admin/contactus`);

    dispatch({
      type: LOAD_ALL_CONTACTUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_CONTACTUS_FAIL,
      payload: error,
    });
  }
};

//get contactus detail
export const getContactusDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACTUS_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/contactus/${id}`);
    // console.log('data ', data)
    dispatch({
      type: CONTACTUS_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTUS_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Reply contactus
export const replyContactus = (
  contactusId,
  replyMessage
) => async (dispatch) => {
  try {
    dispatch({
      type: REPLY_CONTACTUS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + `/admin/contactus/reply/${contactusId}`,
      { contactusId, 
        replyMessage
      },
      config
    );

    dispatch({
      type: REPLY_CONTACTUS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: REPLY_CONTACTUS_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

//get contactus reply detail
export const getContactusReplyDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACTUS_REPLY_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/contactus/reply/${id}`);
    console.log('data ', data)
    dispatch({
      type: CONTACTUS_REPLY_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTUS_REPLY_DETAIL_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Update contactus
export const updateContactus = (
  contactusId,
  firstName,
  lastName,
  mobileNo,
  email,
  subject,
  message
) => async (dispatch) => {
  // console.log('status ', status);
  try {
    dispatch({
      type: UPDATE_CONTACTUS_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/categories/update/${contactusId}`,
      { contactusId, 
        firstName,
        lastName,
        mobileNo,
        email,
        subject,
        message },
      config
    );

    dispatch({
      type: UPDATE_CONTACTUS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CONTACTUS_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

export const addContactus = (
  firstName,
  lastName,
  mobileNo,
  email,
  subject,
  message
) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CONTACTUS_REQUEST,
    });
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      _api_path + "/admin/contactus/add",
      {
        firstName,
        lastName,
        mobileNo,
        email,
        subject,
        message
      },
      config
    );
    if (!data) {
      dispatch({
        type: ADD_CONTACTUS_FAIL,
        payload: "Invalid id",
      });
    }
    dispatch({
      type: ADD_CONTACTUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_CONTACTUS_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

// Delete contactus
export const deleteContactusData = (categoryId) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: DELETE_CONTACTUS_REQUEST,
    });

    const { data } = await axios.delete(
      _api_path + `/admin/contactus/${categoryId}`
    );

    dispatch({
      type: DELETE_CONTACTUS_SUCCESS,
      payload: categoryId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CONTACTUS_FAIL,
      payload: error,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
