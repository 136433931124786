import React from "react";
import { Link } from "react-router-dom";

import { logoutUser } from "../../actions/authActions";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

const DashHeader = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logoutUser());
    // history.push("/login");
    alert.success("Logout successfully.");
  };

  const { user, loading } = useSelector((state) => state.auth);

  /*const [isActive, setActive] = useState(false);

    const handleToggleMenu = () => {
        setActive(!isActive);
    };*/

  return (
    <header>
      <div className="top-header">
        <div className="cust-container">
          <div className="custom-nav-dash clearfix">
            <div className="dash-logo">
              {/* ONE TIME SUCCESS */}
              <Link to="/">
                <img
                  width="95"
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/logo.png`}
                  alt="site-logo"
                />
              </Link>
            </div>

            {/*<div className="nav-togg" onClick={handleToggleMenu}>
                            <span></span>
                        </div>*/}

            <div className="left-user-detail">
              <div className="media">
                <div className="media-left">
                  <div className="user-pros">
                    <img
                      className="media-object"
                      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/user.png`}
                      alt="user"
                    />
                  </div>
                </div>
                <div className="media-body">
                  <p>Welcome back, </p>
                  <h4 className="media-heading">
                    {user.first_name} {user.last_name}
                  </h4>
                </div>
              </div>
            </div>
            <div className="right-nav">
              <ul className="clearfix">
                <li>
                  <Link to="/">Back to Home</Link>
                </li>
                {/* <li>
                  <a href="#!">
                    <i className="fa fa-bell-o"></i> Notifications
                  </a>
                </li> */}
                <li>
                  <Link
                    to="/login"
                    className="nav-link"
                    onClick={logoutHandler}
                  >
                    <i className="fa fa-sign-out"></i> Log Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default DashHeader;
