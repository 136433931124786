import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../actions/commonActions";
import { addCourse, clearErrors } from "../../../actions/adminCourseActions";
import { ADD_COURSE_RESET } from "./../../../constants/adminCourseConstants";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Loader from "../../Loader";
import { getCourseSubjects } from "../../../actions/subjectActions";
import { confirmAlert } from "react-confirm-alert";
import { getAllSavedExamSubjects, saveExamSubjects } from "../../../actions/admin/examSubjectAction";

// import "./Course.css";
const UpdateSubjects = ({ history }) => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();

    const [categoriesData, setCategoriesData] = useState([]);
    const [newsubjects, setNewsubjects] = useState([]);

    const [course, setCourse] = useState({
        category: "",
        title: "",
        status: false,
    });
    const [errorData, setErrorData] = useState({});

    const { category, title, status } = course;

    const { isCourseAdded, user_loading, error, errors } = useSelector(
        (state) => state.courseDetail
    );
    const { courseSubjects, csloading } = useSelector((state) => state.courseSubjects);
    const [subjects, setSubjectsList] = useState([]);
    const courseId = localStorage.getItem("course");
    const categoryId = localStorage.getItem("category");
    const examId = localStorage.getItem("exam");
    const userId = localStorage.getItem("user");
    const maxSubjects = localStorage.getItem("maxSubjects");

    const subscriptionPlan = localStorage.getItem("subscriptionPlan");
    const { savedAllSubjects } = useSelector((state) => state.examSubjects);
    const [requestedSubject, setRequestedSubject] = useState([]);

    useEffect(() => {

        dispatch(getCourseSubjects(courseId, examId));

        return () => {

        }
    }, [])
    useEffect(() => {
        dispatch(getAllSavedExamSubjects())
    }, [])


    useEffect(() => {
        if (savedAllSubjects && savedAllSubjects?.length > 0) {
            savedAllSubjects?.map((item) => {
                // console.log(item.exam);
                if (item?.exam?._id == examId) {
                    let temp = [];
                    // console.log(item)
                    if (item?.user?._id == userId) {
                        item.subjectsUpdate.map((item) => {
                            temp.push(item)
                        })
                        setRequestedSubject(temp)
                    }
                }

            })
        }


        return () => {

        }
    }, [savedAllSubjects])



    const submitHandler = (e) => {
        e.preventDefault();
        if (newsubjects?.length == 0) {
            confirmAlert({
                title: 'Error',
                message: (
                    <div>

                        <p>
                            Please select at least one subject.
                        </p>

                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
            return;
        }
        let temp = [];
        newsubjects?.map((item) => {
            temp.push(item.value)
        })


        const payload = {
            userId: userId,
            examId: examId,
            categoryId: categoryId,
            courseId: courseId,
            userSubscription: subscriptionPlan,
            subjects: temp,
            subjectsProcessed: temp,
            subjectsUpdate: [],
            updateRequest: true
        }

        dispatch(saveExamSubjects(
            payload
        ))

        history.push(`/admin/student-registered-subjects`)
    };

    useEffect(() => {
        if (courseSubjects !== undefined && courseSubjects?.length > 0) {
            const tmpArr = [];
            courseSubjects?.map((course) => {
                // console.log(course);
                tmpArr?.push({ label: course?.title, value: course?._id })
            })
            setSubjectsList(tmpArr)
        }
    }, [courseSubjects])


    useEffect(() => {

        const filteredSubjects = subjects.filter(subject => requestedSubject.some(reqSubject => reqSubject.id === subject.value));
        setNewsubjects(filteredSubjects)

    }, [subjects])

    const handleChange = (item) => {
        if (item?.length > Number(maxSubjects)) {
            confirmAlert({
                title: 'Error',
                message: (
                    <div>

                        <p>
                            You have reached maximun limit ({maxSubjects}) subject for this exam.
                        </p>

                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
            return;
        }

        setNewsubjects(item);

    }
    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Update
                                <small>Student Subjects</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard"></i> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/student-registered-subjects">
                                        <i className="fa fa-users"></i> Registered Subjects
                                    </Link>
                                </li>
                                <li className="active">Update Registered Subjects</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Following subjects are requested by student to update </h3>

                                    <ul>

                                        {requestedSubject && requestedSubject?.length > 0 && requestedSubject.map((item, index) => {
                                            return (<li>
                                                {index + 1}) {item.title}
                                            </li>)
                                        })

                                        }
                                    </ul>
                                </div>

                                <form
                                    id="quickForm"
                                    onSubmit={submitHandler}
                                    encType="multipart/form-data"
                                >
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="category">Subjects</label>
                                                <Select
                                                    value={newsubjects}
                                                    onChange={(item) => handleChange(item)}
                                                    options={subjects}
                                                    isMulti
                                                    components={animatedComponents}
                                                    isSearchable={true}
                                                />


                                            </div>

                                        </div>


                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default UpdateSubjects