import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";
import ReactHtmlParser from "react-html-parser"

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import {
  getTheories,
  deleteTheoryData,
} from "../../../actions/adminTheoryActions";

import Loader from "../../Loader";

const ListTheories = ({ history, match }) => {
  // const [users, setUsersData] = useState({});
  const alert = useAlert();
  const dispatch = useDispatch();

  const sectionId = match.params.sectionId;
  const subjectId = match.params.subjectId;
  const yearId = match.params.yearId;

  const handleDeleteTheory = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteTheoryData(id));
            // history.push(`/admin/courses`);
            alert.success("Theory deleted successfully");
            dispatch(getTheories(subjectId, yearId,sectionId));
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(getTheories(subjectId, yearId, sectionId));
  }, [dispatch]);

  const { theories, tloading } = useSelector((state) => state.theoryList);

  return (
    <Fragment>
      {tloading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Theories</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to={`/admin/topicsection/${subjectId}/${yearId}`}>
                    <i className="fa fa-dashboard"></i> Topic Sections
                  </Link>
                </li>
                <li className="active">Theories</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Theories</h3>
                      <div className="box-tools">
                        <Link
                          to={`/admin/theories/${subjectId}/${yearId}/${sectionId}/add`}
                          className="btn btn-success btn-sm"
                        >
                          + Add Theory
                        </Link>
                      </div>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Sr.</th>
                            <th>Theory</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tloading ? (
                            <Loader />
                          ) : theories !== undefined &&
                            theories.length ? (
                            theories.map((item, i) => (
                              <tr key={item._id}>
                                <td>{i + 1}</td>
                                <td>{ReactHtmlParser(item.question)}</td>

                                <td>
                                  {item.status === true && (
                                    <span className="badge bg-green">
                                      Active
                                    </span>
                                  )}
                                  {item.status === false && (
                                    <span className="badge bg-yellow">
                                      InActive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/theories/edit/${subjectId}/${yearId}/${item._id}`}
                                    className="btn btn-success btn-sm"
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                  &nbsp;
                                  <a
                                    className="btn btn-danger btn-sm"
                                    title="Delete"
                                    onClick={() =>
                                      handleDeleteTheory(item._id)
                                    }
                                    key={item._id}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListTheories;
