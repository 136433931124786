export const LOAD_ALL_NEWS_REQUEST = "LOAD_ALL_NEWS_REQUEST";
export const LOAD_ALL_NEWS_SUCCESS = "LOAD_ALL_NEWS_SUCCESS";
export const LOAD_ALL_NEWS_FAIL = "LOAD_ALL_NEWS_FAIL";

export const NEWS_DETAIL_REQUEST = "NEWS_DETAIL_REQUEST";
export const NEWS_DETAIL_SUCCESS = "NEWS_DETAIL_SUCCESS";
export const NEWS_DETAIL_FAIL = "NEWS_DETAIL_FAIL";

export const ADD_NEWS_REQUEST = "ADD_NEWS_REQUEST";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";
export const ADD_NEWS_RESET = "ADD_NEWS_RESET";
export const ADD_NEWS_FAIL = "ADD_NEWS_FAIL";

export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_RESET = "UPDATE_NEWS_RESET";
export const UPDATE_NEWS_FAIL = "UPDATE_NEWS_FAIL";
export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
