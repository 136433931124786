import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
//import DatePicker from "react-datepicker";
import DatePicker from "react-date-picker";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import Loader from "../Loader";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { register, clearErrors } from "./../../actions/authActions";
import { REGISTER_USER_RESET } from "../../constants/authConstants";

import { getCountries } from "./../../actions/commonActions";

// import nationalityData from "./../../nationality.json";
import nationalityData from "./../../countries.json";
import AuthCommonBanner from "./AuthCommonBanner";

const Register = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [terms, setTerms] = useState(false)
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // dob: "",
    mobile_number: "",
    mobile_code: "",
    nationality: "",
    gender: "",
    password: "",
    confirm_password: "",
  });

  const [errorData, setErrorData] = useState({});

  const [dob, setDob] = useState(new Date());

  const {
    first_name,
    last_name,
    email,
    password,
    // dob,
    mobile_number,
    mobile_code = "234",
    nationality,
    gender,
    confirm_password,
  } = user;

  useEffect(() => {
    dispatch({
      type: REGISTER_USER_RESET,
    });
    dispatch(getCountries());
  }, []);
  const { countries } = useSelector((state) => state.commonData);

  const { loading, isRegistered, isAuthenticated, error, errors } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
      //console.log("error ", errors);
      return;
    }
    if (error) {
      clearErrors();
      if (error !== "Unauthorised access") {
        return alert.error(error);
      }
    }

    if (isRegistered === true && isRegistered !== undefined) {
      alert.success("You have registered successfully, please check your email to activate the account.");
      props.history.push("/login");
      dispatch({
        type: REGISTER_USER_RESET,
      });
    }
    // if (isAuthenticated === true && isAuthenticated != undefined) {
    //   props.history.push("/dashboard");
    // }
  }, [dispatch, isRegistered, alert, error, errors]);

  const [DOBvalue, DOBonChange] = useState(new Date());

  const goToHome = () => {
    props.history.push("/");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // const formData = new FormData();
    // formData.append("first_name", first_name);
    // formData.append("last_name", last_name);
    // formData.append("email", email);
    // formData.append("dob", dob);
    // formData.append("mobile_number", mobile_number);
    // formData.append("mobile_code", mobile_code);
    // formData.append("nationality", nationality);
    // formData.append("gender", gender);
    // formData.append("password", password);
    // formData.append("confirm_password", confirm_password);
    //alert.success(formData.get("first_name"));

    //submit the form
    //dispatch(register(formData));

    if (!terms) {
      setErrorData({...errorData, terms : "Please accept terms and condtion"})
      return;
    }

    dispatch(
      register(
        first_name,
        last_name,
        email,
        DOBvalue,
        gender,
        password,
        confirm_password,
        mobile_number,
        mobile_code ? mobile_code : "234",
        nationality
      )
    );
    // if (errors) {
    //     setErrorData(errors);
    // }
  };

  const onChange = (e) => {
    console.log(e.target.name);
    if (e.target.name === "avatar") {
    } 
    else if (e.target.name === 'terms') {
      setTerms(!terms)
    }
    else {
      setUser({
        ...user,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onPhoneInputChnage = (value, data, event, formattedValue) => {
    setUser({
      ...user,
      mobile_code: value,
    });
  };

  console.log({terms});

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <section className="registration-page clearfix fullHt">
            <div className="right-regi-step login-page">
              <div className="vh-center">
                <h1>
                  User <span>Registration</span>
                </h1>
                <p className="pull-right">
                  <span className="sub">*</span> is mandatory fields
                </p>
                <div className="inner-que">
                  <form onSubmit={submitHandler} encType="multipart/form-data">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="first_name">
                            First Name<span className="sub">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            name="first_name"
                            value={first_name}
                            onChange={onChange}
                          />
                          {errorData.first_name ? (
                            <span className="text-danger">
                              {errorData.first_name}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="last_name">
                            Last Name<span className="sub">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="last_name"
                            value={last_name}
                            onChange={onChange}
                          />
                          {errorData.last_name ? (
                            <span className="text-danger">
                              {errorData.last_name}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="gender">
                            Gender<span className="sub">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="gender"
                            id="gender"
                            value={gender}
                            onChange={onChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                          {errorData.gender ? (
                            <span className="text-danger">
                              {errorData.gender}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dob">Date of Birth</label>
                          <DatePicker
                            value={DOBvalue}
                            selected={dob}
                            onChange={DOBonChange}
                            maxDate={addDays(new Date(), -6500)} //current date
                            placeholderText="Select your date of birth."
                            className="form-control"
                          />
                          {errorData.dob ? (
                            <span className="text-danger">{errorData.dob}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="mobile_code">
                            Mobile Code<span className="sub">*</span>
                          </label>
                          <PhoneInput
                            inputProps={{
                              name: "mobile_code",
                              id: "mobile_code",
                              required: true,
                              autoFocus: true,
                              readOnly: true,
                            }}
                            value={mobile_code}
                            onChange={onPhoneInputChnage}
                            enableSearch
                            country={"ng"}
                            className="form-control"
                          />
                          {errorData.mobile_code ? (
                            <span className="text-danger">
                              {errorData.mobile_code}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="mobile_number">
                            Mobile Number<span className="sub">*</span>
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="mobile_number"
                            name="mobile_number"
                            value={mobile_number}
                            onChange={onChange}
                          />
                          {errorData.mobile_number ? (
                            <span className="text-danger">
                              {errorData.mobile_number}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="nationality">
                            Country<span className="sub">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="nationality"
                            id="nationality"
                            value={nationality}
                            onChange={onChange}
                          >
                            <option value="">Select Country</option>
                            {loading ? (
                              <Loader />
                            ) : (
                              <Fragment>
                                {nationalityData.length
                                  ? nationalityData.map((e, key) => {
                                      return (
                                        <option key={key} value={e.name}>
                                          {e.name}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </Fragment>
                            )}
                          </select>
                          {errorData.nationality ? (
                            <span className="text-danger">
                              {errorData.nationality}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            Email<span className="sub">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={email}
                            onChange={onChange}
                          />
                          {errorData.email ? (
                            <span className="text-danger">
                              {errorData.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="password">
                            Password<span className="sub">*</span>
                          </label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="password"
                            placeholder="Enter Password"
                            value={password}
                            onChange={onChange}
                          />
                          {errorData.password ? (
                            <span className="text-danger">
                              {errorData.password}
                            </span>
                          ) : (
                            ""
                          )}
                          {/*<span style={{ fontSize: "12px" }}>
                                                        Password should be 6 characters long.{" "}
                                                      </span>*/}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="confirm_password">
                            Confirm Password<span className="sub">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirm_password"
                            name="confirm_password"
                            value={confirm_password}
                            onChange={onChange}
                          />
                          {errorData.confirm_password ? (
                            <span className="text-danger">
                              {errorData.confirm_password}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="checkbox">
                          <label>
                            <input type="checkbox" name="terms" id="terms" onChange={onChange}/> By Registering , you are
                            agreeing to the UTME{" "}
                            <a target="_blank" href="/pages/terms-conditions">
                              Terms and Conditions
                            </a>{" "}
                            and{" "}
                            <a target="_blank" href="/pages/privacy-policy">
                              Privacy Policy
                            </a>
                            .
                            {errorData.terms ? (
                            <p className="text-danger">
                              {errorData.terms}
                            </p>
                          ) : (
                            ""
                          )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <ul className="log-btn-main clearfix">
                      <li className="pull-left dont-have-ac">
                        Back to login? <Link to="/login">Log In</Link>
                      </li>
                      <li className="pull-right">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={loading ? true : false}
                        >
                          Sign Up
                        </button>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <AuthCommonBanner></AuthCommonBanner>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

Register.propTypes = {};

export default Register;
