import axios from 'axios';
import {
    LOAD_CMS_PAGE_REQUEST,
    LOAD_CMS_PAGE_SUCCESS,
    LOAD_CMS_PAGE_FAIL,
    CLEAR_ERRORS
} from '../constants/cmsConstants';
const _api_path = '/api/v1';
//get all users
export const getCmsPage = (page_name) => async (dispatch) => {
    try {
        dispatch({
            type: LOAD_CMS_PAGE_REQUEST
        });
        const { data } = await axios.get(_api_path + '/page/'+page_name);
        dispatch({
            type: LOAD_CMS_PAGE_SUCCESS,
            payload: data.data[0]
        });
    } catch (err) {
        dispatch({
            type: LOAD_CMS_PAGE_FAIL,
            payload: err
        });
    }
}

//clear errors
export const clearErrors = () => async (dispatch) =>{
    dispatch({
        type: CLEAR_ERRORS
    });
}