import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { getAllExamTypes } from "./../../../actions/commonActions";
import {
  addSubscription,
  clearErrors,
} from "../../../actions/adminSubscriptionActions";
import { ADD_SUBSCRIPTION_RESET } from "../../../constants/adminSubscriptionConstants";

import Loader from "../../Loader";

const AddSubscription = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  // const [subscriptionId, setSubscriptionId] = useState("");

  const [subscription, setSubscription] = useState({
    examType: "",
    title: "",
    numOfQuestions: "",
    subscriptionType: "",
    duration: "",
    price: "",
    description: "",
    status: false,
  });
  const {
    examType,
    title,
    numOfQuestions,
    subscriptionType,
    duration,
    price,
    description,
    status,
  } = subscription;

  const [errorData, setErrorData] = useState({});

  const { isSubsAdded, sloading, errors, error } = useSelector(
    (state) => state.examDetail
  );

  useEffect(() => {
    dispatch(getAllExamTypes());

    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isSubsAdded === true && isSubsAdded != undefined) {
      alert.success("Subscription successfully added.");
      history.push("/admin/subscriptions");
      dispatch({
        type: ADD_SUBSCRIPTION_RESET,
      });
    }
  }, [dispatch, isSubsAdded, alert, errors, error]);

  const { allExamTypes, eloading } = useSelector((state) => state.commonExams);
  // console.log("allExamTypes ", allExamTypes);

  const onChange = (e) => {
    if (e.target.name === "status") {
      setSubscription({
        ...subscription,
        status: !status,
      });
    } else {
      setSubscription({
        ...subscription,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      addSubscription(
        examType,
        title,
        numOfQuestions,
        subscriptionType,
        duration,
        price,
        description,
        status
      )
    );
  };

  return (
    <Fragment>
      {sloading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Add
                <small>Subscription</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/subscriptions">
                    <i className="fa fa-users"></i> Subscriptions
                  </Link>
                </li>
                <li className="active">Add Subscription</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Subscription</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>
                <form id="quickForm" onSubmit={submitHandler}>
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="examType">Exam Type</label>
                        <select
                          className="form-control"
                          name="examType"
                          value={examType}
                          onChange={onChange}
                        >
                          <option value="">Select</option>
                          {eloading ? (
                            <Loader />
                          ) : (
                            <Fragment>
                              {allExamTypes.length > 0
                                ? allExamTypes.map((e, key) => {
                                    return (
                                      <option key={key} value={e._id}>
                                        {e.title}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Fragment>
                          )}
                        </select>

                        {errorData.course ? (
                          <span className="text-danger">
                            {errorData.course}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="numOfQuestions">
                          Number of questions
                        </label>
                        <input
                          type="text"
                          name="numOfQuestions"
                          className="form-control"
                          id="numOfQuestions"
                          placeholder="Enter number of questions"
                          value={numOfQuestions}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="subscriptionType">
                          Subscription Type
                        </label>
                        <input
                          type="text"
                          name="subscriptionType"
                          className="form-control"
                          id="subscriptionType"
                          value={subscriptionType}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="duration">Duration</label>
                        <input
                          name="duration"
                          className="form-control"
                          id="duration"
                          placeholder="Enter Duration"
                          value={duration}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="price">Price</label>
                        <input
                          name="price"
                          className="form-control"
                          id="price"
                          placeholder="Enter Price"
                          value={price}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="description">Description</label>
                        <textarea
                          name="description"
                          className="form-control"
                          id="description"
                          placeholder="Enter Description"
                          value={description}
                          onChange={onChange}
                        >
                          {description}
                        </textarea>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <div className="col-md-6 row">
                        <div className="form-check">
                          <input
                            style={{ marginRight: "5px" }}
                            type="checkbox"
                            className="form-check-input"
                            name="status"
                            id="status"
                            checked={status}
                            value={status}
                            onChange={onChange}
                          />
                          <label className="form-check-label" htmlFor="status">
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddSubscription;
