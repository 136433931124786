import React, {Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAlert } from 'react-alert';

import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../actions/commonActions';
import { addCategory, clearErrors } from '../../../actions/adminCategoryActions';
import { ADD_CATEGORY_RESET } from './../../../constants/adminCategoryConstants';

import Loader from '../../Loader';

import './AddCategory.css';

const AddCategory = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [category, setCategory] = useState({
        title: "",
        description: "",
        status: "",
        image: "",
        imageName: ""
    });

    const {
        title,
        description,
        status,
        image,
        imageName
    } = category;

    const { isCategoryAdded, user_loading, error } = useSelector(
        (state) => state.categoryDetail
    );

    useEffect(() => {
        if (error) {
            clearErrors();
            return alert.error(error);
        }

        if (isCategoryAdded === true && isCategoryAdded != undefined) {
            alert.success("Category successfully added.");
            dispatch({
                type: ADD_CATEGORY_RESET,
            });
            history.push("/admin/categories");

        }
    }, [dispatch, isCategoryAdded, alert, error]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            addCategory(
                title,
                description,
                status,
                image,
                imageName
            )
        );
    };

    const onChange = (e) => {
        if (e.target.name === "image") {
            let file = e.target.files[0];

            if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                setCategory({
                    ...category,
                    image: "",
                    imageName: ""
                });
                alert.error('Please select valid image.');
                //remove preview picture if any
                const preview = document.getElementById('img-preview');
                preview.src = "";
                return false;
            }

            const reader = new FileReader();

            reader.onload = function(readerEvt) {
                // The file's text will be printed here
                //console.log(readerEvt.target.result);
                let binaryString = readerEvt.target.result;
                let base64String = btoa(binaryString);
                setCategory({
                    ...category,
                    image: base64String,
                    imageName: file.name
                });

                //preview picture
                const preview = document.getElementById('img-preview');
                // console.log('banary image', base64String);
                preview.src = "data:image/png;base64," + base64String;

            };
            reader.readAsBinaryString(file);

        } else {
            setCategory({
                ...category,
                [e.target.name]: e.target.value,
            });
        }
    };

    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (

                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Add
                                <small>Category</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li><Link to="/admin"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
                                <li><Link to="/admin/categories"><i className="fa fa-users"></i> Categories</Link></li>
                                <li className="active">Add Category</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default" >
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add Category</h3>

                                    {/*<div className="box-tools pull-right">

                                    </div>*/}
                                </div>

                                <form
                                    id="quickForm"
                                    onSubmit={submitHandler}
                                    encType="multipart/form-data"
                                >
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="title">Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Enter Title"
                                                    value={title}
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="description">Description</label>
                                                <input
                                                    type="text"
                                                    name="description"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter Description"
                                                    value={description}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="image">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="image"
                                                    name="image"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                />
                                                <div>
                                                    <img id="img-preview" src=""/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="status">
                                                    Status
                                                </label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    value={status}
                                                    onChange={onChange}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="InActive">In Active</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default AddCategory;
