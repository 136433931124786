import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const Page404 = () => {
    return (
        <Fragment>
            <div>
                <h3>Page Not Found</h3>
                <Link to="/" className="btn btn-primary">Home</Link>
            </div>
        </Fragment>
    );
};

export default Page404;