import axios from "axios";
import {
    LOAD_ALL_TESTIMONIAL_REQUEST,
    LOAD_ALL_TESTIMONIAL_SUCCESS,
    LOAD_ALL_TESTIMONIAL_FAIL,
    ADD_TESTIMONIAL_REQUEST,
    ADD_TESTIMONIAL_SUCCESS,
    ADD_TESTIMONIAL_RESET,
    ADD_TESTIMONIAL_FAIL,
    TESTIMONIAL_DETAIL_REQUEST,
    TESTIMONIAL_DETAIL_SUCCESS,
    TESTIMONIAL_DETAIL_FAIL,
    UPDATE_TESTIMONIAL_REQUEST,
    UPDATE_TESTIMONIAL_SUCCESS,
    UPDATE_TESTIMONIAL_RESET,
    UPDATE_TESTIMONIAL_FAIL,
    DELETE_TESTIMONIAL_REQUEST,
    DELETE_TESTIMONIAL_SUCCESS,
    DELETE_TESTIMONIAL_FAIL,
    CLEAR_ERRORS,
} from "../constants/adminTestimonialConstants";

const _api_path = "/api/v1";

//get all testimonial
export const getTestimonials = () => async (dispatch) => {
    try {
        dispatch({
            type: LOAD_ALL_TESTIMONIAL_REQUEST,
        });

        const { data } = await axios.get(`${_api_path}/admin/testimonial-list`);
        // console.log("data", data);
        // return;
        dispatch({
            type: LOAD_ALL_TESTIMONIAL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LOAD_ALL_TESTIMONIAL_FAIL,
            payload: error,
        });
    }
};

//get testimonial detail
export const getTestimonialDetail = (id) => async (dispatch) => {
    try {
        dispatch({
            type: TESTIMONIAL_DETAIL_REQUEST,
        });

        const { data } = await axios.get(`/api/v1/admin/testimonial-list/${id}`);
        // console.log('data ', data)
        dispatch({
            type: TESTIMONIAL_DETAIL_SUCCESS,
            payload: data.data,
        });
    } catch (error) {
        dispatch({
            type: TESTIMONIAL_DETAIL_FAIL,
            payload: error.response.data.errMessage,
        });
    }
};

// add testimonial
export const addTestimonial =
    (name, image, description, status, imageName) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: ADD_TESTIMONIAL_REQUEST,
                });
                const config = {
                    headers: {
                        "content-type": "application/json",
                    },
                };
                const { data } = await axios.post(
                    _api_path + "/admin/testimonial-list/add",
                    {
                        name, image, description, status, imageName
                    },
                    config
                );
                dispatch({
                    type: ADD_TESTIMONIAL_SUCCESS,
                    payload: data.testimonial
                });
            } catch (error) {
                dispatch({
                    type: ADD_TESTIMONIAL_FAIL,
                    payload: error.response.data.errMessage,
                    errors: error.response.data,
                });
            }
        };

// Update testimonial
export const updateTestimonial =
    (testimonialId,
     name,
     description,
     status,
     oldImage,
     logo,
     imageName) =>
        async (dispatch) => {
            // console.log('status ', status);
            try {
                dispatch({
                    type: UPDATE_TESTIMONIAL_REQUEST,
                });

                const config = {
                    // headers: {
                    //     'Content-Type': 'multipart/form-data'
                    // }
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const { data } = await axios.put(
                    _api_path + `/admin/testimonial-list/update/${testimonialId}`,
                    {
                        testimonialId,
                        name,
                        description,
                        status,
                        oldImage,
                        logo,
                        imageName
                    },
                    config
                );

                dispatch({
                    type: UPDATE_TESTIMONIAL_SUCCESS,
                    payload: data.success,
                });
            } catch (error) {
                dispatch({
                    type: UPDATE_TESTIMONIAL_FAIL,
                    payload: error.response.data.errMessage,
                    errors: error.response.data,
                });
            }
        };

// Delete testimonial
export const deleteTestimonialData = (testimonialId) => async (dispatch) => {
    //console.log('userData ', userData);
    try {
        dispatch({
            type: DELETE_TESTIMONIAL_REQUEST,
        });

        const { data } = await axios.delete(
            _api_path + `/admin/testimonial-list/${testimonialId}`
        );

        dispatch({
            type: DELETE_TESTIMONIAL_SUCCESS,
            payload: testimonialId,
        });
    } catch (error) {
        dispatch({
            type: DELETE_TESTIMONIAL_FAIL,
            payload: error,
        });
    }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};
