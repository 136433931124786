import {
  SUBMIT_RESULT_REQUEST,
  SUBMIT_RESULT_SUCCESS,
  SUBMIT_RESULT_RESET,
  SUBMIT_RESULT_FAIL,
  SUBMIT_GRADE_RESULT_REQUEST,
  SUBMIT_GRADE_RESULT_SUCCESS,
  SUBMIT_GRADE_RESULT_RESET,
  SUBMIT_GRADE_RESULT_FAIL,
  GET_USER_RESULTS_REQUEST,
  GET_USER_RESULTS_SUCCESS,
  GET_USER_RESULTS_FAIL,
  GET_RESULT_REQUEST,
  GET_RESULT_SUCCESS,
  GET_RESULT_FAIL,
  GET_RESULT_VIEW_REQUEST,
  GET_RESULT_VIEW_SUCCESS,
  GET_RESULT_VIEW_FAIL,
  CLEAR_ERRORS,
  GET,
  GET_MOCK_RESULT_SUCCESS
} from "./../constants/resultConstants";

export const resultsReducer = (
  state = { results: [], result: {}, gradeResult: {} },
  action
) => {
  switch (action.type) {
    case SUBMIT_RESULT_REQUEST:
      return {
        rLoading: true,
        isSubmitted: false,
      };

    case SUBMIT_RESULT_SUCCESS:
      return {
        ...state,
        rLoading: false,
        isSubmitted: true,
        result: action.payload,
      };

    case SUBMIT_RESULT_RESET:
      return {
        ...state,
        isSubmitted: false,
      };

    case SUBMIT_RESULT_FAIL:
      return {
        ...state,
        rLoading: false,
        isSubmitted: false,
        result: null,
        error: action.payload,
      };

    /**
     * Get all results by user id
     */
    case GET_USER_RESULTS_REQUEST:
      return {
        ...state,
        rLoading: true,
      };

    case GET_USER_RESULTS_SUCCESS:
      return {
        rLoading: false,
        results: action.payload,
      };

    case GET_USER_RESULTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /**
     * submit grade result
     */
    case SUBMIT_GRADE_RESULT_REQUEST:
      return {
        rLoading: true,
        isGraded: false,
      };

    case SUBMIT_GRADE_RESULT_SUCCESS:
      return {
        ...state,
        rLoading: false,
        isGraded: true,
        gradeResult: action.payload,
      };

    case SUBMIT_GRADE_RESULT_RESET:
      return {
        ...state,
        isGraded: false,
      };

    case SUBMIT_GRADE_RESULT_FAIL:
      return {
        ...state,
        rLoading: false,
        isGraded: false,
        gradeResult: null,
        error: action.payload,
      };

    /**
     * Get a single result by quiz id
     */
    case GET_RESULT_REQUEST:
      return {
        ...state,
        rLoading: true,
      };

    case GET_RESULT_SUCCESS:
      return {
        rLoading: false,
        result: action.payload,
      };
    
    case GET_MOCK_RESULT_SUCCESS:
      return {
        rLoading: false,
        result: action.payload,
      };

    case GET_RESULT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /**
     * Get a single result view by quiz id
     */
    case GET_RESULT_VIEW_REQUEST:
      return {
        ...state,
        rLoading: true,
      };

    case GET_RESULT_VIEW_SUCCESS:
      return {
        rLoading: false,
        result: action.payload,
      };

    case GET_RESULT_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
