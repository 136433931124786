import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "moment";
import { useAlert } from "react-alert";

import {
  getUserSubscription,
  userSubscribePlan,
  getUserSubscriptionList,
} from "./../../actions/subscriptionActions";
import CustomDataTable from '../Datatable/CustomDataTable';

import Loader from "../Loader";
import moment from "moment/moment";

const UserSubscriptions = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [subscriptionType, setSubscriptionType] = useState("");

  const { user, isAuthenticated } = useSelector((state) => state.auth);

  const examId = match.params.examId;
  useEffect(() => {
    dispatch(getUserSubscriptionList(user._id));
  }, [dispatch, alert, isAuthenticated, user]);

  const { userSubscriptionList, sloading } = useSelector(
    (state) => state.examSubscriptions
  );
  useEffect(() => { }, [dispatch, alert, userSubscriptionList, user]);

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const tableHeader = [
    {
      title: 'Exam',
      prop: 'exam',
      sortable: true,
      filterable: true
    },
    {
      title: 'Plan',
      prop: 'plan',
      sortable: true,
      filterable: true
    },
    {
      title: 'Description',
      prop: 'description',
      sortable: true,
      filterable: true
    },
    {
      title: 'Subscribe At',
      prop: 'subscribeAt',
      sortable: true,
      filterable: true
    },
    {
      title: 'Expiration Date',
      prop: 'expirationDate',
      sortable: true,
      filterable: true
    },
    {
      title: 'Action',
      prop: 'action',
      sortable: false,
      filterable: false
    }
  ];


  var tableRowData = [];
  if (userSubscriptionList?.length) {
    userSubscriptionList.map(({ exam, planName, description, subscribedAt, expireAt, university }, key) => {
      const itemData = {
        exam: exam.title + (university ? ` (${university.title})` : ""),
        plan: planName,
        description,
        subscribeAt: Moment(subscribedAt).format("YYYY-MM-DD"),
        expirationDate: Moment(expireAt).format("YYYY-MM-DD"),
        action: (
          <button type="submit" className="btn btn-primary" onClick={() => handleSubscribtion(exam?.slug, exam?._id, university?._id)}>
            Upgrade Plan
          </button>
        )
      };
      tableRowData.push(itemData);
    });
  }

  const handleSubscribtion = (examSlug, examId, universityId) => {
    localStorage.setItem("examId", examId);
    
    // Set universityId only if examSlug is 'post-utme'
    if (examSlug === 'post-utme') {
        localStorage.setItem("universityId", universityId);
    }else{
      localStorage.removeItem("universityId");
    }
    
    history.push(`/subscription-plans`);
}



  const onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    }
  };

  const customLabels = {
    first: '<<',
    last: '>>',
    prev: '<',
    next: '>',
    show: 'Display',
    entries: 'rows',
    noResults: 'There are no data to be displayed'
  };

  return (
    <Fragment>
      {sloading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="right-auth-landing">
            <div className="main-view-content">
              <div className="all-ourt-style work-info">
                <div className="all-heads">
                  <h3>My Subscriptions</h3>
                </div>

                <CustomDataTable
                  tableHeaders={tableHeader}
                  tableBody={tableRowData}
                  rowsPerPage={10}
                  rowsPerPageOption={[10, 20, 30, 50]}
                  initialSort={{ prop: 'subscribeAt', isAscending: true }}
                  onSort={onSortFunction}
                  labels={customLabels}
                />

                {/*<table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Exam</th>
                      <th>Plan</th>
                      <th>Description</th>
                      <th>Subscribe At</th>
                      <th>Expiration Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userSubscriptionList !== undefined &&
                    userSubscriptionList.length > 0
                      ? userSubscriptionList.map((item, key) => {
                          return (
                            <Fragment>
                              <tr key={key}>
                                <td>{item.exam.title}</td>
                                <td>{item.planName}</td>
                                <td>{item.description}</td>
                                <td>
                                  {Moment(item.subscribedAt).format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>
                                  {Moment(item.expireAt).format("YYYY-MM-DD")}
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })
                      : (
                        <tr>
                          <td colSpan="5">No record found</td>
                        </tr>
                      )}
                  </tbody>
                </table>*/}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserSubscriptions;
