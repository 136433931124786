import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import ReactSummernote from "react-summernote";
import {
  getHomeContent,
  updateHomeContent,
  clearErrors,
} from "../../../actions/admin/homeSettingActions";
import { UPDATE_HOME_CONTENT_RESET } from "./../../../constants/admin/homeSettingConstants";

import Loader from "../../Loader";

import "./HomeSetting.css";

const HomeSetting = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [homeId, setHomeId] = useState("");

  const [wcHeading, setWhyChooseUsHeading] = useState("");
  const [wcSubheading, setWhyChooseUsSubHeading] = useState("");
  const [wcuTitle1, setWcuTitle1] = useState("");
  const [wcuDescription1, setWcuDescription1] = useState("");
  const [wcuTitle2, setWcuTitle2] = useState("");
  const [wcuDescription2, setWcuDescription2] = useState("");
  const [wcuTitle3, setWcuTitle3] = useState("");
  const [wcuDescription3, setWcuDescription3] = useState("");
  const [wcuTitle4, setWcuTitle4] = useState("");
  const [wcuDescription4, setWcuDescription4] = useState("");
  const [wcuTitle5, setWcuTitle5] = useState("");
  const [wcuDescription5, setWcuDescription5] = useState("");

  const [examHeading, setExamHeading] = useState("");
  const [examSubHeading, setExamSubHeading] = useState("");

  const [aygHeading, setAygHeading] = useState("");
  const [aygSubheading, setAygSubHeading] = useState("");
  const [aygTitle1, setAygTitle1] = useState("");
  const [aygDescription1, setAygDescription1] = useState("");
  const [aygTitle2, setAygTitle2] = useState("");
  const [aygDescription2, setAygDescription2] = useState("");
  const [aygTitle3, setAygTitle3] = useState("");
  const [aygDescription3, setAygDescription3] = useState("");
  const [aygTitle4, setAygTitle4] = useState("");
  const [aygDescription4, setAygDescription4] = useState("");
  const [aygTitle5, setAygTitle5] = useState("");
  const [aygDescription5, setAygDescription5] = useState("");
  const [aygTitle6, setAygTitle6] = useState("");
  const [aygDescription6, setAygDescription6] = useState("");

  const [testimonialHeading, setTestimonialHeading] = useState("");

  const [newsHeading, setNewsHeading] = useState("");
  const [newsSubHeading, setNewsSubHeading] = useState("");

  const [errorData, setErrorData] = useState({});

  useEffect(() => {
    dispatch(getHomeContent());
  }, [dispatch]);

  const { homeContent, isUpdated, hLoading, error, errors } = useSelector(
    (state) => state.homeContent
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (homeContent) {
      setHomeId(homeContent._id);

      if (homeContent.whyChooseUs !== undefined) {
        setWhyChooseUsHeading(homeContent.whyChooseUs.heading);
        setWhyChooseUsSubHeading(homeContent.whyChooseUs.subheading);

        setWcuTitle1(homeContent.whyChooseUs.sections[0].title);
        setWcuDescription1(homeContent.whyChooseUs.sections[0].description);
        setWcuTitle2(homeContent.whyChooseUs.sections[1].title);
        setWcuDescription2(homeContent.whyChooseUs.sections[1].description);
        setWcuTitle3(homeContent.whyChooseUs.sections[2].title);
        setWcuDescription3(homeContent.whyChooseUs.sections[2].description);
        setWcuTitle4(homeContent.whyChooseUs.sections[3].title);
        setWcuDescription4(homeContent.whyChooseUs.sections[3].description);
        setWcuTitle5(homeContent.whyChooseUs.sections[4].title);
        setWcuDescription5(homeContent.whyChooseUs.sections[4].description);
      }
      if (homeContent.examCategories !== undefined) {
        setExamHeading(homeContent.examCategories.heading);
        setExamSubHeading(homeContent.examCategories.subheading);
      }
      if (homeContent.achieveYourGoal !== undefined) {
        setAygHeading(homeContent.achieveYourGoal.heading);
        setAygSubHeading(homeContent.achieveYourGoal.subheading);
        setAygTitle1(homeContent.achieveYourGoal.sections[0].title);
        setAygDescription1(homeContent.achieveYourGoal.sections[0].description);
        setAygTitle2(homeContent.achieveYourGoal.sections[1].title);
        setAygDescription2(homeContent.achieveYourGoal.sections[1].description);
        setAygTitle3(homeContent.achieveYourGoal.sections[2].title);
        setAygDescription3(homeContent.achieveYourGoal.sections[2].description);
        setAygTitle4(homeContent.achieveYourGoal.sections[3].title);
        setAygDescription4(homeContent.achieveYourGoal.sections[3].description);
        setAygTitle5(homeContent.achieveYourGoal.sections[4].title);
        setAygDescription5(homeContent.achieveYourGoal.sections[4].description);
        setAygTitle6(homeContent.achieveYourGoal.sections[5].title);
        setAygDescription6(homeContent.achieveYourGoal.sections[5].description);
      }
      if (homeContent.testimonial !== undefined) {
        setTestimonialHeading(homeContent.testimonial);
      }
      if (homeContent.latestNews !== undefined) {
        setNewsHeading(homeContent.latestNews.heading);
        setNewsSubHeading(homeContent.latestNews.subheading);
      }
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Home content updated successfully");
      dispatch(getHomeContent(homeContent._id));
      history.push(`/admin/home-setting`);
      dispatch({
        type: UPDATE_HOME_CONTENT_RESET,
      });
    }
  }, [dispatch, homeContent, alert, error, errors, history, isUpdated]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateHomeContent(
        homeId,
        wcHeading,
        wcSubheading,
        wcuTitle1,
        wcuDescription1,
        wcuTitle2,
        wcuDescription2,
        wcuTitle3,
        wcuDescription3,
        wcuTitle4,
        wcuDescription4,
        wcuTitle5,
        wcuDescription5,
        examHeading,
        examSubHeading,
        aygHeading,
        aygSubheading,
        aygTitle1,
        aygDescription1,
        aygTitle2,
        aygDescription2,
        aygTitle3,
        aygDescription3,
        aygTitle4,
        aygDescription4,
        aygTitle5,
        aygDescription5,
        aygTitle6,
        aygDescription6,
        testimonialHeading,
        newsHeading,
        newsSubHeading
      )
    );
  };

  return (
    <Fragment>
      {hLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                <small>Home Page Setting</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li className="active">Home Page Setting</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                {/* <div className="box-header with-border">
                  <h3 className="box-title">Home Page Setting</h3>
                </div> */}

                <form id="quickForm" onSubmit={submitHandler}>
                  <div className="box-body">
                    <div>
                      <h4
                        style={{
                          padding: "2px",
                          fontWeight: "bold",
                          display: "inline-block",
                          borderBottom: "1px dotted #5CB85C",
                        }}
                      >
                        Why Choose Us
                      </h4>
                      <div className="form-group">
                        <div className="col-md-6">
                          <label htmlFor="heading">Heading</label>
                          <input
                            type="text"
                            name="heading"
                            className="form-control"
                            id="heading"
                            placeholder="Enter the heading"
                            value={wcHeading}
                            onChange={(e) =>
                              setWhyChooseUsHeading(e.target.value)
                            }
                          />
                          {errorData.title ? (
                            <span className="text-danger">
                              {errorData.title}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="description">Sub Heading</label>
                          <textarea
                            type="text"
                            name="description"
                            className="form-control"
                            id="description"
                            placeholder="Enter sub heading"
                            value={wcSubheading}
                            onChange={(e) =>
                              setWhyChooseUsSubHeading(e.target.value)
                            }
                          ></textarea>
                          {errorData.description ? (
                            <span className="text-danger">
                              {errorData.description}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div>
                        <h5
                          style={{
                            padding: "5px",
                            fontWeight: "bold",
                            display: "inline-block",
                            borderBottom: "1px dotted #5CB85C",
                            marginTop: "0px",
                          }}
                        >
                          Sections
                        </h5>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              id="title"
                              placeholder="Enter the title"
                              value={wcuTitle1}
                              onChange={(e) => setWcuTitle1(e.target.value)}
                            />
                            {errorData.title ? (
                              <span className="text-danger">
                                {errorData.title}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="description">Decription</label>
                            <textarea
                              type="text"
                              name="description"
                              className="form-control"
                              id="description"
                              placeholder="Enter the decription"
                              value={wcuDescription1}
                              onChange={(e) =>
                                setWcuDescription1(e.target.value)
                              }
                            ></textarea>
                            {errorData.description ? (
                              <span className="text-danger">
                                {errorData.description}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              id="title"
                              placeholder="Enter the title"
                              value={wcuTitle2}
                              onChange={(e) => setWcuTitle2(e.target.value)}
                            />
                            {errorData.title ? (
                              <span className="text-danger">
                                {errorData.title}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="description">Decription</label>
                            <textarea
                              type="text"
                              name="description"
                              className="form-control"
                              id="description"
                              placeholder="Enter the decription"
                              value={wcuDescription2}
                              onChange={(e) =>
                                setWcuDescription2(e.target.value)
                              }
                            ></textarea>
                            {errorData.description ? (
                              <span className="text-danger">
                                {errorData.description}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              id="title"
                              placeholder="Enter the title"
                              value={wcuTitle3}
                              onChange={(e) => setWcuTitle3(e.target.value)}
                            />
                            {errorData.title ? (
                              <span className="text-danger">
                                {errorData.title}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="description">Decription</label>
                            <textarea
                              type="text"
                              name="description"
                              className="form-control"
                              id="description"
                              placeholder="Enter the decription"
                              value={wcuDescription3}
                              onChange={(e) =>
                                setWcuDescription3(e.target.value)
                              }
                            ></textarea>
                            {errorData.description ? (
                              <span className="text-danger">
                                {errorData.description}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              id="title"
                              placeholder="Enter the title"
                              value={wcuTitle4}
                              onChange={(e) => setWcuTitle4(e.target.value)}
                            />
                            {errorData.title ? (
                              <span className="text-danger">
                                {errorData.title}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="description">Decription</label>
                            <textarea
                              type="text"
                              name="description"
                              className="form-control"
                              id="description"
                              placeholder="Enter the decription"
                              value={wcuDescription4}
                              onChange={(e) =>
                                setWcuDescription4(e.target.value)
                              }
                            ></textarea>
                            {errorData.description ? (
                              <span className="text-danger">
                                {errorData.description}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              id="title"
                              placeholder="Enter the title"
                              value={wcuTitle5}
                              onChange={(e) => setWcuTitle5(e.target.value)}
                            />
                            {errorData.title ? (
                              <span className="text-danger">
                                {errorData.title}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="description">Decription</label>
                            <textarea
                              type="text"
                              name="description"
                              className="form-control"
                              id="description"
                              placeholder="Enter the decription"
                              value={wcuDescription5}
                              onChange={(e) =>
                                setWcuDescription5(e.target.value)
                              }
                            ></textarea>
                            {errorData.description ? (
                              <span className="text-danger">
                                {errorData.description}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <h4
                      style={{
                        fontWeight: "bold",
                        display: "inline-block",
                        borderBottom: "1px dotted #5CB85C",
                      }}
                    >
                      Exam Categories
                    </h4>
                    <div className="form-group">
                      <div className="col-md-6">
                        <label htmlFor="examHeading">Heading</label>
                        <input
                          type="text"
                          name="examHeading"
                          className="form-control"
                          id="examHeading"
                          placeholder="Enter heading"
                          value={examHeading}
                          onChange={(e) => setExamHeading(e.target.value)}
                        />
                        {errorData.examHeading ? (
                          <span className="text-danger">
                            {errorData.examHeading}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="examSubHeading">Sub Heading</label>
                        <textarea
                          type="text"
                          name="examSubHeading"
                          className="form-control"
                          id="examSubHeading"
                          placeholder="Enter sub heading"
                          value={examSubHeading}
                          onChange={(e) => setExamSubHeading(e.target.value)}
                        ></textarea>
                        {errorData.examSubHeading ? (
                          <span className="text-danger">
                            {errorData.examSubHeading}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div>
                      <h4
                        style={{
                          padding: "2px",
                          fontWeight: "bold",
                          display: "inline-block",
                          borderBottom: "1px dotted #5CB85C",
                        }}
                      >
                        Achieve your goal
                      </h4>
                      <div className="form-group">
                        <div className="col-md-6">
                          <label htmlFor="heading">Heading</label>
                          <input
                            type="text"
                            name="heading"
                            className="form-control"
                            id="heading"
                            placeholder="Enter the heading"
                            value={aygHeading}
                            onChange={(e) => setAygHeading(e.target.value)}
                          />
                          {errorData.title ? (
                            <span className="text-danger">
                              {errorData.title}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="aygSubheading">Sub Heading</label>
                          {/* <textarea
                            type="text"
                            name="aygSubheading"
                            className="form-control"
                            id="aygSubheading"
                            placeholder="Enter sub heading"
                            value={aygSubheading}
                            onChange={(e) => setAygSubHeading(e.target.value)}
                          ></textarea> */}
                          <ReactSummernote
                            value={aygSubheading}
                            options={{
                              lang: "ru-RU",
                              height: 100,
                              dialogsInBody: true,
                              toolbar: [
                                ["style", ["style"]],
                                ["font", ["bold", "underline", "clear"]],
                                ["fontname", ["fontname"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["table", ["table"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]],
                              ],
                            }}
                            onChange={(content) => setAygSubHeading(content)}
                          />
                          {errorData.aygSubheading ? (
                            <span className="text-danger">
                              {errorData.aygSubheading}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div>
                        <h5
                          style={{
                            padding: "5px",
                            fontWeight: "bold",
                            display: "inline-block",
                            borderBottom: "1px dotted #5CB85C",
                            marginTop: "0px",
                          }}
                        >
                          Sections
                        </h5>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="aygTitle1">Title</label>
                            <input
                              type="text"
                              name="aygTitle1"
                              className="form-control"
                              id="aygTitle1"
                              placeholder="Enter the title"
                              value={aygTitle1}
                              onChange={(e) => setAygTitle1(e.target.value)}
                            />
                            {errorData.aygTitle1 ? (
                              <span className="text-danger">
                                {errorData.aygTitle1}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="aygDescription1">Decription</label>
                            <textarea
                              type="text"
                              name="aygDescription1"
                              className="form-control"
                              id="aygDescription1"
                              placeholder="Enter the decription"
                              value={aygDescription1}
                              onChange={(e) =>
                                setAygDescription1(e.target.value)
                              }
                            ></textarea>
                            {errorData.aygDescription1 ? (
                              <span className="text-danger">
                                {errorData.aygDescription1}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="aygTitle2">Title</label>
                            <input
                              type="text"
                              name="aygTitle2"
                              className="form-control"
                              id="aygTitle2"
                              placeholder="Enter the title"
                              value={aygTitle2}
                              onChange={(e) => setAygTitle2(e.target.value)}
                            />
                            {errorData.aygTitle2 ? (
                              <span className="text-danger">
                                {errorData.aygTitle2}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="aygDescription2">Decription</label>
                            <textarea
                              type="text"
                              name="aygDescription2"
                              className="form-control"
                              id="aygDescription2"
                              placeholder="Enter the decription"
                              value={aygDescription2}
                              onChange={(e) =>
                                setAygDescription2(e.target.value)
                              }
                            ></textarea>
                            {errorData.aygDescription2 ? (
                              <span className="text-danger">
                                {errorData.aygDescription2}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="aygTitle3">Title</label>
                            <input
                              type="text"
                              name="aygTitle3"
                              className="form-control"
                              id="aygTitle3"
                              placeholder="Enter the title"
                              value={aygTitle3}
                              onChange={(e) => setAygTitle3(e.target.value)}
                            />
                            {errorData.aygTitle3 ? (
                              <span className="text-danger">
                                {errorData.aygTitle3}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="aygDescription3">Decription</label>
                            <textarea
                              type="text"
                              name="aygDescription3"
                              className="form-control"
                              id="aygDescription3"
                              placeholder="Enter the decription"
                              value={aygDescription3}
                              onChange={(e) =>
                                setAygDescription3(e.target.value)
                              }
                            ></textarea>
                            {errorData.aygDescription3 ? (
                              <span className="text-danger">
                                {errorData.aygDescription3}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="aygTitle4">Title</label>
                            <input
                              type="text"
                              name="aygTitle4"
                              className="form-control"
                              id="aygTitle4"
                              placeholder="Enter the title"
                              value={aygTitle4}
                              onChange={(e) => setAygTitle4(e.target.value)}
                            />
                            {errorData.aygTitle4 ? (
                              <span className="text-danger">
                                {errorData.aygTitle4}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="aygDescription4">Decription</label>
                            <textarea
                              type="text"
                              name="aygDescription4"
                              className="form-control"
                              id="aygDescription4"
                              placeholder="Enter the decription"
                              value={aygDescription4}
                              onChange={(e) =>
                                setAygDescription4(e.target.value)
                              }
                            ></textarea>
                            {errorData.aygDescription4 ? (
                              <span className="text-danger">
                                {errorData.aygDescription4}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="aygTitle5">Title</label>
                            <input
                              type="text"
                              name="aygTitle5"
                              className="form-control"
                              id="aygTitle5"
                              placeholder="Enter the title"
                              value={aygTitle5}
                              onChange={(e) => setAygTitle5(e.target.value)}
                            />
                            {errorData.aygTitle5 ? (
                              <span className="text-danger">
                                {errorData.aygTitle5}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="aygDescription5">Decription</label>
                            <textarea
                              type="text"
                              name="aygDescription5"
                              className="form-control"
                              id="aygDescription5"
                              placeholder="Enter the decription"
                              value={aygDescription5}
                              onChange={(e) =>
                                setAygDescription5(e.target.value)
                              }
                            ></textarea>
                            {errorData.aygDescription5 ? (
                              <span className="text-danger">
                                {errorData.aygDescription5}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-6">
                            <label htmlFor="aygTitle6">Title</label>
                            <input
                              type="text"
                              name="aygTitle6"
                              className="form-control"
                              id="aygTitle6"
                              placeholder="Enter the title"
                              value={aygTitle6}
                              onChange={(e) => setAygTitle6(e.target.value)}
                            />
                            {errorData.aygTitle6 ? (
                              <span className="text-danger">
                                {errorData.aygTitle6}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="description">Decription</label>
                            <textarea
                              type="text"
                              name="aygDescription6"
                              className="form-control"
                              id="aygDescription6"
                              placeholder="Enter the decription"
                              value={aygDescription6}
                              onChange={(e) =>
                                setAygDescription6(e.target.value)
                              }
                            ></textarea>
                            {errorData.aygDescription6 ? (
                              <span className="text-danger">
                                {errorData.aygDescription6}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <h4
                      style={{
                        fontWeight: "bold",
                        display: "inline-block",
                        borderBottom: "1px dotted #5CB85C",
                      }}
                    >
                      Client Words (Testimonials)
                    </h4>
                    <div className="form-group">
                      <div className="col-md-12">
                        <label htmlFor="testimonialHeading">Heading</label>
                        <input
                          type="text"
                          name="testimonialHeading"
                          className="form-control"
                          id="testimonialHeading"
                          placeholder="Enter testimonial heading"
                          value={testimonialHeading}
                          onChange={(e) =>
                            setTestimonialHeading(e.target.value)
                          }
                        />
                        {errorData.testimonialHeading ? (
                          <span className="text-danger">
                            {errorData.testimonialHeading}
                          </span>
                        ) : (
                          ""
                        )}
                        <br />
                      </div>
                    </div>

                    <h4
                      style={{
                        fontWeight: "bold",
                        display: "inline-block",
                        borderBottom: "1px dotted #5CB85C",
                      }}
                    >
                      Latest News
                    </h4>
                    <div className="form-group">
                      <div className="col-md-6">
                        <label htmlFor="newsHeading">Heading</label>
                        <input
                          type="text"
                          name="newsHeading"
                          className="form-control"
                          id="newsHeading"
                          placeholder="Enter news heading"
                          value={newsHeading}
                          onChange={(e) => setNewsHeading(e.target.value)}
                        />
                        {errorData.newsHeading ? (
                          <span className="text-danger">
                            {errorData.newsHeading}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="newsSubHeading">Sub Heading</label>
                        <textarea
                          type="text"
                          name="newsSubHeading"
                          className="form-control"
                          id="newsSubHeading"
                          placeholder="Enter testimonial sub heading"
                          value={newsSubHeading}
                          onChange={(e) => setNewsSubHeading(e.target.value)}
                        ></textarea>
                        {errorData.newsSubHeading ? (
                          <span className="text-danger">
                            {errorData.newsSubHeading}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default HomeSetting;
