import {
  GET_ALL_BANNERS,
  ADD_BANNER_REQUEST,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_RESET,
  ADD_BANNER_FAILED,
  GET_SINGLE_BANNER,
  UPDATE_BANNER_REQUEST,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_RESET,
  UPDATE_BANNER_FAILED,
  DELETE_BANNER,
  CLEAR_ERRORS,
} from "./../../constants/admin/bannerConstants";

const initialState = {
  banners: [],
  banner: {},
  bannerLoading: true,
};

export const bannersReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BANNERS:
      return {
        ...state,
        banners: action.payload.banners,
        bannerLoading: false,
      };

    /**
     * Add
     */
    case ADD_BANNER_REQUEST:
      return {
        bannerLoading: true,
        isAdded: false,
        errors: null,
      };
    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: false,
        isAdded: true,
        banner: action.payload,
        errors: null,
      };
    case ADD_BANNER_RESET:
      return {
        ...state,
        isAdded: false,
      };
    case ADD_BANNER_FAILED:
      return {
        ...state,
        bannerLoading: false,
        isAdded: false,
        banner: null,
        error: action.payload,
        errors: action.errors,
      };

    /**
     * GET BANNER DATA
     */
    case GET_SINGLE_BANNER:
      return {
        ...state,
        bannerLoading: false,
        banner: action.payload,
      };

    /**
     * GET BANNER DATA
     */

    /**
     * UPDATE BANNER REQUEST
     */

    case UPDATE_BANNER_REQUEST:
      return {
        ...state,
        bannerLoading: true,
        errors: null,
      };

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: false,
        isUpdated: action.payload,
        errors: null,
      };

    case UPDATE_BANNER_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_BANNER_FAILED:
      return {
        ...state,
        bannerLoading: false,
        error: action.payload,
        errors: action.errors,
      };
    /**
     * UPDATE BANNER REQUEST
     */
    /**
     * DELETE
     */
    case DELETE_BANNER:
      return {
        ...state,
        bannerLoading: false,
      };
    /**
     * DELETE
     */

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
