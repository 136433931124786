import React, { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { login, clearErrors } from "../../../actions/authActions";

import "../../../assets/admin/dist/css/blue.css";
import "./login.css";

const AdminLogin = ({ history }) => {
  const alert = useAlert();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorData, setErrorData] = useState({});

  const { isAuthenticated, error, loginErrors } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (loginErrors) {
      setErrorData(loginErrors);
      // console.log("loginErrors ", loginErrors); return;
    }

    if (error) {
      clearErrors();
      if (error !== "Unauthorised access") {
        return alert.error(error);
      }      
    }

    if (isAuthenticated === true && isAuthenticated != undefined) {
      history.push("/admin");
    }
  }, [dispatch, isAuthenticated, alert, error, history, loginErrors]);

  const submitHandler = (e) => {
    e.preventDefault();
    //submit the form
    dispatch(login(email, password));
  };

  return (
    <Fragment>
      <div className="login-box">
        <div className="login-logo">
          <Link to="#">
            <b>One Time </b>SUCCESS
          </Link>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Sign in to start your session</p>

          <form onSubmit={submitHandler}>
            <div className="form-group has-feedback">
              <input
                type="email"
                className="form-control"
                autoComplete="off"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
              {errorData.email ? (
                <span className="text-danger">{errorData.email}</span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group has-feedback">
              <input
                type="password"
                className="form-control"
                autoComplete="off"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div className="row">
              {/*<div className="col-xs-8">
                <div className="checkbox icheck">
                  <label>
                    <input type="checkbox" /> Remember Me
                  </label>
                </div>
              </div>*/}
              <div className="col-xs-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-flat"
                >
                  Sign In
                </button>
              </div>
            </div>
          </form>

          {/*<div className="social-auth-links text-center">
                            <p>- OR -</p>
                            <a href="#" className="btn btn-block btn-social btn-facebook btn-flat"><i className="fa fa-facebook"></i> Sign in using
                                Facebook</a>
                            <a href="#" className="btn btn-block btn-social btn-google btn-flat"><i className="fa fa-google-plus"></i> Sign in using
                                Google+</a>
                        </div>*/}
          <br />
          <div className="social-auth-links text-center">
            <Link to="/admin/forgot-password">I forgot my password</Link>
          </div>
        </div>
        <br />
      </div>
    </Fragment>
  );
};

export default AdminLogin;
