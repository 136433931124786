import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAlert } from 'react-alert';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { getUserTheories } from '../../../actions/adminTheoryActions';

import Loader from '../../Loader';

const ListUserTheories = ({ history, match }) => {
	// const [users, setUsersData] = useState({});
	const alert = useAlert();
	const dispatch = useDispatch();

	useEffect(
		() => {
			dispatch(getUserTheories());
		},
		[ dispatch ]
	);

	const { theories, userTheoryList, tloading } = useSelector((state) => state.theoryList);

	return (
		<Fragment>
			{tloading ? (
				<Loader />
			) : (
				<Fragment>
					<div className="content-wrapper">
						<section className="content-header">
							<h1>
								Manage
								<small>Theories</small>
							</h1>
							<ol className="breadcrumb">
								<li>
									<Link to="/admin">
										<i className="fa fa-dashboard" /> Dashboard
									</Link>
								</li>
								<li className="active">User Theories</li>
							</ol>
						</section>

						<section className="content">
							<div className="row">
								<div className="col-xs-12">
									<div className="box">
										<div className="box-header">
											<h3 className="box-title">User Theories</h3>
										</div>
										<div className="box-body table-responsive no-padding">
											<table className="table table-hover">
												<thead>
													<tr>
														<th>Sr.</th>
														<th>Student</th>
                            <th>Exam</th>
                            <th>Assessment</th>
														<th>Status</th>
                            <th>Date</th>
                            <th>Action</th>
													</tr>
												</thead>
												<tbody>
													{tloading ? (
														<Loader />
													) : userTheoryList !== undefined && userTheoryList.length ? (
														userTheoryList.map((item, i) => (
															<tr key={item._id}>
																<td>{i + 1}</td>
																<td>{`${item.user.first_name} ${item.user.last_name}`}</td>
                                <td>{item.exam.title}</td>
                                <td>{item.assessment.title}</td>
																<td>
																	{item.examResult === "pending" && (
																		<span className="badge bg-yellow">Pending</span>
																	)}
																	{item.examResult === "declared" && (
																		<span className="badge bg-green">
                                      Declared
																		</span>
																	)}
																</td>
                                <td>{moment(item.createdAt).format('YYYY-MM-DD HH:MM A')}</td>
																<td>
																	<a
																		className="btn btn-primary btn-sm"
																		title="Proceed to Result"
																		// onClick={() =>
																		//   handleDeleteTheory(item._id)
																		// }
																		key={item._id}
																	>
																		<i className="fa fa-check-circle-o" />
																	</a>
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="2">No records found</td>
														</tr>
													)}
												</tbody>
												<tfoot />
											</table>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ListUserTheories;
