import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { addTestimonial, clearErrors } from "../../../actions/adminTestimonialActions";
import { ADD_TESTIMONIAL_RESET } from "./../../../constants/adminTestimonialConstants";

import Loader from "../../Loader";

import "./Testimonial.css";

const AddTestimonial = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [testimonial, setTestimonial] = useState({
        name: "",
        image: "",
        description: "",
        status: false,
        imageName: ""
    });
    const [errorData, setErrorData] = useState({});

    const { name, image, description, status, imageName } = testimonial;

    const { isTestimonialAdded, user_loading, error, errors } = useSelector(
        (state) => state.testimonialDetail
    );

    useEffect(() => {
        if (errors) {
            setErrorData(errors);
        }

        if (error) {
            clearErrors();
            return alert.error(error);
        }

        if (isTestimonialAdded === true && isTestimonialAdded != undefined) {
            alert.success("Testimonial successfully added.");
            history.push("/admin/testimonials");
            dispatch({
                type: ADD_TESTIMONIAL_RESET,
            });
        }
    }, [dispatch, isTestimonialAdded, alert, error, errors]);

    const onChange = (e) => {
        if (e.target.name === "image") {
            let file = e.target.files[0];

            if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                setTestimonial({
                    ...testimonial,
                    image: "",
                    imageName: "",
                });
                alert.error("Please select valid logo.");
                //remove preview picture if any
                const preview = document.getElementById("img-preview");
                preview.src = "";
                return false;
            }

            const reader = new FileReader();

            reader.onload = function (readerEvt) {
                // The file's text will be printed here
                //console.log(readerEvt.target.result);
                let binaryString = readerEvt.target.result;
                let base64String = btoa(binaryString);
                setTestimonial({
                    ...testimonial,
                    image: base64String,
                    imageName: file.name,
                });

                //preview picture
                const preview = document.getElementById("img-preview");
                // console.log('banary image', base64String);
                preview.src = "data:image/png;base64," + base64String;
                errorData.image = "";
            };
            reader.readAsBinaryString(file);
        } else if (e.target.name === "status") {
            setTestimonial({
                ...testimonial,
                status: !status,
            });
        } else {
            setTestimonial({
                ...testimonial,
                [e.target.name]: e.target.value,
            });
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            addTestimonial(name, image, description, status, imageName)
        );
    };

    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Add
                                <small>Testimonial</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard"></i> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/testimonials">
                                        <i className="fa fa-table"></i> Testimonial
                                    </Link>
                                </li>
                                <li className="active">Add Testimonial</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add Testimonial</h3>

                                    {/*<div className="box-tools pull-right">

                                    </div>*/}
                                </div>

                                <form id="quickForm" onSubmit={submitHandler}>
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Enter the name"
                                                    value={name}
                                                    onChange={onChange}
                                                />
                                                {errorData.name ? (
                                                    <span className="text-danger">{errorData.name}</span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="image">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="image"
                                                    name="image"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                />
                                                {errorData.image ? (
                                                    <span className="text-danger">{errorData.image}</span>
                                                ) : (
                                                    ""
                                                )}
                                                <div>
                                                    <img id="img-preview" src="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="description">Description</label>
                                                <textarea
                                                    type="text"
                                                    name="description"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter the news decription"
                                                    value={description}
                                                    onChange={onChange}
                                                ></textarea>
                                                {errorData.description ? (
                                                    <span className="text-danger">
                            {errorData.description}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="status">Status</label>
                                                <input
                                                    style={{ marginRight: "5px" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    name="status"
                                                    checked={status}
                                                    value={status}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default AddTestimonial;
