import React, { Fragment } from 'react';
import { Link, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { logoutUser } from "../../../actions/authActions";

import { useAlert } from "react-alert";
import Loader from "../../Loader";
// import '../../../assets/admin/plugins/fontawesome-free/css/all.min.css';
import '../../../assets/admin/dist/css/adminlte.min.css';
// import '../../../assets/admin/dist/css/_all-skins.min.css';
import './Header.css';

const Header = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const history = useHistory();

    const { user, loading } = useSelector((state) => state.auth);

    const logoutHandler = () => {
        dispatch(logoutUser());
        history.push("/admin/login");
        alert.success("Logout successfully.");
    };

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <header className="main-header">

                        <Link to="/admin" className="admin-logo">

                            <span className="logo-mini"><b>O</b>TS</span>

                            <span className="logo-lg">One Time Success</span>
                        </Link>
                        <nav className="navbar navbar-static-top">
                            <Link to="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                                <span className="sr-only">Toggle navigation</span>
                            </Link>

                            <div className="navbar-custom-menu">
                                <ul className="nav navbar-nav">

                                    <li className="dropdown user user-menu">
                                        <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                                            <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"} className="user-image" alt="User Image"/>
                                            {/*<span className="hidden-xs">{`${user.first_name} ${user.last_name}`}</span>*/}
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li className="user-header">
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"} className="img-circle" alt="User Image"/>

                                                {/*<p>
                                            Alexander Pierce - Web Developer
                                            <small>Member since Nov. 2012</small>
                                        </p>*/}
                                            </li>
                                            {/*<li className="user-body">
                                    <div className="row">
                                        <div className="col-xs-4 text-center">
                                            <a href="#">Followers</a>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <a href="#">Sales</a>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <a href="#">Friends</a>
                                        </div>
                                    </div>
                                </li>*/}

                                            <li className="user-footer">
                                                <div className="pull-left">
                                                    <a href="#" className="btn btn-default btn-flat">Profile</a>
                                                </div>
                                                <div className="pull-right">

                                                    <Link to="/admin/login" className="btn btn-default btn-flat" onClick={logoutHandler}>
                                                        Logout
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    {/*<li>
                            <a href="#" data-toggle="control-sidebar"><i className="fa fa-gears"></i></a>
                        </li>*/}
                                </ul>
                            </div>
                        </nav>
                    </header>
                </Fragment>
            )}
        </Fragment>

    );
}

export default Header;