import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourses } from '../../../actions/commonActions';
import { getExams } from '../../../actions/adminExamActions';
import { addSubject, clearErrors } from '../../../actions/adminSubjectActions';
import { ADD_SUBJECT_RESET } from './../../../constants/adminSubjectConstants';

import Loader from '../../Loader';

import './Subject.css';
import { getUniversityList } from '../../../actions/adminUniversityAction';

const AddSubject = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();
    const examSlug = localStorage.getItem("examSlug");
    const [university, setUniversity] = useState([]);
    const [universityLists, setuniversityLists] = useState([]);

    const [subject, setSubject] = useState({
        exam: '',
        title: '',
        course: '',
        subjectHour: '',
        subjectTime: '',
        logo: '',
        status: false,
        mandetory: false
    });
    const [errorData, setErrorData] = useState({});
    const [fetchedCourses, setFetchedCourses] = useState([]);
    const [isPostUtme, setIsPostUtme] = useState(false);
    const [courses, setCourses] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const { exams } = useSelector((state) => state.exams);
    const { allCourses, loading } = useSelector((state) => state.commonCourses);
    const { exam, title, course, subjectHour, subjectTime, logo, status, imageName, mandetory } = subject;

    const { isSubjectAdded, user_loading, error, errors, message } = useSelector((state) => state.subjectDetail);

    useEffect(() => {
        if (allCourses && allCourses?.length > 0) {
            const tmpArr = [];
            allCourses.map((course) => {
                tmpArr.push({ label: course?.title, value: course?._id })
            })

            const optionsWithSelectAll = [{ label: "Select All", value: "select_all" }, ...tmpArr];
            setFetchedCourses(optionsWithSelectAll);
            // setFetchedCourses(tmpArr);
        }
    }, [allCourses])

    const { universityList, isUniversityDeleted } = useSelector((state) => state.university);


    useEffect(() => {
        dispatch(getUniversityList())
    }, [])




    useEffect(
        () => {
            dispatch({
                type: ADD_SUBJECT_RESET
            });
            dispatch(getExams());
            dispatch(getAllCourses());

            if (errors) {
                setErrorData(errors);
            }

            if (error) {
                clearErrors();
                return alert.error(error);
            }


        },
        [dispatch, isSubjectAdded, alert, error, errors]
    );

    useEffect(() => {
        if (isSubjectAdded === true && isSubjectAdded != undefined) {
            /*alert.success('Subject successfully added.');
            history.push('/admin/subjects');*/
            alert.success('Subject successfully added.');
            dispatch({
                type: ADD_SUBJECT_RESET
            });
            history.push('/admin/subjects');
        }

        // if (isSubjectAdded === false && isSubjectAdded != undefined) {
        //     alert.error("Maximum mandetory subjects limit is fullfilled");
        //     dispatch({
        //         type: ADD_SUBJECT_RESET
        //     });
        //     return;
        // }
    }, [isSubjectAdded])

    //console.log("allCourses ", allCourses);
    const onChange = (e) => {

        // var isPostUtmExam = [];
        if (e?.target?.name === 'exam') {
            if (exams.length > 0) {
                const slug = exams.filter(function (item) {
                    if (item._id == e.target.value) {
                        return item;
                    }
                });

                if (slug && slug?.length > 0 && slug[0].slug == "post-utme") {
                    setIsPostUtme(true);
                } else {
                    setIsPostUtme(false);
                }
            }
        }


        if (e.target.name === 'logo') {
            let file = e.target.files[0];

            if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                setSubject({
                    ...subject,
                    logo: '',
                    imageName: ''
                });
                alert.error('Please select valid logo.');
                //remove preview picture if any
                const preview = document.getElementById('img-preview');
                preview.src = '';
                return false;
            }

            const reader = new FileReader();

            reader.onload = function (readerEvt) {
                // The file's text will be printed here
                //console.log(readerEvt.target.result);
                let binaryString = readerEvt.target.result;
                let base64String = btoa(binaryString);
                setSubject({
                    ...subject,
                    logo: base64String,
                    imageName: file.name
                });

                //preview picture
                const preview = document.getElementById('img-preview');
                // console.log('banary image', base64String);
                preview.src = 'data:image/png;base64,' + base64String;
            };
            reader.readAsBinaryString(file);
        } else if (e.target.name === 'status') {
            setSubject({
                ...subject,
                status: !status
            });
        }
        else if (e.target.name === 'mandetory') {
            setSubject({
                ...subject,
                mandetory: !mandetory
            });
        }
        else {
            setSubject({
                ...subject,
                [e.target.name]: e.target.value
            });
        }
    };

    var activeExams = [];
    if (exams.length > 0) {
        activeExams = exams.filter(function (item) {
            return item.status === true;
        });
    }

    const submitHandler = (e) => {
        e.preventDefault();

        if (courses?.length === 0) {
            alert.error("Please select at least 1 course")
            return;
        }
        dispatch(
            addSubject(courses, title, subjectHour, subjectTime, logo, imageName, status, exam, mandetory, university)
        );
    };

    useEffect(() => {
        if (universityList !== undefined && universityList?.length > 0 && universityList !== null) {
            const tmpArr = [];
            universityList?.map((course) => {
                tmpArr?.push({ label: course?.title, value: course?._id })
            })

            setuniversityLists(tmpArr)
        }
    }, [universityList])

    const handleCoursesChange = (selectedOptions) => {
        // Check if "Select All" is selected
        if (selectedOptions.some(option => option.value === "select_all")) {
            if (isSelectAll) {
                // Deselect all options if "Select All" was already selected
                setCourses([]);
                setIsSelectAll(false);
            } else {
                // Select all options if "Select All" is newly selected
                setCourses(fetchedCourses.slice(1)); // Exclude "Select All" itself
                setIsSelectAll(true);
            }
        } else {
            // Update with individually selected options
            setCourses(selectedOptions);
            setIsSelectAll(false);
        }
    };

    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Add
                                <small>Subject</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard" /> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/subjects">
                                        <i className="fa fa-users" /> Subjects
                                    </Link>
                                </li>
                                <li className="active">Add Subject</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add Subject</h3>

                                    {/*<div className="box-tools pull-right">

                                    </div>*/}
                                </div>

                                <form id="quickForm" onSubmit={submitHandler} encType="multipart/form-data">
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="exam">Exam</label>
                                                <select
                                                    className="form-control"
                                                    name="exam"
                                                    value={exam}
                                                    onChange={onChange}
                                                >
                                                    <option value="">Select</option>
                                                    {loading ? (
                                                        <Loader />
                                                    ) : (activeExams.length > 0 ? (
                                                        activeExams.map((e, key) => {
                                                            return (
                                                                <option key={key} value={e._id}>
                                                                    {e.title}
                                                                </option>
                                                            );
                                                        })
                                                    ) : (
                                                        ''
                                                    )
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="course">Course</label>
                                                <Select
                                                    value={courses}
                                                    onChange={handleCoursesChange}
                                                    options={fetchedCourses}
                                                    isMulti
                                                    components={animatedComponents}
                                                    isSearchable={true}
                                                />

                                                {errorData.course ? (
                                                    <span className="text-danger">{errorData.course}</span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>

                                        </div>

                                        {isPostUtme && <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="title">Select University</label>

                                                <Select
                                                    value={university}
                                                    onChange={setUniversity}
                                                    options={universityLists}
                                                    // isMulti
                                                    components={animatedComponents}
                                                    isSearchable={true}
                                                />

                                            </div>
                                        </div>}

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="title">Subject Name</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Enter Subject Name"
                                                    value={title}
                                                    required
                                                    onChange={onChange}
                                                />
                                                {errorData.title ? (
                                                    <span className="text-danger">{errorData.title}</span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="logo">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="logo"
                                                    name="logo"
                                                    onChange={onChange}
                                                    required
                                                    accept={`.jpeg, .jpg, .png`}
                                                />
                                                {errorData.logo ? (
                                                    <span className="text-danger">{errorData.logo}</span>
                                                ) : (
                                                    ''
                                                )}
                                                <div>
                                                    <img id="img-preview" src="" />
                                                </div>
                                            </div>


                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <label htmlFor="timeLimit">Time Limit</label>

                                                <select
                                                    className="form-control"
                                                    name="subjectHour"
                                                    value={subjectHour}
                                                    onChange={onChange}
                                                    required
                                                >
                                                    <option value="">Hour</option>
                                                    {[...Array(24)].map((e, i) => <option value={i}>{i}</option>)}
                                                </select>
                                                {errorData.subjectHour ? (
                                                    <span className="text-danger">{errorData.subjectHour}</span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>

                                            <div className="col-md-3">
                                                <label htmlFor="timeLimit">&nbsp;</label>
                                                <select
                                                    className="form-control"
                                                    name="subjectTime"
                                                    value={subjectTime}
                                                    onChange={onChange}
                                                >
                                                    <option value="">Time</option>
                                                    <option value="00">00</option>
                                                    <option value="15">15</option>
                                                    <option value="30">30</option>
                                                    <option value="45">45</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <br />
                                                <label htmlFor="status">Status</label>
                                                <input
                                                    style={{ marginRight: '5px' }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    name="status"
                                                    checked={status}
                                                    value={status}
                                                    onChange={onChange}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <br />
                                                <label htmlFor="status">Mandetory</label>
                                                <input
                                                    style={{ marginRight: '5px' }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    name="mandetory"
                                                    checked={mandetory}
                                                    value={mandetory}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary" name="btnSubmit">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default AddSubject;
