import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const AuthCommonBanner = () => {
  return (
    <Fragment>
      <div
        className="left-regi"
        style={{
          backgroundImage:
            "url(" +
            process.env.REACT_APP_PUBLIC_URL +
            "/assets/img/ots-student-mockup_01.png)",
        }}
      >
        <div className="vh-center">
          {/* <div className="reg-logo">
                        <Link to="/">
                            <img
                                style={{
                                    width: '50px'
                                }}
                                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/foot-logo.png`}
                                alt="SITE-LOGO"
                            />
                        </Link>
                    </div> */}
          <div className="regi-bg-opacity">
            <h1>Let's get started</h1>
            <p>
              Success is no accident. It is hard work, perseverance, learning,
              studying, sacrifice and most of all, love of what you are doing or
              learning to do.
            </p>
          </div>
          <button type="button" className="btn">
            <Link className="foot-btn" to="/">
              Back to Home
            </Link>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default AuthCommonBanner;
