import React from 'react'
import './TabComponent.css';
import { useState, useEffect } from 'react';
import MockScoreAnalytics from './MockScoreAnalytics';
import { useDispatch, useSelector } from 'react-redux';
import { getResultMockAnalyticsData } from '../../actions/resultActions';
import MockPerformanceGraph from './MockPerformanceGraph';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const PerformanceTracker = () => {
    const [activeTab, setActiveTab] = useState(1);
    const { rLoading, result } = useSelector((state) => state.results);
    const { user, loading, error } = useSelector((state) => state.auth);

    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dispatch = useDispatch();
    const [dataByExam, setDataByExam] = useState({});
    const [jecedata, setJeceData] = useState([]);
    const [waecdata, setWaecData] = useState([]);
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    useEffect(() => {
        if (user) {
            dispatch(getResultMockAnalyticsData(user?._id));
        }
    }, [dispatch]);



    useEffect(() => {
        if (result && result.quizResultData?.length > 0) {
            // Define an object to store data for different exams

            const dataByExam = {};
            result.quizResultData.forEach((item) => {
                // Assuming each quiz result is associated with one exam
                const examSlug = item.examSlug;
                const exam = item.exams[0];
                if (examSlug === 'jsce-bece' || examSlug === 'wassce-waec-ssce') {
                    let tempArray = [];
                    let subjectsArray = examSlug === 'jsce-bece' ? result.JECEExamSubjects : result.WAECExamSubjects;
                    subjectsArray?.map((subject) => {
                        let subjectSlug = subject.slug
                        tempArray[subjectSlug] = {
                            labels: [],
                            quizzes: [],
                        };
                        const quizzes = exam?.quizzes?.filter((quiz) =>  quiz?.subjectId[0] === subject?._id);
                        quizzes?.forEach((q) => {
                            const date = new Date(q.createdAt);
                            const hours = (date.getHours() % 12) || 12;
                            const dateString = `${date.getDate()} ${month[date.getMonth()]}`;
                            const accuracyPercentage = ((q.correctCount / q.questionCount) * 100).toFixed(1);
    
                            // Push data to the arrays for this exam type
                            tempArray[subjectSlug].quizzes.push(accuracyPercentage);
                            tempArray[subjectSlug].labels.push(
                                `${dateString} ${hours}:${date.getMinutes()}:${date.getSeconds()} ${hours >= 12 ? 'PM' : 'AM'}`
                            );
                        });
                    })
                    if (examSlug === 'jsce-bece') {
                        setJeceData(tempArray)
                    }
                    else if (examSlug === 'wassce-waec-ssce'){
                        setWaecData(tempArray)
                    }
                }
                else{
                    if (exam) {
    
                        if (!dataByExam[examSlug]) {
                            // Initialize arrays for labels and quizzes for this exam type
                            dataByExam[examSlug] = {
                                labels: [],
                                quizzes: [],
                            };
                        }
    
                        exam.quizzes.forEach((q) => {
                            const date = new Date(q.createdAt);
                            const hours = (date.getHours() % 12) || 12;
                            const dateString = `${date.getDate()} ${month[date.getMonth()]}`;
                            const accuracyPercentage = ((q.correctCount / q.questionCount) * 100).toFixed(1);
    
                            // Push data to the arrays for this exam type
                            dataByExam[examSlug].quizzes.push(accuracyPercentage);
                            dataByExam[examSlug].labels.push(
                                `${dateString} ${hours}:${date.getMinutes()}:${date.getSeconds()} ${hours >= 12 ? 'PM' : 'AM'}`
                            );
                        });
                    }
                }
            });

            // Now you have separate data for each exam type
            setDataByExam(dataByExam);

        }
    }, [result]);
    
    return (
        <div className="right-auth-landing">
            <div className="main-view-content">
                <div className="tabs-container">
                    {result?.subscribedExams?.find((exam) => exam?.exam?.slug === 'jamb-utme') && <button
                        onClick={() => handleTabClick(1)}
                        className={`tab ${activeTab === 1 ? 'active' : ''}`}
                    >
                        JAMB UTME MOCK PERFORMANCE
                    </button>}
                    {result?.subscribedExams?.find((exam) => exam?.exam?.slug === 'wassce-waec-ssce') && <button
                        onClick={() => handleTabClick(2)}
                        className={`tab ${activeTab === 2 ? 'active' : ''}`}
                    >
                        WAEC MOCK PERFORMANCE
                    </button>}
                    {result?.subscribedExams?.find((exam) => exam?.exam?.slug === 'jsce-bece') && <button
                        onClick={() => handleTabClick(3)}
                        className={`tab ${activeTab === 3 ? 'active' : ''}`}
                    >
                        BECE MOCK PERFORMANCE
                    </button>}
                    {result?.subscribedExams?.find((exam) => exam?.exam?.slug === 'post-utme') && <button
                        onClick={() => handleTabClick(4)}
                        className={`tab ${activeTab === 4 ? 'active' : ''}`}
                    >
                        POST UTME MOCK PERFORMANCE
                    </button>}
                </div>
                <div className="content-container">
                    {result?.subscribedExams?.length === 0 && <span>You have not subscribed to any exam yet. <Link to={"/exams"}>Subscribe</Link> </span>}
                    {activeTab === 1 && result?.subscribedExams?.find((exam) => exam?.exam?.slug === 'jamb-utme') && <MockScoreAnalytics></MockScoreAnalytics>}
                    {activeTab === 2 && 
                    result?.WAECExamSubjects?.map((subject) => {
                        return <MockPerformanceGraph
                        label={subject?.title}
                        labels={waecdata[subject?.slug]?.labels || []}
                        quizzes={waecdata[subject?.slug]?.quizzes || []}
                        max={100}

                    />
                    })
                    }
                    {activeTab === 3 && 
                    result?.JECEExamSubjects?.map((subject) => {
                        return <MockPerformanceGraph
                        label={subject?.title}
                        labels={jecedata[subject?.slug]?.labels}
                        quizzes={jecedata[subject?.slug]?.quizzes}
                        max={100}

                    />})}
                    {activeTab === 4 && <MockPerformanceGraph
                        label="POST UTME MOCK PERFORMANCE"
                        labels={dataByExam['post-utme']?.labels}
                        quizzes={dataByExam['post-utme']?.quizzes}
                        max={100}
                        />}
                </div>


            </div>
        </div>
    )
}

export default PerformanceTracker