import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import ProgressBar from "./../ProgressBar/ProgressBar";
import Loader from "../Loader";
import { getResultData } from "./../../actions/resultActions";
import "./result.css";
import PieChart from "./PieChart";
import MarksBar from "../ProgressBar/MarksBar";

const Results = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.auth);
  const userId = user._id;
  const quizId = match.params.quizId;
  useEffect(() => {
    dispatch(getResultData(quizId));
  }, [dispatch, alert]);

  const { rLoading, result } = useSelector((state) => state.results);
  // console.log("result ", result);
  var progress = 0;
  var totalQuestions = 0;
  var attendQuestion = 0;
  var accuracy = 0;
  var correctAnswers = 0;
  var speed = 0;
  var totalTime = 0;
  var usedTime = 0;
  var speedPercent = 0;
  if (result !== undefined && result.quiz !== undefined) {
    totalQuestions = result.quiz.questionCount;
    attendQuestion = result.quiz.questionCount - result.quiz.unseenCount;
    correctAnswers = result.quiz.correctCount;
    progress = (
      (parseInt(attendQuestion) * 100) /
      parseInt(totalQuestions)
    ).toFixed(1);

    var color = "";
    var jambScore = "";
    if (
      result.quiz.exam.slug === "jamb-utme" &&
      result.quiz.assessment.slug === "mock"
    ) {
      accuracy = ((parseInt(correctAnswers) / totalQuestions) * 100).toFixed(1);
      const score = (correctAnswers / totalQuestions) * 400;
      jambScore = Math.round(score);

      if (jambScore <= 199) {
        color = "red";
      }
      if (jambScore >= 200 && jambScore <= 299) {
        color = "yellow";
      }
      if (jambScore >= 300 && jambScore <= 400) {
        color = "green";
      }
    } else {
      accuracy = (
        (parseInt(correctAnswers) / parseInt(totalQuestions)) *
        100
      ).toFixed(1);
    }

    totalTime = result.quiz.time;
    usedTime = result.quiz.usedtime;
    speed = usedTime;

    if (totalTime !== "" && usedTime !== "") {
      let tt = totalTime.split(":"); // split it at the colons
      // Hours are worth 60 minutes.
      let tSeconds = +tt[0] * 60 * 60 + +tt[1] * 60 + +tt[2];

      let ut = usedTime.split(":");
      // Hours are worth 60 minutes.
      let uSeconds = +ut[0] * 60 * 60 + +ut[1] * 60 + +ut[2];
      //console.log(uSeconds);

      let dtime = tSeconds - uSeconds;
      console.log(speedPercent, totalTime, usedTime);

      speedPercent = ((dtime / tSeconds) * 100).toFixed(2);
    }



    var advice = "";
    var adviceDescription = ""
    if (result.advices !== undefined) {
      const listItems = result.advices.map((item) => {
        let min_per = item.min_percentage;
        let max_per = item.max_percentage;
        // alert.info(parseFloat(accuracy) <= max_per);
        if (
          parseFloat(accuracy) >= min_per &&
          parseFloat(accuracy) <= max_per
        ) {
          advice = item.title;
          let tmpArr = [];
          adviceDescription = item?.description?.split(' ');
          for (let i = 0; i < adviceDescription.length; i += 5) {
            tmpArr.push(adviceDescription.slice(i, i + 5).join(' '));
          }
          adviceDescription = tmpArr;
          tmpArr = undefined;
          // break;
        }        
      });
    }
  }
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {result !== undefined && result.quiz !== undefined && (
            <div className="right-auth-landing edit-pros">
              <div className="main-view-content">
                <div className="all-ourt-style w-80-cust clearfix">
                  <div className="over-all-results-graph">
                    <h3 className="over-al-head">
                      Over All Practice Status <span>40% Completed</span>
                    </h3>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="graph-box">
                          <h3>
                            Progress <span>{progress}% Completed</span>
                          </h3>
                          {/* <div className="c100 p40 blue">
                                                        <span>{progress}%</span>
                                                        <div className="slice">
                                                            <div className="bar"></div>
                                                            <div className="fill"></div>
                                                        </div>
                                                    </div> */}
                          <div className="progressbar">
                            <div style={{ width: 150, height: 150 }}>
                              <ProgressBar
                                percentage={progress}
                                pathColor="green"
                              />
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="row">Total Questions</th>
                                  <td className="text-right">
                                    {totalQuestions}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Attend Questions</th>
                                  <td className="text-right">
                                    {attendQuestion}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Skipped Questions</th>
                                  <td className="text-right">
                                    {result.quiz.unseenCount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="graph-box">
                          <h3>
                            Accuracy <span>{accuracy}%</span>
                          </h3>
                          <div className="progressbar">
                            <div style={{ width: 150, height: 150 }}>
                              <ProgressBar
                                percentage={accuracy}
                                pathColor="purple"
                              />
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="row">Total Questions</th>
                                  <td className="text-right">
                                    {totalQuestions}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Attend Questions</th>
                                  <td className="text-right">
                                    {attendQuestion}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Skipped Questions</th>
                                  <td className="text-right">
                                    {result.quiz.unseenCount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="graph-box">
                          <h3>
                            Time <span>{speed}</span>
                          </h3>
                          <div className="progressbar">
                            <div style={{ width: 150, height: 150 }}>
                              <ProgressBar
                                percentage={speedPercent}
                                pathColor="blue"
                              />
                            </div>
                          </div>
                          {/* <div className="c100 p85 orange">
                            <span>{speed}</span>
                            <div className="slice">
                              <div className="bar"></div>
                              <div className="fill"></div>
                            </div>
                          </div> */}
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="row">Total Questions</th>
                                  <td className="text-right">
                                    {totalQuestions}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Attend Questions</th>
                                  <td className="text-right">
                                    {attendQuestion}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Skipped Questions</th>
                                  <td className="text-right">
                                    {result.quiz.unseenCount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-ourt-style w-80-cust clearfix">
                  <h3 className="over-al-head">
                    Result <span>Completed</span>
                  </h3>
                  <div className="row">
                    {/* <div className="col-sm-4">
                      <div className="result-boxers">
                        <h1>Percentage</h1>
                        <div className="progressbar">
                          <div style={{ width: 160, height: 160 }}>
                            <ProgressBar
                              percentage={accuracy}
                              pathColor="purple"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-sm-4">
                      {result.quiz.exam.slug === "jamb-utme" &&
                        result.quiz.assessment.slug === "mock" && (
                          <div className="result-boxers">
                            <h1>JAMB UTME MOCK SCORE</h1>
                            <div className="progressbar">
                              <div style={{ width: 160, height: 160 }}>
                                <MarksBar
                                  percentage={jambScore}
                                  pathColor={color}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="col-sm-4">
                      <div className="result-boxers">
                        <h1>
                          Advice <span>: {advice}</span>
                        </h1>
                        <div className="c100 p100 pink">
                          <span>{advice}</span>
                          <div className="slice">
                            <div className="bar"></div>
                            <div className="fill"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="result-boxers">
                        {/* <h1>
                          Suggestion
                        </h1> */}
                        <div className="advice-container">
                          {/* <p className="advice-description">
                            {adviceDescription}
                          </p> */}

                          <div className="akash">
                            {adviceDescription?.length > 0 && adviceDescription?.map((words, i) => {
                              return <p>{words}</p>
                            })}
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-ourt-style w-80-cust clearfix">
                  <h3 className="over-al-head">
                    Subject Result <span>test</span>
                  </h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{ width: "300px" }}>Subject Name</th>
                          <th style={{ width: "300px" }}>Correct</th>
                          <th style={{ width: "300px" }}>Incorrect</th>
                          <th style={{ width: "300px" }}>Un Answer</th>
                          <th style={{ width: "300px" }}>Accuracy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*<tr>
                          <td>Botany</td>
                          <td>2/5</td>
                          <td>2/5</td>
                          <td>2/5</td>
                          <td>2/5</td>
          </tr>*/}
                        {result.subjectResults.length &&
                          result.subjectResults.map((item) => {
                            var unAnswered =
                              parseInt(item.quesLength) -
                              (parseInt(item.rightAnswer) +
                                parseInt(item.wrongAnswer));
                            // var subjectAccuracy = (
                            //   (parseInt(item.rightAnswer) /
                            //     parseInt(item.quesLength)) *
                            //   100
                            // ).toFixed(2);
                            var subjectAccuracy = "";
                            if (
                              result.quiz.exam.slug === "jamb-utme" &&
                              result.quiz.assessment.slug === "mock"
                            ) {
                              subjectAccuracy = (
                                (parseInt(item.rightAnswer) / 180) *
                                400
                              ).toFixed(1);
                            } else {
                              subjectAccuracy = (
                                (parseInt(item.rightAnswer) /
                                  parseInt(item.quesLength)) *
                                100
                              ).toFixed(1);
                            }
                            return (
                              <tr key={item._id}>
                                <td>{item.subject.title}</td>
                                <td>
                                  {item.rightAnswer}/{item.quesLength}
                                </td>
                                <td>
                                  {item.wrongAnswer}/{item.quesLength}
                                </td>
                                <td>
                                  {unAnswered}/{item.quesLength}
                                </td>
                                <td>{subjectAccuracy}%</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                {result !== undefined && result.quiz !== undefined ? (
                  <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                    <PieChart
                      correctAnswers={result.quiz.correctCount}
                      incorrectAnswers={result.quiz.wrongCount}
                      unseenCount={result.quiz.unseenCount}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                  <div className="pull-left">
                    {/*<button type="button" className="btn view-ans-btn">
                      Back
                        </button>*/}
                    <Link to={`/results`} className="btn view-ans-btn">
                      Back
                    </Link>
                  </div>
                  <div className="pull-right">
                    <Link
                      to={`/practice-results-view/${result.quiz._id}`}
                      className="btn view-ans-btn"
                    >
                      View Corrections
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Results;
