import axios from 'axios';
import {
	LOAD_ALL_QUESTIONS_REQUEST,
	LOAD_ALL_QUESTIONS_SUCCESS,
	LOAD_ALL_QUESTIONS_FAIL,
	ADD_QUESTION_REQUEST,
	ADD_QUESTION_SUCCESS,
	ADD_QUESTION_RESET,
	ADD_QUESTION_FAIL,
	QUESTION_DETAIL_REQUEST,
	QUESTION_DETAIL_SUCCESS,
	QUESTION_DETAIL_FAIL,
	UPDATE_QUESTION_REQUEST,
	UPDATE_QUESTION_SUCCESS,
	UPDATE_QUESTION_RESET,
	UPDATE_QUESTION_FAIL,
	DELETE_QUESTION_REQUEST,
	DELETE_QUESTION_SUCCESS,
	DELETE_QUESTION_FAIL,
	//question subject
	LOAD_QUESTION_SUBJECTS_REQUEST,
	LOAD_QUESTION_SUBJECTS_SUCCESS,
	LOAD_QUESTION_SUBJECTS_FAIL,
	SUBJECT_YEARS_REQUEST,
	SUBJECT_YEARS_SUCCESS,
	SUBJECT_YEARS_FAIL,
	ADD_QUESTION_SUBJECT_REQUEST,
	ADD_QUESTION_SUBJECT_SUCCESS,
	ADD_QUESTION_SUBJECT_FAIL,
	SUBJECT_YEAR_DETAIL_REQUEST,
	SUBJECT_YEAR_DETAIL_SUCCESS,
	SUBJECT_YEAR_DETAIL_FAIL,
	UPDATE_SUBJECT_YEAR_REQUEST,
	UPDATE_SUBJECT_YEAR_SUCCESS,
	UPDATE_SUBJECT_YEAR_RESET,
	UPDATE_SUBJECT_YEAR_FAIL,

	//question topic sections
	GET_TOPIC_SECTIONS_REQUEST,
	GET_TOPIC_SECTIONS_SUCCESS,
	GET_TOPIC_SECTIONS_FAIL,
	CLEAR_ERRORS,
	GET_ALL_TOPIC_SUCCESS,
	GET_ALL_TOPIC_REQUEST,
	GET_ALL_TOPIC_FAIL
} from '../constants/adminQuestionConstants';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const _api_path = '/api/v1';

//get all questions
export const getQuestions = (subjectId, yearId, topicId, sectionId, universityId) => async (dispatch) => {
	try {
		dispatch({
			type: LOAD_ALL_QUESTIONS_REQUEST
		});

		const { data } = await axios.get(`${_api_path}/admin/questions/${subjectId}/${yearId}/${topicId}/${sectionId}/${universityId}`);

		dispatch({
			type: LOAD_ALL_QUESTIONS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: LOAD_ALL_QUESTIONS_FAIL,
			payload: error
		});
	}
};

//get question detail
export const getQuestionDetail = (subjectId, yearId, id) => async (dispatch) => {
	try {
		dispatch({
			type: QUESTION_DETAIL_REQUEST
		});

		const { data } = await axios.get(`/api/v1/admin/questions/${subjectId}/${yearId}/${id}`);
		// console.log('data ', data)
		dispatch({
			type: QUESTION_DETAIL_SUCCESS,
			payload: data.data
		});
	} catch (error) {
		dispatch({
			type: QUESTION_DETAIL_FAIL,
			payload: error.response.data.errMessage
		});
	}
};

// add question
export const addQuestion = (
	subjectId,
	topicId,
	yearId,
	sectionId,
	title,
	passage_check,
	passage,
	questionData,
	status,
	logo,
	imageName,
	university,
	examSlug
) => async (dispatch) => {
	try {
		dispatch({
			type: ADD_QUESTION_REQUEST
		});
		const config = {
			headers: {
				//"content-type": "multipart/form-data",
				// "Accept": "application/json",
				'content-type': 'application/json'
			}
		};
		const { data } = await axios.post(
			_api_path + `/admin/questions/${sectionId}/add`,
			{
				subjectId,
				topicId,
				yearId,
				sectionId,
				title,
				passage_check,
				passage,
				questionData,
				status,
				logo,
				imageName,
				university,
				examSlug
			},
			config
		);

		dispatch({
			type: ADD_QUESTION_SUCCESS,
			payload: data.user
		});
	} catch (error) {
		dispatch({
			type: ADD_QUESTION_FAIL,
			payload: error.response.data.errMessage,
			errors: error.response.data
		});
	}
};

//get subject year detail
/*export const getSubjectYearDetail = (syId) => async (dispatch) => {
  try {
	dispatch({
	  type: SUBJECT_YEAR_DETAIL_REQUEST,
	});

	const { data } = await axios.get(`/api/v1/subject-years/detail/${syId}`);
	// console.log('data ', data)
	dispatch({
	  type: SUBJECT_YEAR_DETAIL_SUCCESS,
	  payload: data.subjectYear,
	});
  } catch (error) {
	dispatch({
	  type: SUBJECT_YEAR_DETAIL_FAIL,
	  payload: error.response.data.errMessage,
	});
  }
};*/

// Update question
export const updateQuestion = (
	questionId,
	title,
	topicId,
	sectionId,
	questionName,
	passage_check,
	passage,
	option_1,
	option_2,
	option_3,
	option_4,
	option_5,
	correct_answer,
	currentAnswer,
	description,
	status,
	oldImage,
	logo,
	imageName
) => async (dispatch) => {
	// console.log('status ', status);
	try {
		dispatch({
			type: UPDATE_QUESTION_REQUEST
		});

		const config = {
			// headers: {
			//     'Content-Type': 'multipart/form-data'
			// }
			headers: {
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.put(
			_api_path + `/admin/questions/update/${questionId}`,
			{
				questionId,
				title,
				topicId,
				sectionId,
				question: questionName,
				passage_check,
				passage,
				option_1,
				option_2,
				option_3,
				option_4,
				option_5,
				correct_answer,
				currentAnswer,
				description,
				status,
				oldImage,
				logo,
				imageName
			},
			config
		);

		dispatch({
			type: UPDATE_QUESTION_SUCCESS,
			payload: data.success
		});
	} catch (error) {
		dispatch({
			type: UPDATE_QUESTION_FAIL,
			payload: error.response.data.errMessage,
			errors: error.response.data
		});
	}
};

// Delete question
export const deleteQuestionData = (questionId) => async (dispatch) => {
	//console.log('userData ', userData);
	try {
		dispatch({
			type: DELETE_QUESTION_REQUEST
		});

		const { data } = await axios.delete(_api_path + `/admin/questions/${questionId}`);

		dispatch({
			type: DELETE_QUESTION_SUCCESS,
			payload: questionId
		});
	} catch (error) {
		dispatch({
			type: DELETE_QUESTION_FAIL,
			payload: error
		});
	}
};

/**
 * QUESTION SUBJECTS
 */
//get all question subjects
export const getQuestionSubjects = () => async (dispatch) => {
	try {
		dispatch({
			type: LOAD_QUESTION_SUBJECTS_REQUEST
		});

		const { data } = await axios.get(`${_api_path}/admin/question-subjects`);
		// console.log("data ", data);
		// return;
		dispatch({
			type: LOAD_QUESTION_SUBJECTS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: LOAD_QUESTION_SUBJECTS_FAIL,
			payload: error
		});
	}
};

// add question subject
export const addQuestionSubject = (subject, year, university, status) => async (dispatch) => {
	try {
		dispatch({
			type: ADD_QUESTION_SUBJECT_REQUEST
		});
		const config = {
			headers: {
				//"content-type": "multipart/form-data",
				// "Accept": "application/json",
				'content-type': 'application/json'
			}
		};
		const { data } = await axios.post(
			_api_path + '/admin/question-subjects/add',
			{
				subject,
				year,
				university,
				status
			},
			config
		);

		dispatch({
			type: ADD_QUESTION_SUBJECT_SUCCESS,
			payload: data.user
		});
	} catch (error) {
		dispatch({
			type: ADD_QUESTION_SUBJECT_FAIL,
			payload: error.response.data.errMessage,
			errors: error.response.data
		});
	}
};

//get subject year detail
export const getSubjectYearDetail = (syId) => async (dispatch) => {
	try {
		dispatch({
			type: SUBJECT_YEAR_DETAIL_REQUEST
		});

		const { data } = await axios.get(_api_path + `/admin/subject-years/detail/${syId}`);
		// console.log("data ", data);
		// return;
		dispatch({
			type: SUBJECT_YEAR_DETAIL_SUCCESS,
			payload: data.data
		});
	} catch (error) {
		dispatch({
			type: SUBJECT_YEAR_DETAIL_FAIL,
			payload: error.response.data.errMessage
		});
	}
};

// Update question subject year
export const updateSubjectYear = (syId, subject, year) => async (dispatch) => {
	try {
		dispatch({
			type: UPDATE_SUBJECT_YEAR_REQUEST
		});

		const config = {
			// headers: {
			//     'Content-Type': 'multipart/form-data'
			// }
			headers: {
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.put(
			_api_path + `/admin/subject-years/update/${syId}`,
			{
				syId,
				subject,
				year
			},
			config
		);

		dispatch({
			type: UPDATE_SUBJECT_YEAR_SUCCESS,
			payload: data.success
		});
	} catch (error) {
		dispatch({
			type: UPDATE_SUBJECT_YEAR_FAIL,
			payload: error.response.data.errMessage,
			errors: error.response.data
		});
	}
};

//get all subject years
export const getSubjectYears = (subjectId) => async (dispatch) => {
	try {
		dispatch({
			type: SUBJECT_YEARS_REQUEST
		});

		const { data } = await axios.get(`${_api_path}/admin/subject-years/${subjectId}`);
		// console.log("data ", data);
		// return;
		dispatch({
			type: SUBJECT_YEARS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: SUBJECT_YEARS_FAIL,
			payload: error
		});
	}
};

//get all subject years
export const deleteSubjectYears = (subjectId) => async (dispatch) => {
	try {
		dispatch({
			type: SUBJECT_YEARS_REQUEST
		});

		const { data } = await axios.delete(`${_api_path}/admin/subject-years/delete/${subjectId}`);
		// console.log("data ", data);
		// return;
		dispatch({
			type: SUBJECT_YEARS_SUCCESS,
			payload: data
		});

	} catch (error) {
		dispatch({
			type: SUBJECT_YEARS_FAIL,
			payload: error
		});
	}
};






// Delete question subject
export const deleteQuestionSubjectData = (questionId) => async (dispatch) => {
	//console.log('userData ', userData);
	try {
		dispatch({
			type: DELETE_QUESTION_REQUEST
		});

		const { data } = await axios.delete(_api_path + `/admin/questions/${questionId}`);

		dispatch({
			type: DELETE_QUESTION_SUCCESS,
			payload: questionId
		});
	} catch (error) {
		dispatch({
			type: DELETE_QUESTION_FAIL,
			payload: error
		});
	}
};

/**
 * QUESTION TOPIC SECTIONS
 */
//get all topics
export const getTopics = (subjectId) => async (dispatch) => {
	try {
		dispatch({
			type: GET_ALL_TOPIC_REQUEST
		});

		const { data } = await axios.get(`${_api_path}/admin/topics/`+subjectId);
		// console.log("data ", data);
		// return;
		dispatch({
			type: GET_ALL_TOPIC_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: GET_ALL_TOPIC_FAIL,
			payload: error
		});
	}
};


//get all topic sections
export const getQuestionTopicSections = (subjectId, yearId) => async (dispatch) => {
	try {
		dispatch({
			type: GET_TOPIC_SECTIONS_REQUEST
		});

		const { data } = await axios.post(`${_api_path}/admin/question-topic-sections`, {
			subjectId,
			yearId
		});

		// return;
		dispatch({
			type: GET_TOPIC_SECTIONS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: GET_TOPIC_SECTIONS_FAIL,
			payload: error
		});
	}
};

//clear errors
export const clearErrors = () => async (dispatch) => {
	dispatch({
		type: CLEAR_ERRORS
	});
};
