import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useAlert } from "react-alert";

const _api_path = "/api/v1";

const ActivateAccount = ({match, history}) => {
  const alert = useAlert();

  const [activated, setActivated] = useState(false);

  const userId = match.params.id;
  useEffect(() => {
    axios
      .get(_api_path + `/activate-account/${userId}`)
      .then((item) => {
        setActivated(true);
      });

  }, [activated]);

  if (activated) {
    alert.success("Account activated successfully");
    history.push(`/login`);
  }
  
  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <h1>Account Activation</h1>
        </div>
      </section>

      <section className="news-page-sec">
        <div className="container">
          <div className="text-center">Please wait your account activating...</div>
        </div>
      </section>
    </Fragment>
  );
};

export default ActivateAccount;
