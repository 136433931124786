import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import {
  getAdvices,
  deleteAdviceData,
} from "../../../actions/adminAdviceActions";

import Loader from "../../Loader";

const ListAdvices = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdvices());
  }, [dispatch]);

  const handleDeleteAdvice = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteAdviceData(id));
            // history.push(`/admin/advice-list`);
            alert.success("Advice deleted successfully");
            dispatch(getAdvices());
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  const { advices, aloading } = useSelector((state) => state.adviceList);
  // console.log("advices", advices);
  return (
    <Fragment>
      {aloading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Advices</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li className="active">Advices</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Advice</h3>
                      <div className="box-tools">
                        <Link
                          to={"/admin/advice-list/add"}
                          className="btn btn-success btn-sm"
                        >
                          + Add Advice
                        </Link>
                      </div>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Advice Name</th>
                            <th>Min percentage</th>
                            <th>Max percentage</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {advices !== undefined && advices.length ? (
                            advices.map((item) => (
                              <tr key={item._id}>
                                <td>{item.title}</td>
                                <td>{item.min_percentage}</td>
                                <td>{item.max_percentage}</td>
                                <td>
                                  {item.status === true && (
                                    <span className="badge bg-green">
                                      Active
                                    </span>
                                  )}
                                  {item.status === false && (
                                    <span className="badge bg-yellow">
                                      InActive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/advice-list/${item._id}/edit`}
                                    className="btn btn-success btn-sm"
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                  &nbsp;
                                  <a
                                    className="btn btn-danger btn-sm"
                                    title="Delete"
                                    onClick={() => handleDeleteAdvice(item._id)}
                                    key={item._id}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListAdvices;
