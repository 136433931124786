import axios from "axios";
import {
  GET_EXAM_SUBSCRIPTIONS_REQUEST,
  GET_EXAM_SUBSCRIPTIONS_SUCCESS,
  GET_EXAM_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  USER_SUBSCRIPTION_REQUEST,
  USER_SUBSCRIPTION_SUCCESS,
  USER_SUBSCRIPTION_FAIL,
  PLAN_SUBSCRIPTION_REQUEST,
  PLAN_SUBSCRIPTION_SUCCESS,
  PLAN_SUBSCRIPTION_FAIL,
  USER_SUBSCRIPTION_LIST_REQUEST,
  USER_SUBSCRIPTION_LIST_SUCCESS,
  USER_SUBSCRIPTION_LIST_FAIL,
  CLEAR_ERRORS,
} from "../constants/subscriptionConstants";

const _api_path = "/api/v1";

//get exam all subscriptions
export const getExamSubscriptions = (examId, userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_EXAM_SUBSCRIPTIONS_REQUEST,
    });

    const { data } = await axios.get(
      _api_path + `/get-subscriptions/${examId}/${userId}`
    );

    dispatch({
      type: GET_EXAM_SUBSCRIPTIONS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_EXAM_SUBSCRIPTIONS_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

//get user subscription
export const getUserSubscription = (userId, examId, universityId = null) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SUBSCRIPTION_REQUEST,
    });
    let data;
    if (universityId !== null) {
      data = await axios.get(
        _api_path + `/get-user-subscriptions/${userId}/${examId}/${universityId}`
      );
    } else {
      data = await axios.get(
        _api_path + `/get-user-subscriptions/${userId}/${examId}`
      );
    }
    // return;
    dispatch({
      type: USER_SUBSCRIPTION_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: USER_SUBSCRIPTION_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

//subscribe the plan
export const userSubscribePlan =
  (examId, universityId, subscriptionId, userId, paymentData) => async (dispatch) => {
    try {
      dispatch({
        type: PLAN_SUBSCRIPTION_REQUEST,
      });
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        _api_path + "/subscribe-plan",
        {
          examId,
          universityId,
          subscriptionId,
          userId,
          paymentData
        },
        config
      );
      // console.log("data ", data);
      dispatch({
        type: PLAN_SUBSCRIPTION_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: PLAN_SUBSCRIPTION_FAIL,
        payload: error.response.data.errMessage,
      });
    }
  };

//get user subscriptions
export const getUserSubscriptionList = (userId, examId) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SUBSCRIPTION_LIST_REQUEST,
    });

    const { data } = await axios.get(
      _api_path + `/user-subscription-list/${userId}`
    );
    // console.log("data ", data);
    // return;
    dispatch({
      type: USER_SUBSCRIPTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_SUBSCRIPTION_LIST_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

//get subscription details by id
export const getSubscriptionById = (subscriptionId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUBSCRIPTION_REQUEST,
    });

    const { data } = await axios.get(
      _api_path + `/get-subscription/${subscriptionId}`
    );

    dispatch({
      type: GET_SUBSCRIPTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTION_FAIL,
      payload: error.response.data.errMessage,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
