import React from 'react'
import { Fragment } from 'react';
import { Link } from "react-router-dom";
import Loader from '../../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteUniversity, getUniversityList } from '../../../actions/adminUniversityAction';
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert";
const ListUniversity = () => {

    const dispatch = useDispatch();
    const alert = useAlert();

    // const {} = useSelector((state) => state))
    const { universityList, loading, isUniversityDeleted } = useSelector((state) => state.university);


    useEffect(() => {
        dispatch(getUniversityList())
    }, [])

    const handleDeleteUniversity = (id) => {
        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        dispatch(deleteUniversity(id));
                        alert.success("University deleted successfully");
                        dispatch(getUniversityList());
                    },
                },
                {
                    label: "No",
                    onClick: () => alert.success("No university deleted"),
                },
            ],
        });


    }


    return (
        <Fragment>
            {false ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Manage
                                <small>University</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard"></i> Dashboard
                                    </Link>
                                </li>
                                <li className="active">University</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="box">
                                        <div className="box-header">
                                            <h3 className="box-title">University</h3>
                                            <div className="box-tools">
                                                <div className="box-tools">
                                                    <Link
                                                        to={"/admin/university/add"}
                                                        className="btn btn-success btn-sm"
                                                    >
                                                        + Add University
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-body table-responsive no-padding">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>University Name</th>
                                                        <th>Logo</th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {universityList?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.title}</td>
                                                            <td>
                                                                <img
                                                                    src={
                                                                        item.logo
                                                                            ? process.env.REACT_APP_PUBLIC_URL + "/assets/img/subjects/" + item.logo
                                                                            : process.env.REACT_APP_PUBLIC_URL + '/assets/img/No-Image-Placeholder.svg' // Replace with the path to your default image
                                                                    }

                                                                    alt='university logo'
                                                                />
                                                            </td>
                                                            <td>
                                                                {item.description}
                                                            </td>
                                                            <td>{item.status ? "Active" : "InActive"}</td>
                                                            <td>  <Link
                                                                to={`/admin/university/edit/${item._id}`}
                                                                className="btn btn-success btn-sm"
                                                                title="Edit"
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                            </Link>
                                                                &nbsp;
                                                                <a
                                                                    className="btn btn-danger btn-sm"
                                                                    title="Delete"
                                                                    onClick={() => handleDeleteUniversity(item._id)}
                                                                    key={item._id}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </a></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot></tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default ListUniversity