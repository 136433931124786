import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";

import { getAllExamTypes } from "./../../actions/commonActions";
import { getUserSubscription } from "./../../actions/subscriptionActions";
import Loader from "../Loader";

const ExamType = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [examTypeId, setExamTypeId] = useState("");
  const [examType, setExamType] = useState("");
  const [filteredExams, setFilteredExams] = useState([]);

  const { userSubscription } = useSelector((state) => state.examSubscriptions);

  const { eloading, allExamTypes } = useSelector((state) => state.commonExams);
  // let filteredExams = [];
  const examTypes = [
    {
      id: 1,
      title: "Objective",
      uniqueTitle: "objective",
      logo: "multichoice-icon.png"
    },
    {
      id: 2,
      title: "Theory",
      uniqueTitle: "theory",
      logo: "theory-icon.png"
    }
  ];

  useEffect(() => {
    dispatch(getAllExamTypes())
    setExamTypeId(localStorage.getItem("examType"));
  }, [dispatch, alert]);

  useEffect(() => {
    if (allExamTypes?.length > 0) {
      let temp = [];
      const examId = localStorage.getItem("examId");
      const exam = allExamTypes?.find((item) => item._id == examId);
      const stringToFilter = exam?.examType;
      temp = examTypes?.filter(exam => {
        return stringToFilter == "both" || exam.uniqueTitle == stringToFilter;
      });
      setFilteredExams(temp);
    }

  }, [allExamTypes]);

  const onChange = (e) => {
    setExamTypeId(e.target.value);
    setExamType(e.target.dataset.name);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (examType !== "" && examType !== null && examType !== undefined) {
      localStorage.setItem("examType", examType);
      // history.push(`/categories`);
      history.push(`/mode-of-assessment`);
    } else {
      alert.error("Please select exam type to proceed.");
      return false;
    }
  };

  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <ul className="clearfix paginations-cust">
            <li>
              <a href="#!">Home</a>
            </li>
            <li>
              <a href="/exams">Exams</a>
            </li>
          </ul>
          <h1>Choose Exam Type</h1>
        </div>
      </section>

      <section className="subscription-page">
        <div className="container">
          <div className="end-plan">
            <h1>Choose Exam Type</h1>
          </div>
          <div className="custom-radios-plan examtype-spaner">
            {eloading ? (
              <Loader />
            ) : (
              <div className="wrapper clearfix">
                {filteredExams !== undefined && filteredExams.length > 0
                  ? filteredExams.map((item, key) => {
                    const indexId = key + 1;
                    return (
                      <Fragment key={indexId}>
                        <input
                          type="radio"
                          name="select"
                          id={`option-${indexId}`}
                          value={item.id}
                          data-name={item.uniqueTitle}
                          checked={examTypeId == item.id ? true : false}
                          onClick={onChange}
                          onChange={onChange}
                        />

                        <label
                          htmlFor={`option-${indexId}`}
                          className={`option option-${indexId}`}
                        >
                          <div className="dot"></div>
                          <div className="extype">
                            <img
                              src={
                                process.env.REACT_APP_PUBLIC_URL +
                                "/assets/img/" +
                                item.logo
                              }
                              alt="exam-logo"
                            />
                          </div>
                          <span>{item.title}</span>
                        </label>
                      </Fragment>
                    );
                  })
                  : ""}
              </div>
            )}
          </div>
          <div className="next-prev-steps text-center">
            {/*<button type="button" disabled className="chose-more">
              <i className="fas fa-arrow-left"></i> Prev
            </button>*/}
            <button
              type="submit"
              // disabled={!examType}
              className="chose-more"
              onClick={submitHandler}
            >
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ExamType;
