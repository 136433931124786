import React from "react";

const Loader = () => {
  // return <tr className="loader"></tr>;

  return (
   
        <tr className="loader">
          <td colSpan={10}>
            <div className="spinner-container">
              <p>Loading, please wait...</p>
              <div className="spinner"></div>
            </div>
          </td>
        </tr>
     
  )
};

export default Loader;
