import React, { Fragment } from 'react';
import Header from "../components/Includes/Header";
import Footer from "../components/Includes/Footer";

const AppLayout = props => {
    return (
        <Fragment>
            <div className="App">
                <Header/>
                {props.children}
                <Footer/>
            </div>
        </Fragment>
    );
};

export default AppLayout;
