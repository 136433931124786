import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import {
  getInstructions
} from "../../../actions/adminInstructionActions";
//import { deleteUserData } from '../../../actions/commonActions';

import Loader from "../../Loader";
// import Loader from "react-loader-spinner";

const ListInstructions = ({ history }) => {
  // const [users, setUsersData] = useState({});
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstructions());
  }, [dispatch]);

  const { instructions, iLoading } = useSelector((state) => state.instructionList);

  return (
    <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Instructions</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li className="active">Instructions</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Instructions</h3>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Instructions</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {iLoading ? (
                            <Loader />
                          ) : instructions.length ? (
                            instructions.map((item) => (
                              <tr key={item._id}>
                                <td>{item.content}</td>
                                <td>
                                  {item.status === true && (
                                    <span className="badge bg-green">
                                      Active
                                    </span>
                                  )}
                                  {item.status === false && (
                                    <span className="badge bg-yellow">
                                      InActive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/exam-instructions/edit/${item._id}`}
                                    className="btn btn-success btn-sm"
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
    </Fragment>
  );
};

export default ListInstructions;
