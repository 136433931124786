import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import LineChart from "./LineChart";

import { getExams } from "../../../actions/adminExamActions";
import { getSubjects } from "../../../actions/adminSubjectActions";
import { getStudents } from "./../../../actions/adminStudentActions";

const AdminDashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExams());
        dispatch(getSubjects());
        dispatch(getStudents());
    }, [dispatch]);

    const { exams } = useSelector((state) => state.exams);
    const { subjects } = useSelector((state) => state.subjects);
    const { students } = useSelector((state) => state.students);

    var activeExams = [];
    if (exams.length > 0) {
        activeExams = exams.filter(function (item) {
            return item.status === true;
        });
    }

    var activeSubjects = [];
    if (subjects.length > 0) {
        activeSubjects = subjects.filter(function (item) {
            return item.status === true;
        });
    }

    var activeStudents = [];
    if (students.length > 0) {
        activeStudents = students.filter(function (item) {
            return item.accountStatus === "Active";
        });
    }

    // console.log("activeStudents", activeStudents);

    //make count student registration per month
    var months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (students.length > 0) {
        var date;
        for (var i = 0; i < students.length; i++) {
            date = new Date(students[i].createdAt);
            // if (date.getFullYear() == 2016) {
            // months[date.getMonth()] += +students[i].commission;
            months[date.getMonth()] += 1;
            // }
        }
        // console.log("months", months);
    }

    return (
        <Fragment>
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Dashboard
                        <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/admin">
                                <i className="fa fa-dashboard"></i> Home
                            </Link>
                        </li>
                        <li className="active">Dashboard</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-lg-3 col-xs-6">
                            <div className="small-box bg-yellow">
                                <div className="inner">
                                    <h3>{activeExams.length > 0 ? activeExams.length : 0}</h3>

                                    <p>TOTAL ACTIVE EXAMS</p>
                                </div>
                                {/*<div className="icon">
                  <i className="ion ion-list"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fa fa-arrow-circle-right"></i>
  </a>*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-xs-6">
                            <div className="small-box bg-aqua">
                                <div className="inner">
                                    <h3>
                                        {activeSubjects.length > 0 ? activeSubjects.length : 0}
                                    </h3>

                                    <p>TOTAL ACTIVE SUBJECTS</p>
                                </div>
                                {/*<div className="icon">
                                    <i className="ion ion-bag"></i>
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-xs-6">
                            <div className="small-box bg-red">
                                <div className="inner">
                                    <h3>
                                        {activeStudents.length > 0 ? activeStudents.length : 0}
                                    </h3>

                                    <p>TOTAL ACTIVE STUDENTS</p>
                                </div>
                                {/*<div className="icon">
                  <i className="ion ion-pie-graph"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fa fa-arrow-circle-right"></i>
                </a>*/}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <h3>Students</h3>
                        </div>
                        <div className="col-md-12">
                            <LineChart studentData={months} />
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
};

export default AdminDashboard;
