import axios from "axios";
import {
    LOAD_ALL_CMS_REQUEST,
    LOAD_ALL_CMS_SUCCESS,
    LOAD_ALL_CMS_FAIL,

    CMS_DETAIL_REQUEST,
    CMS_DETAIL_SUCCESS,
    CMS_DETAIL_FAIL,

    UPDATE_CMS_REQUEST,
    UPDATE_CMS_SUCCESS,
    UPDATE_CMS_FAIL,

    CLEAR_ERRORS,
} from "../constants/cmsConstants";

const _api_path = "/api/v1";
//get all pages
export const getCmsPages = () => async (dispatch) => {
    try {
        dispatch({
            type: LOAD_ALL_CMS_REQUEST,
        });
        const { data } = await axios.get(_api_path + "/admin/static-pages");
        dispatch({
            type: LOAD_ALL_CMS_SUCCESS,
            payload: data,
        });
    } catch (err) {
        dispatch({
            type: LOAD_ALL_CMS_FAIL,
            payload: err,
        });
    }
};

//get subscription detail
export const getPageDetail = (id) => async (dispatch) => {
    try {
        dispatch({
            type: CMS_DETAIL_REQUEST,
        });

        const { data } = await axios.get(`${_api_path}/admin/static-pages/${id}`);
        // console.log('data ', data)
        dispatch({
            type: CMS_DETAIL_SUCCESS,
            payload: data.data,
        });
    } catch (error) {
        dispatch({
            type: CMS_DETAIL_FAIL,
            payload: error.response.data.errMessage,
        });
    }
};

// Update page
export const updatePage = (
    pageId,
    page_title,
    content,
    status
) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_CMS_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const { data } = await axios.put(
            _api_path + `/admin/static-page/${pageId}`,
            {
                pageId,
                page_title,
                content,
                status,
            },
            config
        );

        dispatch({
            type: UPDATE_CMS_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CMS_FAIL,
            payload: error.response.data.errMessage,
        });
    }
};


//clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};
