import React, { Fragment } from "react";

const Dashboard = () => {
  return (
    <Fragment>
      <div className="right-auth-landing">
        <div className="main-view-content">
          <div className="all-ourt-style w-80-cust  work-info">
            <div className="all-heads">
              <h3>Dashboard</h3>
            </div>
            <div className="editable-forms-users">Welcome to Dashboard</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
