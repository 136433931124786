import {
  LOAD_ALL_ADVICE_REQUEST,
  LOAD_ALL_ADVICE_SUCCESS,
  LOAD_ALL_ADVICE_FAIL,
  ADD_ADVICE_REQUEST,
  ADD_ADVICE_SUCCESS,
  ADD_ADVICE_RESET,
  ADD_ADVICE_FAIL,
  ADVICE_DETAIL_REQUEST,
  ADVICE_DETAIL_SUCCESS,
  ADVICE_DETAIL_FAIL,
  UPDATE_ADVICE_REQUEST,
  UPDATE_ADVICE_SUCCESS,
  UPDATE_ADVICE_RESET,
  UPDATE_ADVICE_FAIL,
  DELETE_ADVICE_REQUEST,
  DELETE_ADVICE_SUCCESS,
  DELETE_ADVICE_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminAdviceConstants";

export const adviceListReducer = (state = { advices: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_ADVICE_REQUEST:
      return {
        aloading: true,
        advices: [],
      };

    case LOAD_ALL_ADVICE_SUCCESS:
      return {
        aloading: false,
        advices: action.payload.advices,
      };

    case LOAD_ALL_ADVICE_FAIL:
      return {
        aloading: false,
        error: action.payload,
      };

    case UPDATE_ADVICE_REQUEST:
      return {
        ...state,
        aloading: true,
        errors: null,
      };

    case UPDATE_ADVICE_SUCCESS:
      return {
        ...state,
        aloading: false,
        isUpdated: action.payload,
      };

    case UPDATE_ADVICE_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_ADVICE_FAIL:
      return {
        ...state,
        aloading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const adviceDetailReducer = (state = { advice: {} }, action) => {
  switch (action.type) {
    case ADVICE_DETAIL_REQUEST:
      return {
        ...state,
        aloading: true,
      };

    case ADVICE_DETAIL_SUCCESS:
      return {
        aloading: true,
        advice: action.payload,
      };

    case ADVICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_ADVICE_REQUEST:
      return {
        aloading: true,
        isAdviceAdded: false,
        errors: null,
      };

    case ADD_ADVICE_SUCCESS:
      return {
        ...state,
        aloading: false,
        isAdviceAdded: true,
        advice: action.payload,
        errors: null,
      };

    case ADD_ADVICE_RESET:
      return {
        ...state,
        isAdviceAdded: false,
        errors: null,
      };

    case ADD_ADVICE_FAIL:
      return {
        ...state,
        aloading: false,
        isAdviceAdded: false,
        advice: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteAdviceReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ADVICE_REQUEST:
      return {
        aloading: true,
      };

    case DELETE_ADVICE_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_ADVICE_FAIL:
      return {
        aloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
