import React, {Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContactusReplyDetail } from '../../../actions/adminContactusActions';
import Loader from "../../Loader";
import Moment from "moment";

const ContactusReply = (props) => {
    const dispatch  = useDispatch();
    const contactusId = props.id;
    useEffect(() => {
        dispatch(
            getContactusReplyDetail(contactusId)
        )
    }, [dispatch,contactusId]);

    const { contactusReplyDetail, user_loading } = useSelector(state => state);

    return (
        <Fragment>
        {user_loading ? (
            <Loader />
        ) : (
        <Fragment>
            <div><strong>Replied Messages:</strong></div>            
            { contactusReplyDetail.contactus?.map(catItem => (
                <div key={catItem.id}>
                    <div className="col-md-12">
                        <label>Replied Message:&nbsp; </label>
                        {catItem.replyMessage.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, " ")}
                    </div>
                    <div className="col-md-12">
                        <label>Replied Date:&nbsp; </label>
                        {Moment(catItem.createdAt).format("YYYY-MM-DD")}
                    </div>                    
                </div>        
            ))}
        </Fragment>
        )}
        </Fragment>
    )
}

export default ContactusReply;