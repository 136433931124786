import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Loader from "../Loader";

import DatePicker from "react-date-picker";
import { addDays } from "date-fns";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { updateProfile, clearErrors } from "../../actions/userActions";
import { loadUser } from "../../actions/authActions";
import { UPDATE_PROFILE_RESET } from "../../constants/userConstants";

import nationalityData from "./../../nationality.json";

const EditProfile = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [mobile_code, setMobileCode] = useState("");
  const [nationality, setNationality] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");

  const [errorData, setErrorData] = useState({});

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProfile(
        first_name,
        last_name,
        mobile_code,
        mobile_number,
        email,
        DOBvalue,
        gender,
        nationality
      )
    );
  };

  const { user } = useSelector((state) => state.auth);
  const { loading, isUpdated, error, errors } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setMobileCode(user.mobile_code);
      setMobileNumber(user.mobile_number);
      setNationality(user.nationality);
      setGender(user.gender);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Profile updated successfully");
      dispatch(loadUser());

      //history.push(`/admin/users/${user._id}`);
      history.push(`/profile`);

      dispatch({
        type: UPDATE_PROFILE_RESET,
      });
    }
  }, [dispatch, isUpdated, alert, error, errors, history]);

  const goToEditUser = () => {
    history.push("/profile/edit");
  };

  const onPhoneInputChnage = (value, data, event, formattedValue) => {
    setMobileCode(value);
  };

  const [DOBvalue, setDate] = useState(new Date(user.dob));

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="right-auth-landing">
            <div className="main-view-content">
              <div className="all-ourt-style w-80-cust  work-info">
                <div className="all-heads">
                  <h3>Edit Profile</h3>
                </div>
                <div className="editable-forms-users">
                  <form
                    id="quickForm"
                    onSubmit={submitHandler}
                    encType="multipart/form-data"
                  >
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="first_name">
                            First Name <span className="impot-star">*</span>
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            className="form-control"
                            id="first_name"
                            placeholder="Enter First Name"
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          {errorData.first_name ? (
                            <span className="text-danger">
                              {errorData.first_name}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="last_name"> Last Name</label>
                          <input
                            type="text"
                            name="last_name"
                            className="form-control"
                            id="last_name"
                            placeholder="Enter Last Name"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          {errorData.last_name ? (
                            <span className="text-danger">
                              {errorData.last_name}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            Email <span className="impot-star">*</span>
                          </label>
                          <input
                            readOnly
                            type="text"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errorData.email ? (
                            <span className="text-danger">
                              {errorData.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="nationality"> Nationality</label>
                          <select
                            className="form-control"
                            name="nationality"
                            id="nationality"
                            value={nationality}
                            onChange={(e) => setNationality(e.target.value)}
                          >
                            <option value="">Select Nationality</option>
                            {loading ? (
                              <Loader />
                            ) : (
                              <Fragment>
                                {nationalityData.length
                                  ? nationalityData.map((e, key) => {
                                      return (
                                        <option
                                          key={key}
                                          
                                          value={e.nationality}
                                        >
                                          {e.nationality}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </Fragment>
                            )}
                          </select>
                          {errorData.nationality ? (
                            <span className="text-danger">
                              {errorData.nationality}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="mobile_code">
                            Mobile Code <span className="impot-star">*</span>
                          </label>
                          <PhoneInput
                            inputProps={{
                              name: "mobile_code",
                              id: "mobile_code",
                              required: true,
                              autoFocus: true,
                              readOnly: true,
                            }}
                            value={mobile_code}
                            onChange={onPhoneInputChnage}
                            enableSearch
                            country={"ng"}
                            className="form-control"
                          />
                          {errorData.mobile_code ? (
                            <span className="text-danger">
                              {errorData.mobile_code}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="mobile_number">
                            Mobile Number <span className="impot-star">*</span>
                          </label>
                          <input
                            type="text"
                            name="mobile_number"
                            className="form-control"
                            id="mobile_number"
                            placeholder="Enter Mobile Number"
                            value={mobile_number}
                            onChange={(e) => setMobileNumber(e.target.value)}
                          />
                          {errorData.mobile_number ? (
                            <span className="text-danger">
                              {errorData.mobile_number}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="gender">
                            Gender <span className="impot-star">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="gender"
                            id="gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                          {errorData.gender ? (
                            <span className="text-danger">
                              {errorData.gender}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dob">Date of Birth</label>
                          <DatePicker
                            name="dob"
                            value={DOBvalue}
                            selected={DOBvalue}
                            onChange={(date) => setDate(date)}
                            dateFormat="YYYY-MM-DD"
                            maxDate={addDays(new Date(), -6500)} //current date
                            placeholderText="Select your date of birth."
                            className="form-control"
                          />
                          {errorData.dob ? (
                            <span className="text-danger">{errorData.dob}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="text-right foot-btns">
                      <button
                        type="submit"
                        className="btn btn-primary scoler-details"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditProfile;
