import React, { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";

import { logoutUser } from "../../actions/authActions";

import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";

const UserLeftSide = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logoutUser());
    // history.push("/login");
    alert.success("Logout successfully.");
  };
  return (
    <Fragment>
      <div className="left-navigations">
        <ul className="clearfix">
          <li className="">
            <NavLink
              to="/dashboard"
              activeStyle={{ background: "#14df7d" }}
              exact
            >
              <i className="fa fa-desktop"></i> Dashboard
            </NavLink>
          </li>
          <li className="">
            <NavLink to="/profile" activeStyle={{ background: "#14df7d" }}>
              <i className="far fa-user-circle"></i> My Account
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/saved-subjects"
              activeStyle={{ background: "#14df7d" }}
              exact
            >
              <i className="fa fa-list"></i> Registered Subjects
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/my-subscriptions"
              activeStyle={{ background: "#14df7d" }}
              exact
            >
              <i className="fa fa-list"></i> Subscriptions
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/results"
              activeStyle={{ background: "#14df7d" }}
              exact
            >
              <i className="fa fa-list"></i> Results
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/mock-score"
              activeStyle={{ background: "#14df7d" }}
              exact
            >
              <i className="fa fa-list"></i> Mock Performance Tracker
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/account-setting"
              activeStyle={{ background: "#14df7d" }}
              exact
            >
              <i className="fa fa-cog"></i> Account Setting
            </NavLink>
          </li>

          {/*<li>
            <a href="javascript:void(0)">
              <i className="fas fa-pen-alt"></i> Status Active
            </a>
          </li>
          <li>
            <a href="javascript:void(0)">
              <i className="fa fa-list"></i> Member Since{" "}
            </a>
          </li>
          <li>
            <a href="javascript:void(0)">
              <i className="fa fa-list"></i> UTME Advice
            </a>
  </li>*/}
          
          {/* <li className="">
            <NavLink to="/calculator" activeStyle={{background:'#14df7d'}} exact>
              <i className="fa fa-calculator"></i> Calculator
            </NavLink>
          </li> */}
          
          {/* <li className="">
            <NavLink
              to="/saved-exams"
              activeStyle={{ background: "#14df7d" }}
              exact
            >
              <i className="fa fa-list"></i> Saved Exams
            </NavLink>
          </li> */}
          
          <li>
            <NavLink
              to="/login"
              className="nav-link"
              onClick={logoutHandler}
              activeStyle={{ background: "#14df7d" }}
              exact
            >
              <i className="fa fa-sign-out"></i> Log Out
            </NavLink>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default UserLeftSide;
