import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAlert } from 'react-alert';
import moment from 'moment';
import Loader from '../Loader';
import CustomDataTable from '../Datatable/CustomDataTable';
import { getSavedSubjects } from '../../actions/subjectActions';

import './subject.css';

const SavedSubjects = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const userId = user._id;
    const router = useHistory();

    useEffect(
        () => {
            localStorage.removeItem('registeredSubject')
            localStorage.removeItem("examId");
            localStorage.removeItem("examSlug");
            localStorage.removeItem("examName");
            localStorage.removeItem("examinationType");
            localStorage.removeItem("categoryId");
            localStorage.removeItem("courseId");
            localStorage.removeItem("RegSubjects");
            localStorage.removeItem("assessmentName");
            localStorage.removeItem("assessmentId");
            localStorage.removeItem("maximumSubjects");
            dispatch(getSavedSubjects(userId));
        },
        [dispatch, alert, user]
    );

    const { savedSubjects, csloading } = useSelector((state) => state.courseSubjects);

    const [subjects, setSubjects] = useState([]);
    const [currentRow, setCurrentRow] = useState("");

    const handleCheck = (e) => {
        // const id = e.target.value;
        const index = e.target.dataset.index;
        let checkArray = [...subjects, e.target.value];
        if (subjects.includes(e.target.value)) {
            checkArray = checkArray.filter((sub) => sub !== e.target.value);
        }
        setSubjects(checkArray);
        setCurrentRow(index);
    };

    const handleCustomize = (e) => {
        e.preventDefault();
        let id = e.target.dataset.id;
        let currentIndex = e.target.dataset.idx;
        const { exam, course, category, subjects, assessment } = savedSubjects?.find((regSub) => regSub._id === id)
        localStorage.setItem('registeredSubject', id)
        localStorage.setItem("examId", exam?._id);
        localStorage.setItem("examSlug", exam?.slug);
        localStorage.setItem("examName", exam?.title);
        localStorage.setItem("examinationType", exam?.examType);
        localStorage.setItem("categoryId", category?._id);
        localStorage.setItem("courseId", course?._id);
        localStorage.setItem("RegSubjects", JSON.stringify(subjects));
        localStorage.setItem("assessmentName", assessment.title);
        localStorage.setItem("assessmentId", assessment._id);
        localStorage.setItem("maximumSubjects", exam.maximumSubjects);
        router.push(`/course-subjects`)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let id = e.target.dataset.id;
        let currentIndex = e.target.dataset.idx;
        // alert.info(currentIndex);
        //var res = subjects.filter(item => !savedSubjects[currentIndex].subjects.includes(item));

        const currentExam = savedSubjects[currentIndex];

        const currentSubjects = savedSubjects[currentIndex].subjects;
        // console.log('subjects', subjects);
        const myArrayFiltered = subjects.filter((el) => {
            return currentSubjects.some((f) => {
                // console.log(f._id, el)
                return f._id === el;
            });
        });

        if (myArrayFiltered.length > 0 && currentRow === id) {
            const selectdSubjects = myArrayFiltered.toString();
            localStorage.setItem('savedId', id);
            localStorage.setItem('examId', currentExam.exam._id);
            localStorage.setItem('examName', currentExam.exam.title);
            localStorage.setItem('categoryId', currentExam.category._id);
            localStorage.setItem('courseId', currentExam.course._id);
            localStorage.setItem('assessmentId', currentExam.assessment._id);
            localStorage.setItem('assessmentSlug', currentExam.assessment.slug);
            localStorage.setItem('assessmentName', currentExam.assessment.title);
            localStorage.setItem('subjects', selectdSubjects);

            history.push('/registered-subjects');
        }
        else if (currentRow === "" || currentRow !== id) {
            alert.error("Please select subject(s) before proceeding!");
            return false;
        }
    };

    const tableHeader = [
        {
            title: 'ID',
            prop: 'id',
            sortable: true,
            filterable: true
        },
        {
            title: 'Exam',
            prop: 'exam',
            sortable: true,
            filterable: true
        },
        {
            title: 'Category',
            prop: 'category',
            sortable: true,
            filterable: true
        },
        {
            title: 'Course',
            prop: 'course',
            sortable: true,
            filterable: true
        },
        {
            title: 'Assessment',
            prop: 'assessment',
            sortable: true,
            filterable: true
        },
        {
            title: 'Subjects',
            prop: 'subjects',
            sortable: true,
            filterable: true
        },
        {
            title: 'Date',
            prop: 'date',
            sortable: true,
            filterable: true
        },
        { title: 'Action', prop: 'action' }
    ];


    var tableRowData = [];
    if (savedSubjects !== undefined && savedSubjects.length > 0) {
        savedSubjects.map((item, key) => {
            let itemData = {
                id: key + 1,
                exam: item.exam?.title,
                category: item.category?.title,
                course: item.course?.title,
                assessment: item.assessment?.title,
                subjects: <ul className="subject-list">
                    {item.subjects.length > 0 &&
                        item.subjects.map((subject, iKey) => {
                            return (
                                <li id={iKey}>
                                    <input
                                        type="checkbox"
                                        name="subject"
                                        id={`subject-${subject.id}-${key}`}
                                        onClick={handleCheck}
                                        value={subject.id}
                                        data-index={item._id}
                                    />{' '}
                                    <label htmlFor={`subject-${subject.id}-${key}`}>{subject.title}</label>
                                </li>
                            );
                        })}
                </ul>,
                date: moment(item.createdAt).format('YYYY-MM-DD'),
                action:
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            data-id={item._id}
                            data-subjects={item.subjects}
                            data-idx={key}
                        >
                            Proceed for Test
                        </button>
                        <button
                            className="btn btn-warning"
                            style={{ marginTop: "2rem" }}
                            onClick={handleCustomize}
                            data-id={item._id}
                            data-subjects={item.subjects}
                            data-idx={key}
                        >
                            Customize Test
                        </button>
                    </div>

            };
            tableRowData.push(itemData);
        });
    }

    const onSortFunction = {
        date(columnValue) {
            // Convert the string date format to UTC timestamp
            // So the table could sort it by number instead of by string
            return moment(columnValue, 'Do MMMM YYYY').valueOf();
        }
    };

    const customLabels = {
        first: '<<',
        last: '>>',
        prev: '<',
        next: '>',
        show: 'Display',
        entries: 'rows',
        noResults: 'There are no data to be displayed'
    };

    return (
        <Fragment>
            {csloading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="right-auth-landing">
                        <div className="main-view-content">
                            <div className="all-ourt-style work-info">
                                <div className="all-heads">
                                    <h3>Saved Exams</h3>
                                </div>

                                <CustomDataTable
                                    tableHeaders={tableHeader}
                                    tableBody={tableRowData}
                                    rowsPerPage={10}
                                    rowsPerPageOption={[10, 20, 30, 40]}
                                    initialSort={{ prop: 'course', isAscending: true }}
                                    onSort={onSortFunction}
                                    labels={customLabels}
                                />

                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default SavedSubjects;
