import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../actions/commonActions";
import {
  getCourseDetail,
  updateCourse,
  clearErrors,
} from "../../../actions/adminCourseActions";
import { UPDATE_COURSE_RESET } from "./../../../constants/adminCourseConstants";

import Loader from "../../Loader";

import "./Course.css";

const EditCourse = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [courseId, setCourseId] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(false);

  const [errorData, setErrorData] = useState({});

  const { isUpdated, user_loading, error, errors } = useSelector(
    (state) => state.courses
  );

  const currentUserId = match.params.id;
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getCourseDetail(currentUserId));
  }, [dispatch]);

  const { allCategories, loading } = useSelector(
    (state) => state.commonCategories
  );

  const { course } = useSelector((state) => state.courseDetail);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (course) {
      setCourseId(course._id);
      setCategory(course.category);
      setTitle(course.title);
      setStatus(course.status);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Course updated successfully");
      dispatch(getCourseDetail(course._id));
      history.push(`/admin/courses`);
      dispatch({
        type: UPDATE_COURSE_RESET,
      });
    }
  }, [dispatch, course, alert, error, errors, history, isUpdated]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateCourse(courseId, category, title, status));
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Course</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/courses">
                    <i className="fa fa-users"></i> Courses
                  </Link>
                </li>
                <li className="active">Edit Course</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Course</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="category">Category</label>
                        <select
                          className="form-control"
                          name="category"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">Select</option>
                          {loading ? (
                            <Loader />
                          ) : (
                            <Fragment>
                              {allCategories.length > 0
                                ? allCategories.map((e, key) => {
                                    return (
                                      <option key={key} value={e._id}>
                                        {e.title}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Fragment>
                          )}
                        </select>
                        {errorData.category ? (
                          <span className="text-danger">
                            {errorData.category}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {errorData.title ? (
                          <span className="text-danger">{errorData.title}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status </label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          checked={status}
                          value={status}
                          onChange={(e) => setStatus(!status)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditCourse;
