import {
  LOAD_ALL_EXAMS_REQUEST,
  LOAD_ALL_EXAMS_SUCCESS,
  LOAD_ALL_EXAMS_FAIL,
  ADD_EXAM_REQUEST,
  ADD_EXAM_SUCCESS,
  ADD_EXAM_RESET,
  ADD_EXAM_FAIL,
  EXAM_DETAIL_REQUEST,
  EXAM_DETAIL_SUCCESS,
  EXAM_DETAIL_FAIL,
  UPDATE_EXAM_REQUEST,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_RESET,
  UPDATE_EXAM_FAIL,
  DELETE_EXAM_REQUEST,
  DELETE_EXAM_SUCCESS,
  DELETE_EXAM_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminExamConstants";

export const examsReducer = (state = { exams: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_EXAMS_REQUEST:
      return {
        user_loading: true,
        exams: [],
      };

    case LOAD_ALL_EXAMS_SUCCESS:
      return {
        user_loading: false,
        exams: action.payload.data,
      };

    case LOAD_ALL_EXAMS_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case UPDATE_EXAM_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case UPDATE_EXAM_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_EXAM_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case UPDATE_EXAM_FAIL:
      return {
        ...state,
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const examDetailReducer = (state = { exam: {} }, action) => {
  switch (action.type) {
    case EXAM_DETAIL_REQUEST:
      return {
        ...state,
        user_loading: true,
      };

    case EXAM_DETAIL_SUCCESS:
      return {
        user_loading: true,
        exam: action.payload,
      };

    case EXAM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_EXAM_REQUEST:
      return {
        user_loading: true,
        isExamAdded: false,
        errors: null,
      };

    case ADD_EXAM_SUCCESS:
      return {
        ...state,
        user_loading: false,
        isExamAdded: true,
        exam: action.payload,
        errors: null,
      };

    case ADD_EXAM_RESET:
      return {
        ...state,
        isExamAdded: false,
        errors: null,
      };

    case ADD_EXAM_FAIL:
      return {
        ...state,
        user_loading: false,
        isExamAdded: false,
        exam: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteExamReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_EXAM_REQUEST:
      return {
        user_loading: true,
      };

    case DELETE_EXAM_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_EXAM_FAIL:
      return {
        user_loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
