import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";

import { getNewsList } from "./../../actions/newsActions";
import { getUserSubscription } from "./../../actions/newsActions";
import Loader from "../Loader";

const Exams = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [examType, setExamType] = useState("");
  const [nextPage, setNextPage] = useState("");

  const { user, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getNewsList());
  }, [dispatch, alert, isAuthenticated, user]);

  const { news, nloading } = useSelector((state) => state.allNewsList);
  console.log("news ", news);

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log("userSubscription ", userSubscription);
  };

  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <ul className="clearfix paginations-cust">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/news">News</Link>
            </li>
          </ul>
          <h1>News</h1>
        </div>
      </section>

      <section className="news-page-sec">
        <div className="container">
          <p className="text-center">
              Our strong sense of identification with client projects means that we are constantly striving to provide solutions, even for issues they aren’t yet aware of. To this end, we adopt a progressive approach to technology and marketing techniques.
          </p>

          {nloading ? (
            <Loader />
          ) : (
            <div className="row">
              {news !== undefined && news.length > 0
                ? news.map((item, key) => {
                    const indexId = key + 1;
                    return (
                      <Fragment>
                        <div className="col-sm-4">
                          <div className="top-blog">
                            <h4>
                              {/*by admin <span>-</span> 20 May, 2019*/}
                              by admin <span>-</span> {item.createdAt}
                            </h4>
                            <h1>{item.title}</h1>
                            <Link to={`/news/${item._id}`} className="redmor">
                              Read More
                            </Link>
                          </div>
                          <div
                            className="bottom-blog"
                            // style={{
                            //   backgroundImage: `url("../assets/frontend/img/blog-3.jpeg")`,
                            // }}
                            style={{
                              backgroundImage: `url("${process.env.REACT_APP_PUBLIC_URL}/assets/img/news/${item.logo}")`,
                            }}
                          ></div>
                        </div>
                      </Fragment>
                    );
                  })
                : ""}
            </div>
          )}
        </div>
      </section>
    </Fragment>
  );
};

export default Exams;
