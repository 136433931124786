import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryDetail,
  updateCategory,
  clearErrors,
} from "../../../actions/adminCategoryActions";
import { UPDATE_CATEGORY_RESET } from "../../../constants/adminCategoryConstants";

import Loader from "../../Loader";

const EditCategory = ({ history, match }) => {
  const [categoryId, setCategoryId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(false);

  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const alert = useAlert();
  const dispatch = useDispatch();

  const { isUpdated, user_loading, error } = useSelector(
    (state) => state.categories
  );

  const currentUserId = match.params.id;
  useEffect(() => {
    dispatch(getCategoryDetail(currentUserId));
  }, [dispatch]);

  const { category } = useSelector((state) => state.categoryDetail);

  useEffect(() => {
    if (category) {
      setCategoryId(category._id);
      setTitle(category.title);
      setDescription(category.description);
      setStatus(category.status);
      setImage(category.image);
      //setImageName(category.image);
      setOldImage(category.image);
      setImagePreview(
        process.env.REACT_APP_PUBLIC_URL + "/assets/img/categories/" + category.image
      );
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Category updated successfully");
      dispatch(getCategoryDetail(category._id));

      history.push(`/admin/categories`);

      dispatch({
        type: UPDATE_CATEGORY_RESET,
      });
    }
  }, [dispatch, category, alert, error, history, isUpdated]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateCategory(
        categoryId,
        title,
        description,
        status,
        oldImage,
        image,
        imageName
      )
    );
  };

  const onChange = (e) => {
    let file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setImage("");
      setImageName("");
      alert.error("Please select valid image.");
      //remove preview picture if any
      const preview = document.getElementById("img-preview");
      preview.src = "";
      return false;
    }

    const reader = new FileReader();

    reader.onload = function (readerEvt) {
      // The file's text will be printed here
      //console.log(readerEvt.target.result);
      let binaryString = readerEvt.target.result;
      let base64String = btoa(binaryString);
      setImage(base64String);
      setImageName(file.name);

      //preview picture
      const preview = document.getElementById("img-preview");
      // console.log('banary image', base64String);
      preview.src = "data:image/png;base64," + base64String;
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Category</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/categories">
                    <i className="fa fa-users"></i> Categories
                  </Link>
                </li>
                <li className="active">Edit Category</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Category</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>
                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">Image</label>
                        <input
                          type="file"
                          name="image"
                          className="form-control"
                          id="image"
                          onChange={onChange}
                          accept={`.jpeg, .jpg, .png`}
                        />
                        <div>
                          <img id="img-preview" src={imagePreview} />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="description">Description</label>
                        <textarea
                          name="description"
                          className="form-control"
                          id="description"
                          placeholder="Enter Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        >
                          {description}
                        </textarea>
                      </div>
                    </div>

                    <div className="form-group mb-0">
                      <div className="col-md-6 row">
                        <div className="form-check">
                          <input
                            style={{ marginRight: "5px" }}
                            type="checkbox"
                            className="form-check-input"
                            id="status"
                            checked={status}
                            value={status}
                            onChange={(e) => setStatus(!status)}
                          />
                          <label className="form-check-label" for="status">
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditCategory;
