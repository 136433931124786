import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import ReactSummernote from "react-summernote";

// Import bootstrap(v3 or v4) dependencies

import {
  getEmailTemplateDetail,
  updateEmailtemplate,
  clearErrors,
} from "../../../actions/admin/emailTemplateActions";
import { UPDATE_ETEMPLATE_RESET } from "./../../../constants/admin/emailTemplateConstants";

import Loader from "../../Loader";

import "./EmailTemplate.css";

const EditEmailTemplate = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [templateId, setTemplateId] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [status, setStatus] = useState(true);

  const [errorData, setErrorData] = useState({});

  const currentTemplateId = match.params.id;
  useEffect(() => {
    dispatch(getEmailTemplateDetail(currentTemplateId));
  }, [dispatch]);

  const { emailtemplate, etemplateLoading, isUpdated, error, errors } =
    useSelector((state) => state.emailtemplates);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (emailtemplate) {
      setTemplateId(emailtemplate._id);
      setSubject(emailtemplate.subject);
      setContent(emailtemplate.content);
      setStatus(emailtemplate.status);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Email template updated successfully");
      dispatch(getEmailTemplateDetail(emailtemplate._id));
      history.push(`/admin/emailtemplates`);
      dispatch({
        type: UPDATE_ETEMPLATE_RESET,
      });
    }
  }, [dispatch, emailtemplate, alert, error, errors, isUpdated]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateEmailtemplate(templateId, subject, content, status));
  };

  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Edit
            <small>Email Template</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li>
              <Link to="/admin/emailtemplates">
                <i className="fa fa-users"></i> Email Templates
              </Link>
            </li>
            <li className="active">Edit Email Template</li>
          </ol>
        </section>

        <section className="content">
          <div className="box box-default">
            <div className="box-header with-border">
              <h3 className="box-title">Edit Email Template</h3>

              {/*<div className="box-tools pull-right">

                                    </div>*/}
            </div>

            <form id="quickForm" onSubmit={submitHandler}>
              <div className="box-body">
                <div className="form-group row">
                  <div className="col-md-12">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      id="subject"
                      placeholder="Enter Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    {errorData.subject ? (
                      <span className="text-danger">{errorData.subject}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-12">
                    <label htmlFor="content">Content</label>
                    <ReactSummernote
                      value={content}
                      options={{
                        lang: "ru-RU",
                        height: 300,
                        dialogsInBody: true,
                        toolbar: [
                          ["style", ["style"]],
                          ["font", ["bold", "underline", "clear"]],
                          ["fontname", ["fontname"]],
                          ["para", ["ul", "ol", "paragraph"]],
                          ["table", ["table"]],
                          ["insert", ["link", "picture", "video"]],
                          ["view", ["fullscreen", "codeview"]],
                        ],
                      }}
                      onChange={(content) => setContent(content)}
                    />
                    {errorData.content ? (
                      <span className="text-danger">{errorData.content}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="status">Status </label>
                    <input
                      style={{ marginRight: "5px" }}
                      type="checkbox"
                      className="form-check-input"
                      id="status"
                      checked={status}
                      value={status}
                      onChange={(e) => setStatus(!status)}
                    />
                  </div>
                </div> */}

                <div className="box-footer">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default EditEmailTemplate;
