import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getTopicSectionDetail,
  updateTopicSection,
  clearErrors,
} from "../../../actions/adminTopicSectionActions";
import { UPDATE_TOPIC_SECTION_RESET } from "./../../../constants/adminTopicSectionConstants";

import Loader from "../../Loader";

import "./TopicSection.css";

const EditTopicSection = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [section_name, setSectionName] = useState("");
  const [topic_count, setTopicCount] = useState("");
  const [status, setStatus] = useState(false);

  const [errorData, setErrorData] = useState({});

  const { isUpdated, user_loading, error, errors } = useSelector(
    (state) => state.topicSections
  );

  const sectionId = match.params.sectionId;
  useEffect(() => {
    dispatch(getTopicSectionDetail(sectionId));
  }, [dispatch]);

  const { topicSection } = useSelector((state) => state.topicSectionDetail);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (topicSection) {
      //setTopicId(topicSection._id);
      setSectionName(topicSection.section_name);
      setTopicCount(topicSection.topic_count);
      setStatus(topicSection.status);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Topic updated successfully");
      dispatch(getTopicSectionDetail(topicSection._id));
      history.push(`/admin/topic-sections/${topicSection.topic}`);
      dispatch({
        type: UPDATE_TOPIC_SECTION_RESET,
      });
    }
  }, [dispatch, topicSection, alert, error, errors, history, isUpdated]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateTopicSection(sectionId, section_name, topic_count, status));
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Topic</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                {topicSection !== undefined && (
                  <li>
                    <Link to={`/admin/topic-sections/${topicSection.topic}`}>
                      <i className="fa fa-table"></i> Topic Sections
                    </Link>
                  </li>
                )}
                <li className="active">Edit Topic Section</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Topic Section</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="section_name">Section Name</label>
                        <input
                          type="text"
                          name="section_name"
                          className="form-control"
                          id="section_name"
                          placeholder="Enter Section Name"
                          value={section_name}
                          onChange={(e) => setSectionName(e.target.value)}
                        />
                        {errorData.section_name ? (
                          <span className="text-danger">
                            {errorData.section_name}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="topic_count">Topic Count</label>
                        <input
                          type="text"
                          name="topic_count"
                          className="form-control"
                          id="topic_count"
                          placeholder="Enter Topic Count"
                          value={topic_count}
                          onChange={(e) => setTopicCount(e.target.value)}
                        />
                        {errorData.topic_count ? (
                          <span className="text-danger">
                            {errorData.topic_count}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status </label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          checked={status}
                          value={status}
                          onChange={(e) => setStatus(!status)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditTopicSection;
