import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { getAllExamTypes } from "./../../../actions/commonActions";
import {
  getSubscriptionDetail,
  updateSubscription,
  clearErrors,
} from "../../../actions/adminSubscriptionActions";
import { UPDATE_SUBSCRIPTION_RESET } from "../../../constants/adminSubscriptionConstants";

import Loader from "../../Loader";

const EditSubscription = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [subscriptionId, setSubscriptionId] = useState("");
  const [examType, setExamType] = useState("");
  const [title, setTitle] = useState("");
  const [numOfQuestions, setNumOfQuestions] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(false);

  const [errorData, setErrorData] = useState({});

  const { isUpdated, user_loading, error } = useSelector(
    (state) => state.subscriptions
  );

  const currentUserId = match.params.id;
  useEffect(() => {
    dispatch(getAllExamTypes());
    dispatch(getSubscriptionDetail(currentUserId));
  }, [dispatch]);

  const { subscription } = useSelector((state) => state.subscriptionDetail);

  useEffect(() => {
    if (subscription) {
      setSubscriptionId(subscription._id);
      setExamType(subscription.examType);
      setTitle(subscription.title);
      setNumOfQuestions(subscription.numOfQuestions);
      setSubscriptionType(subscription.subscriptionType);
      setDuration(subscription.duration);
      setPrice(subscription.price);
      setDescription(subscription.description);
      setStatus(subscription.status);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Subscription updated successfully");
      dispatch(getSubscriptionDetail(subscription._id));

      history.push(`/admin/subscriptions`);

      dispatch({
        type: UPDATE_SUBSCRIPTION_RESET,
      });
    }
  }, [dispatch, subscription, alert, error, history, isUpdated]);

  const { allExamTypes, eloading } = useSelector((state) => state.commonExams);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateSubscription(
        subscriptionId,
        examType,
        title,
        numOfQuestions,
        duration,
        subscriptionType,
        price,
        description,
        status
      )
    );
  };

  const onChange = (e) => {};

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Subscription</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/subscriptions">
                    <i className="fa fa-users"></i> Subscriptions
                  </Link>
                </li>
                <li className="active">Edit Subscription</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Subscription</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>
                <form id="quickForm" onSubmit={submitHandler}>
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="examType">Exam Type</label>
                        <select
                          className="form-control"
                          name="examType"
                          value={examType}
                          onChange={(e) => setExamType(e.target.value)}
                        >
                          <option value="">Select</option>
                          {eloading ? (
                            <Loader />
                          ) : (
                            <Fragment>
                              {allExamTypes.length > 0
                                ? allExamTypes.map((e, key) => {
                                    return (
                                      <option key={key} value={e._id}>
                                        {e.title}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Fragment>
                          )}
                        </select>

                        {errorData.course ? (
                          <span className="text-danger">
                            {errorData.course}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="numOfQuestions">
                          Number of questions
                        </label>
                        <input
                          type="text"
                          name="numOfQuestions"
                          className="form-control"
                          id="numOfQuestions"
                          placeholder="Enter number of questions"
                          value={numOfQuestions}
                          onChange={(e) => setNumOfQuestions(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="subscriptionType">
                          Subscription Type
                        </label>
                        <input
                          type="text"
                          name="subscriptionType"
                          className="form-control"
                          id="subscriptionType"
                          value={subscriptionType}
                          onChange={(e) => setSubscriptionType(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="duration">Duration</label>
                        <input
                          name="duration"
                          className="form-control"
                          id="duration"
                          placeholder="Enter Duration"
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="price">Price</label>
                        <input
                          name="price"
                          className="form-control"
                          id="price"
                          placeholder="Enter Price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="description">Description</label>
                        <textarea
                          name="description"
                          className="form-control"
                          id="description"
                          placeholder="Enter Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        >
                          {description}
                        </textarea>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <div className="col-md-6 row">
                        <div className="form-check">
                          <input
                            style={{ marginRight: "5px" }}
                            type="checkbox"
                            className="form-check-input"
                            id="status"
                            checked={status}
                            value={status}
                            onChange={(e) => setStatus(!status)}
                          />
                          <label className="form-check-label" htmlFor="status">
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditSubscription;
