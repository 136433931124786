import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getNewsList } from "./../../actions/newsActions";
import Loader from "../Loader";

const HomeNews = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsList());
  }, [dispatch]);

  const { news, nloading } = useSelector((state) => state.allNewsList);

  var latestNews = [];
  if (news.length > 0) {
    latestNews = news.slice(-2);
  }

  return (
    <>
      {latestNews !== undefined && latestNews.length > 0 && (
        <section className="news-sec">
          <div className="container">
            <div className="section-head">
              {/* <h6>Sub Heading</h6> */}
              <h1>{props.heading}</h1>
              <p>{props.subheading}</p>
            </div>
            <div className="row">
              {nloading ? (
                <Loader />
              ) : (
                <>
                  {latestNews !== undefined && latestNews.length > 0
                    ? latestNews.map((item, key) => {
                        const indexId = key + 1;
                        return (
                          <div key={indexId} className="col-sm-4">
                            <div className="top-blog">
                              <h4>
                                by admin <span>-</span>{" "}
                                {moment(item.createdAt).format("DD MMM, YYYY")}
                              </h4>
                              <h1>{item.title}</h1>
                              <Link to={`/news/${item._id}`} className="redmor">
                                Read More
                              </Link>
                            </div>
                            <div
                              className="bottom-blog"
                              style={{
                                backgroundImage: `url("${process.env.REACT_APP_PUBLIC_URL}/assets/img/news/${item.logo}")`,
                              }}
                            ></div>
                          </div>
                        );
                      })
                    : ""}
                </>
              )}
            </div>
            <div className="text-center" style={{ marginTop: "20px" }}>
              <Link className="foot-btn" to="/news">
                Load More
              </Link>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default HomeNews;
