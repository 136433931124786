import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { deleteSavedExamSubjects, getAllSavedExamSubjects, saveExamSubjects } from "../../../actions/admin/examSubjectAction";
import { getCourseSubjects } from "../../../actions/subjectActions";
import Loader from "../../Loader";
import makeAnimated from "react-select/animated";
import Select from "react-select";


const AdminRegisteredSubjects = ({ history }) => {
    const sloading = false;
    const dispatch = useDispatch()
    const { savedAllSubjects ,csloading} = useSelector((state) => state.examSubjects);
    const animatedComponents = makeAnimated();

    const { savedSubjects } = useSelector((state) => state.examSubjects);
    const { isSaved } = useSelector((state) => state.courseSubjects);
    const { courseSubjects } = useSelector((state) => state.courseSubjects);
    const [subjects, setSubjectsList] = useState([]);

    // const userId = user._id;


    const [proceedSubjects, setProceedSubjects] = useState([]);

    useEffect(() => {
        dispatch(getAllSavedExamSubjects())
    }, [dispatch])

    // console.log("savedAll", savedAllSubjects);

    const handleCheck = () => {

    }

    const handleUpdate = (item) => {
        dispatch(getCourseSubjects(item?.course?._id, item?.exam?._id));
        console.log(item.exam);
        localStorage.setItem("maxSubjects",item?.exam?.maximumSubjects);
        localStorage.setItem("exam", item?.exam?._id)
        localStorage.setItem("course", item?.course?._id)
        localStorage.setItem("subscriptionPlan", item?.subscriptionPlan?._id)
        localStorage.setItem("user", item?.user?._id)
        localStorage.setItem("category", item?.category?._id)
        history.push(`/admin/student-registered-subjects/update`)
    }

    const handleDelete = (item) => {

        dispatch(deleteSavedExamSubjects(item._id))
        dispatch(getAllSavedExamSubjects())


    }

    const subjectsList = ["Math", "Science", "English", "History"]; // Example list of subjects
    const [isModalOpen, setModalOpen] = useState(true);
    const [selectedSubject, setSelectedSubject] = useState('');

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleUpdateSubject = () => {
        // Implement your update logic here
        console.log(`Updating subject: ${selectedSubject}`);
        handleCloseModal();
    };

    useEffect(() => {
        if (courseSubjects !== undefined && courseSubjects?.data?.length > 0) {
            const tmpArr = [];
            courseSubjects?.data?.map((course) => {
                tmpArr?.push({ label: course?.title, value: course?._id })
            })
            setSubjectsList(tmpArr)
        }
    }, [courseSubjects])


    return (
        <Fragment>
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Manage
                        <small>Student Registered Subjects</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/admin">
                                <i className="fa fa-dashboard"></i> Dashboard
                            </Link>
                        </li>
                        <li className="active">Student Registered Subjects</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">Student Registered Subjects</h3>
                                    <div className="box-tools"></div>
                                </div>
                                <div className="box-body table-responsive no-padding">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Exam Type</th>
                                                <th>Subscription Type</th>
                                                <th>Duration</th>
                                                <th>Price</th>
                                                <th>Subscribed At</th>
                                                <th>Expiration</th>
                                                <th>Status</th>
                                                <th>Registered Subjects</th>
                                                <th>Processed Subjects</th>
                                                <th>Update Request Subjects</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {csloading ? (
                                                <Loader
                                                   
                                                />
                                            ) : savedAllSubjects !== undefined &&
                                                savedAllSubjects?.length ? (
                                                savedAllSubjects?.map((item) => (
                                                    <tr key={item._id}>
                                                        <td>
                                                            {item?.user?.first_name} {item?.user?.last_name}
                                                        </td>
                                                        <td>{item?.exam?.title}</td>
                                                        <td>{item?.subscriptionPlan?.planName}{" "}{item?.subscriptionPlan?.subscriptionType}</td>
                                                        <td>{item?.subscriptionPlan?.duration}</td>
                                                        <td>₦{item?.subscriptionPlan?.price}</td>
                                                        <td>
                                                            {Moment(item?.subscriptionPlan?.subscribedAt).format("YYYY-MM-DD")}
                                                        </td>
                                                        <td>
                                                            {Moment(item?.subscriptionPlan?.expireAt).format("YYYY-MM-DD")}
                                                        </td>

                                                        <td>
                                                            {item?.status === true && (
                                                                <span className="badge bg-green">Active</span>
                                                            )}
                                                            {item?.subscriptionPlan?.status === false && (
                                                                <span className="badge bg-yellow">
                                                                    InActive
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <ul className="subject-list">
                                                                {item.subjects.length > 0 &&
                                                                    item.subjects.map((subject, iKey) => {
                                                                        return (
                                                                            <li id={iKey} key={iKey}>
                                                                                
                                                                                <label htmlFor={`subject-${subject?.subject?.id}`}>{iKey + 1}) {subject?.subject?.title} </label>
                                                                            </li>
                                                                        );
                                                                    })}
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul className="subject-list">
                                                                {item.subjectsProcessed.length > 0 &&
                                                                    item.subjectsProcessed.map((subject, iKey) => {
                                                                        return (
                                                                            <li id={iKey} key={iKey}>
                                                                               
                                                                                <label htmlFor={`subject-${subject.id}`}>{iKey + 1}) {subject.title} <i className="fa fa-check" style={{ color: "green" }} /> </label>
                                                                            </li>
                                                                        );
                                                                    })}
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul className="subject-list">
                                                                {item && item?.subjectsUpdate && item?.subjectsUpdate?.length > 0 ?
                                                                    item?.subjectsUpdate?.map((subject, iKey) => {
                                                                        return (
                                                                            <li id={iKey}>
                                                                               
                                                                                <label htmlFor={`subject-${subject.id}`}>{iKey + 1}) {subject.title}  </label>
                                                                            </li>
                                                                        );
                                                                    }) : "No Update Request"}
                                                            </ul>
                                                        </td>

                                                        <td>
                                                            <>

                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() => handleUpdate(item)
                                                                    }

                                                                >Update

                                                                </button>

                                                                <>
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-danger btn-sm"
                                                                        style={{ marginTop: "10px" }}
                                                                        onClick={() => handleDelete(item)
                                                                        }

                                                                    >Delete

                                                                    </button>
                                                                </>
                                                            </>

                                                        </td>

                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="5" style={{ textAlign: "center" }}>
                                                        No records found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
            </div>
        </Fragment>
    )
}

export default AdminRegisteredSubjects