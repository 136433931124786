import {
  LOAD_ALL_NEWS_REQUEST,
  LOAD_ALL_NEWS_SUCCESS,
  LOAD_ALL_NEWS_FAIL,
  ADD_NEWS_REQUEST,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_RESET,
  ADD_NEWS_FAIL,
  NEWS_DETAIL_REQUEST,
  NEWS_DETAIL_SUCCESS,
  NEWS_DETAIL_FAIL,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_RESET,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  CLEAR_ERRORS,
} from "./../constants/adminNewsConstants";

export const newsListReducer = (state = { news: [] }, action) => {
  switch (action.type) {
    case LOAD_ALL_NEWS_REQUEST:
      return {
        nloading: true,
        news: [],
      };

    case LOAD_ALL_NEWS_SUCCESS:
      return {
        nloading: false,
        news: action.payload.data,
      };

    case LOAD_ALL_NEWS_FAIL:
      return {
        nloading: false,
        error: action.payload,
      };

    case UPDATE_NEWS_REQUEST:
      return {
        ...state,
        nloading: true,
        errors: null,
      };

    case UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        nloading: false,
        isUpdated: action.payload,
      };

    case UPDATE_NEWS_RESET:
      return {
        ...state,
        isUpdated: false,
        errors: null,
      };

    case UPDATE_NEWS_FAIL:
      return {
        ...state,
        nloading: false,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newsDetailReducer = (state = { news: {} }, action) => {
  switch (action.type) {
    case NEWS_DETAIL_REQUEST:
      return {
        ...state,
        nloading: true,
      };

    case NEWS_DETAIL_SUCCESS:
      return {
        nloading: true,
        news: action.payload,
      };

    case NEWS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_NEWS_REQUEST:
      return {
        nloading: true,
        isNewsAdded: false,
        errors: null,
      };

    case ADD_NEWS_SUCCESS:
      return {
        ...state,
        nloading: false,
        isNewsAdded: true,
        news: action.payload,
        errors: null,
      };

    case ADD_NEWS_RESET:
      return {
        ...state,
        isNewsAdded: false,
        errors: null,
      };

    case ADD_NEWS_FAIL:
      return {
        ...state,
        nloading: false,
        isNewsAdded: false,
        news: null,
        error: action.payload,
        errors: action.errors,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_NEWS_REQUEST:
      return {
        nloading: true,
      };

    case DELETE_NEWS_SUCCESS:
      return state.filter((item) => String(item.id) !== String(action.payload));

    case DELETE_NEWS_FAIL:
      return {
        nloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
