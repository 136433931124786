import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";

import { getSingleNews } from "./../../actions/newsActions";
import Loader from "../Loader";

const Exams = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.auth);

  const currentId = match.params.id;
  useEffect(() => {
    dispatch(getSingleNews(currentId));
  }, [dispatch, alert, isAuthenticated, user]);

  const { newsDetail, nloading } = useSelector((state) => state.singleNews);
  console.log("newsDetail ", newsDetail);

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log("userSubscription ", userSubscription);
  };

  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <ul className="clearfix paginations-cust">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/news">News</Link>
            </li>
          </ul>
          <h1>News Details</h1>
        </div>
      </section>

      <section className="news-page-sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="news-image">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/img/news/" +
                    newsDetail.logo
                  }
                  alt="news-image"
                />
              </div>
              <div className="top-blog">
                <h4>
                  by admin <span>-</span> {newsDetail.createdAt}
                </h4>
                <h1>{newsDetail.title}</h1>
              </div>
              <div className="news-content">
                <p>{newsDetail.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Exams;
