export const LOAD_ALL_CMS_REQUEST = 'LOAD_ALL_CMS_REQUEST';
export const LOAD_ALL_CMS_SUCCESS = 'LOAD_ALL_CMS_SUCCESS';
export const LOAD_ALL_CMS_FAIL = 'LOAD_ALL_CMS_FAIL';

export const CMS_DETAIL_REQUEST = 'CMS_DETAIL_REQUEST';
export const CMS_DETAIL_SUCCESS = 'CMS_DETAIL_SUCCESS';
export const CMS_DETAIL_FAIL = 'CMS_DETAIL_FAIL';

export const UPDATE_CMS_REQUEST = 'UPDATE_CMS_REQUEST';
export const UPDATE_CMS_SUCCESS = 'UPDATE_CMS_SUCCESS';
export const UPDATE_CMS_RESET = 'UPDATE_CMS_RESET';
export const UPDATE_CMS_FAIL = 'UPDATE_CMS_FAIL';


export const LOAD_CMS_PAGE_REQUEST = 'LOAD_CMS_PAGE_REQUEST';
export const LOAD_CMS_PAGE_SUCCESS = 'LOAD_CMS_PAGE_SUCCESS';
export const LOAD_CMS_PAGE_FAIL = 'LOAD_CMS_PAGE_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';