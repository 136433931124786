import axios from "axios";
import {
    LOAD_ALL_THEORY_REQUEST,
    LOAD_ALL_THEORY_SUCCESS,
    LOAD_ALL_THEORY_FAIL,
    ADD_THEORY_REQUEST,
    ADD_THEORY_SUCCESS,
    ADD_THEORY_RESET,
    ADD_THEORY_FAIL,
    THEORY_DETAIL_REQUEST,
    THEORY_DETAIL_SUCCESS,
    THEORY_DETAIL_FAIL,
    UPDATE_THEORY_REQUEST,
    UPDATE_THEORY_SUCCESS,
    UPDATE_THEORY_RESET,
    UPDATE_THEORY_FAIL,
    DELETE_THEORY_REQUEST,
    DELETE_THEORY_SUCCESS,
    DELETE_THEORY_FAIL,

    GET_THEORY_EXAM_REQUEST,
    GET_THEORY_EXAM_SUCCESS,
    GET_THEORY_EXAM_FAIL,

    CLEAR_ERRORS,
} from "../constants/adminTheoryConstants";

const _api_path = "/api/v1";

//get all theory
export const getTheories = (subjectId, yearId, sectionId) => async (dispatch) => {
    try {
        dispatch({
            type: LOAD_ALL_THEORY_REQUEST,
        });

        const { data } = await axios.get(`${_api_path}/admin/theories/${subjectId}/${yearId}/${sectionId}`);
        // console.log("data", data);
        // return;
        dispatch({
            type: LOAD_ALL_THEORY_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LOAD_ALL_THEORY_FAIL,
            payload: error,
        });
    }
};

//get theory detail
export const getTheoryDetail = (subjectId, yearId, id) => async (dispatch) => {
    try {
        dispatch({
            type: THEORY_DETAIL_REQUEST,
        });

        const { data } = await axios.get(`/api/v1/admin/theory/${subjectId}/${yearId}/${id}`);
        // console.log('data ', data)
        dispatch({
            type: THEORY_DETAIL_SUCCESS,
            payload: data.data,
        });
    } catch (error) {
        dispatch({
            type: THEORY_DETAIL_FAIL,
            payload: error.response.data.errMessage,
        });
    }
};

// add theory
export const addTheory = (subjectId,
                          yearId,
                          sectionId,
                          title,
                          question,
                          description,
                          detailed_answer,
                          status,
                          logo,
                          imageName) =>
    async (dispatch) => {
        try {
            dispatch({
                type: ADD_THEORY_REQUEST,
            });
            const config = {
                headers: {
                    //"content-type": "multipart/form-data",
                    // "Accept": "application/json",
                    "content-type": "application/json",
                },
            };
            const { data } = await axios.post(
                _api_path + "/admin/theories/add",
                {
                    subjectId,
                    yearId,
                    sectionId,
                    title,
                    question,
                    description,
                    detailed_answer,
                    status,
                    logo,
                    imageName
                },
                config
            );
            // console.log("data ", data);
            dispatch({
                type: ADD_THEORY_SUCCESS,
                payload: data.user,
            });
        } catch (error) {
            dispatch({
                type: ADD_THEORY_FAIL,
                payload: error.response.data.errMessage,
                errors: error.response.data,
            });
        }
    };

// Update theory
export const updateTheory = (
    theoryId,
    title,
    question,
    description,
    detailed_answer,
    status,
    oldImage,
    logo,
    imageName
) =>
    async (dispatch) => {
        // console.log('status ', status);
        try {
            dispatch({
                type: UPDATE_THEORY_REQUEST,
            });

            const config = {
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // }
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const { data } = await axios.put(
                _api_path + `/admin/theories/update/${theoryId}`,
                {
                    theoryId,
                    title,
                    question,
                    description,
                    detailed_answer,
                    status,
                    oldImage,
                    logo,
                    imageName
                },
                config
            );

            dispatch({
                type: UPDATE_THEORY_SUCCESS,
                payload: data.success,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_THEORY_FAIL,
                payload: error.response.data.errMessage,
                errors: error.response.data,
            });
        }
    };

// Delete theory
export const deleteTheoryData = (theoryId) => async (dispatch) => {
    //console.log('userData ', userData);
    try {
        dispatch({
            type: DELETE_THEORY_REQUEST,
        });

        const { data } = await axios.delete(
            _api_path + `/admin/theories/${theoryId}`
        );

        dispatch({
            type: DELETE_THEORY_SUCCESS,
            payload: theoryId,
        });
    } catch (error) {
        dispatch({
            type: DELETE_THEORY_FAIL,
            payload: error,
        });
    }
};

//get user exam theories
export const getUserTheories = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_THEORY_EXAM_REQUEST,
        });

        const { data } = await axios.get(`${_api_path}/admin/user-theories`);

        dispatch({
            type: GET_THEORY_EXAM_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_THEORY_EXAM_FAIL,
            payload: error,
        });
    }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};
