import axios from "axios";
import {
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  CLEAR_ERRORS,
} from "../constants/userConstants";
const _api_path = "/api/v1";

// Update user profile
export const updateProfile = (
  first_name,
  last_name,
  mobile_code,
  mobile_number,
  email,
  dob,
  gender,
  nationality
) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: UPDATE_PROFILE_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/me/update`,
      {
        first_name,
        last_name,
        mobile_code,
        mobile_number,
        email,
        dob,
        gender,
        nationality,
      },
      config
    );

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

// Update user password
export const updatePassword = (
  oldPassword,
  newPassword,
  confirmPassword
) => async (dispatch) => {
  //console.log('userData ', userData);
  try {
    dispatch({
      type: UPDATE_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/password/update`,
      { oldPassword, newPassword, confirmPassword },
      config
    );

    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.errMessage,
      errors: error.response.data,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
