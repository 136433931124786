import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
    getTestimonialDetail,
    updateTestimonial,
    clearErrors,
} from "../../../actions/adminTestimonialActions";
import { UPDATE_TESTIMONIAL_RESET } from "./../../../constants/adminTestimonialConstants";

import Loader from "../../Loader";

import "./Testimonial.css";

const EditTestimonial = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [testimonialId, setTestimonialId] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(false);
    const [logo, setLogo] = useState("");
    const [imageName, setImageName] = useState("");
    const [oldImage, setOldImage] = useState("");
    const [imagePreview, setImagePreview] = useState("");

    const [errorData, setErrorData] = useState({});

    const { isUpdated, aloading, error, errors } = useSelector(
        (state) => state.testimonialList
    );

    const currentItemId = match.params.id;
    useEffect(() => {
        dispatch(getTestimonialDetail(currentItemId));
    }, [dispatch]);

    const { testimonial } = useSelector((state) => state.testimonialDetail);

    useEffect(() => {
        if (errors) {
            setErrorData(errors);
        }

        if (testimonial) {
            setTestimonialId(testimonial._id);
            setName(testimonial.name);
            setDescription(testimonial.description);
            setStatus(testimonial.status);
            setLogo(testimonial.image);
            setOldImage(testimonial.image);
            setImagePreview(process.env.REACT_APP_PUBLIC_URL + "/assets/img/testimonial/" + testimonial.image);
        }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (isUpdated) {
            alert.success("Testimonial updated successfully");
            dispatch(getTestimonialDetail(testimonial._id));
            history.push(`/admin/testimonials`);
            dispatch({
                type: UPDATE_TESTIMONIAL_RESET,
            });
        }
    }, [dispatch, testimonial, alert, error, errors, history, isUpdated]);

    const onChange = (e) => {
        let file = e.target.files[0];

        if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setLogo("");
            setImageName("");
            alert.error("Please select valid image.");
            //remove preview picture if any
            const preview = document.getElementById("img-preview");
            preview.src = "";
            return false;
        }

        const reader = new FileReader();

        reader.onload = function (readerEvt) {
            // The file's text will be printed here
            //console.log(readerEvt.target.result);
            let binaryString = readerEvt.target.result;
            let base64String = btoa(binaryString);
            setLogo(base64String);
            setImageName(file.name);

            //preview picture
            const preview = document.getElementById("img-preview");
            // console.log('banary image', base64String);
            preview.src = "data:image/png;base64," + base64String;
        };
        reader.readAsBinaryString(file);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            updateTestimonial(
                testimonialId,
                name,
                description,
                status,
                oldImage,
                logo,
                imageName
            )
        );
    };

    return (
        <Fragment>
            {aloading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Edit
                                <small>Testimonial</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard"></i> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/testimonials">
                                        <i className="fa fa-table"></i> Testimonial
                                    </Link>
                                </li>
                                <li className="active">Edit Testimonial</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Edit Testimonial</h3>

                                    {/*<div className="box-tools pull-right">

                                    </div>*/}
                                </div>

                                <form id="quickForm" onSubmit={submitHandler}>
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Enter the name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                                {errorData.name ? (
                                                    <span className="text-danger">{errorData.name}</span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="image">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="image"
                                                    name="image"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                />
                                                {errorData.image ? (
                                                    <span className="text-danger">{errorData.image}</span>
                                                ) : (
                                                    ""
                                                )}
                                                <div>
                                                    <img id="img-preview" src={imagePreview} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="description">Decription</label>
                                                <textarea
                                                    type="text"
                                                    name="description"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter the news decription"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                ></textarea>
                                                {errorData.description ? (
                                                    <span className="text-danger">{errorData.description}</span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="status">Status </label>
                                                <input
                                                    style={{ marginRight: "5px" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    checked={status}
                                                    value={status}
                                                    onChange={(e) => setStatus(!status)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default EditTestimonial;
