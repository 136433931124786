import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../actions/commonActions";
import { addCourse, clearErrors } from "../../../actions/adminCourseActions";
import { ADD_COURSE_RESET } from "./../../../constants/adminCourseConstants";

import Loader from "../../Loader";

import "./Course.css";

const AddCourse = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [categoriesData, setCategoriesData] = useState([]);

  const [course, setCourse] = useState({
    category: "",
    title: "",
    status: false,
  });
  const [errorData, setErrorData] = useState({});

  const { category, title, status } = course;

  const { isCourseAdded, user_loading, error, errors } = useSelector(
    (state) => state.courseDetail
  );

  useEffect(() => {
    dispatch(getAllCategories());

    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isCourseAdded === true && isCourseAdded != undefined) {
      alert.success("Course successfully added.");
      history.push("/admin/courses");
      dispatch({
        type: ADD_COURSE_RESET,
      });
    }
  }, [dispatch, isCourseAdded, alert, error, errors]);

  const { allCategories, loading } = useSelector(
    (state) => state.commonCategories
  );
  //console.log("allCategories ", allCategories);
  const onChange = (e) => {
    if (e.target.name === "status") {
      setCourse({
        ...course,
        status: !status,
      });
    } else {
      setCourse({
        ...course,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addCourse(category, title, status));
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Add
                <small>Course</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/courses">
                    <i className="fa fa-users"></i> Courses
                  </Link>
                </li>
                <li className="active">Add Course</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Course</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="category">Category</label>
                        <select
                          className="form-control"
                          name="category"
                          value={category}
                          onChange={onChange}
                        >
                          <option value="">Select</option>
                          {loading ? (
                            <Loader />
                          ) : (
                            <Fragment>
                              {allCategories.length > 0
                                ? allCategories.map((e, key) => {
                                    return (
                                      <option key={key} value={e._id}>
                                        {e.title}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Fragment>
                          )}
                        </select>
                        {errorData.category ? (
                          <span className="text-danger">
                            {errorData.category}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={onChange}
                        />
                        {errorData.title ? (
                          <span className="text-danger">{errorData.title}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status</label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          name="status"
                          checked={status}
                          value={status}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddCourse;
