import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import swal from "sweetalert";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import Loader from "../Loader";
import { login, clearErrors } from "./../../actions/authActions";
import { logoutUser } from "./../../actions/authActions";
import AuthCommonBanner from "./AuthCommonBanner";

// form validation rules
const validationSchema = yup.object().shape({
  email: yup.string().required("Email is required").email("Email is invalid"),
  password: yup.string().required("Password is required"),
});

const Login = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [errorData, setErrorData] = useState({});

  const { user, loading, isAuthenticated, error, loginErrors } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (loginErrors) {
      setErrorData(loginErrors);
      // console.log("loginErrors ", loginErrors); return;
    }

    if (isAuthenticated === true && isAuthenticated !== undefined) {
      if (user.role === "admin") {
        history.push("/admin");
        return;
      }
      if (user.accountStatus === "InActive") {
        swal(
          "Login Error",
          "Your account is not yet activated, please activate it from your email account or contact adminstrator.",
          "error"
        );
        logoutHandler();
        return;
      }
      if (user.accountStatus === "Blocked") {
        swal("Login Error", "Your account is blocked.", "error");
        logoutHandler();
        return;
      }
      if (user.accountStatus === "Suspended") {
        swal("Login Error", "Your account is suspended.", "error");
        logoutHandler();
        return;
      }
      history.push("/dashboard");
    } else {
      if (
        error &&
        isAuthenticated === false &&
        error !== "Unauthorised access"
      ) {
        clearErrors();
        return swal("Login Error", error, "error");
      }
    }
    // rns@gmail.com
  }, [dispatch, user, isAuthenticated, alert, error, history, loginErrors]);

  const logoutHandler = () => {
    dispatch(logoutUser());
  };

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const submitForm = (data, e) => {
    // alert.info(isAuthenticated);
    e.preventDefault();
    dispatch(login(data.email, data.password));
    /*if (error) {
          clearErrors();
          return swal("Login Error", error, "error");
        }*/
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <section className="registration-page clearfix fullHt">
            <div className="right-regi-step login-page">
              <div className="vh-center">
                <h1>
                  User <span>Login</span>
                </h1>
                <p className="pull-right">
                  <span className="sub">*</span> is mandatory fields
                </p>
                <div className="inner-que">
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email">
                            Email<span className="sub">*</span>
                          </label>
                          <input
                            ref={register}
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            id="email"
                            name="email"
                            tabIndex="1"
                          />
                          <div className="invalid-feedback">
                            {errors.email?.message}
                          </div>
                          {errorData.email ? (
                            <span className="text-danger">
                              {errorData.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="password">
                            Password<span className="sub">*</span>
                          </label>
                          <label className="pull-right dont-have-ac">
                            <Link to="/forgot-password">Forgot?</Link>
                          </label>
                          <input
                            ref={register}
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            tabIndex="2"
                          />
                          <div className="invalid-feedback">
                            {errors.password?.message}
                          </div>
                          {errorData.password ? (
                            <span className="text-danger">
                              {errorData.password}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <ul className="log-btn-main clearfix">
                      <li className="pull-left dont-have-ac">
                        Don't have an account?{" "}
                        <Link to="/register">Sign Up</Link>
                      </li>
                      <li className="pull-right">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          tabIndex="3"
                        >
                          Login
                        </button>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <AuthCommonBanner></AuthCommonBanner>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Login;
