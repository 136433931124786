import {
  GET_EXAM_SUBSCRIPTIONS_REQUEST,
  GET_EXAM_SUBSCRIPTIONS_SUCCESS,
  GET_EXAM_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  USER_SUBSCRIPTION_REQUEST,
  USER_SUBSCRIPTION_SUCCESS,
  USER_SUBSCRIPTION_FAIL,
  PLAN_SUBSCRIPTION_REQUEST,
  PLAN_SUBSCRIPTION_SUCCESS,
  PLAN_SUBSCRIPTION_FAIL,
  USER_SUBSCRIPTION_LIST_REQUEST,
  USER_SUBSCRIPTION_LIST_SUCCESS,
  USER_SUBSCRIPTION_LIST_FAIL,
  CLEAR_ERRORS,
} from "../constants/subscriptionConstants";

export const examSubscriptionsReducer = (
  state = {
    examSubscriptions: [],
    userSubscription: null,
    userSubscriptionList: [],
    subscriptionInfo: {},
    isPlan: false,
  },
  action
) => {
  switch (action.type) {
    case GET_EXAM_SUBSCRIPTIONS_REQUEST:
      return {
        sloading: true,
        isPlan: false,
        examSubscriptions: [],
      };

    case GET_EXAM_SUBSCRIPTIONS_SUCCESS:
      return {
        sloading: false,
        isPlan: true,
        examSubscriptions: action.payload,
      };

    case GET_EXAM_SUBSCRIPTIONS_FAIL:
      return {
        sloading: false,
        isPlan: false,
        error: action.payload,
      };

    case GET_SUBSCRIPTION_REQUEST:
      return {
        sloading: true,
        subscription: [],
      };

    case GET_SUBSCRIPTION_SUCCESS:
      return {
        sloading: false,
        subscription: action.payload,
      };

    case GET_SUBSCRIPTION_FAIL:
      return {
        sloading: false,
        error: action.payload,
      };
    case USER_SUBSCRIPTION_REQUEST:
      return {
        sloading: true,
        isPlan: false,

        userSubscription: null,
      };

    case USER_SUBSCRIPTION_SUCCESS:
      return {
        sloading: false,
        isPlan: true,

        userSubscription: action.payload.userSubscription,
      };

    case USER_SUBSCRIPTION_FAIL:
      return {
        sloading: false,
        isPlan: false,

        userSubscription: null,

        error: action.payload,
      };

    case PLAN_SUBSCRIPTION_REQUEST:
      return {
        sloading: true,
        isPlanSubscribed: false,
      };

    case PLAN_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        sloading: false,
        isPlanSubscribed: true,
        userSubscription: action.payload,
      };

    case PLAN_SUBSCRIPTION_FAIL:
      return {
        ...state,
        sloading: false,
        isPlanSubscribed: false,
        error: action.payload,
      };

    case USER_SUBSCRIPTION_LIST_REQUEST:
      return {
        sloading: true,
        userSubscriptionList: null,
      };

    case USER_SUBSCRIPTION_LIST_SUCCESS:
      return {
        sloading: false,
        userSubscriptionList: action.payload.userSubscription,
      };

    case USER_SUBSCRIPTION_LIST_FAIL:
      return {
        sloading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
