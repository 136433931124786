import React, { Fragment } from 'react';

const Footer = () => {
    return (
        <Fragment>
            <footer className="main-footer">
                {/*<div className="pull-right hidden-xs">
                    <b>Version</b> 2.4.13
                </div>*/}
                <strong>Copyright &copy; 2020-2021 <a href="http://otsdev.katdev.com">OTS</a>.</strong> All rights
                reserved.
            </footer>

        </Fragment>
    );
}

export default Footer;