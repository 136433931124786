import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import ReactHtmlParser from 'react-html-parser';

import Loader from "../Loader";

import { getResultDataView } from "./../../actions/resultActions";

import "./result.css";

const ResultView = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.auth);

  const userId = user._id;
  const quizId = match.params.quizId;
  useEffect(() => {
    dispatch(getResultDataView(quizId));
  }, [dispatch, alert]);

  const { rLoading, result } = useSelector((state) => state.results);
  // console.log("result ", result);

  useEffect(() => { }, [result, rLoading]);
  const [currentSubject, setCurrentSubject] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);

  const handleSubjectType = (e) => {
    e.preventDefault();
    const currentIndex = parseInt(e.target.dataset.id);
    setCurrentSubject(currentIndex);
    setCurrentQuestion(0);
    if (
      result !== undefined &&
      result.subjectResults !== undefined &&
      result.subjectResults.length > 0 &&
      result.subjectResults[currentIndex].results !== undefined &&
      result.subjectResults[currentIndex].results.length > 0
    ) {
      setNumberOfQuestions(result.subjectResults[currentIndex].results.length);
    }
  };

  const handleQuestionTick = (e) => {
    e.preventDefault();
    const currentQue = e.target.dataset.id;
    // alert.info(currentQue);
    setCurrentQuestion(currentQue);
  };


  return (
    <Fragment>
      {rLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <section className="auth-dash-sec fullHt">
            <div className="left-navigations">
              <div className="user-exam">
                <div className="user-pros-exam">
                  <img
                    src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"}
                    alt="user"
                  />
                </div>
                <h4>
                  <b>Name</b>: {user.first_name} {user.last_name}
                </h4>
                <h4>
                  <i className="fas fa-calculator"></i> : Study
                </h4>
              </div>
              <div className="bot-examers">
                <h2>Subjects</h2>
                <div className="subject-tab">
                  <ul className="nav nav-tabs" role="tablist">
                    {result && result !== undefined &&
                      result.subjectResults !== undefined &&
                      result.subjectResults.length > 0 &&
                      result.subjectResults.map((subItem, i) => {
                        return (
                          <Fragment key={i}>
                            <li role="presentation" className="active">
                              <button
                                type="submit"
                                data-id={i}
                                onClick={handleSubjectType}
                              >
                                {subItem.subject.title}
                              </button>
                            </li>
                          </Fragment>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="right-auth-landing edit-pros">
              <div className="main-view-content">
                <div className="all-ourt-style w-80-cust clearfix">
                  <div className="sel-exam-quest">
                    <h4>Questions</h4>
                    <div className="quet-lister">
                      {result !== undefined &&
                        result.subjectResults !== undefined &&
                        result.subjectResults.length > 0 &&
                        result.subjectResults[currentSubject].results !==
                        undefined &&
                        result.subjectResults[currentSubject].results.length >
                        0 &&
                        [
                          ...Array(
                            result.subjectResults[currentSubject].results.length
                          ),
                        ].map((e, i) => {
                          let className = "";
                          if (currentQuestion === i) {
                            className = "isActiveQuestion active";
                          }
                          return (
                            <Fragment key={i}>
                              <a
                                className={className}
                                href="#"
                                data-id={i}
                                onClick={handleQuestionTick}
                              >
                                {i + 1}
                              </a>{" "}
                            </Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {result !== undefined &&
                  result.subjectResults !== undefined &&
                  result.subjectResults.length > 0 &&
                  result.subjectResults[currentSubject].results !== undefined &&
                  result.subjectResults[currentSubject].results.length > 0 && (
                    <Fragment>
                      <div className="all-ourt-style w-80-cust work-info exam-set">
                        <div className="all-heads clearfix">
                          <h2>
                            {
                              
                              ReactHtmlParser(result.subjectResults[currentSubject].results[
                                currentQuestion
                              ]?.title)
                            }
                          </h2>
                          <h2>
                            {
                             
                              ReactHtmlParser(result.subjectResults[currentSubject].results[
                                currentQuestion
                              ].question)
                            }
                          </h2>
                        </div>

                        {
                         
                          result.subjectResults[currentSubject].results[
                            currentQuestion
                          ].logo !== undefined
                          && result.subjectResults[currentSubject].results[
                            currentQuestion
                          ].logo !== null
                          && result.subjectResults[currentSubject].results[
                            currentQuestion
                          ].logo !== ""
                          && (
                            <Fragment>
                              <img
                                src={
                                  process.env.REACT_APP_PUBLIC_URL +
                                  "/assets/img/questions/" +
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].logo
                                }
                                alt="question-image"
                                style={{ height: "200px" }}
                              />
                            </Fragment>

                          )}

                        <div className="lister-opt-ans">
                          <div className="row">
                           
                            <div className="col-sm-12">
                              <h3>
                                <span>Answered</span>:
                                {ReactHtmlParser(result !== undefined &&
                                  result.subjectResults !== undefined &&
                                  result.subjectResults.length > 0 &&
                                  result.subjectResults[currentSubject]
                                    .results !== undefined &&
                                  result.subjectResults[currentSubject].results
                                    .length > 0 &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].resultData !== undefined &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].resultData?.answered?.option)}
                              </h3>
                            </div>
                            <div className="col-sm-12">
                              <h3>
                                <span>Correct Answer</span>:
                                {ReactHtmlParser(result !== undefined &&
                                  result.subjectResults !== undefined &&
                                  result.subjectResults.length > 0 &&
                                  result.subjectResults[currentSubject]
                                    .results !== undefined &&
                                  result.subjectResults[currentSubject].results
                                    .length > 0 &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].resultData !== undefined &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].resultData?.correctAnswer?.option)}
                              </h3>
                            </div>
                            <div className="col-sm-12">
                              <h3>
                                <span>Description</span>:
                                {
                                  ReactHtmlParser(result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].description)}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}

                {/*<div className="all-ourt-style w-80-cust clearfix">
              <div className="sel-exam-quest">
                <h4>Mark And Grade</h4>
                <div className="mark-of-grade">--</div>
              </div>
                          </div>*/}
                <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                  <div className="pull-left">
                    <Link
                      to={`/practice-results/${quizId}`}
                      className="btn view-ans-btn"
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ResultView;
