import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactHtmlParser from "react-html-parser"

import { useDispatch, useSelector } from "react-redux";
import {
  getQuestions,
  deleteQuestionData,
} from "../../../actions/adminQuestionActions";

import Loader from "../../Loader";
import { QUESTION_DETAIL_RESET } from "../../../constants/adminQuestionConstants";
import './QuestionStyles.css';
const ListQuestions = ({ history, match }) => {
  // const [users, setUsersData] = useState({});
  const alert = useAlert();
  const dispatch = useDispatch();
  const sectionId = match.params.sectionId;
  const subjectId = match.params.subjectId;
  const yearId = match.params.yearId;
  const topicId = match.params.topicId;
  const [update, setUpdate] = useState(false);
  const universityId = localStorage.getItem("universityId");
  const Id = universityId;
  const slug = localStorage.getItem("examSlug");
  const subjectName = localStorage.getItem("subjectName");



  // console.log("universityId", universityId);
  const subjectYear = localStorage.getItem("year");

  const handleDeleteQuestion = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteQuestionData(id));
            dispatch(getQuestions(subjectId, yearId, topicId, sectionId, Id));
            alert.success("Question deleted successfully");
            setUpdate(true);
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  useEffect(() => {
    dispatch({
      type: QUESTION_DETAIL_RESET,
    });
    // dispatch("LOAD_ALL_QUESTIONS_REQUEST")
    dispatch(getQuestions(subjectId, yearId, topicId, sectionId, Id));
  }, [dispatch, update]);

  const { questions, user_loading, } = useSelector((state) => state);
  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Questions</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to={`/admin/topicsection/${subjectId}/${yearId}`}>
                    <i className="fa fa-dashboard"></i> Topic Sections
                  </Link>
                </li>
                <li className="active">Questions [Exam:- {slug}, Subject:- {subjectName}- {subjectYear}]</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Questions</h3>
                      <div className="box-tools">
                        <Link
                          // to={`/admin/questions/add/${subjectId}/${yearId}/${sectionId}`}
                          to={`/admin/questions/add/${subjectId}/${yearId}/${sectionId}/${topicId}`}
                          className="btn btn-success btn-sm"
                        >
                          + Add Question
                        </Link>
                        {' '}
                        <Link
                          to={`/admin/questions/import/${sectionId}/${subjectId}/${yearId}/${topicId}`}
                          className="btn btn-primary btn-sm"
                        >
                          Import Questions
                        </Link>
                        {' '}
                        <a
                          href={process.env.REACT_APP_PUBLIC_URL + "/questions_import_sample.xlsx"}
                          download
                          className="btn btn-warning btn-sm"
                        >
                          Download Import Sample
                        </a>
                      </div>
                    </div>
                    <div className="box-body table-responsive no-padding">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Sr.</th>
                            <th>Question</th>
                            <th>Linked</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {questions?.user_loading ? (
                            <Loader />
                          ) : questions?.questions !== undefined &&
                            questions?.questions.length ? (
                            questions?.questions?.map((item, i) => {
                              // if (topicId ? item.topic === topicId : 1) {
                              return (
                                <tr key={item._id}>
                                  <td>{i + 1}</td>

                                  {/* <td>{item.question.replace(/<[^>]+>/g, "")}</td> */}
                                  <td className="queImage">{ReactHtmlParser(item.question)}</td>
                                  <td>{item.passageCheck ? `Yes - ${item.group}` : "No"}</td>

                                  <td>
                                    {item.status === true && (
                                      <span className="badge bg-green">
                                        Active
                                      </span>
                                    )}
                                    {item.status === false && (
                                      <span className="badge bg-yellow">
                                        InActive
                                      </span>
                                    )}
                                  </td>
                                  <td>

                                    <Link
                                      to={`/admin/questions/edit/${subjectId}/${yearId}/${item._id}/${topicId}`}
                                      className="btn btn-success btn-sm"
                                      title="Edit"
                                    >
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    &nbsp;
                                    <button

                                      className="btn btn-danger btn-sm"
                                      title="Delete"
                                      onClick={() =>
                                        handleDeleteQuestion(item._id)
                                      }
                                      key={item._id}
                                      role="button"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              )
                              // }
                            }
                            )
                          ) : (
                            <tr>
                              <td colSpan="2">No records found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListQuestions;
