import React from 'react'
import { Fragment } from 'react'
import Loader from '../../Loader'
import { ADD_UNIVERSITY_RESET } from '../../../constants/adminUniversityConstants'
import { addUniversity, clearErrors } from "../../../actions/adminUniversityAction"
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Link } from 'react-router-dom';

import { useAlert } from 'react-alert';
const AddUniversity = ({ history }) => {

    const alert = useAlert();
    const dispatch = useDispatch();

    const [university, setUniversity] = useState({
        title: '',
        logo: '',
        description: '',
        status: false,
        imageName: ''
    });
    const {
        title,
        description,
        status,
        logo,
        imageName
    } = university;

    const { isUniversityAdded, loading, errors, error } = useSelector((state) => state.university);
    const [errorData, setErrorData] = useState({});

    useEffect(
        () => {
            if (isUniversityAdded === true && isUniversityAdded != undefined) {
                alert.success('university successfully added.');
                history.push('/admin/university');
                dispatch({
                    type: ADD_UNIVERSITY_RESET
                });
            }
        },
        [dispatch, isUniversityAdded, alert, errors, error]
    );

    useEffect(() => {
        if (errors) {
            setErrorData(errors);
        }

        if (error) {
            clearErrors();
            return alert.error(error);
        }
    }, [])

    console.log("errors", error, errors);

    const onChange = (e) => {
        if (e.target.name === 'logo') {
            let file = e.target.files[0];

            if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                setUniversity({
                    ...university,
                    logo: '',
                    imageName: ''
                });
                alert.error('Please select valid logo.');
                const preview = document.getElementById('img-preview');
                preview.src = '';
                return false;
            }

            const reader = new FileReader();

            reader.onload = function (readerEvt) {
                let binaryString = readerEvt.target.result;
                let base64String = btoa(binaryString);
                setUniversity({
                    ...university,
                    logo: base64String,
                    imageName: file.name
                });

                const preview = document.getElementById('img-preview');
                preview.src = 'data:image/png;base64,' + base64String;
            };
            reader.readAsBinaryString(file);
        } else {
            setUniversity({
                ...university,
                [e.target.name]: e.target.value
            });
        }
    };


    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            addUniversity(
                title,
                description,
                status,
                logo,
                imageName
            )
        );
    };

    return (
        <Fragment>
            {false ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Add
                                <small>University</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard" /> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/university">
                                        <i className="fa fa-users" /> University
                                    </Link>
                                </li>
                                <li className="active">Add University</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add University</h3>
                                </div>

                                <form id="quickForm" onSubmit={submitHandler} encType="multipart/form-data">
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="title">Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Enter Title"
                                                    value={title}
                                                    onChange={onChange}
                                                    required
                                                />
                                                {errorData.section_name ? (
                                                    <span className="text-danger">
                                                        {errorData.section_name}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}

                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="title">Description</label>
                                                <input
                                                    type="text"
                                                    name="description"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter Description"
                                                    value={description}
                                                    onChange={onChange}
                                                    required
                                                />
                                            </div>

                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="logo">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="logo"
                                                    name="logo"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                    required
                                                />

                                                <div>
                                                    <img id="img-preview" src="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="status">Status</label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    value={status}
                                                    onChange={onChange}
                                                    required
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="InActive">In Active</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default AddUniversity