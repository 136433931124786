import axios from "axios";
import {
  GET_ALL_ETEMPLATES,
  GET_SINGLE_ETEMPLATE,
  UPDATE_ETEMPLATE_REQUEST,
  UPDATE_ETEMPLATE_SUCCESS,
  UPDATE_ETEMPLATE_FAILED,

  CLEAR_ERRORS,
} from "./../../constants/admin/emailTemplateConstants";

const _api_path = "/api/v1";
//get all customers
export const getEmailtemplates = () => async (dispatch) => {
  const { data } = await axios.get(_api_path + "/admin/emailtemplates");
  // console.log('data', data);
  dispatch({
    type: GET_ALL_ETEMPLATES,
    payload: data,
  });
};

// Update emailtemplate profile
export const getEmailTemplateDetail = (emailtemplateId) => async (dispatch) => {
  const { data } = await axios.get(
    _api_path + `/admin/emailtemplates/${emailtemplateId}`
  );

  dispatch({
    type: GET_SINGLE_ETEMPLATE,
    payload: data.emailtemplate,
  });
};

// Update emailtemplate profile
export const updateEmailtemplate = (
  templateId, subject, content, status
) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ETEMPLATE_REQUEST,
    });

    const config = {
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      _api_path + `/admin/emailtemplates/update/${templateId}`,
      {
        templateId, subject, content, status
      },
      config
    );

    dispatch({
      type: UPDATE_ETEMPLATE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    var errorMsg = error.response.data.errMessage;
    if (
      error.response.data.error !== undefined &&
      error.response.data.error.code === 11000
    ) {
      errorMsg = "Entered email already exist, please try another.";
    }
    dispatch({
      type: UPDATE_ETEMPLATE_FAILED,
      payload: errorMsg,
      errors: error.response.data,
    });
  }
};

//clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};